import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/main.css';
import './assets/styles/light.css';
import './assets/styles/animates.css';
import './assets/styles/print.css';
import MainRouter from './MainRouter';
import { extendDayJs } from './helpers/dayjs';
import 'swiper/css';

extendDayJs();

if(window.location.origin.includes('chronos.mg')){
  window.document.domain = 'chronos.mg';
} else {
  //window.document.domain = 'localhost';
}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  //<React.StrictMode>
    <MainRouter />
  //</React.StrictMode>
);
