import dayjs from "dayjs"
import styled from "styled-components"
import { INote } from "../../../store/Notes"
import store from "../../../store/Store"
import { Recommendation } from "./Recommendation"
import { observer } from "mobx-react-lite"


export const Note = observer(({
  note,
  background,
  color,
  goToEvent,
  setShowToaster,
}: {
  note: INote
  background: string
  color: string
  goToEvent?(note: INote): void
  setShowToaster?(value: boolean): void
}) => {
  
  const interval = `${dayjs(note.from).format('D MMM')} — ${dayjs(note.to).format('D MMM')}`

  const removeRecommendation = (recommendationId: number) => {
    store.notes.removeFromNote(note.id, recommendationId)
  }

  return (
    <NoteContainer>
      <Description>
        <div className='title' onClick={() => goToEvent?.(note)}>{note.title}</div>
        <div className='interval'>{interval}</div>
      </Description>

      <div>
        {
          note.recommendations.map((item, index) => (
            <Recommendation
              key={`${note.sphereName}_note_recommendation_${index}`}
              background={background}
              color={color}
              text={item.text!}
              saved
              onClickBookmark={() => removeRecommendation(item.id!)}
            />
          ))
        }
      </div>
    </NoteContainer>
  )
})

const NoteContainer = styled.div`
  position: relative;
`

const Description = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;

  margin-bottom: 1rem;

  .title {
    color: rgba(0, 0, 0, 0.7);
    font-size: 0.875rem;
  }

  .interval {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.75rem;
    white-space: nowrap;
  }
`
