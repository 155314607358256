import styled from 'styled-components';
import { copyToClipboard } from '../../../helpers/strings';
import { buttonStyle } from '../../../helpers/ui';
import { Button } from '../../../libs';


const SUPPORT_EMAIL = 'support@chronos.mg';
const EMAIL_IS_COPIED = 'Почта скопирована';

const SupportAndActions = ({
  leftFiveMinutes,
}: {
  leftFiveMinutes: boolean;
}) => {
  const copyEmail = () => copyToClipboard(SUPPORT_EMAIL, EMAIL_IS_COPIED);

  return <>
    {
      leftFiveMinutes
        ? <Text>
            Возникли вопросы с консультацией или специалистом? напишите в поддержку <span>{SUPPORT_EMAIL}</span> или свяжитесь с вашим менеджером
          </Text>
        : <Text>
            Нужна помощь или у вас есть вопросы? Напишите нам
          </Text>
    }

    <Button
      color='transparentWithBorder'
      onClick={copyEmail}
      style={buttonStyle}
      size="big"
    >
      <ButtonContent>{'Скопировать почту'}</ButtonContent>
    </Button>
  </>
}

export default SupportAndActions;

const Text = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 165%;

  span {
    font-weight: 500;
    color: var(--text-primary);
  }
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.4rem;
  }

  & > span {
    margin-top: 0.1rem;
    opacity: 0.9;
  }
`;
