import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import store from '../../../store/Store';
import BottomButton from './BottomButton';
import ym from 'react-yandex-metrika';
import DescriptionBlock from './DescriptionBlock';
import ServiceGroups from './ServiceGroups';
import useLS from '../../../hooks/useLS';
import { IServiceGroup, sessionFormatOptions } from '../Services';
import { getSpecialistText, groupingServices } from '../helpers/services';
import ButtonSwitcher from '../../../components/ui/ButtonSwitcher';
import { CloseSmallBlack as CloseSmallIcon, Ok } from '../../../assets/icons/system/system-icons';
import { SERVICE_ID } from '../../../helpers/ls/consultations';
import { IServiceDataEx, ISession, SessionFormat } from '../../../libs';

export default function ServicesModal(): JSX.Element {
  const [session, setSession] = useState<ISession | null>(null);
  const [serviceGroups, setServiceGroups] = useState<IServiceGroup[] | null>(null);
  const [showButton, setShowButton] = useState(false);
  const [serviceId, setServiceId, saveServiceId] = useLS(SERVICE_ID);
  const [previousServiceId] = useState(serviceId);
  const [sessionFormat, setSessionFormat, saveSessionFormat] = useLS('session-format', SessionFormat.Online);
  const [previousSessionFormat] = useState(sessionFormat);
  const [sessionId] = useLS('session-id');

  const getSession = async () => {
    if (sessionId !== null) {
      const session = await store.getSession(sessionId!);
      setSession(session);
    }
  };

  useEffect(() => {
    setShowButton(false);
    //setSessionFormat(sessionFormat);
  }, [sessionFormat])

  useEffect(() => {
    getSession();
  }, []);

  useEffect(() => {
    if (store.services && store.serviceCategories) {
      let servicesForGrouping = store.services;

      if(store.professional){
        servicesForGrouping = store.professional.services.reduce((acc,service, i) =>  {
          const serviceUnion = {...store.services![service.id], format: service.format }
          return { ...acc, [i + 1]: serviceUnion }
        }, {});
      }

      const services = groupingServices(servicesForGrouping, store.serviceCategories, sessionFormat);

      setServiceGroups(services);
    }

  }, [store.services, store.serviceCategories, store.professional, sessionFormat]);

  const closeHandler = () => {
    if (previousServiceId) {
      store.setCurrentService(previousServiceId);
    }
    if (sessionFormat !== store.services?.[previousServiceId].format && store.services?.[previousServiceId].format !== SessionFormat.Any) {
      setSessionFormat(previousSessionFormat);
    }
    store.modal.showHandler.servicesModal = false;
  };

  const onCardClick = (service: IServiceDataEx) => {
    const { id } = service;
    setServiceId(id)
    store.setCurrentService(+id);
    setShowButton(true);
  };

  const confirmHandler = async () => {
    saveServiceId();
    saveSessionFormat();
    if (session) {
      session.data.serviceId = serviceId!;
      await store.updateSession(session.id, session);
    }
    ym('reachGoal', 'Products-list_click-button_change-product');
    await store.setProfessionals(serviceId!, sessionFormat);
    store.modal.showHandler.servicesModal = false;
  };

  return <Modal>
    <Header>{'Выбор услуги'}
      <CloseSmallIcon onClick={closeHandler} />
    </Header>
    <ModalContainer>
      <ButtonSwitcher
        options={sessionFormatOptions}
        value={sessionFormat}
        onChange={setSessionFormat}
      />
      <DescriptionBlock
        style={{ marginBottom: '16px' }}
        text={getSpecialistText(sessionFormat)}
      />

      <ServiceGroups groups={serviceGroups} sessionFormat={sessionFormat} onServiceClick={onCardClick} />

    </ModalContainer>
    {showButton && (previousServiceId !== serviceId || sessionFormat !== previousSessionFormat) && (
      <BottomButton onClick={confirmHandler}><ButtonContent><span>Изменить услугу</span><Ok/></ButtonContent></BottomButton>
    )}
  </Modal>;
}

const ModalContainer = styled.div`
  max-width: 550px;
  margin: 0 auto;
  width: 100%;
`;

const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #f8fcff;
  justify-content: center;
  padding: 16px;

  > svg {
    width: 24px;
    height: 24px;
    right: 16px;
    position: absolute;
    cursor: pointer;
  }
`;

const Modal = styled.div`
  box-sizing: border-box;
  background: #f8fcff;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 70px 16px;
  overflow-y: auto;
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 2.4rem;
  font-size: 0.8rem;
  letter-spacing: 0.25px;


  & > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.4rem;
  }

  & > span {
    margin-top: 0.15rem;
  }
`
