import { ReactNode } from "react"
import styled from "styled-components"
import { ChevronRightIcon }from '../../../assets/icons/system/system-icons'


interface ButtonContentProps {
  className?: string
  title: string
  icon?: ReactNode
}

export const ButtonContent = (props: ButtonContentProps) => {
  const {
    className,
    title,
    icon = <ChevronRightIcon/>
  } = props

  return (
    <Container className={className ?? ''}>
      <p>{title}</p>
      {icon}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.875rem;
  padding: 0 0.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--color-white);
  }
`
