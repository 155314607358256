import { makeAutoObservable } from 'mobx';
import Api from "../helpers/api";
import { searchParams } from '../helpers/url';
import { setConfirmationStepLS, setProductDataLS, setProductTokenLS } from './localStorageHelpers';
import store from './Store'
import { IEmoji } from '../libs';

interface IPrice {
  code: string
  id: number
  productId: number
  value: number
}

export interface IProduct {
  code: string
  description: string
  emoji: IEmoji
  id: number
  name: string
  price: IPrice
  version: string
  removed: boolean | null
}

export interface IPurchasedProduct {
  code: string
  createdAt: string
  emoji: IEmoji
  id: number
  name: string
  paidProductTableId: number
  paymentId: string
  productId: number
  userId: number
  version: string
  isTrial: boolean
  partner1?: {
    date: string
    name: string
    time: string
  }
  partner2?: {
    date: string
    name: string
    time: string

  }
  solarBirthDate?: string
  periods?: {
    end: string
    start: string
  }[]
  place1?: string
  place2?: string; 
}

export default class Products {
  constructor() {
    makeAutoObservable(this);
  }

  products: IProduct[] = []
  productData: IProduct | null = null
  purchasedProducts: IPurchasedProduct[] = []

  isSharedProduct: string | null = null

  async getProducts() {
    const result: IProduct[] = await Api.indiGetProducts({currencyCode: 'RUB'}) as any;
    const noEmotions = searchParams().get('no-emotions');
    if(noEmotions) this.products = result.filter(item => +item.id !== 2);
    else this.products = result
    return result
  }

  async getPurchasedProducts() {
    const result: IPurchasedProduct[] = await Api.indiGetPurchasedProducts() as any;
    this.purchasedProducts = result.sort((a,b) => a.id < b.id ? 1 : -1);
  }

  async checkProduct(paymentId: string) {
    const result = await Api.indiIsProductPaid({paymentId})
    return result
  }

  setProductData(data: IProduct) {
    this.productData = data
  }

  setIsSharedProduct(value: string | null) {
    this.isSharedProduct = value
  }

  selectProduct(data: IProduct) {
    const token = data.id;
    store.payments.setToken(token)
    setProductTokenLS(token.toString())
    store.products.setProductData(data)
    setProductDataLS(data)
    store.promocode.resetPromocode()
    setConfirmationStepLS('')
  }
}
