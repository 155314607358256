import styled, { css } from 'styled-components';

export const Label = styled.div`
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;

  border: 1px solid #E2E2E2;
  border-radius: 0.75rem;
  padding: 0 0.5rem;
  height: 1.5rem;

  color: var(--text-secondary);
  font-size: 0.875rem;
  line-height: 1;
`;

export const Row = styled.div<{
  mt?: string;
  mr?: string;
  mb?: string;
  ml?: string;
  justify?: string;
  align?: string;
  gap?: string;
  fd?: string;
}>`
  display: flex;
  ${p => css`margin: ${p.mt || '0'} ${p.mr || '0'} ${p.mb || '1rem'} ${p.ml || '0'};`}
  ${p => css`justify-content: ${p.justify || 'initial'};`}
  ${p => css`align-items: ${p.align || 'initial'};`}
  ${p => css`gap: ${p.gap || '0'};`}
  ${p => css`flex-direction: ${p.fd || 'row'};`}
`;

export const Link = styled.button`
  color: var(--color-gradus-blue);
  font-size: 0.875rem;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;

  background: none;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;

  & > svg {
    height: 1.3125rem;
  }
`;

export const Markup = styled.div<{short?: boolean}>`
  & > p {
    font-weight: 400;
    font-size: ${p => p.short ? '0.875rem' : '1rem'};
    line-height: 1.5;
    color: var(--text-primary);
    margin-top: 0;
    margin-bottom: 1rem;
  }

  & > h4 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.1666;
    color: black;
    margin-top: 0;
    margin-bottom: 0.75rem;
  }

  & > ul > li {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--text-primary);
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  ${p => p.short
      ? css`
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        `
      : ''
  }

  & > *:last-child {
    margin-bottom: 0rem;
  }
`;

export const PinButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.04);

  border-radius: 0.75rem;
  height: 1.5rem;
  width: 1.5rem;

  color: var(--text-secondary);

  & > svg {
    width: 0.75rem;
    height: 0.75rem;
  }
`;
