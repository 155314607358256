import { observer } from 'mobx-react-lite';
import { MainPadding } from '../../components/layout/elements';
import { blogGroups } from './helpers/blog';
import { BlogGroup } from './components/BlogGroup';

interface BlogProps {
  rollDown: () => void
}

export const Blog = observer((props: BlogProps) => {
  return (
    <MainPadding className="no-print">
      {blogGroups.map((group, index) => (
        <BlogGroup
          key={`blog-group-${index}`}
          title={group.title}
          articles={group.articles}
          rotation={group.rotation}
          rollDown={props.rollDown}
        />
      ))}
    </MainPadding>
  );
});
