import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { MyDayBadIcon, MyDayGoodIcon } from '../../../../assets/icons/myday';
import { FlexContainer } from '../../../../components/layout/elements';
import { Colors, Positions, Tip } from '../../../../components/Tip';
import { List } from '../../../../components/ui/List';
import { gtmSend } from '../../../../helpers/url';
import { IDayDescription } from '../../../../store/Events';
import { getMyDayLS, MyDayTips, setMyDayLS } from '../../../../store/localStorageHelpers';
import store from '../../../../store/Store';
import { windowsStore } from 'src/store/Windows';


export const MyDayEventDescription = observer(({
  data
}: {
  data: IDayDescription | null;
}) => {
  const [showTip, setShowTip] = useState(false)

  const userId = store.sessionData?.id;
  const fromMenu = store.onboarding.fromMenu
  const windows = windowsStore.getWindows()

  const hideTip = useCallback(() => {
    setShowTip(false)

    const myDayDataLS = getMyDayLS()

    const newData = {
      ...myDayDataLS,
      onboarding: {
        ...myDayDataLS.onboarding,
        showedTips: [
          ...myDayDataLS.onboarding?.showedTips ?? [],
          MyDayTips.DESCRIPTION
        ],
      }
    }

    setMyDayLS(newData)
  }, [])

  const onCloseTip = useCallback(() => {
    hideTip()
    gtmSend({ 'event': 'indi_onboarding_events_recom', 'user_id': userId ? userId : '' })
  }, [userId, hideTip])

  useEffect(() => {
    return () => {
      const myDayDataLS = getMyDayLS()

      if (showTip && !myDayDataLS?.onboarding?.showedTips?.includes(MyDayTips.DESCRIPTION)) {
        onCloseTip()
      }
    }
  }, [showTip, onCloseTip])

  const checkTip = useCallback(() => {
    const myDayDataLS = getMyDayLS()

    if (windows.length > 1 || fromMenu || myDayDataLS?.onboarding?.showedTips?.includes(MyDayTips.DESCRIPTION)) return

    if (store.onboarding.detectOldUser()) {
      hideTip()
      return
    }

    setTimeout(() => setShowTip(true), 2000)
  }, [fromMenu, hideTip, windows])

  useEffect(() => {
    checkTip()
  }, [checkTip])

  return data ? <Container onClick={() => {}} opened={true} className="ob-z-index">

    <Description>
      {data.atmosphere && <div>{'Атмосфера сегодняшнего дня наполнена ' + data.atmosphere}.</div>}
      {data.attention.length > 0 && <div>{'Также вам следует обратить особое внимание на ' + data.attention.join(', ')}.</div>}
      {data.importance && <div>{'Особое влияние этого дня отразится на ' + data.importance.description1 + ', при этом не забывайте ' + data.importance.description2}.</div>}
    </Description>

    <Blocks>
      {
				data.toDo.length > 0 && <List
																	icon={<MyDayGoodIcon/>}
																	title={'Дела на сегодня'}
																	items={data.toDo}/>
			}

      {
				data.notToDo.length > 0 && <List
																			icon={<MyDayBadIcon/>}
																			title={'Избегайте'}
																			items={data.notToDo}/>
			}

      {
        showTip && (
          <TipWrapper>
            <StyledTip
              color={Colors.DARK}
              text={'Следуйте рекомендациям. Это полезные советы, которые помогут сделать ваш день лучше'}
              //This empty function is needed for showing cross
              onClose={()=>{}}
              onClickTip={onCloseTip}
              trianglePosition={Positions.BOTTOM_LEFT}
            />
          </TipWrapper>
        )
      }
    </Blocks>
  </Container> : null
})

const Container = styled(FlexContainer)<{importantBg?: string, opened: boolean}>`
  flex-direction: column;
  gap: 10px;

  line-height: 1.45;
  margin: 0.5rem 0 2rem 0;

  &:last-child {
    margin-bottom: 6px;
  }
`

const Description = styled(FlexContainer)`
  flex-direction: column;
  margin-bottom: 1.25rem;
  gap: 1rem;
`

const Blocks = styled(FlexContainer)`
  flex-direction: column;
  gap: 1.5rem;

  position: relative;
`

const TipWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: -110px;
  width: 100%;
`

const StyledTip = styled(Tip)`
  max-width: 300px;
`
