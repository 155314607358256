import React from 'react';
import styled, { css } from 'styled-components'
import { getYearText } from '../../../helpers/dates'
import NorthMaps from './NorthMaps'
import store from '../../../store/Store'
import {observer} from 'mobx-react-lite'
import AvatarImage from '../../../assets/images/profile-picture.png';
import { StarIcon } from '../../../assets/icons/system/system-icons';
import { IWorkProfilesItem } from '../../../libs';

export default observer(function ProfessionalCard(props: {
  onClick(): void;
  bestProfessionalId: number | null;
} & IWorkProfilesItem): JSX.Element {
  const isBestProfessional = props.bestProfessionalId === props.userId

  if (!store.specializations) return <Card />;

  const specializationName = store.specializations[props.specialization]?.name || '';

  return <>
    {isBestProfessional && <BestProfessional><StarIcon />Лучший {specializationName} для этой услуги</BestProfessional>}
    <Card onClick={props.onClick} isBestProfessional={isBestProfessional}>
    <CardHeader>
      <Avatar src={props.avatarUrl || AvatarImage}/>
      {!props.northMaps && <NorthMaps size={'small'}/>}
    </CardHeader>
    <CardBody>
      <Title>{props.fullName}</Title>
      <Row style={{marginBottom: '12px'}}>
        {!!props.age && <Text>{props.age} {getYearText(props.age)}</Text>}
          <Text>{specializationName}</Text>
      </Row>
      <Row>
        {!!props.experience && <TextRaw>
          <Span color={'red'}>{props.experience}</Span> {getYearText(props.experience)} {'опыта'}
        </TextRaw>}
        {!!props.sessionsCount && <TextRaw>
          <Span color={'yellow'}>{props.sessionsCount}</Span> {'консультации'}
        </TextRaw>}
      </Row>
    </CardBody>
  </Card>
  </>
});

const Span = styled.span<{color: string;}>`
  color: ${props => props.color === 'red' ? '#EF598B' : '#F59300'}
`;

const TextRaw = styled.p`
  margin-right: 18px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
`;

const Text = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  
  &:nth-child(1) {
    :after {
      margin: 0 8px;
      content: '·';
    }
  }
`;

const Row = styled.div`
  display: flex;
`;

const Card = styled.div<{isBestProfessional?: boolean}>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0 3.2px 14px rgba(0, 0, 0, 0.06), 0 0.6px 1.8px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-bottom: 16px;
  transition: background 0.2s ease;
  cursor: pointer;

  :active {
    background: rgba(0, 0, 0, 0.04);
  }
  
  ${ props => props.isBestProfessional && css`
    border: 1px solid #F59300;
    border-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  `
  }
`;

const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
`;

const CardHeader = styled.div`
  display: flex;
  margin-bottom: 22px;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;  
`;

const Title = styled.p`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0 0 2px;
`;

const BestProfessional = styled.div`
  color: white;
  background: #F59300;
  padding: 0.5rem 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    margin-right: 0.5rem;
    align-self: flex-start;
  }
`;
