import { Indi } from "../../libs";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { toISOString } from "../../helpers/dates";
import { gtmSend } from "../../helpers/url";
import { useBackWindow } from "../../hooks/router-hooks";
import { INote } from "../../store/Notes";
import { popupsStore } from "../../store/Popups";
import store from "../../store/Store";
import { windowsStore } from "../../store/Windows";
import { getNotesLS, getPinnedGroups } from "../../store/localStorageHelpers";
import MyEventsScrollView, { WINDOW_ID_MYDAY_SCROLL_VIEW } from "../my-day/components/MyEventsScrollView";
import { EmptyNotes } from "./components/EmptyNotes";
import { NotesGroup } from "./components/NotesGroup";
import { MainPadding, WindowContainer } from "../../components/layout/elements";
import { WindowHeader } from "../windows/components/WindowHeader";


export const WINDOW_ID_NOTES: string = 'WINDOW_ID_NOTES';

export const openNotes = (backToEvents?: boolean, showOnClose?: boolean) => {
  windowsStore.open(
    WINDOW_ID_NOTES,
    <Notes backToEvents={backToEvents} showOnClose={showOnClose}/>,
  )
}

interface NotesProps {
  backToEvents?: boolean
  showOnClose?: boolean
}

export const Notes = observer((props: NotesProps) => {

  const containerRef = useRef<HTMLDivElement>(null)

  useBackWindow('', WINDOW_ID_NOTES)

  const userId = store.sessionData?.id
  const notes = store.notes.notesList
  const events = store.events.events

  const notesLS = getNotesLS()
  const pinnedGroups = getPinnedGroups()

  useEffect(() => {
    gtmSend({'event': 'ind_prognosis_note_entry', 'user_id': userId ? userId : ''})

    return () => {
      if (props.backToEvents) {
        popupsStore.decreaseZIndex()
      }
    }
  }, [])

  useEffect(() => {
    if (userId) {
      store.notes.buildNotes(userId)
    }
  }, [userId])

  const notesGroups = notes.reduce((acc, next) => {
    const spheres = {...acc}

    spheres[next.sphereName] = spheres[next.sphereName] || []

    spheres[next.sphereName].push(next)

    spheres[next.sphereName].sort((sphere1, sphere2) => dayjs(sphere1.to).diff(dayjs()) - dayjs(sphere2.to).diff(dayjs()))

    return spheres
  }, {} as Record<string, INote[]>)

  const spheres = notes.reduce((acc, next) => {
      const spheres = [...acc]

      if (!spheres.includes(next.sphereName)) {
        spheres.push(next.sphereName)
      }

      return spheres
    }, [] as string[])
    .sort((sphere1, sphere2) => pinnedGroups.indexOf(sphere2) - pinnedGroups.indexOf(sphere1))

  const goToEvent = (note?: INote) => {
    const openEvent = (events: Indi.Events.Event[], event: Indi.Events.Event) =>  {
      windowsStore.open(
        WINDOW_ID_MYDAY_SCROLL_VIEW,
        <MyEventsScrollView list={events} currentId={event.id} />,
        'fade',
        true,
        false,
        true
      )
    }

    if (note) {
      for (let key in events) {
        const event = events[key].events.find(event => event.title === note.title && event.startDate === note.from && event.endDate === note.to)

        if (events[key].events.length > 0 && event) {
          openEvent(events[key].events, event)
          break
        }
      }
    } else {
      const day = toISOString(dayjs().startOf('day'))
      const event = events[day].events.find(event => event.recommendations.length > 0)

      if (event) {
        openEvent(events[day].events, event)
      }
    }
  }



  const onClose = () => windowsStore.close(WINDOW_ID_NOTES)

  return (
    <WindowContainer ref={containerRef}>
      <WindowHeader title='Мои заметки' onBack={props.showOnClose ? onClose : undefined}/>
      <NotesContainer isEmpty={notes.length === 0}>
        {
          spheres.length > 0
            ? <>
                {spheres.map((sphere, index) => (
                  <NotesGroup
                    key={`notes_group_${sphere}_${index}`}
                    notes={notesGroups[sphere]}
                    sphere={sphere}
                    goToEvent={(note) => goToEvent(note)}
                    setShowToaster={value => {}}
                  />
                ))}
              </>
            : <EmptyNotes goToEvent={goToEvent}/>
        }
      </NotesContainer>
    </WindowContainer>
  )
})

const NotesContainer = styled(MainPadding)<{isEmpty: boolean}>`
  display: flex;
  flex-direction: column;

  padding-bottom: 8rem;

  ${p => p.isEmpty && css`
    justify-content: center;
    align-items: center;

    min-height: 70vh;
    padding-bottom: 1rem;
  `}
`

