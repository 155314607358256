import React, { useEffect } from 'react';
import HelpChoose from './HelpChoose';
import ym from "react-yandex-metrika";

const amoChatButton = '#amobutton';

export default function Help(){
  const [hasAmoChatButton, setHasAmoChatButton] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      if(document.querySelector(amoChatButton)) setHasAmoChatButton(true);
    }, 750);
  }, [])

  if(!hasAmoChatButton) return <></>;

  const onClick = () => {
    //@ts-ignore
    window.amoSocialButton('runChatShow')
    ym('reachGoal','indi_opened-chat');
  }

  return (
    <HelpChoose
      handleClick={onClick}
      buttonText="Задать вопрос"
      title="Не знаете что выбрать?"
      text="Наши менеджеры ответят вам в любой момент"
      containerStyle={{padding: '16px 12px 12px 12px'}}
    />
  );
}
