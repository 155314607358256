import styled from 'styled-components'

export default function TooltipWrapper({
  children,
  text,
  open
}: {
  children: React.ReactNode,
  text: string,
  open: boolean
}) {
  return <Container>
    {children}
    {open && <Tooltip>
      {text}
    </Tooltip>}
  </Container>
}

const Container = styled.div`
  position: relative;
`

const Tooltip = styled.div`
  position: absolute;
  top: 0;
  background: var(--bg-500);
  box-shadow: 0px 4px 14px rgba(129, 136, 157, 0.08), 0px 0.75px 2px rgba(137, 146, 172, 0.12);
  border-radius: 16px;
  color: var(--color-white);
  width: 90%;
  left: 50%;
  transform: translate(-50%, calc(100% + 0.5rem));
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 135%;

  :after {
    top: -1rem;
    content: '';
    left: calc(50% - 0.5rem);
    display: block;
    position: absolute;
    border: 0.5rem solid transparent; /* Прозрачные границы */
    border-bottom: 0.5rem solid var(--bg-500); /* Добавляем треугольник */
  }
  
`