import { observer } from "mobx-react-lite";
import React from "react";
import styled, { css } from "styled-components";
import { GoogleIcon } from "../../../assets/icons/social/social_icons";
import { Warning } from "../../../assets/icons/system/system-icons";
import api, { getHTTPMessage } from "../../../helpers/api";
import { gtmSend } from "../../../helpers/url";
import store from "../../../store/Store";
import { WindowFormContainer } from "../../../components/layout/elements";
import { Button } from "../../../components/ui/Button/Button";
import ConfirmCodeInput, { IRefConfirmCodeInput } from "../../../components/ui/ConfirmCodeInput";
import CountDownTimer, { IRefCountDownTimer } from "../../../components/ui/CountDownTimer";
import { isEmailInvalid } from "../../../helpers/fields";
import { windowsStore } from "../../../store/Windows";
import { WindowHeader } from "../../windows/components/WindowHeader";
import { PASS_MIN_LENGTH, alphaAndDigitValidation } from "../../../helpers/ui";
import { Auth, WINDOW_ID_AUTH } from "../../profile/Auth";
import { Footer, MiniTitle, RegistrationContent } from "../Registration";
import { Input, InputContainer, TInputMode } from "../../../libs";

import ReRecaptcha, { TReReCaptchaApi } from "../../../components/ReRecaptcha";


export const WINDOW_ID_EMAIL_AUTH = 'WINDOW_ID_EMAIL_AUTH';
export const GOOGLE_AUTH_LINK = `${process.env.REACT_APP_AUTH}/api/auth/google`;

export enum AUTH_TYPE {
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp'
}

enum STEP {
  AUTH = 1,
  RESTORE = 2,
  CONFIRM = 3,
  RESET = 4
}

export interface IAuthTypeData {
  title?: string;
  subtitle?: string;
  onPnoneNumber?: (phone: string) => Promise<any>;
  onCode?: (phone: string, code: string) => Promise<any>;
  onSuccess?:(channel: string, sessionData?: any) => void;
}

export default observer(function EmailAuth(props: IAuthTypeData) {

  const [step, setStep] = React.useState<STEP>(STEP.AUTH);
  const [channel, setChannel] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [channelMessage, setChannelMessage] = React.useState<string>('');
  const [passwordMessage, setPasswordessage] = React.useState<string>('');
  const [disableSend, setDisableSend] = React.useState<boolean>(false);

  // restore password
  const [restoreChannel, setRestoreChannel] = React.useState<string>('');
  const [restoreChannelMessage, setRestoreChannelMessage] = React.useState<string>('');

  const [newPassword, setNewPassword] = React.useState<string>('');
  const [newPasswordMode, setNewPasswordMode] = React.useState<TInputMode>('normal');
  const [newPasswordMessage, setNewPasswordMessage] = React.useState<string>('');

  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [confirmPasswordMode, setConfirmPasswordMode] = React.useState<TInputMode>('normal');
  const [confirmPasswordMessage, setConfirmPasswordMessage] = React.useState<string>('');

  const [codeMessage, setCodeMessage] = React.useState<string>('');

  const CodeInputRef = React.useRef<IRefConfirmCodeInput>(null);
  const CountDownRef = React.useRef<IRefCountDownTimer>(null);
  const captchaRef = React.useRef<TReReCaptchaApi | null>(null);


  const userId = store.sessionData?.id || '';

  React.useEffect(() => {
    gtmSend({ 'event': 'chronos_e-mail_sign-in', 'user_id': userId });
  }, []);

  React.useEffect(() => {
    const isChannelValid = Boolean(channel.length && !isEmailInvalid(channel));
    const isPasswordValid = Boolean(password.length && (password.length >= 8));
    setDisableSend(!isChannelValid || !isPasswordValid);

    setChannelMessage(!isChannelValid ? 'Неверный формат email' : '');
    setPasswordessage(!isPasswordValid ? 'Длина пароля не может быть меньше 8 символов' : (!password.length ? 'Введите пароль' : ''));
  }, [channel, password]);

  React.useEffect(() => {
    setRestoreChannelMessage('');
  }, [restoreChannel]);

  const onClose = () => {
    if(step > STEP.AUTH) {
      setStep(step => step -1);
    } else {
      windowsStore.close(WINDOW_ID_EMAIL_AUTH);
    }
  }

  const onFormSubmit = async (evt?: any) => {
    try {
      const result = await api.signInWithEmail({ email: channel, password: password })
      store.isAuth = true;
      store.lsSave('token', result.token)
      const sessionData = await store.getProfile();

      gtmSend({ 'event': 'chronos_mobile_done', 'user_id': userId });

      if(props.onSuccess) {
        props.onSuccess(channel, sessionData);
        return;
      }

      if (store.hasIndiSubscriptionOrTrial) {
        windowsStore.closeAll();
      } else if (store.hasCanceledSubscriptionOrTrial || store.userNotUseIndi) {
        windowsStore.closeAll();
      }

    } catch (err: any) {
      const messages = getHTTPMessage(err._errors);
      setChannelMessage(messages);
      setPasswordessage(messages);
    }
  }

  const onGoogleSignIn = async (evt?: any) => {
    windowsStore.open(WINDOW_ID_AUTH, <Auth googleAuth={true} />);
  }

  const onForgonPassword = () => {
    setStep(STEP.RESTORE);
  }

  const sendRestoreChannel = async () => {
    CodeInputRef.current?.clear();
    CountDownRef.current?.restart();
    setCodeMessage('');

    try {
      let channelResult = restoreChannel.replace(/[\\(\\)\s]/g, '');
      // российские номера приводим к +7......... форме
      if (/^8/.test(channelResult) && channelResult.length === 11) {
        channelResult = channelResult.replace(/^8/, '+7')
      }
      setRestoreChannel(channelResult);

      captchaRef.current?.reset()
      const token = await captchaRef.current?.executeAsync();

      if (token) {
        const result = await api.restorePassword({ channel: channelResult, token });
        setStep(STEP.CONFIRM);
      } else {
        console.error('Captcha error')
      }
    } catch (error: any) {
      const messages = getHTTPMessage([error.message]);
      setRestoreChannelMessage(messages);
      console.log('restore err -', error);
    }
  }

  const onConfirmCodeFilling = async (code: string) => {
    if (!code) {
      throw Error('Confirm code error' + `${code}`);
    }

    try {

      captchaRef.current?.reset()
      const captchaToken = await captchaRef.current?.executeAsync();

      const result = await api.restorePasswordConfirm({ channel: restoreChannel, code: code, captchaToken });

      setStep(STEP.RESET);
      return result;
    } catch (error: any) {
      const messages = getHTTPMessage([error.message]);
      setCodeMessage(messages);
      console.log('onConfirmCodeFilling error -', messages);
    }
  }

  const resetPassword = async () => {
    try {
      const result = await api.changePassword({ newPassword, confirmPassword });
      setStep(STEP.AUTH);
    } catch (error: any) {
      const messages = getHTTPMessage([error.message]);
      setRestoreChannelMessage(messages);
    }
  }

  // валидация
  React.useEffect(() => {
    if (newPassword) {
      const errFormat = !alphaAndDigitValidation(newPassword);
      const errLength = newPassword.length < PASS_MIN_LENGTH;
      const mode = errFormat || errLength ? 'error' : 'normal'
      const message = errLength ? 'Длина пороля меньше 8 символов' : errFormat ? 'Используйте латинские буквы и цифры' : '';
      setNewPasswordMode(mode);
      setNewPasswordMessage(message);
    }
    if (confirmPassword) {
      setConfirmPasswordMode(confirmPassword !== newPassword ? 'error' : 'normal');
      setConfirmPasswordMessage(confirmPassword !== newPassword ? 'Нет полного совпадения!' : '');
    }
  }, [newPassword, confirmPassword]);


  const showChannelError = Boolean(channelMessage) && channel.length > 0;
  const showPasswordError = Boolean(passwordMessage) && password.length > 0;
  const restoreChannelError = Boolean(restoreChannelMessage) && restoreChannel.length > 0;

  return (
    <WindowFormContainer>
      <StyledWindowHeader title={step === STEP.AUTH ? 'Вход' : ''} onBack={onClose} />
      <EmailAuthContent paddingBlock={true}>
        <MiniTitle>
          {step === STEP.AUTH && <span>Только для зарегистрированных пользователей</span>}
          {step === STEP.RESTORE && <><h2>Забыли пароль?</h2><span>Мы вышлем на вашу почту или телефон код для восстановления пароля</span></>}
          {step === STEP.CONFIRM && <><h2>Восстановление пароля</h2><span>Мы выслали на <strong>{restoreChannel}</strong> код. Введите его чтобы подтвердить почту или номер телефона</span></>}
          {step === STEP.RESET && <><h2>Придумаем новый пароль</h2><span>Убедитесь, что используете буквы разного регистра и цифры</span></>}
        </MiniTitle>
        { step === STEP.AUTH &&
          <>
            <InputContainer
              label="Email"
              message={showChannelError ? <><Warning />{channelMessage}</> : ''}
            >
              <Input
                size="big"
                value={channel}
                onChange={setChannel}
                mode={showChannelError ? 'error' : 'normal'}
              />
            </InputContainer>
            <InputContainer
              label="Пароль"
              message={showPasswordError ? <><Warning />{passwordMessage}</> : ''}
            >
              <Input
                size="big"
                type="password"
                value={password}
                onChange={setPassword}
                mode={showPasswordError ? 'error' : 'normal'}
              />
            </InputContainer>
          </>
        }

        {
          step === STEP.RESTORE &&
          <>
            <InputContainer label={'Почта или телефон'} message={restoreChannelMessage}>
              <Input
                size='big'
                value={restoreChannel}
                mode={restoreChannelError ? 'error' : 'normal'}
                clearable
                onChange={(val: string) => setRestoreChannel(val)}
              />
            </InputContainer>
          </>
        }

        {
          step === STEP.CONFIRM &&
          <>
            <ConfirmCodeInput ref={CodeInputRef} length={4} onFilling={onConfirmCodeFilling} errorMessage={codeMessage} />
            <CountDownTimer ref={CountDownRef} minutes={1} onClick={sendRestoreChannel} />
          </>
        }

        {
          step === STEP.RESET &&
          <>
            <InputContainer label={'Придумаем новый пароль'} message={newPasswordMessage}>
              <Input
                name="password"
                size='big'
                value={newPassword}
                type="password"
                mode={newPasswordMode}
                readonly
                onFocus={(e: any) => e.target.removeAttribute('readonly')}
                onChange={(val: string) => setNewPassword(val)}
              />
            </InputContainer>
            <InputContainer label={'подтвердите пароль'} message={confirmPasswordMessage}>
              <Input
                name="password-confirm"
                size='big'
                value={confirmPassword}
                type="password"
                mode={confirmPasswordMode}
                onChange={(val: string) => setConfirmPassword(val)}
              />
            </InputContainer>
          </>
        }

        <FooterStyled>
          { step === STEP.AUTH &&
            <>
              <Button onClick={onFormSubmit} disabled={disableSend}>Войти</Button>
                <div className="delimiter">ИЛИ</div>
              <GoogleButton onClick={onGoogleSignIn}><GoogleIcon />Войти с помощью Google</GoogleButton>
            </>
          }
          { ![STEP.AUTH, STEP.CONFIRM].includes(step) &&
            <>
              <Button onClick={
                step === STEP.RESTORE
                  ? sendRestoreChannel
                  : resetPassword
              } disabled={
                  (step === STEP.RESTORE && false)
                  || (step === STEP.RESET && !!newPasswordMessage)
                }>Восстановить</Button>
            </>
          }
        </FooterStyled>

        {step === STEP.AUTH && <div className="forgot-password-button" onClick={onForgonPassword}>Забыли пароль?</div>}

        <ReRecaptcha
          size="invisible"
          badge="bottomleft"
          ref={captchaRef}
        />
      </EmailAuthContent>

    </WindowFormContainer>
  );
});

const StyledWindowHeader = styled(WindowHeader)`
  justify-content: flex-start;
`;

const EmailAuthContent = styled(RegistrationContent) <{ paddingBlock: boolean }>`
  box-sizing: border-box;
  height: calc(100% - 3.5rem);

  ${p => !p.paddingBlock && css`
    padding-left: 0;
    padding-right: 0;
  `}

  & .forgot-password-button {
    position: absolute;
    width: calc(100% - 2rem);
    padding: 0.5rem 0;
    bottom: 2rem;
    text-align: center;

    font-size: 0.875rem;
    color: var(--color-gradus-blue);
    cursor: pointer;
  }
`;

const FooterStyled = styled(Footer)`
  padding: 0;
  margin-top: 1rem;

  & .delimiter {
    position: relative;
    margin: 1.5rem 0;
    color: var(--text-third);
    font-size: 0.875rem;
    letter-spacing: -0.025em;
    text-align: center;
  }
`;

export const GoogleButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  color: var(--text-primary);
  background-color: var(--color-gray-bg-transparent);

  & svg {
    width: 1.563rem;
    height: 1.5rem;
  }
`;
