import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FlexContainer } from '../../../components/layout/elements';
import { TPaywallSlide } from '../helpers/sliders';

interface ISliderProps {
  slides: TPaywallSlide[]
  activeSlide?: number
  setActiveSlide?: (index: number) => void
}

export default function Slider(props: ISliderProps) {
  const [activeSlide, setActiveSlide] = useState(0)
  const [swiper, setSwiper] = useState<any>(null)

  const slideTo = (index: number) => swiper && swiper.slideTo(index)

  useEffect(() => {
    if (props.activeSlide && props.activeSlide > 0) {
      slideTo(props.activeSlide)
    }
  }, [props.activeSlide])

  const onSlideChangeHandler = (slide: {activeIndex: number}) => {
    setActiveSlide(slide.activeIndex)
    props.setActiveSlide && props.setActiveSlide(slide.activeIndex)
  }

  return (
    <Container>
      <Swiper slidesPerView={1}
        onSlideChange={onSlideChangeHandler}
        onSwiper={setSwiper}
        >
          {props.slides.map(slide => {
            return (
              <SwiperSlide key={`slide_${slide.id}`}>
                <SlideContainer>
                  <ImgContainer>
                    <Img src={slide.image} longImg={slide.id === 5}/>
                  </ImgContainer>
                  <Text>
                    {slide.text}
                  </Text>
                </SlideContainer>
              </SwiperSlide>
            )
          })}
      </Swiper>

      <Points>
      {props.slides.map((item, index) => <Point key={item.id} active={index === activeSlide} />)}
      </Points>
    </Container>
  )
}

const Container = styled.div`
  padding-bottom: 2rem;

  .swiper-wrapper {
    align-items: end;
  }
`

const SlideContainer = styled(FlexContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Points = styled(FlexContainer)`
  display: flex;
  justify-content: center;

  gap: 0.75rem;
  margin-top: 1.5rem;
`

const Point = styled.div<{active: boolean}>`
  width: 0.5rem;
  height: 0.5rem;
  background: var(--paywall-point-background);
  border-radius: 50%;

  ${p => p.active && css`
    background: var(--color-white);
  `}
`

const Text = styled.div`
  font-size: 1.5rem;
  line-height: 1.75rem;
  width: 20rem;
  max-width: 95%;
  color: var(--color-white);
  text-align: center;
`

const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 60vh;
`

const Img = styled.img<{longImg?: boolean}>`
  width: ${p => p.longImg ? '65%' : '90%'};
  margin-bottom: 1.5rem;
`
