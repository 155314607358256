import styled from "styled-components"
import { Cross } from "../../../assets/icons/system/system-icons"
import { Offer } from "../../../components/Offer"
import background from "../../../assets/images/offer_purpure_bg.jpg"
import { Radius } from "../../../components/ui/Button/Button"

interface ConsultationOfferProps {
  className?: string
  onClick(): void
  onClose(): void
}

export const ConsultationOffer = (props: ConsultationOfferProps) => {
  const {
    className,
    onClick,
    onClose,
  } = props

  const CloseIcon = <div onClick={onClose}><Cross/></div>

  return (
    <Container className={className}>
      <StyledOffer
        icon={CloseIcon}
        title='14 дней Chronos Plus в подарок'
        text='Получайте персональные ежедневные прогнозы по важным сферам каждый день'
        buttonTitle='Активировать 14 дней бесплатно'
        buttonColor='gradient_purpure'
        onClick={onClick}
        background={`url(${background}) 0 0/cover no-repeat`}
        radius={Radius.RECTANGLE}
      />
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  bottom: 1rem;
  left: 0;

  padding: 0 1rem;
  z-index: 100;
`

const StyledOffer = styled(Offer)`
  .title {
    font-size: 1.125rem;
  }
`
