import React from 'react';
import styled, {css} from "styled-components";
import {IIntro} from "./Emotions";
import { TextBlockType } from '../../../../libs';

interface IIntroProps {
  data: IIntro
}

function Intro(props: IIntroProps) {
  return (
    <Container id={'intro'}>
      <Title>{props.data.title}</Title>

      {props.data.textBlocks.map((textBlock , index)=> {
        return <Paragraph key={`intro_textBlock_${index}`} type={textBlock.type}>
          {textBlock.subtitle && <div className={'subtitle'}>{textBlock.subtitle}</div>}
          <div className={'text'}>{textBlock.text}</div>
        </Paragraph>
      })
      }
    </Container>
  );
}

export default Intro;

const Container = styled.div`
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  box-shadow: 0px 3.2px 14px rgba(0, 0, 0, 0.06), 0px 0.6px 1.8px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 1rem;
  margin-bottom: 1.25rem;
`

const Title = styled.div`
  font-size: 1.125rem;
  margin: 0.5rem 0;
`

const Paragraph = styled.div<{type: TextBlockType}>`
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 1rem;

  .subtitle {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0.3rem;
    line-height: 1.313rem;
  }

  ${p => p.type === 'SPECIAL' && css`
    color: var(--text-secondary);
    background-color: var(--bg-highlighted-text);
    border-radius: 0.5rem;
    padding: 1rem;
  `}
`
