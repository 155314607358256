import dayjs from 'dayjs';
import React from 'react';
import styled, { css } from "styled-components";
import store from '../../../../store/Store';
import { SOLAR } from '../../helpers/constants';
import Recommendations from "./Recommendations";
import { IContent } from "./types";
import { Badge } from '../../../../libs';


interface ISphereProps {
  data: IContent
}

export default React.memo(function Sphere(props: ISphereProps) {
  if (!props.data) return null

  const statusText = store.solar.getStatus(props.data.period.start, props.data.period.end, SOLAR)?.text ?? ''
  const statusType = store.solar.getStatus(props.data.period.start, props.data.period.end, SOLAR)?.type

  const getPeriod = () => {
    const start = dayjs(props.data.period.start).utc().format('DD MMM,  HH:mm')
    const end = dayjs(props.data.period.end).utc().format('DD MMM,  HH:mm')

    return `${start} — ${end}`
  }

  return (
    <Container id={`#${statusType}`}>
      <Header>
        <Title status={statusType}>
          <h2>{props.data.title}</h2>
          <div  className={'period'}>{getPeriod()}</div>
        </Title>
        {statusText && <CustomBadge value={statusText} size={'big'} status={statusType}/>}
      </Header>

      <Description>{props.data.description}</Description>

      <Keywords>{props.data.keywords.map((item, index) => {
        return <Keyword key={`keyword_${index}`}>{item.text}</Keyword>
      })}</Keywords>

      {props.data.recommendations.map((item, index) => {
        return <Recommendations key={`recommendations_${index}`} data={item} isDayDescription={index === 1}/>})}
    </Container>
  );
})

const Container = styled.div`
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;

  margin-bottom: 0.5rem;

  @media print {
    margin-top: 2rem;
}
`

const Title = styled.div<{status: string | undefined}>`
  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    text-decoration: ${p => p.status === 'end' ? 'line-through' : 'none'};
  }

  .period {
    color: var(--text-third);
    font-size: 0.875rem;
  }
`

const CustomBadge = styled(Badge)<{status: string | undefined}>`
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 3.5rem;
  padding: 0.4rem 0.5rem;
  top: 0.4rem;
  white-space: nowrap;

  ${p => p.status === 'takePlace' && css`
    color: var(--color-orange);
    background: var(--color-orange-bg);
  `}

  ${p => p.status === 'end' && css`
    color: var(--text-secondary);
    background: rgba(0, 0, 0, 0.04);
  `}
`

const Description = styled.div`
  color: var(--text-secondary);
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 1.25rem;
`

const Keywords = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Keyword = styled.div`
  font-size: 0.75rem;
  color: var(--color-gradus-blue);
  background-color: var(--color-gradus-blue-bg);
  padding: 0.2rem 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.75rem;
  border-radius: 2rem;
  white-space: nowrap;
`
