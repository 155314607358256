import dayjs from "dayjs";
import { makeAutoObservable } from "mobx";

export class Trial {
  constructor() {
    makeAutoObservable(this)
  }

  activeTrial: boolean = false

  setActiveTrial(trialExpiryDate: string) {
    this.activeTrial = dayjs().isBefore(trialExpiryDate)
  }
}
