import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { ChevronDownIcon } from '../../../../assets/icons/system/system-icons';

import {IRecommendations} from "./types";
import { observer } from 'mobx-react-lite';
import store from '../../../../store/Store';

interface IListProps {
  data: IRecommendations
  isDayDescription: boolean
}

export default React.memo(observer(function Recommendations(props: IListProps) {
  const [open, setOpen] = useState(!props.isDayDescription)

  useEffect(() => {
    store.solar.isPrinting && setOpen(true)
  }, [store.solar.isPrinting])

  const getHTMLText = (text: string) => {
    return {__html: text}
  }

  return (
    <Container open={open}>
      <div className={'header'} onClick={() => setOpen(!open)}>
        <h3>{props.data.title}</h3>
        <ChevronDownIcon/>
      </div>
      <ul>
        {props.data.textBlocks.map((item, index )=> {
          if (props.isDayDescription) {
            return <div key={`textBlock_description_${index}`} className={'description'} dangerouslySetInnerHTML={getHTMLText(item.text)} />
          } else {
              return <li key={`textBlock_${index}`} dangerouslySetInnerHTML={getHTMLText(item.text)}/>
          }
        })}
      </ul>
    </Container>
  );
}))

const Container = styled.div<{ open: boolean }>`
  margin-bottom: 1.5rem;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h3 {
    color: var(--color-gradus-blue);
    font-size: 0.875rem;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  svg {
    color: var(--color-gradus-blue);
    transform: ${p => p.open ? 'rotate(0.5turn)' : ''};
  }

  ul {
    display: ${p => p.open ? 'block' : 'none'};
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--text-secondary);
    margin-bottom: 0.5rem;
  }

  li:before {
    content: '';
    display: inline-block;
    width: 0.25rem;
    height: 0.25rem;
    vertical-align: middle;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.17);
    margin-right: 0.5rem;
  }

  .description {
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--text-secondary);
    margin-bottom: 1rem;
  }
`
