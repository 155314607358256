import styled, { css } from "styled-components"
import background from "../../../assets/images/bg-consultation-modal.jpg"
import { Modal } from "../../../components/Modal"
import { isMobile } from "react-device-detect"
import { Button } from "../../../libs"
import { useEffect } from "react"
import store from "src/store/Store"


interface ConsultationModalProps {
	onClose(): void
}

export const ConsultationModal = (props: ConsultationModalProps) => {
  useEffect(() => {
    store.shutter.hideShutter()

    return () => {
      store.shutter.showShutter()
    }
  }, [])

	return (
		<StyledModal isMobile={isMobile}>
			<Container background={background}>
				<StyledButton
					color={'var(--color-purpur)'}
					onClick={props.onClose}
				>
					Продолжить
				</StyledButton>
			</Container>
		</StyledModal>
	)
}

const Container = styled.div<{background?: string}>`
	display: flex;
	flex-direction: column;
	justify-content: end;
	align-items: center;

	min-height: 820px;
  height: 100%;
	padding: 1rem;

	${p => p.background && css`
    background-image: url(${p.background});
		background-position: 0 0;
		background-repeat: no-repeat;
		background-size: cover;
  `};
`

const StyledButton = styled(Button)`
	width: 100%;
  padding: 1rem 2rem;
  font-size: 1.125rem;
`

const StyledModal = styled(Modal)<{isMobile: boolean}>`
	width: ${isMobile ? '100vw' : '100%'};
  height: 100%;
`
