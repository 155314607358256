import { makeAutoObservable } from "mobx";
import { getMainPageLS } from "./localStorageHelpers";

export class Widgets {
  constructor() {
		makeAutoObservable(this)

    const mainPageLS = getMainPageLS()
    this.collapsed = mainPageLS?.widgets?.collapsed ?? true
	}

  collapsed: boolean

  setCollapsed(value: boolean) {
    this.collapsed = value
  }
}
