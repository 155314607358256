import React from 'react';
import styled from 'styled-components';
import { AppsIcon, ChevronLeftBold } from '../../../assets/icons/system/system-icons';
import { Avatar } from '../../../components/Avatar';
import { FlexContainer } from '../../../components/layout/elements';


export const PopupHeader = ({
  title,
  onBack,
  rightElement,
  className,
  userId,
  firstScreen,
  username,
}: {
  title: string,
  onBack?: () => void,
  rightElement?: React.ReactNode
  className?: string
  userId?: number
  firstScreen?: boolean
  username?: string
}) => {
  const BackIcon = firstScreen ? <AppsIcon/> : <ChevronLeftBold/>

  return <Container className={className}>
    {onBack && <IconContainer onClick={onBack} firstScreen={firstScreen}>{BackIcon}</IconContainer>}
    {firstScreen && <StyledAvatar userId={userId} name={username}/>}
    <Title onClick={onBack} firstScreen={firstScreen}>{title}</Title>
    {rightElement && <div>{rightElement}</div>}
  </Container>
}

const Container = styled(FlexContainer)`
  padding: 1rem 1rem 0.5rem;
  gap: 0.5rem;
  align-items: center;
  z-index: 2;
`

const IconContainer = styled.span<{firstScreen?: boolean}>`
  svg {
    width: ${p => p.firstScreen ? '1.5rem' : '2rem'};
    height: ${p => p.firstScreen ? '1.5rem' : '2rem'};
  }
`

const Title = styled.div<{firstScreen?: boolean}>`
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--text-primary);

  :first-letter {
    text-transform: uppercase;
  }

  margin-right: auto;

`

const StyledAvatar = styled(Avatar)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
`
