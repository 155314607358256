import { IAuthIndi } from "src/store/Auth";
import { Indi } from "../../../libs";
import dayjs, { Dayjs } from "dayjs";

export const getTariffsInfo = (tariff: Indi.indiGetTariffs.Tariff) => {
    switch(tariff.id) {
        case 1:
           return { paymentPeriodText: 'ежемесячно' };
        case 2:
            return { paymentPeriodText: 'каждые 6 месяцев' };
        case 3:
            return { paymentPeriodText: 'каждый год' };
        default:
            return { paymentPeriodText: '' };
    }
}

export const isHasSubscriptionOrTrial = (sessionData: IAuthIndi): boolean => {
    const currentDay: Dayjs = dayjs();
    const { expiryDate, trialExpiryDate } = sessionData?.indi || {};
    
    return Boolean(
      (expiryDate && currentDay.isBefore(expiryDate)) ||
      (trialExpiryDate && currentDay.isBefore(trialExpiryDate))
    );
}