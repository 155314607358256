import React from "react";
import styled from "styled-components";


export default function DelimiterWithText({
  text,
  className
}: {
  text?: string;
  className?: string;
}) {

  return (
    <DelimiterWithTextContainer className={className}>{text}</DelimiterWithTextContainer>
  )
}

const DelimiterWithTextContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  text-align: center;
  gap: 1rem;

  color: var(--text-secondary);
  font-weight: 500;
  font-size: 0.875rem;

  &::before,
  &::after {
    content: "";
    flex: 1;
    height: 0.063rem;
    background-color: var(--text-secondary);
  }
`;