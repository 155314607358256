import styled from "styled-components";
import { FlashIcon, FlowerIcon, HeartIcon } from "../../../../assets/icons/synastry";
import { ArrowRight } from "../../../../assets/icons/system/system-icons";
import { ICard, cards } from "../../../payment/helpers/tariffs";
import { Select } from "../../../../components/ui/Select";
import { Button } from "../../../../libs";
import Promocode from "../../../promocode/Promocode";

interface ISynastryUpdater {
  price: number;
  discount: number;
  promocode: string;
  isFreeCharge: boolean;
  disabled?: boolean;
  buy(): void;
  setCard(value: any): void;
  getFreeCharge(): void
  card: ICard;
}

function SynastryFinishing(props: ISynastryUpdater) {
  const tablets: any[] = [{
    icon: <HeartIcon/>,
    text: 'Получите полезные рекомендации по развитию ваших отношений'
  }, {
    icon: <FlowerIcon/>,
    text: 'Узнайте как партнеры воспринимают друг друга в отношениях'
  }, {
    icon: <FlashIcon/>,
    text: 'Получите особенности вашей совместимости по каждой сфере'
  }]

  return (
    <Container className="no-print">
      <IconContainer><ArrowRight/></IconContainer>
      <div className={'title'}>Обновите Синастрию до полной версии</div>

      {tablets.map((item, index) => {
        return (
          <Tablet key={`tablet_${index}`}>
            <div className={'icon-container'}>{item.icon}</div>
            <div>{item.text}</div>
          </Tablet>
        )
      })}

      <PromocodeContainer><Promocode promo={props.promocode ?? ''}/></PromocodeContainer>

			<CardSelectorContainer>
				<Select title="Карта для оплаты" options={cards} value={props.card} onSelect={props.setCard} />
			</CardSelectorContainer>

      <Total>
        <div>Итого к оплате</div>

        {
          props.isFreeCharge
            ? <div>0</div>
            : <Price>
                <span className={'oldPrice'}>{props.discount > 0 ? props.price : ''}</span>
                <span className={'finalPrice'}> {Math.round(props.price * (1 - props.discount) + Number.EPSILON)}{props.card.symbol}</span>
              </Price>
        }

      </Total>

      <CustomButton color={'var(--color-green)'}
                    size={'medium'}
                    onClick={props.isFreeCharge ? props.getFreeCharge : props.buy}
                    disabled={props.disabled}>
          <Price>
            {props.isFreeCharge
              ? <span>Получить бесплатно</span>
              : <>
                  <span>Получить за</span>
                  <span className={'oldPrice'}>{props.discount > 0 ? props.price : ''}</span>
                  <span className={'finalPrice'}> {Math.round(props.price * (1 - props.discount) + Number.EPSILON)}{props.card.symbol}</span>
                </>}
          </Price>
      </CustomButton>
    </Container>
  );
}

export default SynastryFinishing;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;

  .title {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
  }

`

const IconContainer = styled.div`
  color: var(--color-gradus-blue);
  transform: rotate(90deg);
  width: 2.5rem;
  height: 2.5rem;
  margin-bottom: 0.5rem;

  svg {
    width: 100%;
    height: 100%;
  }
`

const Tablet = styled.div`
  display: flex;
  align-items: center;

  color: var(--text-secondary);
  width: 100%;
  background: var(--color-white);
  border: 1px solid var(--border-light);
  box-shadow: var(--box-shadow);
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1rem;
  box-sizing: border-box;

  .icon-container {
    margin-right: 1rem;
  }
`

const Total = styled.div`
  display: flex;
  justify-content: space-between;

  color: var(--text-secondary);
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  .price {
    color: var(--accent-green);
  }
`

const CustomButton = styled(Button)`
  width: 100%;
  height: 3rem;
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.2px;
`

const PromocodeContainer = styled.div`
  width: 100%;
  margin-top: 2rem;

  div:first-child {
    padding: 0 !important;
  }
`

const Price = styled.span`
  span {
    margin-right: 0.2rem;
  }

  .oldPrice {
    text-decoration: line-through;
  }
`

const CardSelectorContainer = styled.div`
	width: 100%;
	margin-bottom: 1rem;
`
