import dayjs from 'dayjs';
import { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gtmSend, searchParams } from '../../../helpers/url';
import store from '../../../store/Store';
import DescriptionBlock from '../components/DescriptionBlock';
import { buttonStyle } from '../../../helpers/ui';
import { getFormatText } from '../helpers/services';
import { Accordion } from '../../../components/ui/Accordion';
import { Hr } from '../../../components/ui/Hr';
import { faq } from '../helpers/content';
import { Button, IServiceDataEx, ISession, SessionFormat, SessionStatus } from '../../../libs';


const SessionInfo = ({
  session,
  service,
}: {
  session: ISession;
  service?: IServiceDataEx | null;
}) => {
  const sp = searchParams();
  const cameFromSuccessPayment = sp.has('payed');
  const payButtonIsClicked = useRef(false);
  const userId = store.sessionData?.id;
  const offlineConsultation = session.data.format === SessionFormat.Offline

  useEffect(() => {
    gtmSend({ 'event': 'indi_consultation-page_visit', 'user_id': userId ? userId : '' })
  }, [])

  const paymentHandler = () => {
    if (session && !payButtonIsClicked.current) {
      store.payments.paymentTinkoff(session.id)
        .then((result: any) => {
          payButtonIsClicked.current = true;
          if (result?.url) window.location.href = result.url;
        })
        .catch((err) => {
          console.log('err from sessionInfo payment -', err)
        })
    }
  };

  const getTime = () => {
    return `${dayjs(session.start).format('HH:mm')} - ${dayjs(session.start).add(service?.duration ?? 0, 'minutes').format('HH:mm')}`
  }

  const getDate = () => {
    return dayjs(session.start).format('DD MMMM, dddd')
  }

  const downloadDocument = (index: number) => {
    session.data.documents && window.open(session.data.documents[index].url);
  };

  const onClickFAQ = useCallback((open: boolean) => {
    !open && gtmSend({ 'event': 'indi_consultation-page_faq_click', 'user_id': userId ? userId : '' })
  }, [userId])

  const serviceName = service ? store.getServiceInList(service.id)?.name : '';

  return <>
    {
      !offlineConsultation && <Text>
        Кнопка для подключения активируется за 5 минут до начала консультации
      </Text>
    }

    {
      offlineConsultation
      && !session.data.documents
      && <DescriptionBlock
        style={{ marginBottom: '16px' }}
        text="Менеджер отправит вам уведомление сразу после загрузки астрологом файла с разбором"
      />
    }

    {
      session.data.documents &&
      <Documents>
        <div className={'title'}>Файлы с разбором астролога</div>
        {
          session.data.documents?.map((document, index) => {
            return <Document key={document.name} onClick={() => downloadDocument(index)}>
              {document.name}
            </Document>
          })
        }
      </Documents>
    }

    <Column>
      <Label>Время консультации</Label>
      <Value>{getTime()}</Value>
    </Column>

    <Column>
      <Label>Дата</Label>
      <Value>{getDate()}</Value>
    </Column>

    <Column>
      <Label>Услуга</Label>
      <Value>{`${getFormatText(session.data.format)} формат.`} {serviceName}</Value>
    </Column>

    {
      session.data.format === SessionFormat.Online &&
      <Column>
        <Label>Платформа для связи</Label>
        <Value>
          Zoom
        </Value>
      </Column>
    }

    <StyledHr />

    <Column>
      <Value>Часто задаваемые вопросы</Value>
      {
        faq.map((item, index) => <>
          <Accordion
            key={`session_faq_accordion_${index}`}
            title={item.title}
            content={item.text}
            onClick={onClickFAQ} />
          <Hr />
        </>
        )}

    </Column>

    {
      session.data.status === SessionStatus.Init && !cameFromSuccessPayment &&
      <Button
        style={buttonStyle}
        color="blue"
        onClick={() => paymentHandler()}
        size="big"
      >
        <ButtonContent>Оплатить</ButtonContent>
      </Button>
    }
  </>
}

export default SessionInfo;

const Value = styled.p`
  font-size: 24px;
  color: #000000;
  display: flex;
  align-items: center;
  line-height: 28px;

  > svg {
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }
`;

const Label = styled.p`
  color: rgba(0, 0, 0, 0.37);
  margin-bottom: 4px;
  line-height: 28px;
  font-size: 14px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  font-size: 1rem;
  color: var(--color-gradus-blue);

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.4rem;
  }

  & > span {
    margin-top: 0.1rem;
    opacity: 0.9;
  }
`;
const Text = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 165%;
`;
const Documents = styled.div`
  margin-bottom: 1rem;

  .title {
    font-size: 1.3rem;
  }
`;
const Document = styled.div`
  color: var(--color-gradus-blue);
  cursor: pointer;
`;

const StyledHr = styled(Hr)`
  margin: 1rem 0 1.25rem 0;
`
