import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { capitalize } from '../../../helpers/strings';
import { getFormattedInterval } from '../../../helpers/dates';
import Actions from './Actions';

import { ArrowRight as ArrowIcon, PinIcon, ClockIcon2 as ClockIcon } from '../../../assets/icons/system/system-icons';
import { PinButton, Row } from '../../../components/legacy';


export default function OtherItems(
  {
    icon,
    title,
    items,
    extended = false,
    isPinned = false,
    togglePin = () => {},
  }: {
    icon?: any;
    title?: string;
    items?: {
      title?: string;
      time?: string;
      onClick?(): void;
      started?: boolean;
      period?: {
        start: string;
        end: string;
      };
    }[];
    extended?: boolean;
    isPinned?: boolean;
    togglePin?: (v: any) => void;
  }
) {

  return (
    <>
      { (title || icon) &&
          <Row mb="0" align="center" justify="space-between">
            <Row mb="0" align="center" gap="0.75rem">
              {icon}
              {isPinned && <PinButton><PinIcon /></PinButton>}
              {title && <Title>{title}</Title>}
            </Row>
            { !extended &&
                <Actions
                  actions={[
                    {
                      label: isPinned ? 'Открепить' : 'Закрепить',
                      action: togglePin,
                    },
                  ]}
                />
            }
          </Row>
      }
      <Container>
        { items?.map((item, i: number) => {
            const period = item.period?.start && item.period?.end
              ? getFormattedInterval(item.period.start, item.period.end)
              : null;

            const itemStart = item?.period?.start || item?.time
              ? dayjs(item?.period?.start || item?.time, 'DD.MM.YYYY HH:mm:ss')
              : null;

            const isStarted = itemStart
              ? dayjs().isAfter(itemStart)
              : false;

            const timeLeft = isStarted
              ? 'Уже началось'
              : itemStart
                  ? `До начала ${dayjs().to(itemStart, true)}`
                  : '';

            return (
              <Item clickable={!!item.onClick} onClick={item.onClick} key={`item_${i}`}>
                <InfoWrapper>
                  { item.title
                      ? <>
                          <ItemTitle>{item.title}</ItemTitle>
                          <ItemTime started={isStarted}>
                            <ClockIcon />
                            {timeLeft}{period && ' • '}{period}
                          </ItemTime>
                        </>
                      : period
                          ? <ItemTitle>{period}</ItemTitle>
                          : <ItemTitle>{capitalize(dayjs(item.time, 'DD.MM.YYYY HH:mm:ss').format('dd, D MMM'))}</ItemTitle>
                  }
                </InfoWrapper>
                {item.title && item.onClick &&
                  <LinkWrapper>
                    <ArrowIcon />
                  </LinkWrapper>
                }
              </Item>
            )
        })}
      </Container>
    </>
  );
}

const Title = styled.h3`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.1666;
  color: var(--color-black);
  margin: 0;
`;

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #F3F3F3;
  box-shadow: 0px 3.2px 14px rgba(0, 0, 0, 0.06), 0px 0.6px 1.8px rgba(0, 0, 0, 0.04);
  border-radius: 1rem;
  overflow: hidden;
`;

const Item = styled.div<{clickable?: boolean;}>`
  border-bottom: 1px solid #F3F3F3;
  padding: 0.875rem 1.25rem 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${p => p.clickable ? 'pointer' : 'initial'};

  &:last-child {
    border-bottom: none;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const LinkWrapper = styled.div`
  color: var(--text-secondary);
`;

const ItemTitle = styled.h4`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875;
  color: var(--text-primary);
  margin: 0;
`;

const ItemTime = styled.p<{started?: boolean;}>`
  font-size: 0.875rem;
  line-height: 1.5;
  color: ${p => p.started ? 'var(--color-green)' : 'var(--text-secondary)'};
  margin: 0;

  display: inline-flex;
  align-items: center;
  gap: 0.5rem;

  & > svg {
    width: 1rem;
    height: 1rem;
    stroke: ${p => p.started ? 'rgba(81, 135, 25, 0.37)' : 'var(--text-third)'};
  }
`;