import { IUser } from "../libs";

export const profile: IUser = {
  id: -1,
  sessionId: -1,
  permissions: null,
  showWelcomeInstruments: false,
  firstName: "",
  gender: null,
  lastName: '',
  levelOfAstrology: 2,
  phoneNumber: null,
  email: null,
  avatarUrl: null,
  birth: {
    dateTime: '',
    gmt: 0
  },
  partner: ''
} as any; // FIXME: any
