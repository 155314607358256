import styled from "styled-components";
import bgPath from '../../assets/images/purple-bg.jpg';
import { observer } from "mobx-react-lite";
import { MainPadding } from "../../components/layout/elements";
import { LogoWhiteIcon } from "../../assets/icons/system/system-icons";
import { useCallback, useEffect, useState } from "react";
import store from "../../store/Store";
import { Loading } from "./components/Loading";
import { Form } from "./components/Form";


export const OnboardingDataFilling = observer(() => {
  const [loading, setLoading] = useState(false)
  const profile =  store.profile

  useEffect(() => {
    if (profile.firstName) setLoading(true)
  }, [profile])

  const finishLoading = useCallback(() => {
    setLoading(false)
  }, [setLoading])

  return (
    <Container>
      <LogoContainer>
        <LogoWhiteIcon/>
      </LogoContainer>

      {
        loading
          ? <Loading finishLoading={finishLoading}/>
          : <Form startLoading={() => setLoading(true)}/>
      }

    </Container>
  )
}
)
const Container = styled(MainPadding)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--color-paywall-bg) url(${bgPath});
  background-size: cover;
  background-position: center center;
  padding: 1.5rem;
  z-index: var(--z-index-modal);

  overflow: scroll;
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 5rem;
  padding-bottom: 3.5rem;
`
