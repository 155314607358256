import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { MainPadding, WindowContainer } from '../../components/layout/elements';
import { windowsStore } from '../../store/Windows';
import { PaymentCommon } from './components/payment-common';
import { PaymentPaywall } from './components/payment-paywall';
import { Indi } from '../../libs';

import PaymentPaywallTariffs from './components/payment-paywall-tariffs';
import { TariffPeriod,  } from './helpers/tariffs';
import { FreePeriod } from './helpers/tariffs';


export const WINDOW_ID_PAYMENT: string = 'WINDOW_ID_PAYMENT';

export const Payment = observer(({
  selectedTariff,
  freePeriod,
  trialPeriod = 7,
  direct,
}: {
  selectedTariff: Indi.indiGetTariffs.Tariff,
  freePeriod?: FreePeriod,
  trialPeriod?: number,
  direct?: boolean,
}) => {

  const onClose = async () => {
    if (freePeriod) {
      window.confirm('Регистрация уже пройдена, вам осталось только активировать триал. Вы точно хотите выйти?');
    }

    windowsStore.close(WINDOW_ID_PAYMENT);
  }

  return <WindowContainer>
    {
			freePeriod
				? <PaymentPaywallTariffs
            onClose={onClose}
						tariffPeriod={TariffPeriod.MONTH}
					  freePeriod={freePeriod}
            trialPeriod={trialPeriod}
            direct={direct}
          />
				: <PaymentCommon onClose={onClose} tariff={selectedTariff} />
		}
  </WindowContainer>
})

export const PaymentHeader = styled(MainPadding)`
  margin: 0.5rem auto 0;
`

export const PaymentContent = styled(MainPadding)`
  flex: 1;
`

export const PaymentFooter = styled(MainPadding)`
  height: min-content;
`
