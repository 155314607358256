import styled from "styled-components"
import { FlexContainer } from "../layout/elements"
import { ReactNode } from "react"

interface ListProps {
	className?: string
	icon: ReactNode
	title: string
	items: string[]
}

export const List = (props: ListProps) => {

	return (
		<Container className={props.className ?? ''}>
			<Title>
        {props.icon}
				{props.title}
      </Title>

      <Items>
        {props.items.map((item, i) => <Item key={i}>{item[0].toUpperCase() + item.substring(1)}</Item>)}
      </Items>
		</Container>
	)
}

const Container = styled.div`
`

const Title = styled(FlexContainer)`
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.5rem;
  font-weight: 500;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const Items = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
`

const Item = styled.li`
	display: flex;
  background: var(--input-background);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
`
