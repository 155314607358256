import React from 'react';
import styled from "styled-components";
import {MenuItem, MenuPosition} from "./types";
import DropDownMenuItem from "./DropDownMenuItem";

interface IDropDownListProps {
    items: MenuItem[]
    subMenuSidePosition: MenuPosition
    onClose: () => void
}

export default React.memo(function DropDownList(props: IDropDownListProps) {
    return (
        <Container className='containerD'>
            {
                props.items.map((item, index) => <DropDownMenuItem item={item}
	                                                                   subMenuSidePosition={props.subMenuSidePosition}
	                                                                   onClose={props.onClose}
	                                                                   key={index}/>)
            }
        </Container>
    );
})

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 0.438rem 0;
`;

const Delimiter = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  max-height: 1px;
  margin: 0.375rem 0;
  padding: 0;
  background-color: var(--button-border);
`;