import { s3urls } from "../../../helpers/api";

const print = () => {
  setTimeout(() => {window.print()}, 100)
}

export const getContextMenuState = (saveLink: () => void, buyProduct: () => void) => [
  [
    {
      icon: null,
      title: 'Скопировать ссылку',
      action: saveLink,
    },
  ],
  [
    {
      icon: null,
      title: 'Скачать в PDF',
      action: print,
    },
  ],
  [
    {
      icon: null,
      title: 'Вернуться к покупкам',
      action: buyProduct,
    },
  ],[
    {
      icon: null,
      title: 'Сообщить о проблеме',
      className: 'drop',
      action: () => {
        const otherWindow = window.open();
        otherWindow!.opener = null;
        // @ts-ignore
        otherWindow!.location = s3urls.support;
      },
    },
  ],
];

export const getContextMenuStateButton = (saveLink: () => void,) => [
  [
    {
      icon: null,
      title: 'Скопировать ссылку',
      action: saveLink,
    },
  ],
  [
    {
      icon: null,
      title: 'Скачать в PDF',
      action: print,
    },
  ],
];
