import React from 'react';
import { TextInput } from '../ui/TextInput';
import { IPlace } from '../../libs';
import { gtmSend, searchParams } from '../../helpers/url';
import store from 'src/store/Store';
import { observer } from 'mobx-react-lite';

export const PrognosesForm = observer(({
  name,
  nameOnChange,
  nameError,
  date,
  dateOnChange,
  dateOnInvalid,
  dateError,
  time,
  timeOnChange,
  timeOnInvalid,
  timeError,
  timeNotion,
  place,
  placeOnChange,
  placeError,
  minDate,
  maxDate,
  originalDate,
  sendAnalitics = false,
  direct
}: {
  name: string,
  nameOnChange: (value: string) =>  void,
  nameError: string,
  date: string,
  dateOnChange: (value: string) =>  void,
  dateOnInvalid: () => void,
  dateError: string,
  time: string,
  timeOnChange: (value: string) =>  void,
  timeOnInvalid: () => void,
  timeError: string,
  timeNotion?: string
  place: IPlace,
  placeOnChange: (value: IPlace) =>  void,
  placeError: string,
  minDate?: string,
  maxDate?: string,
  originalDate?: string,
  sendAnalitics?: boolean
  direct?: boolean
}) => {
  const userId = store.sessionData?.id || 'unauthorized'
  const sp = searchParams()

  return <>
    <TextInput
      value={name}
      onChange={nameOnChange}
      title={'Имя'}
      error={nameError}
      onFocus={() => {
        if (sendAnalitics) {
          const event = !direct
            ? 'indi_prognosis_personal-info_name_click'
            : 'indi_prognos_pers-info_name_click-light'
          gtmSend({
            'event': event,
            'userId': userId,
            'utm_source': sp.get('utm_source'),
          })
        }
      }}
    />

    <TextInput
      value={date}
      onChange={dateOnChange}
      title={'Дата рождения'}
      type={'date'}
      error={dateError}
      minDate={minDate}
      maxDate={maxDate}
      notion={originalDate ? 'Дату можно изменить в пределах одного дня' : ''}
      onInvalid={dateOnInvalid}
      onFocus={() => {
        if (sendAnalitics) {
          const event = !direct
            ? 'indi_prognosis_personal-info_date_click'
            : 'indi_prognos_pers-info_date_click-light'

          gtmSend({
            'event': event,
            'userId': userId,
            'utm_source': sp.get('utm_source'),
          })
        }
      }}
    />

    <TextInput
      value={time}
      onChange={timeOnChange}
      title={'Время рождения'}
      notion={timeNotion}
      type={'time'}
      error={timeError}
      onInvalid={timeOnInvalid}
      onFocus={() => {
        if (sendAnalitics) {
          const event = !direct
            ? 'indi_prognosis_personal-info_time_click'
            : 'indi_prognos_pers-info_time_click-light'

          gtmSend({
            'event': event,
            'userId': userId,
            'utm_source': sp.get('utm_source'),
          })
        }
      }}
    />

    <TextInput
      value={place.name}
      place={place}
      onChange={placeOnChange}
      title={'Место рождения'}
      type={'place'}
      error={placeError}
      onFocus={() => {
        if (sendAnalitics) {
          const event = !direct
            ? 'indi_prognosis_personal-info_place_click'
            : 'indi_prognos_pers-info_place_click-light'

          gtmSend({
            'event': event,
            'userId': userId,
            'utm_source': sp.get('utm_source'),
          })
        }
      }}
    />
  </>
})
