export function showPotentialDay() {
  const icons = document.querySelectorAll<HTMLElement>('.potential-day_icon')
  const percents = document.querySelectorAll<HTMLElement>('.potential-day_percent')
  const mainPageInnerContainer = document.getElementById('main-page-active-subscription-screen-inner-containerRef')

  if (icons && percents && mainPageInnerContainer) {
    icons.forEach(icon => icon.style.zIndex = 'var(--z-index-onboarding-showed-element)')
    percents.forEach(percent => percent.style.zIndex = 'var(--z-index-onboarding-showed-element)')
    mainPageInnerContainer.classList.remove('smooth')
    mainPageInnerContainer.style.transform = 'none'
    mainPageInnerContainer.classList.add('smooth')
  }
}

export function hidePotentialDay() {
  const icons = document.querySelectorAll<HTMLElement>('.potential-day_icon')
  const percents = document.querySelectorAll<HTMLElement>('.potential-day_percent')
  const mainPageInnerContainer = document.getElementById('main-page-active-subscription-screen-inner-containerRef')

  if (icons && percents && mainPageInnerContainer) {
    icons.forEach(icon => icon.style.zIndex = 'inherit')
    percents.forEach(percent => percent.style.zIndex = 'inherit')

  }
}

export const showMainPageText = () => {
  const mainPage = document.getElementById('main-page-active-subscription-screen')
  const screenHeight = window.innerHeight

  if (mainPage && screenHeight < 700) {
    mainPage.scrollTop = 50
  }
}

export const showAstroevents = () => {
  const astroEvents = document.querySelectorAll<HTMLElement>('.astroEvent ')
  const mainPage = document.getElementById('main-page-active-subscription-screen')
  const screenHeight = window.innerHeight

  if (astroEvents && mainPage) {

    if (screenHeight < 700) {
      mainPage.scrollTop = 270
    } else {
      mainPage.scrollTop = 100
    }

    astroEvents.forEach(astroEvent => {
      astroEvent.style.zIndex = String(+astroEvent.style.zIndex + 100)
    })
  }
}

export const hideAstroevents = () => {
  const astroEvents = document.querySelectorAll<HTMLElement>('.astroEvent')
  const mainPage = document.getElementById('main-page-active-subscription-screen')
  const mainPageInnerContainer = document.getElementById('main-page-active-subscription-screen-inner-containerRef')

  if (astroEvents && mainPage && mainPageInnerContainer) {
    astroEvents.forEach(astroEvent => {
      astroEvent.style.zIndex = String(+astroEvent.style.zIndex - 100)
    })

    mainPage.scrollTop = 0

    mainPageInnerContainer.style.transform = 'translateX(-100%)'
    mainPageInnerContainer.classList.add('smooth')
  }
}


export function showShutter() {
  const shutter = document.getElementById('shutter')

  if (shutter) {
    shutter.style.zIndex = 'var(--z-index-onboarding-showed-element)'
  }
}

export function hideShutter() {
  const shutter = document.getElementById('shutter')

  if (shutter) {
    shutter.style.zIndex = 'var(--z-index-shutter)'
  }
}
