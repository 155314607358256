import React from 'react'
import styled from 'styled-components'
import { Button } from '../../../components/ui/Button/Button'
import store from '../../../store/Store'
import { openSmsAuthWindow } from '../../registration/sms'

export default function EmptyPurchasedProducts() {

  const toAuth = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
    if (event) {
      openSmsAuthWindow();
      event.stopPropagation();
    }
  }

  return (
    <Container>
      <div className="title">У вас пока нет покупок</div>
      {!store.isAuth && <div className="auth">Авторизуйтесь, чтобы получить доступ к купленным продуктам</div>}
      {!store.isAuth && <StyledButton onClick={toAuth}>Войти</StyledButton>}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 70vh;
  line-height: 1rem;


  .title {
    font-size: 1.2rem;
    color: var(--text-secondary);
    margin-bottom: 1rem;
  };

  .auth {
    text-align: center;
    color: var(--text-third);
    margin-bottom: 2rem;
  }
`

const StyledButton = styled(Button)`
  width: 60%;
  font-size: 1rem;
`
