import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FlexContainer } from '../layout/elements';
import { SelectDropdown } from './SelectDropdown';
import { ArrowRight, ChevronDownIcon } from '../../assets/icons/system/system-icons';

export type ISelectOptions = {
  id: string,
  title: string
}

export const Select = <T extends ISelectOptions>({
  title,
  options,
  value,
  onSelect,
  plain = false,
  className
}: {
  title: string,
  options: T[],
  value: T,
  plain?: boolean;
  onSelect: (value: T) => void;
  className?: string;
}) => {
  const [ showDropdown, setShowDropdown ] = useState(false);

  return <Container className={className}>
    { Boolean(title) && !plain && <Title>{title}</Title> }
    
    <SelectContainer plain={plain} onClick={() => setTimeout(() => setShowDropdown(true), 0)}>
      <Current plain>{value.title}</Current>
      {!plain ? <Edit>Изменить</Edit> : <ChevronDownIcon/>}
    </SelectContainer>

    {showDropdown && 
      <SelectDropdownContainer plain={plain}>
        <SelectDropdown
          value={value}
          options={options}
          onCancel={() => setShowDropdown(false)}
          onSelect={onSelect}
          plain={plain}
        />
      </SelectDropdownContainer>
    }

  </Container>
}

const Container = styled.div`
  position: relative;
`

const Title = styled.div`
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
`

const SelectContainer = styled(FlexContainer)<{ plain: boolean }>`
  position: relative;
  padding: 0.875rem 0.75rem;
  
  ${p => p.plain && css`
    padding: 0.5rem 0.75rem;
  `}
  
  /* padding-right: 1rem; */
  align-items: center;
  background: var(--input-background);
  justify-content: space-between;
  cursor: pointer;
  border-radius: 12px;
`

const Current = styled.div<{ plain: boolean }>`
  color: var(--text-primary);
  font-size: ${p => p.plain ? '0.75rem' : '1.25rem'};
  font-weight: 500;
`

const Edit = styled.div`
  font-size: 0.75rem;
`

const SelectDropdownContainer = styled.div<{ plain: boolean }>`
  position: absolute;
  top: 3rem;
  left: 20%;
  width: auto;
  
  ${p => p.plain && css`
    top: 0;
    left: 0;
  `}
  z-index: var(--z-index-dropdown);
`
