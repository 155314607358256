import { history } from 'src/MainRouter';
import { Banner } from 'src/components/banners/Banner/Banner';
import { gtmSend, searchParams } from 'src/helpers/url';
import { openPaymentPaywallWindow, WINDOW_PAYMENT_PAYWALL } from 'src/modules/payment/components/payment-paywall';
import { FreePeriod, TariffPeriod } from 'src/modules/payment/helpers/tariffs';
import { windowsStore } from 'src/store/Windows';
import styled, { css } from 'styled-components';
import { ChevronRightIcon } from '../../../../assets/icons/system/system-icons';
import bannerBackground from '../../../../assets/images/banners/free_fourteen.jpg';
import store from 'src/store/Store';
import { observer } from 'mobx-react-lite';


export const addUTM = (sp: URLSearchParams, product: string) => {
  sp.append('d', '14')
  sp.append('utm_source', `indi_${product}`)
  sp.append('utm_medium', 'banner')
  sp.append('utm_campaign', '14days')
}

export const removeUTM = (sp: URLSearchParams) => {
  sp.delete('d')
  sp.delete('utm_source')
  sp.delete('utm_medium')
  sp.delete('utm_campaign')
}

interface FreeFourteenBannerProps {
  className?: string
  product: string
}

export const FreeFourteenBanner = observer((props: FreeFourteenBannerProps) => {
  const {
    className,
    product,
  } = props

  const userId = store.sessionData?.id

  const sp = searchParams()

  const onClickBanner = () => {
    gtmSend({ 'event': `indi_${product}_ad_14days`, 'user_id': userId ? userId : '' })
    
    const onClose = () => {
      windowsStore.close(WINDOW_PAYMENT_PAYWALL)
      removeUTM(sp)
      history.push({pathname: window.location.pathname, search: sp.toString()})
    }

    addUTM(sp, product)
    openPaymentPaywallWindow(TariffPeriod.MONTH, onClose, FreePeriod.FOURTEEN_FREE_DAYS)
    history.push({pathname: window.location.pathname, search: sp.toString()})
  }

  return (
    <StyledBanner
      className={className}
      label='Chronos Plus'
      title='Только для вас, подписка на 14 дней бесплатно!'
      buttonContent={<ButtonContent>Попробовать 14 дней <ChevronRightIcon/></ButtonContent>}
      onClick={onClickBanner}
      backgroundImg={bannerBackground}
      buttonColor='blue-secondary'
    />
  )
})

const StyledBanner = styled(Banner)`
  margin-top: 0.5rem;

  ${p => p.backgroundImg && css`
    background: url(${p.backgroundImg}) no-repeat right bottom / cover;
  `}

  .label {
    color: var(--color-blue-secondary);
  }

  .title {
    color: var(--text-primary);
  }

  .text {
    color: var(--text-primary);
  }

  .banner_button {
    margin-top: 1.25rem;
    width: fit-content;
  }
`

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.875rem;
  padding: 0 0.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--color-white);
  }
`
