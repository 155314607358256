import { useRef } from 'react';
import { SwipeEventData, useSwipeable } from 'react-swipeable';

export const swipeAndCall = (e: SwipeEventData, callback: () => void) => {
  if((e.event.target as HTMLElement).closest('.no-swipe')) return;
  if(e.velocity > 0.5 && Math.abs(e.deltaX) > 80) {
    callback();
  }
}

export const swipeAndCallV = (e: SwipeEventData, callback: () => void) => {
  if((e.event.target as HTMLElement).closest('.no-swipe')) return;
  if(e.velocity > 0.6 && Math.abs(e.deltaY) > 80) {
    callback();
  }
}

export const useHorizontalSwipes = <T extends HTMLElement>(
  onSwipedLeft: () => void,
  onSwipedRight: () => void
) => {
  const ref = useRef<T>(null);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: (e) => swipeAndCall(e, onSwipedLeft),
    onSwipedRight: (e) => swipeAndCall(e, onSwipedRight),
  });

  return {
    ref,
    swipeHandlers
  }
}

export const useVerticalSwipes = <T extends HTMLElement>(
  onSwipedDown: () => void
) => {
  const ref = useRef<T>(null);

  const swipeHandlers = useSwipeable({
    onSwipedDown: (e) => swipeAndCallV(e, onSwipedDown),
  });

  return {
    ref,
    swipeHandlers
  }
}