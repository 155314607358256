import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import styled, { css } from "styled-components"
import Overlay from "../../../components/Overlay"
import { Colors, Positions, Tip } from "../../../components/Tip"
import { gtmSend } from "../../../helpers/url"
import { getMainPageLS, setMainPageLS } from "../../../store/localStorageHelpers"
import store from "../../../store/Store"
import { OnboardingModal } from "./components/onboarding-modal"
import { hideAstroevents, hidePotentialDay, showAstroevents, showMainPageText, showPotentialDay } from "./helpers/dom"
import { observer } from "mobx-react-lite"
import { isMobile } from "react-device-detect"


interface MainPageOnboardingProps {
  hideOnboarding: () => void
  mainPageRef: React.RefObject<HTMLDivElement>
}

export const MainPageOnboarding = observer((props:  MainPageOnboardingProps) => {
  const [step, setStep] = useState(0)

  const tipWrapperRef = useRef<HTMLDivElement>(null)

  const userId = store.sessionData?.id
  const mainPageDataLS = useMemo(() => getMainPageLS(), [])

  const setShowedOnboardingLS = useCallback(() => {
    const newData = {
      ...mainPageDataLS,
      onboarding: {
        ...mainPageDataLS.onboarding,
        showedOnboarding: true,
      }
    }

    setMainPageLS(newData)
  }, [mainPageDataLS])

  useEffect(() => {
    return () => {
      props.hideOnboarding()
      setShowedOnboardingLS()
    }
  }, [setShowedOnboardingLS, props])

  const skipOnboarding = useCallback(() => {
    props.hideOnboarding()
    setShowedOnboardingLS()

    gtmSend({'event': `indi_onboarding_main_skip`, 'user_id': userId ? userId : ''})
  }, [userId, props, setShowedOnboardingLS])

  const startOnboarding = useCallback(() => {
    setStep(1)

    gtmSend({ 'event': 'indi_onboarding_main_start', 'user_id': userId ? userId : '' })
  }, [setStep, userId])

  const getTip = (step: number) => {
    const indicator = {
      current: 0,
      length: 3,
      position: Positions.CENTER,
      size: 0.375,
    }

    switch(step) {
      case 0:
        props.mainPageRef.current?.classList.add('block-scroll')
        return <OnboardingModal skipOnboarding={skipOnboarding} startOnboarding={startOnboarding}/>
      case 1: {
        showPotentialDay()
        indicator.current = 1

        if (tipWrapperRef.current) {
          tipWrapperRef.current.style.position = 'absolute'
          tipWrapperRef.current.style.top = '190px'
        }

        const onClickHandler = () => {
          setStep(step => step + 1)
          hidePotentialDay()
          gtmSend({'event': `indi_onboarding_main_step2`, 'user_id': userId ? userId : ''})
        }

        const button = {
          title: 'Далее',
          onClick: onClickHandler,
          position: Positions.CENTER
        }

        return (
          <Tip
            title="Наблюдайте за потенциалом"
            text="Потенциал дня отражает соотношение благоприятных и напряженных событий, помогая планировать день"
            color={Colors.LIGHT}
            button={button}
            indicator={indicator}
            trianglePosition={Positions.TOP_CENTER}
          />
        )
      }

      case 2: {
        if (!store.onboarding.activeSubscriptionScreenTextRef?.current) return
        const top = store.onboarding.activeSubscriptionScreenTextRef?.current?.getBoundingClientRect().bottom ?? 0
        store.onboarding.activeSubscriptionScreenTextRef.current.classList.add('onboarding_text')
        const screenHeight = window.innerHeight
        const gap = screenHeight > 700 ? 50 : 0
        showMainPageText()

        if (tipWrapperRef.current) {
          tipWrapperRef.current.style.position = 'absolute'
          tipWrapperRef.current.style.top = `${top + gap}px`
        }

        const onClickHandler = () => {
          setStep(step => step + 1)
          props.mainPageRef.current?.classList.remove('block-scroll')
          store.onboarding.activeSubscriptionScreenTextRef?.current?.classList.remove('onboarding_text')
          gtmSend({'event': `indi_onboarding_main_step3`, 'user_id': userId ? userId : ''})
        }

        const button = {
          title: 'Далее',
          onClick: onClickHandler,
          position: Positions.CENTER
        }

        indicator.current = 2

        return (
          <TipWrapper>
            <Tip
              title="Короткое описание отражает атмосферу дня"
              text="Нажмите узнать больше, чтобы посмотреть полное описание и узнать, какие события влияют на вас"
              color={Colors.LIGHT}
              button={button}
              indicator={indicator}
              trianglePosition={Positions.TOP_CENTER}
            />
          </TipWrapper>
        )
      }

      case 3: {
        showAstroevents()

        const top = store.onboarding.widgetsAccordionRef?.current?.getBoundingClientRect().bottom ?? 0

        if (tipWrapperRef.current) {
          tipWrapperRef.current.style.position = 'absolute'
          tipWrapperRef.current.style.top = `${top}px`
        }

        const onClickHandler = () => {
          hideAstroevents()
          props.hideOnboarding()
          setShowedOnboardingLS()
          gtmSend({'event': `indi_onboarding_main_step4_done`, 'user_id': userId ? userId : ''})
        }

        const button = {
          title: 'Завершить',
          onClick: onClickHandler,
          position: Positions.CENTER
        }

        indicator.current = 3

        return (
          <Tip
            title="Исследуйте Астрособытия"
            text="Следите за фазами Луны, затмениями и ретроградными планетами, чтобы заранее узнать, как они могут повлиять на вас"
            color={Colors.LIGHT}
            button={button}
            indicator={indicator}
            trianglePosition={Positions.TOP_CENTER}
          />
        )
      }
    }
  }

  return (
    <StyledOverlay>
      <TipWrapper ref={tipWrapperRef}>
        {getTip(step)}
      </TipWrapper>
    </StyledOverlay>
  )
})

const StyledOverlay = styled(Overlay)`
  z-index: var(--z-index-modal);

  ${!isMobile && css`
    align-items: start;
    padding-top: 6rem;
  `}
`

const TipWrapper = styled.div`
  width: 100%;
  padding: 1rem;
`
