import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Title from '../components/Title';
import store from '../../../store/Store';
import {observer} from 'mobx-react-lite';
import useLS from '../../../hooks/useLS';

import PersonalForm, { IPersonalForm } from '../../../components/forms/PersonalForm';
import { searchParams } from '../../../helpers/url';
import { history } from '../../../MainRouter';
import { SessionFormat, getGMT } from '../../../libs';


export default observer(function PersonalData(): JSX.Element {

  const [formData, setFormData] = useState<IPersonalForm>({ name: '', dateTime: '', place: null });

  const [sessionId] = useLS('session-id');
  const [activeTime] = useLS('active-time');
  const [sessionFormat] = useLS('session-format', SessionFormat.Online);

  const sp = searchParams()

  useEffect(() => {
    if (store.profile.birth.dateTime) {
      setFormData(state => ({ ...state!, dateTime: store.profile.birth.dateTime! }))
    }
    if (store.profile.birth.place) {
      setFormData(state => ({ ...state, place: store.profile.birth.place! }))
    }
  }, [store.profile.birth]);

  useEffect(() => {
    setFormData(state => ({ ...state, name: store.profile.firstName }))
  }, [store.profile.firstName]);

  const updateSession = async (sessionData: any) => {
    const session = sessionId
      ? await store.getSession(sessionId)
      : null;

    if (session && store.sessionData) {
      session.data.fullName = sessionData.fullName || '';
      session.data.birth.place = sessionData.place;
      session.data.birth.dateTime = sessionData.dateTime;
      session.data.birth.gmt = sessionData.gmt;

      await store.updateSession(sessionId, session);
    }
  };

  const confirmHandler = async (formData: IPersonalForm) => {
    const sessionData: any = {};

    sessionData.fullName = formData.name;
    sessionData.dateTime = formData.dateTime;
    sessionData.place = formData.place!;
    sessionData.gmt = getGMT(formData.dateTime, formData.place!.lat, formData.place!.lon);

    await updateSession(sessionData);
    history.push({pathname: '/payment', search: sp.toString()});
  };

  return <PersonalDataContainer>
    <Title
      service={store.currentService}
      sessionFormat={sessionFormat}
      text={`${store.professional?.profile.firstName || ''} ${store.professional?.profile.lastName || ''}`}
      dateTime={activeTime!}
    />
    <Text>
      Ваши данные нужны астрологу для<br/>
      проведения консультации. Без них не<br/>
      получится построить натальную карту и<br/>
      получить точный результат.<br/>
    </Text>
    <PersonalForm data={formData} submitTitle='Продолжить' onSubmit={confirmHandler} />
  </PersonalDataContainer>
});


const PersonalDataContainer = styled.main`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const Text = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  margin-bottom: 1rem;
  line-height: 165%;
`;
