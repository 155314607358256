import styled from "styled-components";
import { CloseIcon } from './CloseIcon';

export const TrialClose = ({
  onClick,
	className,
}: {
  onClick: () => void;
	className?: string
}) => {
  return <TrialCloseContainer className={className ?? ''}>
    <CloseIcon onClick={onClick} />
  </TrialCloseContainer>
}

const TrialCloseContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: var(--button-z-index);

  > div {
    background: var(--input-background);
    color: var(--input-border);
  }
`
