import styled from "styled-components"
import { MyDayPinIcon } from "../../assets/icons/myday"
import { memo } from "react"

interface PinProps {
  pinned: boolean
  setPinned(): void
}

export const Pin = memo((props: PinProps) => {
  return (
    <Container onClick={props.setPinned} className={'pin'}>
      <Circle pinned={props.pinned}>
				<MyDayPinIcon/>
			</Circle>
    </Container>
  )
})

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 2rem;
	height: 2rem;
	margin-left: 0.3rem;
`

const Circle = styled.div<{pinned: boolean}>`
  padding: 0.2rem;
  border-radius: 50%;
  color: ${p => p.pinned ? 'var(--color-white)' : 'var(--color-black)'};
  background: ${p => p.pinned ? 'var(--color-black)' : 'var(--color-white)'};
  filter: var(--shadow);
`
