import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { ReactComponent as MoonIconDecreasing1 } from '../../../assets/icons/moon/moon-icon-for-wiget/moon-icon-decreasing1.svg';
import { ReactComponent as MoonIconDecreasing2 } from '../../../assets/icons/moon/moon-icon-for-wiget/moon-icon-decreasing2.svg';
import { ReactComponent as MoonIconFull } from '../../../assets/icons/moon/moon-icon-for-wiget/moon-icon-full.svg';
import { ReactComponent as MoonIconGrowing1 } from '../../../assets/icons/moon/moon-icon-for-wiget/moon-icon-growing1.svg';
import { ReactComponent as MoonIconGrowing2 } from '../../../assets/icons/moon/moon-icon-for-wiget/moon-icon-growing2.svg';
import { ReactComponent as MoonIconNew } from '../../../assets/icons/moon/moon-icon-for-wiget/moon-icon-new.svg';
import { ArrowRightInCircle } from '../../../assets/icons/system/system-icons';
import { gtmSend } from '../../../helpers/url';
import store from '../../../store/Store';
import { openMoonPhase } from '../../moon-phase/moon-phase';
import { MainPageBlockContainer, MainPageBlockHeader } from './main-page-blocks-world';

export const MainPageBlocksMoon = observer(() => {

  const isAuthFinished = store.isAuthFinished;
  const dayData = store.prognoses.getDay(dayjs().local());
  const status = store.events.status;
  const userId = store.sessionData?.id;

  const prognoses = store.prognoses.prognoses;

  const moon = useMemo(() =>
    prognoses && store.prognoses.getMoonPhase()
  , [prognoses]);

  const moonStatus = useMemo(() =>
  prognoses && store.prognoses.getMoonStatus()
  , [prognoses]);

  const moonDay = moonStatus?.day || null;
  const moonPhase = moonStatus?.phase || null;
  const titleMoon = moonPhase === 'NEW' ? 'Новолуние' :
   moonPhase === 'FULL' ? 'Полнолуние' :
   moonPhase === 'GROWING' ? 'Растущая' :
   moonPhase === 'DECREASING' ? 'Убывающая' : null;

  const openMoonPopup = () => {
    if (!dayData) return;

    openMoonPhase(dayData.id, moonPhase)
  }

  const onclickHandler = () => {
    openMoonPopup()
    gtmSend({'event': 'ind_prognosis_general_common-prognosis-moon-bunner_click', 'user_id': userId ? userId : ''})
  }

  if(!isAuthFinished || !status || !moon) {
    return <MainPageBlockContainer>
      <Skeleton style={{'width': '100%', 'height': '100%'}} />
    </MainPageBlockContainer>
  }

  return <Container onClick={onclickHandler} className='ob-0'>
    <MainPageBlockHeader>
      {moonDay} лунные сутки
      <ArrowRightInCircle />
    </MainPageBlockHeader>
    { typeof(moonDay) === 'number'
        ? <Moon>
          {moonPhase === 'NEW' ? <MoonIconNew/> :
           moonPhase === 'GROWING' && moonDay < 8 ? <MoonIconGrowing1/> :
           moonPhase === 'GROWING' && moonDay >= 8 ? <MoonIconGrowing2/> :
           moonPhase === 'FULL' ? <MoonIconFull/> :
           moonPhase === 'DECREASING' && moonDay < 22 ? <MoonIconDecreasing1/> :
           moonPhase === 'DECREASING' && moonDay >= 22 ? <MoonIconDecreasing2/> : null }
          </Moon>
        : null
    }
    <Title>{titleMoon}</Title>
  </Container>
})

const Container = styled(MainPageBlockContainer)`
  background: linear-gradient(180deg, #BFC6FF -22.9%, #C0C6FF -15.25%, #C1C7FF -7.6%, #C4C9FF 0.06%, #C8CDFF 7.71%, #CDD1FF 15.36%, #D3D5FF 23.02%, #DADBFF 30.67%, #E1E0FE 38.32%, #E7E5FE 45.98%, #EDEAFE 53.63%, #F2EEFE 61.29%, #F6F1FE 68.94%, #F9F3FE 76.59%, #FBF5FE 84.25%, #FBF5FE 91.9%);
`

const Title = styled.div`
  position: absolute;
  padding: 0.875rem 0.875rem;
  bottom: 0;
  font-size: 1rem;
  font-weight: 500;
`

const Moon = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
