import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { siteKey } from "../helpers/recaptcha";

export * from "react-google-recaptcha";

export type TCaptchaTheme = 'light' | 'dark';
export type TCaptchaType = 'image' | 'audio';
export type TCaptchaSize = 'compact' | 'normal' | 'invisible';
export type TCaptchaBadge = 'bottomright' | 'bottomleft' | 'inline';

export type TReReCaptchaApi = {
    executeAsync: () => Promise<string | null | undefined>
    reset: () => void
}

export type IReRecaptchaProps = {
    size?: TCaptchaSize;
    badge?: TCaptchaBadge;
}

export default React.forwardRef<TReReCaptchaApi, IReRecaptchaProps>(function ReRecaptcha({
    size = 'normal',
    badge = 'inline',
}: IReRecaptchaProps, ref: any) {

    const captchaRef = React.useRef<ReCAPTCHA>(null)

    React.useImperativeHandle(ref, () => ({
        executeAsync: async () => {
            return await captchaRef.current?.executeAsync();
        },
        reset: () => {
            captchaRef.current?.reset();
        }
    }), []);

    return (
        <div className="re-re-captcha" ref={ref}>
            <ReCAPTCHA
              sitekey={siteKey}
              size={size}
              badge={badge}
              ref={captchaRef}
            />
        </div>
    );

})

