import { observer } from "mobx-react-lite"
import { useState } from "react"
import styled from "styled-components"
import { MainPadding, WindowContainer } from "../../components/layout/elements"
import { Button } from "../../components/ui/Button/Button"
import { TextInput } from "../../components/ui/TextInput"
import { isEmailInvalid } from "../../helpers/fields"
import { gtmSend } from "../../helpers/url"
import store from "../../store/Store"
import { windowsStore } from "../../store/Windows"
import { WindowHeader } from "../windows/components/WindowHeader"


export const WINDOW_ID_SUPPORT: string = 'WINDOW_ID_SUPPORT'

export const openSupport = (showToaster?: () => void) => windowsStore.open(
  WINDOW_ID_SUPPORT,
  <Support showToaster={showToaster}/>,
)

enum MailErrors {
  EMAIL = 'Укажите корректный адрес',
  TITLE = 'Укажите тему письма',
  TEXT = 'Напишите сообщение',
}

interface SupportProps {
  showToaster?: () => void
}

export const Support = observer((props: SupportProps) => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const [title, setTitle] = useState('')
  const [titleError, setTitleError] = useState('')

  const [text, setText] = useState('')
  const [textError, setTextError] = useState('')

  const userId = store.sessionData?.id

  const onClose = () => windowsStore.close(WINDOW_ID_SUPPORT)

  const onChangeEmailHandler = (value: string) => {
    setEmail(value);
    setEmailError('');
  }

  const onChangeTitleHandler = (value: string) => {
    setTitle(value);
    setTitleError('');
  }

  const onChangeTextHandler = (value: string) => {
    setText(value);
    setTextError('');
  }

  const checkMail = () => {
    const { emailError, titleError, textError } = {
      emailError: !email || isEmailInvalid(email),
      titleError: !title,
      textError:  !text
    }

    if (emailError) setEmailError(MailErrors.EMAIL)
    if (titleError) setTitleError(MailErrors.TITLE)
    if (textError) setTextError(MailErrors.TEXT)

    return emailError || titleError || textError
  }

  const sendMail = () => {
    if (checkMail()) return

    store.support.sendMailToSupport(email, title, text)
    gtmSend({'event': 'indi_profile_support_form_sent', 'user_id': userId})
    props.showToaster?.()
    onClose()
  }

  return (
    <WindowContainer>
      <WindowHeader title={'Поддержка'} onBack={onClose}/>
      <Container>
        <div>
          <Description>
            <div className={'title'}>Связаться с поддержкой</div>
            <div className={'text'}>Есть вопросы или нужна помощь? Напишите нам, и мы ответим по электронной почте</div>
          </Description>

          <Inputs>
            <TextInput
              title={'Почта для связи'}
              value={email}
              onChange={onChangeEmailHandler}
              placeholder={'example@domain.ru'}
              error={emailError}
            />

            <TextInput
              title={'Тема обращения'}
              value={title}
              onChange={onChangeTitleHandler}
              placeholder={'Укажите тему вашего обращения'}
              error={titleError}
            />

            <TextInput
              value={text}
              onChange={onChangeTextHandler}
              placeholder={'Расскажите подробнее о своей проблеме и пожеланиях'}
              style={{'minHeight': '10rem'}}
              textarea
              error={textError}
            />
          </Inputs>
        </div>

        <Footer>
          <StyledButton onClick={sendMail}>Отправить</StyledButton>
          <StyledButton onClick={onClose} color={'gray'}>Вернуться</StyledButton>
        </Footer>
      </Container>
    </WindowContainer>
  )
})

const Container = styled(MainPadding)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  width: 100%;
`

const Description = styled.div`
  .title {
    font-size: 1.25rem;
    font-weight: 500;
  }

  .text {
    color: var(--text-secondary);
    margin-top: 0.5rem;
  }
`

const Inputs = styled.div`
  margin-top: 1.5rem;
`

const Footer = styled.div`
`

const StyledButton = styled(Button)`
  margin-top: 1rem;
`
