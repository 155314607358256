import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import MoonImgFull from '../../assets/icons/moon/png/moon-img-full.png';
import MoonImgGrowingOne from '../../assets/icons/moon/png/moon-img-growing-1.png';
import MoonImgGrowingTwo from '../../assets/icons/moon/png/moon-img-growing-2.png';
import MoonImgNew from '../../assets/icons/moon/png/moon-img-new.png';
import MoonImgWaningOne from '../../assets/icons/moon/png/moon-img-waning-1.png';
import MoonImgWaningTwo from '../../assets/icons/moon/png/moon-img-waning-2.png';
import { Globe } from '../../assets/icons/system/system-icons';
import InfoBlock, { BlockStatus } from '../../components/InfoBlock';
import Markup from '../../components/Markup';
import { FlexContainer, MainPadding, Row, WindowContainer } from '../../components/layout/elements';
import { getFormattedInterval, isIntervalsIntersect } from '../../helpers/dates';
import { ROUTES } from '../../helpers/routes';
import { capitalize } from '../../helpers/strings';
import { useBackWindow } from '../../hooks/router-hooks';
import store from '../../store/Store';
import { windowsStore } from '../../store/Windows';
import { gmtLocal } from '../prognoses/prognoses-daily';
import { Indi, MoonPhases } from 'src/libs';
import { WindowHeader } from '../windows/components/WindowHeader';
import { useMoveShutter } from '../../hooks/popup-hooks';


const titles: { [key: string]: string } = {
  [Indi.GetMoon.MoonPhase.NEW]: 'Новолуние',
  [Indi.GetMoon.MoonPhase.GROWING]: 'Растущая Луна',
  [Indi.GetMoon.MoonPhase.FULL]: 'Полнолуние',
  [Indi.GetMoon.MoonPhase.DECREASING]: 'Убывающая Луна',
  IDLE: 'Холостая луна',
  NOTIDLE: 'Нет холостой луны',
}

interface MoonPhaseProps {
  id: number
  moonPhase?: string | null
}
export const WINDOW_ID_MOONPHASE: string = 'WINDOW_ID_MOONPHASE';

export const openMoonPhase = (id: number, moonPhase?: MoonPhases | null) => windowsStore.open(
  WINDOW_ID_MOONPHASE,
  <MoonPhase id={id} moonPhase={moonPhase}/>,
  undefined,
  true,
  true,
)

export const MoonPhase = observer((props: MoonPhaseProps) => {
  const [moon, setMoon] = useState<Indi.GetMoon.Response | null>(null)

  const containerRef = useRef<HTMLDivElement>(null)

  const shutterRef = store.shutter.shutterRef

  useMoveShutter(containerRef, shutterRef)

  useBackWindow(ROUTES.MOONPHASE, WINDOW_ID_MOONPHASE);

  const id = props.id;

  const [dayData, moonDescriptions, idleIntervals, haircutIntervals] = useMemo(() => ([
    store.prognoses.getDayById(+id),
    store.prognoses.getMoonPhase()?.descriptions,
    store.prognoses.getIdleIntervals(),
    store.prognoses.getHaircutIntervals(),
  ]), [id]);

  const [isIdle] = useMemo(() => {
    if(dayData && idleIntervals?.length) {
      return [
        !!idleIntervals.find(interval =>
          isIntervalsIntersect(
            dayData.period.start,
            dayData.period.end,
            interval.period?.start,
            interval.period?.end)
        ),
      ]
    }
    return [];
  }, [dayData, idleIntervals]);

  const getMoon = async (day: string) => {
    const formattedDay = dayjs.utc(day, 'DD.MM.YYYY HH:mm:ss')
    let result
    if(formattedDay.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')){
      result = await store.prognoses.getMoon(dayjs().toISOString(), gmtLocal)
    } else {
      const dayMinusGMT = formattedDay.subtract(gmtLocal, 'hour').toISOString();
      result = await store.prognoses.getMoon(dayMinusGMT, gmtLocal)
    }
    setMoon(result)
  }

  useEffect(() => {
    dayData && getMoon(dayData.period.start)
  }, [dayData])

  const isNew = props.moonPhase === "NEW" ? true : false;
  const isFull = props.moonPhase === "FULL" ? true : false;

  const hint = useMemo(() => {
    if(isNew) {
      return moonDescriptions?.find(d => d.type === 'NEW');
    }
    if(isFull) {
      return moonDescriptions?.find(d => d.type === 'FULL');
    }
  }, [isFull, isNew, moonDescriptions]);

  const description = useMemo(() => isIdle
    ? moonDescriptions?.find(d => d.type === 'IDLE')
    : moonDescriptions?.find(d => d.type === 'NOTIDLE')
  , [isIdle, moonDescriptions]);

  const getMoonImage = () => {
    if (moon?.phase === Indi.GetMoon.MoonPhase.NEW) return MoonImgNew
    if (moon?.phase === Indi.GetMoon.MoonPhase.FULL) return MoonImgFull

    if (moon?.phase === Indi.GetMoon.MoonPhase.GROWING) {
      if (moon.day < 8) {
        return MoonImgGrowingOne
      } else {
        return MoonImgGrowingTwo
      }
    }

    if (moon?.phase === Indi.GetMoon.MoonPhase.DECREASING) {
      if (moon.day < 22) {
        return MoonImgWaningOne
      } else {
        return MoonImgWaningTwo
      }
    }
  }

  const onClose = () => {
    windowsStore.close(WINDOW_ID_MOONPHASE)
  }

  return (
    <WindowContainer ref={containerRef}>
      <WindowHeader title='Лунный календарь' onBack={onClose}/>
      {
        moon && (
          <Container>
            <MoonContainer>
              <Moon src={getMoonImage()} />
            </MoonContainer>

            <Title>
              <div className='title'>{titles[moon.phase]}</div>
              <div className='subtitle'>{moon.day} лунные сутки</div>
            </Title>

            { hint && <Row mb="1rem">
              <InfoBlock status={BlockStatus.UPCOMING} icon={<Globe />} title={titles[hint.type]} >
                {hint.text}
              </InfoBlock>
            </Row>}

            { description && <DescriptionBlock>
              <Markup>
                  { titles[description.type] && <h4>{titles[description.type]}</h4> }
                  <p>{description.text}</p>
              </Markup>
            </DescriptionBlock> }

            { idleIntervals?.length
                ? <>
                    <Row mb="0.75rem">
                      <Markup>
                        <h4>Даты холостых лун</h4>
                      </Markup>
                    </Row>
                    { idleIntervals.map((idle, i: number) => {
                        const isStarted = dayjs().isAfter(dayjs(idle.period.start, 'DD.MM.YYYY HH:mm:ss')) && dayjs().isBefore(dayjs(idle.period.end, 'DD.MM.YYYY HH:mm:ss'));
                        const period = getFormattedInterval(idle.period.start, idle.period.end, true);

                        return (
                          <Row mb="0.5rem" key={`idle_intervals_${i}`}>
                            <InfoBlock
                              title={period}
                              titleRight={isStarted ? 'Началась' : null}
                              status={isStarted ? BlockStatus.NEGATIVE : BlockStatus.NEUTRAL}
                            />
                          </Row>
                        );
                    })}
                    <Row mb="1rem" />
                  </>
                : null
            }

            { haircutIntervals?.length
                ? <>
                    <Row mb="0.75rem">
                      <Markup>
                        <h4>Дни для стрижки</h4>
                      </Markup>
                    </Row>
                    {haircutIntervals.map((haircutPeriod, i: number) => {
                      const d = dayjs(haircutPeriod.period.start, 'DD.MM.YYYY HH:mm:ss');
                      const isToday = d.isToday();
                      const timeLeft = isToday
                        ? 'Сегодня хороший день'
                        : capitalize(dayjs().to(d));
                      const status = isToday ? BlockStatus.POSITIVE :  BlockStatus.NEUTRAL;

                      return (
                        <Row mb="0.5rem" key={`haircut_intervals_${i}`}>
                          <InfoBlock title={timeLeft} titleRight={capitalize(d.format('dd, D MMM'))} status={status} />
                        </Row>
                      );
                    })}
                    <Row mb="1rem" />
                  </>
                : null
            }
          </Container>
        )
      }
    </WindowContainer>
  )
})

const Container = styled(MainPadding)`
`

const MoonContainer = styled(FlexContainer)`
  justify-content: center;
  margin: 0rem 0 2rem;
`

const Title = styled(FlexContainer)`
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 2rem;


  .title {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--text-primary);
  }

  .subtitle {
    color: var(--text-secondary);
    margin-top: 0.5rem;
  }
`

const Moon = styled.img`
  width: 5.5rem;
  height: 5.5rem;
`

const DescriptionBlock = styled.div`
  margin-bottom: 1rem;
`
