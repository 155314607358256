import React, { ReactNode } from 'react';
import styled from "styled-components";
import { s3urls } from '../helpers/api';

export enum PolicyTarget {
  SERVICES = 'services',
  PRIVACY_AGREEMENT = 'privacy_agreement',
}

interface Policy {
  country?: string
  action?: string | ReactNode
  fixedPosition?: boolean
  bottom?: number
  text?: ReactNode | string
  className?: string
}

export default React.memo(function Policy(props: Policy) {
  const {
    action = 'Продолжая',
    text,
    className = '',
  } = props

  const getLinks = () => {
    switch (props.country) {
      case 'kz':
      case 'us':
        return (
          <>
            <a target="_blank" href={s3urls.privacyPolicyKZ} rel="noreferrer">политикой конфиденциальности</a>&nbsp;и&nbsp;
            <a target="_blank" href={s3urls.termsOfUseKZ} rel="noreferrer">пользовательским соглашением</a>
          </>
        )
      default:
        return (
          <>
            <a target="_blank" href={s3urls.privacyPolicyRU} rel="noreferrer">политикой конфиденциальности</a>&nbsp;и&nbsp;
            <a target="_blank" href={s3urls.termsOfUseRU} rel="noreferrer">пользовательским соглашением</a>
          </>
        )
    }
  }

  return (
    <Container
      fixedPosition={props.fixedPosition}
      bottom={props.bottom}
      className={className}
    >
      <>
        {
          text
            ? <>{text}</>
            : <>{action}<span>, вы соглашаетесь с </span></>
        }
        {getLinks()}
      </>
    </Container>
  );
})

const Container = styled.div<{ fixedPosition?: boolean, bottom?: number }>`
  margin-top: 1.5rem;
  color: var(--text-secondary);
  font-size: 0.8rem;
  font-weight: 400;
  position: ${p => p.fixedPosition ? 'fixed' : 'relative'};
  bottom: ${p => p.bottom ? `${p.bottom}px` : '0'};
  text-align: center;
  max-width: 550px;
  width: 90%;
  line-height: 1.125rem;
`
