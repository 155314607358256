import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Ok as OkIcon,  DownloadIcon, ArrowRight as ArrowIcon } from '../assets/icons/system/system-icons';
import store from '../store/Store';
import dayjs from 'dayjs';
import 'dayjs/locale/ru.js';
import {observer} from 'mobx-react-lite';
import AvatarImage from '../assets/images/profile-picture.png';
import { secondsToHms } from '../helpers/dates';
import { IServiceDataEx, ISession, ISessionData, SessionFormat, SessionStatus } from '../libs';
dayjs.locale('ru');

export default observer(function SessionCard(props: {
  onClick(): void;
	className?: string;
} & ISession): JSX.Element {
  const [service, setService] = useState<IServiceDataEx | null>(null);
  const [isPassed, setIsPassed] = useState(false);

  useEffect(() => {
    setIsPassed(dayjs(props.start).add(1, 'hour').isBefore(dayjs()));
  }, [props]);

  useEffect(() => {
    setService(store.getService(props.data.serviceId));
  }, [store.services, store.professional, props.data.serviceId]);

  if (!service) return <></>;

  const getStatusText = (sessionData: ISessionData) => {
    if (sessionData.status === SessionStatus.Init) return 'Требуется оплата!';
    if (sessionData.status === SessionStatus.Finished || sessionData.status === SessionStatus.PaidOut) return 'Сеанс завершён';
    if (isPassed && !(sessionData.rate >= 0)) return 'Оцените консультацию';
    if(props.data.format === SessionFormat.Offline) return `Астролог загрузит файл разбора ${dayjs(props.start).fromNow()}, в ${dayjs(props.start).format('HH:mm')}`
    return `${dayjs(props.start).fromNow()}, в ${dayjs(props.start).format('HH:mm')} (${secondsToHms(sessionData.duration)})`;
  };

	const getTitle = () => {
		return props.data.format === SessionFormat.Offline ? 'Письменный анализ с' : 'Онлайн консультация с'
	}

  return <Card onClick={props.onClick} className={props.className ?? ''}>
    <CardHeader>
      <Avatar src={props.avatarUrl || AvatarImage} />
      {isPassed && <IconContainer><OkIcon /></IconContainer>}
      {props.data.documents?.some(({ url }) => url) && <IconContainer><DownloadIcon /></IconContainer>}
    </CardHeader>
    <Row>
      <CardBody>
        <Title>
					{getTitle()} <br/>
          {props.fullName}
        </Title>
        <Description red={props.data.status === SessionStatus.Init}>{getStatusText(props.data)}</Description>
      </CardBody>
      <IconContainer color={'blue'}>
        <ArrowIcon />
      </IconContainer>
    </Row>
  </Card>;
});

const Row = styled.div`
  display: flex;
`

const IconContainer = styled.span<{color?: string;}>`
  display: flex;
  width: 2rem;
  height: 2rem;
  background: ${props => props.color === 'blue' ? 'rgba(71, 96, 231, 0.1)' : 'var(--color-green)'};
  border-radius: 50%;
  margin-top: auto;
  color: var(--color-purpur);

  > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin: auto;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0 3.2px 14px rgba(0, 0, 0, 0.06), 0 0.6px 1.8px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-bottom: 8px;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border: 2px solid transparent;
  margin-right: 16px;
  min-width: 100%;

  :active {
    background: rgba(0, 0, 0, 0.04);
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const Avatar = styled.img`
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  margin-right: 0.5rem;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  flex: 1;
`;

const Title = styled.p`
  font-size: 1.25rem;
	font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  margin: 0 0 8px;
`;

const Description = styled.p<{ red: boolean }>`
  color: ${props => props.red ? 'red' : 'rgba(0, 0, 0, 0.6)'};
  font-size: 14px;
`;
