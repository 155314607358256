import React from "react";
import styled, { css } from "styled-components";
import { observer } from 'mobx-react-lite';
import dayjs from "dayjs";
import api, {getHTTPMessage } from "../../../helpers/api";
import { windowsStore } from "../../../store/Windows";
import { popupsStore } from "../../../store/Popups";
import { TariffPeriod, FreePeriod, ICard, cards } from "../helpers/tariffs";
import { TrialClose } from "../../paywall/components/Close/TrialClose";
import store from "../../../store/Store";
import { defaultPlace } from "../../registration/Registration";
import { history } from "../../../MainRouter";
import { numberWord } from "../../../helpers/strings";
import { TariffSwitcherNew } from '../../paywall/components/TariffSwitcherNew';
import { Select } from '../../../components/ui/Select';
import { Button } from "../../../components/ui/Button/Button";
import Policy from "../../../components/Policy";
import { PromocodeButton } from "../../promocode/PromocodeButton";
import ym from "react-yandex-metrika";
import { gtmSend, searchParams } from "../../../helpers/url";
import { PartnerLinkTargets, getUTMCommentForCP, show } from "src/libs";
import ReRecaptcha, { TReReCaptchaApi } from "../../../components/ReRecaptcha";


export const WINDOW_PAYMENT_PAYWALL_TARIFFS = 'WINDOW_PAYMENT_PAYWALL_TARIFFS';

export const AUTOTEST_EMAILS = process.env.REACT_APP_AUTOTEST_EMAILS?.replace(/\s/g, '').split(',');

export const openPaymentWithTariffsWindow = (tariffPeriod: TariffPeriod, onClose: () => void, freePeriod?: FreePeriod, trialPeriod?: number) => {
  windowsStore.open(
    WINDOW_PAYMENT_PAYWALL_TARIFFS,
    <PaymentPaywallTariffs
      tariffPeriod={tariffPeriod}
      onClose={onClose}
      freePeriod={freePeriod}
      trialPeriod={trialPeriod} />,
    'fade'
  )
}

const PaymentPaywallTariffs = observer(function ({
  tariffPeriod,
  freePeriod,
  trialPeriod = 7,
  onClose,
  direct
}: {
  tariffPeriod: TariffPeriod,
  freePeriod?: FreePeriod,
  trialPeriod?: number
  onClose: () => void;
  direct?: boolean;
}) {

  const isAutotest = AUTOTEST_EMAILS?.includes(store.profile.email || '');

  const isAuth = store.isAuth;
  const userId = store.sessionData?.id || undefined;

  const { firstName, birth: { dateTime, place = defaultPlace }} = store.profile;
  const email = store.profile.email || '';
  const userStatus = store.auth.userStatus;

  const tariffs = store.events.tariffs.filter(({id}) => id !== 3)//.map(item => ({ price: item.price, period: item.period }))

  const promoData = store.promocode.promoData
  const partner = store.partner.partner;
	const discount = store.partner.partnerDiscount;


  const [ currentTariff, setCurrentTariff ] = React.useState(tariffs[0]);
  const [ error, setError ] = React.useState('');
  const [ card, setCard ] = React.useState(cards[0]);
  const [ notify, setNotify ] = React.useState(false);
  const [ isWaiting, setIsWaiting ] = React.useState(true);


  const tariffInfo = store.events.tariffs.find(t => t.id === currentTariff.id)!;
  const buttonTrialTitle = `Начать ${trialPeriod} ${numberWord(trialPeriod!, ['день', 'дня', 'дней'])} бесплатно`;

  const finalPrice = Math.round((tariffInfo.price / tariffInfo.period) * (1 - discount));

  const paymentType = (freePeriod && !promoData) ? 'indiTrial' : 'indiSubscribe';

  const captchaRef = React.useRef<TReReCaptchaApi | null>(null)

  const sp = searchParams()

  React.useEffect(() => {
    const sp = searchParams()
    store.partner.setPartnerDiscount(PartnerLinkTargets.ChronosPlus, partner)
    const event = !direct
      ? 'indi_chronos-plus_payment-page_visit'
      : 'indi_chronos-plus_pay-page_visit-light'

    gtmSend({
      'event': event,
      'user_id': userId,
      'utm_source': sp.get('utm_source'),
    })

    return () => {
      gtmSend({ 'event': 'indi_chronos-plus_payment-page_left', 'user_id': userId })
    }
  }, []);

  React.useEffect(() => {
    if(captchaRef.current) {
      setIsWaiting(false)
    }
  }, [captchaRef.current])

  React.useEffect(() => {
    store.events.loadTariffs(card.country)
  }, [card])

  const onCardSelectHandler = (card: ICard) => {
    store.events.loadTariffs(card.country)
    setCard(card)
  }

  const closePaymentPaywall = () => {
    // hideOffer()
    popupsStore.closeAll()
    store.promocode.resetPromocode()
    history.push({pathname: '/'})
    window.location.reload()
  }

  const getTrialPeriod = () => {
    return trialPeriod || (freePeriod === FreePeriod.TRIAL ? 7 : 14);
  }

  const onPayment = async () => {
    if (freePeriod === FreePeriod.TRIAL && !promoData) gtmSend({ 'event': 'ind_prognosis_trial_tariff_start', 'user_id': userId })
    if (tariffPeriod === 12) gtmSend({ 'event': 'ind_prognosis_check-out-bunner_click', 'user_id': userId })

      //@ts-ignore
      ym('reachGoal','indi_paywall_trial-start_click')

      if (promoData) {
        const result = await store.promocode.usePromocode(promoData.title, undefined, undefined, email)
        const isFreeCharge = result.subscription === 1 || result.subscription === 2
        if (isFreeCharge) {
          closePaymentPaywall()
          return
        }
      }

      if (isAutotest) {
        await api.indiActivateTrialForTest(userId, email);
        closePaymentPaywall();
        return;
      }

      try {
        const captchaToken = await captchaRef.current?.executeAsync();

        const data = {
          captchaToken,
          type: paymentType,
          trialPeriod: trialPeriod || (freePeriod === FreePeriod.TRIAL ? 7 : 14),
          subscription: currentTariff.id,
          period: currentTariff.period,
          country: card.id,
          language: 'ru',
          userStatus,
          forUserEmail: email,
          comment: getUTMCommentForCP(),
          notify: notify,
          promocodeTitle: promoData?.title
        }

        const result = await api.payment(
          data,
          freePeriod ? 'auth' : 'charge'
        );


        if (!result.failed) {
          if (freePeriod === FreePeriod.TRIAL && !promoData) {
            const event = !direct
              ? 'ind_prognosis_trial_card_done'
              : 'ind_prognos_trial_card_done-light'

            gtmSend({
              'event': event,
              'user_id': userId ?? 'unauthorized',
              'utm_source': sp.get('utm_source'),
            })
            ym('reachGoal', 'start-trial-chronos-plus')
          }

          setTimeout(() => {
            closePaymentPaywall()
          }, 1000)

          return
        }

        if (result.failed) {
          promoData && store.promocode.cancelPromocode(promoData.id, undefined)

          show({
            type: 'error',
            text: getHTTPMessage(result?.error?._errors),
          });
        }
      } catch (e) {
        promoData && store.promocode.cancelPromocode(promoData.id, undefined)
        console.error(`Payment trial error: ${e}`)
      }
  }



  return (
    <PaymentPaywallTariffsContainer>
      <Header>
        <div className="controls"><TrialClose onClick={onClose} /></div>
        <div className="title">Попробуйте первые {trialPeriod} {numberWord(trialPeriod!, ['день', 'дня', 'дней'])} <span>бесплатно</span></div>
      </Header>
      <Main>
        <StyledTariffSwitcher
          tariffs={tariffs}
          current={currentTariff}
          setTariff={setCurrentTariff}
          discount={discount}
          symbol={card.symbol}
        />
        {/* {error && <Error>{error}</Error>} */}

        <div className="future-info">
          До {dayjs().add(trialPeriod, 'day').format('D MMMM')} бесплатно, <br />потом {finalPrice}{card.symbol} / мес
        </div>

        <div className="buy-attributes">
          <div className="left side">
            <Select plain title="" options={cards} value={card} onSelect={onCardSelectHandler} />
          </div>
          <div className="right side">
            <PromocodeButton subscription applied={!!promoData} disabled={isWaiting} />
          </div>
        </div>

        <div className="caption">
          Для проверки карты мы спишем 1 ₽ и сразу вернём. Подписку можно отменить в любой момент.
        </div>


        {/* <Select title="Карта для оплаты" options={cards} value={card} onSelect={setCard} /> */}
      </Main>
      <Footer>
        <StyledButton onClick={onPayment} color="green" disabled={isWaiting}>{buttonTrialTitle}</StyledButton>

        <StyledPolicy
          country={card.id}
          action={<span>Нажимая кнопку <b>«{buttonTrialTitle}»</b></span>}
          fixedPosition={false}
          bottom={10}
        />

      </Footer>
      <ReRecaptcha size="invisible" badge="bottomleft" ref={captchaRef}/>
    </PaymentPaywallTariffsContainer>
  );
});

export default PaymentPaywallTariffs;

const PaymentPaywallTariffsContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.5rem 1rem;
  background: var(--color-white);
  gap: 0.5rem;
  padding-bottom: 4.5rem;
  overflow: hidden auto;
`;

const Header = styled.header`
	display: flex;
  flex-direction: column;
  align-items: center;

  flex: 30%;
  flex-shrink: 0;
  flex-grow: 0;

  gap: 0.5rem;

  & .controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  & .title {
    max-width: 80%;
    margin-top: auto;
    margin-bottom: 1.5rem;
    font-size: 2rem;
    line-height: 1.1;
    text-align: center;

    & > span {
      background-image: linear-gradient(to right, #7D79EA, #D646D9, #E8AA63);
      color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
    }
  }
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  flex: 55%;
  /* overflow-y: scroll; */

  & .future-info {
    position: relative;
    margin-top: 1rem;
    text-align: center;
    font-size: 1rem;
    line-height: 1.313rem;
    font-weight: 400;
    color: var(--text-secondary);
  }

  & .buy-attributes {
    display: flex;
    position: relative;
    flex-direction: row;
    margin-top: auto;
    gap: 0.25rem;

    & .side {
      flex: 50%;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  & .caption {
      width: 100%;
      margin-top: 0.75rem;
      margin-bottom: 1rem;

      font-size: 0.688rem;
      color: var(--text-secondary);
      text-align: center;
    }
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 15%;
  flex-shrink: 0;
  flex-grow: 0;

  text-align: center;
`;

const Error = styled.div`
  color: var(--color-red);
  font-size:  0.875rem;
`

const StyledTariffSwitcher = styled(TariffSwitcherNew)`

`;

const StyledButton = styled(Button)<{ disabled: boolean }>`
  color: var(--text-primary);

  ${p => p.disabled && css`
    opacity: 0.2;
    pointer-events: none;
  `}
`

const StyledPolicy = styled(Policy)`
  font-size: 0.688rem;
  line-height: 1.3;
`;
