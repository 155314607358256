import React, {useEffect, useRef, useState} from 'react';
import styled, {css} from "styled-components";
import {ISwipeTab} from "./Swiper";
import store from "../../../store/Store";

interface ISwiperItemProps {
  tab: ISwipeTab
  index: number
}

export default React.memo(function SwiperItem(props: ISwiperItemProps) {
  const [active, setActive] = useState(false)
  const container = document.querySelector('.window-container')
  const linkRef = useRef<HTMLDivElement>(null)
  const gap = props.index === 0 ? 300 : 165
  const disable = store.synastry.trialSynastry && props.tab.key !== 'diagramData'
  const scrollIntoViewOptions: ScrollIntoViewOptions = {inline: 'end', block: 'end'}
  const hasMarker = props.tab.key !== 'diagramData' && props.tab.key !== 'intro'

  const checkPosition = () => {
    const category = document.getElementById(props.tab.key)

    if (category) {
      const height = category.scrollHeight
      const top = category.offsetTop
      const isActive = top < gap && -(height - 180) < top

      if (isActive) {
        setActive(true)
      } else {
        setActive(false)
      }
    }
  }

  const onScroll = () => {
    checkPosition()
  }

  useEffect(() => {
    if (props.index === 0) setActive(true)

    container && container.addEventListener('scroll', onScroll)

    return () => {
      container && container.removeEventListener('scroll', onScroll)
    }
  }, [])

  const moveTabs = () => {
    if (linkRef.current && linkRef.current.parentElement) {
      linkRef.current.scrollIntoView(scrollIntoViewOptions)
      linkRef.current.parentElement.scrollLeft += 15
    }
  }

  useEffect(() => {
    if (active) moveTabs()
  }, [active])

  const moveContent = (key: string, index: number) => {
    const category = document.getElementById(props.tab.key)
    category && category.scrollIntoView()
  }

  const onClickHandler = () => {
    if (!disable) {
      moveContent(props.tab.key, props.index)
    }
  }

  return (
        <Link ref={linkRef} onClick={onClickHandler} active={active}>
          {hasMarker && <Marker colorKey={props.tab.key}/>}
          {props.tab.title}
        </Link>
  );
})

const Link = styled.div<{active: boolean}>`
  display: flex;
  align-items: center;

  font-size: 0.8rem;
  color: var(--text-third);
  border-radius: 37px;
  padding: 0.3rem 0.8rem;
  margin: 0 0.4rem;
  cursor: pointer;
  background: #F8FCFF;
  border: 1px solid #EFEFEF;
  opacity: 0.8;
  max-height: 2rem;
  white-space: nowrap;

  ${p => p.active && css`
    color: var(--text-primary) !important;
    background: #FFFFFF;
    box-shadow: 0px 3.2px 14px rgba(0, 0, 0, 0.08), 0px 0.6px 1.8px rgba(0, 0, 0, 0.08);
    opacity: 1;
  `}
`

const Marker = styled.div<{ colorKey?: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 0.8rem;
  background-color: ${props => props.colorKey ? `var(--color-${props.colorKey})` : 'red'};
`
