import React from 'react';
import styled, { css } from 'styled-components';
import { FlexContainer } from '../../../components/layout/elements';
import { Checkbox } from './Checkbox';
import { getTariffsInfo } from '../helpers/tariffs';
import { Indi } from '../../../libs';

export const TariffSwitcher = ({
  tariffs,
  current,
  setTariff,
	discount,
	symbol,
  className
}:{
  tariffs: Indi.indiGetTariffs.Tariff[],
  current: Indi.indiGetTariffs.Tariff,
  setTariff: (tariff: Indi.indiGetTariffs.Tariff) => void;
	discount: number,
	symbol: string,
  className?: string
}) => {
  return <Container className={className}>
    {tariffs.map(tariff => {
      const checked = tariff.id === current.id;
			const finalPrice = Math.round(tariff.price * (1 - discount))

      return <Item key={tariff.id} onClick={() => setTariff(tariff)} checked={checked} className='item'>
        <ItemHeader>
          <Checkbox checked={checked} />
          {tariff.discountText && <ItemDiscountText>{tariff.discountText}</ItemDiscountText>}
        </ItemHeader>

        <ItemPrice>
          {finalPrice} {symbol}
          {
						(tariff.oldPrice || discount > 0) && <ItemOldPrice>
								{
									discount > 0
										? tariff.price
										: tariff.oldPrice
								} {symbol}
							</ItemOldPrice>
					}
        </ItemPrice>
        <ItemTitle>{getTariffsInfo(tariff).paymentPeriodText}</ItemTitle>
      </Item>;
    })}

    <WhiteOverlay left={current.id === tariffs[0].id} />

  </Container>
}

const Container = styled(FlexContainer)`
  background: var(--input-background);
  padding: 0.25rem;
  border-radius: 12px;
  gap: 0.25rem;
  position: relative;
`

const WhiteOverlay = styled.div<{left: boolean}>`
  width: calc((100% - 0.25rem - 0.5rem)/2);
  height: calc(100% - 0.5rem);
  z-index:1;
  position: absolute;
  background: var(--color-white);
  border-radius: 8px;
  left: ${p => p.left ? '1.5%' : '50%'};
  transition: left 0.2s;
  will-change: left;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06);
`

const Item = styled.div<{checked: boolean}>`
  padding: 0.5rem;
  flex: 1;
  color: var(--text-primary);
  border-radius: 8px;
  position: relative;
  z-index: 2;

  ${p => p.checked && css`
    /* background: var(--color-white); */
  `}
`

const ItemHeader = styled(FlexContainer)`
  justify-content: space-between;
`

const ItemTitle = styled.div`
  margin-top: 0.15rem;
  font-size: 0.875rem;
`

const ItemPrice = styled.div`
  margin-top: 0.75rem;
  font-size: 1.25rem;
`

const ItemDiscountText = styled.div`
  background-color: var(--color-orange);
  font-size: 0.75rem;
  padding: 0.15rem 0.5rem;
  border-radius: 900px;
`

const ItemOldPrice = styled.span`
  color: var(--text-secondary);
  font-size: 0.75rem;
  margin-left: 0.25rem;
  text-decoration: line-through;
`
