import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import styled from 'styled-components';
import { history } from '../../../MainRouter';
import AvatarImage from '../../../assets/images/profile-picture.png';
import Policy from '../../../components/Policy';
import api from '../../../helpers/api';
import { gtmSend, searchParams } from '../../../helpers/url';
import useLS from '../../../hooks/useLS';
import store from '../../../store/Store';
import { ConsultationOffer } from '../components/ConsultationOffer';
import ScheduleModal from '../components/ScheduleModal';
import Rate from './Rate';
import SessionInfo from './SessionInfo';
import SupportAndActions from './SupportAndActions';
import Unscheduled from './Unscheduled';
import ZoomButton from './ZoomButton';
import { IServiceDataEx, ISession, IWorkProfileEx, SessionFormat, SessionStatus, Indi } from '../../../libs';
import { getConsultationsLS, setConsultationsLS } from '../../../store/localStorageHelpers';
import { WINDOW_PAYMENT_PAYWALL, openPaymentPaywallWindow } from '../../payment/components/payment-paywall';
import PaymentPaywallTariffs, { WINDOW_PAYMENT_PAYWALL_TARIFFS, openPaymentWithTariffsWindow } from '../../payment/components/payment-paywall-tariffs';
import { TariffPeriod } from '../../payment/helpers/tariffs';
import { windowsStore } from '../../../store/Windows';
import { GiftIcon } from '../../../assets/icons/system/system-icons';

import { FreePeriod } from '../../payment/helpers/tariffs';


export default observer(function SessionDetails() {
  const sp = searchParams();
  const paymentError = sp.has('paymentError');
  const cameFromSuccessPayment = sp.has('payed');

  const { id: sessionId } = useParams<{ id: string }>();
  const [, , saveFirstLoginAttempt, removeFirstLoginAttempt] = useLS('FirstLoginAttempt');

  const [session, setSession] = useState<ISession | null>(null);
  const [professional, setProfessional] = useState<IWorkProfileEx | null>(null);
  const [service, setService] = useState<IServiceDataEx | null>(null);
  const [isPassed, setIsPassed] = useState(false);
  const [canRate, setCanRate] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showOffer, setShowOffer] = useState(false);
  const [showOfferIcon, setShowOfferIcon] = useState(false);

  const isUnscheduled = session?.type === 'unschedule';
  const isCancelled = session?.data?.status === SessionStatus.Canceled;
  const consultationLS = getConsultationsLS()
  const offers = store.sessionData?.indi?.offers
	const leftFiveMinutes = Boolean(session && dayjs(session.start).diff(dayjs(), 'minute') <= 5);
  const userId = store.sessionData?.id;

  useEffect(() => {
    store.getProfile();
  }, []);

  useEffect(() => {
    if (session) {
      const start = dayjs(session.start);
      const end = start.add(session.data.duration, 'minutes');
      const passed = end.isBefore(dayjs());
      const ratingPossible = end.add(1, 'day').isAfter(dayjs());

      setIsPassed(passed);
      setCanRate(ratingPossible);

      ym('reachGoal', getYandexArgs(passed));

      if (cameFromSuccessPayment && session.data.status === SessionStatus.Init) {
        setTimeout(getSession, 3000);
      }
    }
  }, [session]);

  useEffect(() => {
    session && setService(store.getService(session.data.serviceId));
  }, [session, store.services, store.professional]);

  useEffect(() => {
    checkAuth();
  }, [store.profileSessions, store.profile, sessionId]);

  const getYandexArgs = (passed: boolean) => {
    if (session) {
      if (session.data.status === SessionStatus.Paid) return 'dashboard_click-card_cons-page-waiting';
      if (session.data.status === SessionStatus.Init) return 'dashboard_click-card_cons-page-not-paid';
      if (passed && session.data.rate !== 0) return 'dashboard_click-card_cons-page-done';
      if (passed && session.data.rate === 0) return 'dashboard_click-card_cons-page-feedback';
    }
  };

  const checkOffers = () => {
    offers?.forEach(offer => {
      const hideOffer = consultationLS.offers?.find(item => item.type === Indi.IndiUseOffer.Offers.FOURTEEN_FREE_DAYS)?.hide

      if (offer.type === Indi.IndiUseOffer.Offers.FOURTEEN_FREE_DAYS && offer.available) {
        !hideOffer &&  setShowOffer(true)
        setShowOfferIcon(true)
      }

    })
  }

  const returnOffer = () => {
    const updatedOffers = consultationLS.offers?.map(offer => {
      if (offer.type === Indi.IndiUseOffer.Offers.FOURTEEN_FREE_DAYS) offer.hide = false
      return offer
    }) ?? []

    setShowOffer(true)
    setConsultationsLS({
      ...consultationLS,
      offers: updatedOffers
    })
  }

  const hideOffer = () => {
    const updatedOffers = consultationLS.offers?.map(offer => {
      if (offer.type === Indi.IndiUseOffer.Offers.FOURTEEN_FREE_DAYS) offer.hide = true
      return offer
    }) ?? []

    setShowOffer(false)
    setConsultationsLS({
      ...consultationLS,
      offers: updatedOffers
    })
  }

  const goToPaywall = () => {
    // openPaymentPaywallWindow(
    //   TariffPeriod.MONTH,
    //   () => windowsStore.close(WINDOW_PAYMENT_PAYWALL),
    //   FreePeriod.FOURTEEN_FREE_DAYS
    // )

    openPaymentWithTariffsWindow(
      TariffPeriod.MONTH,
      () => windowsStore.close(WINDOW_PAYMENT_PAYWALL_TARIFFS),
      FreePeriod.FOURTEEN_FREE_DAYS
    )

    gtmSend({'event': 'indi_consultation-sub-bunner-14days_click', 'user_id': userId ? userId : ''})
  }

  useEffect(() => {
    checkOffers()
  }, [offers]);

  const getSession = async () => {
    let session = await api.getSession(+sessionId) as ISession;

    if (!session) {
      return;
    }

    const professional = await store.setProfessional(session.userId)
    professional && setProfessional(professional)

    const isStarted = dayjs(session.start).add(-5, 'minutes').isBefore(dayjs());
    if (isStarted && !session.data.meeting && session.data.format === SessionFormat.Online) {
      session = await api.getSessionMeeting(+sessionId) as ISession;
    }

    setSession(session);
  };

  const checkAuth = async () => {
    if (!store.isAuthFinished) {
      return;
    }

    if (!store.isAuth) {
      saveFirstLoginAttempt(true);
      return store.auth.goToAuth({weak: true})
    }

    if (!store.profileSessions?.find(session => session.id === +sessionId)) {
      history.push({pathname: '/services', search: sp.toString()});
    }
    await getSession();
    removeFirstLoginAttempt();
  }

	const getTitle = () => {
		if (session) {
			return session.data.format === SessionFormat.Offline ? 'Письменный анализ с' : 'Онлайн консультация с'
		}
	}

  if (!store.profile || !professional || !session || !service) return <SessionDetailsContainer />;

  return (
    <SessionDetailsContainer>
      {
        showOfferIcon && <IconContainer onClick={returnOffer}>
                          <GiftIcon/>
                         </IconContainer>
      }

      <Avatar src={professional?.profile.avatarUrl || AvatarImage} />

      {paymentError && <PaymentError>Ошибка оплаты, попробуйте еще раз</PaymentError>}

			<Title>
				{getTitle()} <br/>
				{professional?.profile.firstName} {professional?.profile.lastName ?? ''}
			</Title>

      {
				isUnscheduled
          ? <Unscheduled
              setShowModal={setShowModal}
              setShowCancel={setShowCancel}
            />
          : !isPassed && !isCancelled && !isUnscheduled
              ? null
              : <Rate session={session} />
      }

      {
				!isCancelled &&
	        <>
	          {
							!isUnscheduled &&
								<ZoomButton
									session={session}
									isPassed={isPassed}
									canRate={canRate}/>
						}
	          <SessionInfo
							session={session}
							service={service}/>
	        </>
      }

      <SupportAndActions
        leftFiveMinutes={leftFiveMinutes}
      />

      { showModal && <ScheduleModal
          session={session || undefined}
          setSession={setSession}
          onClose={() => setShowModal(false)}
          fromCancelation={showCancel}
        />
      }

			<StyledPolicy text='Ознакомьтесь с'/>

      {
        showOffer && <ConsultationOffer
                        onClick={goToPaywall}
                        onClose={hideOffer}/>
      }
    </SessionDetailsContainer>
  );
});

const Title = styled.div`
	font-size: 1.5rem;
	font-weight: 500;
	margin-bottom: 1rem;
`;

const Avatar = styled.img`
  height: 72px;
  width: 72px;
  margin-bottom: 18px;
  border-radius: 50%;
  object-fit: cover;
`;

const SessionDetailsContainer = styled.main`
  display: flex;
  flex-direction: column;
`;

const PaymentError = styled.div`
  color: red;
  margin-bottom: 10px;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 2rem;

  background: rgba(222, 89, 244, 0.1);
  padding: 0.35rem;
  border-radius: 50%;

  svg {
    width: 1.75rem;
    height: 1.75rem;
  }
`

const StyledPolicy = styled(Policy)`
	text-align: left;
	margin-top: 0;
`
