import { makeAutoObservable } from "mobx";
import store from "./Store";
import api from "src/helpers/api";
import { getGMTLocal } from "src/helpers/dates";
import { Indi } from "src/libs";
import dayjs from "dayjs";


export class WebPush {
  constructor() {
    makeAutoObservable(this)
  }

  urlBase64ToUint8Array(base64UrlData: string) {
		const padding = '='.repeat((4 - base64UrlData.length % 4) % 4);
		const base64 = (base64UrlData + padding)
			.replace(/-/g, '+')
			.replace(/_/g, '/');

		const rawData = window.atob(base64);
		const buffer = new Uint8Array(rawData.length);

		for (let i = 0; i < rawData.length; ++i) {
			buffer[i] = rawData.charCodeAt(i);
		}

		return buffer;
	}

  async registerSW() {
    await navigator.serviceWorker.register('./service-worker.js')
  }

  async subscribe(vapidPublicKey: Uint8Array, swScope?: any) {
    return navigator.serviceWorker
      .getRegistration(swScope)
      .then((registration) => {
        return registration?.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: vapidPublicKey,
          })
          .then((pushSubscription) => {
            return pushSubscription
          })
          .catch((error) => {
            throw new Error(error)
          })
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

	async saveSubscription(subscription: any, userId: number, gmt: number) {
    try {
      const data = {
        subscription,
        gmt
      }

      await api.indiWebPushAllow({userId, data})
    } catch(e) {
      console.error(`Saving subscription error: ${e}`)
    }
	}

  async pushTestNotification(userId: number) {
    api.indiWebPushTest(userId)
	}

  async getModal(userId: number) {
    try {
      const result = await api.indiWebPushGetModal(userId)
      return result
    } catch(e) {
      console.error(`Getting WebPush Modal error: ${e}`)
    }
  }

  async setModal(userId: number, value: boolean) {
    try {
      const data = {
        userId,
        data: {
          modal: value
        }
      }

      const result = await api.indiWebPushPutModal(data)
      return result
    } catch(e) {
      console.error(`Putting Web Push Modal error: ${e}`)
    }
  }

  async initWebPus() {
    const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY ?? ''
    const convertedVapidPublicKey = this.urlBase64ToUint8Array(vapidPublicKey)

    await this.registerSW()
    const pushSubscription = await this.subscribe(convertedVapidPublicKey)
    const userId = store?.sessionData?.id
    const gmt = getGMTLocal(true)

    if (userId) {
      await this.saveSubscription(pushSubscription, userId, gmt)

      const subscribe = [
        {
          notificationId: Indi.WebPush.NotificationId.PROGNOSIS_REMINDER_NOTIFICATION_ID,
          status: true
        },
        {
          notificationId: Indi.WebPush.NotificationId.IMPORTANT_EVENT_NOTIFICATION_ID,
          status: true
        },
      ]

      await this.setWebPushSubscribe(userId, subscribe)
    }
  }

  async getWebPushSubscribe(userId: number) {
    try {
      const result = await api.indiWebPushGetSubscribe(userId)
      return result
    } catch(e) {
      console.error(`Error in getWebPushSubscribe: ${e}`)
    }
  }

  async setWebPushSubscribe(userId: number, subscriptions: Indi.WebPush.Subscribe.Subscription[]) {
    try {
      const data = {
        subscriptions
      }

      await api.indiWebPushSubscribe({userId, data})
    } catch(e) {
      console.error(`Error in setWebPushSubscribe: ${e}`)
    }
  }

  isPWA() {
    //@ts-ignore
    return (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true)
  }

  detectOldUser() {
    const newUsersDate = '2024-09-09T00:00:00.000Z'
    const {startPlan} = store.getIndiDates()
    return dayjs(startPlan).isBefore(dayjs(newUsersDate))
  }

  postMessage(data: any) {
    navigator.serviceWorker.ready.then((registration) => {
      if (registration.active) {
        registration.active.postMessage(data)
      }
    })
  }
}
