import React from 'react';
import styled, { css } from 'styled-components';
import { FlexContainer } from '../../../components/layout/elements';

export const Checkbox = ({
  checked
}:{
  checked: boolean
}) => {
  return <Container checked={checked}>

  </Container>
}

const Container = styled(FlexContainer)<{checked: boolean}>`
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  border: 1px solid var(--text-primary);
  cursor: pointer;
  align-items: center;
  justify-content: center;

  ${p => p.checked && css`
    background: var(--text-primary);
    border: none;

    &:before {
      content: '';
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      background: var(--color-white);
      border-radius: 50%;
    }
  `}
`