import React from 'react';
import styled from 'styled-components';

import { Warning3 } from '../../../assets/icons/system/system-icons';
import { show } from '../../../libs';

const email = 'support@сhronos.mg';

export enum SupportType {
  LIMITS = 'limits',
  QUESTIONS = 'questions',
}

interface PaymentSupportProps {
  type?: SupportType
}

export const PaymentSupport = (props: PaymentSupportProps) => {
  const {
    type = SupportType.QUESTIONS
  } = props

  const copyEmail = () => {
    navigator.clipboard.writeText(email).then(_data => {
      show({
        type: 'success',
        text: "Ссылка скопирована",
        timeout: 3000
      });
    })
  }

  if (type === SupportType.LIMITS) {
    return <InfoContainer>
            Платежи иностранной картой доступны только для стран СНГ и Украины. Если возникнут другие проблемы, напишите на <span onClick={copyEmail} title="Скопировать ссылку">{email}</span>
          </InfoContainer>
  }

  return <Text>Возникли проблемы? Напишите на <span onClick={copyEmail} title="Скопировать ссылку">{email}</span></Text>
}

export const InfoContainer = ({
  children,
  styles
}: {
  children: React.ReactNode,
  styles?: React.CSSProperties
}) => {
  return <Container style={styles}>
    <div className='icon'><Warning3/></div>
    <div className='content'>
      {children}
    </div>
  </Container>
}

const Container = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  gap: 0.625rem;
  margin-top: 0.5rem;
  padding: 0.75rem;
  border-radius: 0.75rem;

  background-color: #FFEEE1;
  color: var(--text-secondary);
  font-size: 0.6875rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .content > span {
    color: var(--color-gradus-blue);
    cursor: pointer;
  }
`

const Text = styled.div`
  font-size: 0.6875rem;
  margin-top: 1rem;
  padding: 0 0.5rem;

  span {
    color: var(--color-gradus-blue);
    cursor: pointer;
  }
`
