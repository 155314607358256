import styled from "styled-components"

interface ReRemovedRecommendationProps {
  clearTimer(): void
}

export const RemovedRecommendation = (props: ReRemovedRecommendationProps) => {

  return (
    <Container>
      <div>Заметка удалена</div>
      <div className="button" onClick={props.clearTimer}>Вернуть?</div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  color: var(--text-third);
  font-size: 1.125rem;
  padding: 2rem 1rem;
  border: 2px dashed rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;

  .button {
    color: var(--color-blue);
    font-size: 0.875rem;
  }
`
