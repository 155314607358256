import styled from "styled-components"
import { SizifIcon, SmileIcon, ThumbsUpThreeIcon } from "../assets/icons/system/system-icons"
import { openPaymentWindow, openSmsRegistrationWindow } from "../modules/paywall/Paywall"
import { Button } from "./ui/Button/Button"
import { CloseButton } from "./ui/CloseButton"
import store from "../store/Store"
import { observer } from "mobx-react-lite"
import { FreePeriod } from "../modules/payment/helpers/tariffs"


interface Benefits {
	title: string
	subtitle: string
	icon: any
}

const titles: string[] = [
	'Оформите подписку Chronos Plus за 499 ₽ в месяц',
	'Оформите подписку Chronos Plus'
]

const benefits: Benefits[] = [
	{
		title: 'Список ежедневных событий',
		subtitle: 'Подскажем, чего ждать в будущем и как подготовиться',
		icon: <SmileIcon/>,
	},
	{
		title: 'Рекомендации по сферам',
		subtitle: 'Выполняйте их, чтобы избежать негатива',
		icon: <ThumbsUpThreeIcon/>,
	},
	{
		title: 'Календарь планирования',
		subtitle: 'Покажем благоприятные даты для выполнения трудных дел',
		icon: <SizifIcon/>,
	},
]

const buttons: string[] = [
	'Оплатить подписку',
	'Попробовать за 1 ₽'
]


interface PaywallProps {
	offerSubscription: boolean
	onClose(): void
}

export const Paywall = observer((props: PaywallProps) => {
	const isAuth = store.isAuth;
	const tariff6 = store.events.tariffs.find(item => item.period === 6)!

	const signIn = () => {
		openSmsRegistrationWindow()
		props.onClose()
	}

	const onClickHandler = () => {
		if (isAuth) {
      openPaymentWindow(tariff6, { freePeriod: !props.offerSubscription ? FreePeriod.TRIAL : undefined});
    } else {
      openSmsRegistrationWindow();
    }

		props.onClose()
	}

	return (
		<Overlay>
			<Container>
				<Title>{titles[props.offerSubscription ? 0 : 1]}</Title>

				<Content>
					<span>Пользуйтесь полным функционалом:</span>
						<div className="list">
							{benefits.map((item, index) => {
								return (
									<div key={`blog-benefits-${index}`} className="item">
										<div>{item.icon}</div>
										<div className="description">
											<div className="title">{item.title}</div>
											<div>{item.subtitle}</div>
										</div>
									</div>
								)
							})}
						</div>
				</Content>

				<Buttons>
					<Button color="orange" onClick={onClickHandler}>{buttons[props.offerSubscription ? 0 : 1]}</Button>
					{!isAuth && <StyledButton onClick={signIn}>Войти в аккаунт</StyledButton>}
				</Buttons>

				<CloseButton top={-40} right={-10} onClose={props.onClose} />
			</Container>
		</Overlay>
	)
})

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  backdrop-filter: brightness(0.1) blur(2px);

  z-index: 20;
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	color: var(--text-primary-white);
	width: 80%;
	max-width: 20rem;
	position: relative;
`

const Content = styled.div`
	margin-top: 1.5rem;

	.item {
		display: flex;

		margin-top: 1rem;

		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}

	.description {
		margin-left: 0.75rem;
		font-size: 0.875rem;

		.title {
			font-size: 1.125rem;
			font-weight: 500;
			margin-bottom: 0.5rem;
		}
	}
`

const Title = styled.div`
	font-size: 1.5rem;
	font-weight: 500;
`

const Buttons = styled.div`
	margin-top: 2rem;
`

const StyledButton = styled(Button)`
	color: var(--text-primary-white);
	background: var(--element-background-white-10);
	margin-top: 0.5rem;
`
