import { observer } from "mobx-react-lite"
import { useCallback, useEffect, useMemo } from "react"
import styled from "styled-components"
import Overlay from "../../../components/Overlay"
import { Colors, Positions, Tip } from "../../../components/Tip"
import store from "../../../store/Store"
import { gtmSend } from "../../../helpers/url"


export const startShowingOnboardingDate = '2024-08-01T00:00:00.000Z'

interface ShutterOnboardingProps {
  hideOnboarding: () => void
}

export const ShutterOnboarding = observer((props:  ShutterOnboardingProps) => {
  const shutterRef = store.shutter.shutterRef
  const userId = store.sessionData?.id

  useEffect(() => {
    if (shutterRef?.current) {
      shutterRef.current.style.pointerEvents = 'none'
    }

    return () => {
      if (shutterRef?.current) {
        shutterRef.current.style.pointerEvents = 'auto'
      }
    }
  }, [shutterRef])

  const onClickHandler = useCallback(() => {
    props.hideOnboarding()
    gtmSend({'event': `indi_onboarding_hub_2sessions`, 'user_id': userId ? userId : ''})
  }, [props, userId])

  const button = useMemo(() => ({
    title: 'Продолжить',
    onClick: onClickHandler,
    position: Positions.CENTER
  }), [onClickHandler])

  return (
    <StyledOverlay>
      <TipWrapper>
        <Tip
          title="Раскройте хаб-меню"
          text="Потяните вверх, чтобы открыть хаб-меню и изучить разделы, статьи и практики"
          color={Colors.LIGHT}
          button={button}
          trianglePosition={Positions.BOTTOM_CENTER}
        />
      </TipWrapper>
    </StyledOverlay>
  )
})

const StyledOverlay = styled(Overlay)`
  align-items: end;
`

const TipWrapper = styled.div`
  width: 100%;
  padding: 1rem 1rem 10rem 1rem;

`
