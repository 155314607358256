import styled from "styled-components"

export const Divider = () => {
  return (
    <Container>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--element-border-10);
  margin: 1rem 0;
`
