import styled, { css } from "styled-components"
import background from '../../../../assets/images/onboarding/modal_bg.jpg'
import { Button } from "../../../../components/ui/Button/Button"


interface OnboardingModalProps {
  skipOnboarding(): void
  startOnboarding(): void
}

export const OnboardingModal = (props: OnboardingModalProps) => {
  return (
    <Container background={background}>
      <Description>
        <div className="title">Добро пожаловать в Chronos Plus</div>
        <div className="subtitle">Коротко расскажем, как начать пользоваться приложением</div>
      </Description>
      <Buttons>
        <Button onClick={props.startOnboarding}>Начать знакомство</Button>
        <Button color={"gray"} onClick={props.skipOnboarding}>Пропустить</Button>
      </Buttons>
    </Container>
  )
}

const Container = styled.div<{background: string}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  gap: 1.5rem;

  min-height: 480px;
  max-width: 358px;
  border-radius: 1.5rem;
  padding: 1rem;
  margin: 0 1rem;
  z-index: var(--z-index-modal);

  ${p => p.background && css`
      background-image: url(${p.background});
  		background-position: center center;
  		background-repeat: no-repeat;
  		background-size: cover;
  `};
`

const Description = styled.div`
  text-align: center;

  .title {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .subtitle {
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  width: 100%;

`
