import dayjs from "dayjs";
import { makeAutoObservable } from "mobx";
import { RefObject } from "react";
import store from "./Store";

export class Onboarding {
  constructor() {
    makeAutoObservable(this)
  }

  detectOldUser() {
    const startShowingOnboardingDate = '2024-09-19T00:00:00.000Z'
    const {startPlan} = store.getIndiDates()
    return dayjs(startPlan).isBefore(dayjs(startShowingOnboardingDate))
  }

  // main_page
  activeSubscriptionScreenTextRef: RefObject<HTMLDivElement> | null = null
  widgetsAccordionRef: RefObject<HTMLDivElement> | null = null
  WidgetsLength = 0

  setActiveSubscriptionScreenTextRef(ref: RefObject<HTMLDivElement>) {
    this.activeSubscriptionScreenTextRef = ref
  }

  setWidgetsAccordion(ref: RefObject<HTMLDivElement>) {
    this.widgetsAccordionRef = ref
  }

  setWidgetsLength(length: number) {
    this.WidgetsLength = length
  }

  // my_day
  fromMenu = false
  firstEventsRef: RefObject<HTMLDivElement> | null = null

  setFromMenu(value: boolean) {
    this.fromMenu = value
  }

  setFirstEventsRef(ref: RefObject<HTMLDivElement>) {
    this.firstEventsRef = ref
  }
}
