import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ThumbsUpIcon } from '../../../assets/icons/system/system-icons';

import store from '../../../store/Store';

export default function WhatToDo(
  {
    extended = false,
    id,
  }: {
    extended?: boolean;
    id: string;
  }
) {
  const dayData = useMemo(() => {
    return store.prognoses.getDayById(+id);
  }, [id]);

  if(!dayData?.recommendations?.length) {
    return null;
  }

  const { recommendations: [whatToDo, whatNotToDo] } = dayData;

  return (
    <Container>
      <Wrapper extended={extended}>
        { whatToDo.text &&
            <Block positive={true} extended={extended}>
              { extended &&
                  <IconWrapper positive={true}>
                    <ThumbsUpIcon />
                  </IconWrapper>
              }
              <InfoWrapper positive={true}>
                <h3>Что делать</h3>
                { whatToDo.text.split('\n').map((textItem: string) => (
                    <p key={textItem}>{textItem}</p>
                  ))
                }
              </InfoWrapper>
            </Block>
        }
        { whatNotToDo.text &&
            <Block extended={extended}>
              { extended &&
                  <IconWrapper>
                    <ThumbsUpIcon />
                  </IconWrapper>
              }
              <InfoWrapper>
                <h3>Что не делать</h3>
                { whatNotToDo.text.split('\n').map((textItem: string) => (
                    <p key={textItem}>{textItem}</p>
                  ))
                }
              </InfoWrapper>
            </Block>
        }
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  overflow-x: auto;
`;

const Wrapper = styled.div<{extended?: boolean}>`
  display: flex;
  flex-wrap: ${p => p.extended ? 'wrap' : 'nowrap'};
  margin-bottom: 1rem;
  box-sizing: border-box;
  gap: 1rem;
  width: ${p => p.extended ? '100%' : '120%'};
`;

const Block = styled.div<{positive?: boolean; extended?: boolean}>`
  background-color: ${p => p.positive ? 'var(--color-green-bg-transparent)' : 'var(--color-red-bg-transparent)'};
  border-radius: 0.5rem;
  box-sizing: border-box;
  padding: 0.75rem 0.75rem 2rem;
  width: 100%;
  display: flex;
  gap: 0.75rem;
`;

const IconWrapper = styled.div<{positive?: boolean}>`
  & > svg {
    fill: ${p => p.positive ? 'var(--color-green)' : 'var(--color-red)'};
    transform: ${p => p.positive ? 'none' : 'rotate(180deg)'};
  }
`;

const InfoWrapper = styled.div<{positive?: boolean}>`
  & > h3 {
    color: ${p => p.positive ? 'var(--color-green)' : 'var(--color-red)'};
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.1875;
    margin: 0 0 0.75rem;
  }

  & > p {
    color: var(--text-primary);
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
  }

  & > p:not(:last-child) {
    margin: 0 0 0.625rem;
  }
`;