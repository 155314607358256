import { memo, useState } from "react"
import styled, { css } from "styled-components"
import { ChevronDownIcon } from "../../assets/icons/system/system-icons"


interface AccordionProps {
	className?: string
	title: string
	content: string
	onClick?(open?: boolean): void
}

export const Accordion = memo((props: AccordionProps) => {
	const {
		className,
		title,
		content,
		onClick
	} = props

  const [open, setOpen] = useState(false)

	const toggle = () => {
		setOpen(open => !open)
		onClick && onClick(open)
	}

	return (
		<Container className={className}>
			<Header
				onClick={toggle}
				open={open}
			>
				<span>{title}</span><div><ChevronDownIcon/></div>
			</Header>
			{open && <Content>{content}</Content>}
		</Container>
	)
})

const Container = styled.div`
  margin: 0.5rem 0;
`

const Header = styled.div<{ open: boolean }>`
  display: flex;
  justify-content: space-between;

  font-size: 1rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.70);

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  ${p => p.open && css`
    svg {
      transform: rotate(180deg);
    }
  `}
`

const Content = styled.div`
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.60);
`
