import dayjs from 'dayjs';
import { makeAutoObservable, onBecomeObserved } from 'mobx';
import { HarmonicIcon, MediumIcon, TenseIcon } from '../assets/icons/favorability';
import badBackgroundPath from "../assets/images/main/bg-bad.jpg";
import luckyBackgroundPath from "../assets/images/main/bg-lucky.jpg";
import typicalBackgroundPath from "../assets/images/main/bg-typical.jpg";
import api from '../helpers/api';
import { toISOString } from '../helpers/dates';
import { TUserStateKeys } from '../modules/main-page/helpers/states';
import store from './Store';
import { Indi, Region } from '../libs';

export interface IDayDescription {
  atmosphere: string;
  attention: string[];
  importance: {
    description1: string;
    description2: string;
  };
  toDo: string[];
  notToDo: string[];
}

export interface IEvents {
	day: Indi.Events.Day;
  events: Indi.Events.Event[];
  feedback?: Indi.Events.Feedback;
}

export default class Events {
  month: any = {};
  events: Record<string, {
    day: Indi.Events.Day;
    events: Indi.Events.Event[];
    feedback?: Indi.Events.Feedback;
  }> = {};
  status: TUserStateKeys | null = null;
  tariffs: Indi.indiGetTariffs.Tariff[] = [];
	percents: Record<string, number> = {}

  myBlockLoaded: boolean = false;
  monthBlockLoaded: boolean = false;
	showEvents: boolean = false;

  constructor() {
    makeAutoObservable(this);
    onBecomeObserved(this, 'month', this.loadMonth.bind(this))
    this.loadTariffs();
  }

  setStatus() {
    if (store.isAuth) {
      const info = store.sessionData?.indi!;
      if (dayjs(info.expiryDate).isAfter(dayjs())) {
        this.status = 'active';
      } else if (!dayjs(info.expiryDate).isAfter(dayjs())) {
        this.status = 'pause';
      } else if (!info.expiryDate) {
        this.status = 'inactive';
      } else {
        this.status = 'inpayed';
      }
    } else {
      this.status = 'unlogin';
    }
  }

  loadEventsFirst() {
    const from = dayjs().startOf('day');
    const to = dayjs().add(1, 'week').add(-1, 'second').startOf('day');
    this.loadEventsByDate(from, to)
  }

  async loadEventsByDate(date: dayjs.Dayjs, to?: dayjs.Dayjs) {
    const keyFrom = toISOString(date.startOf('day'));
    const keyTo = to && toISOString(to.startOf('day'));

    if (!this.events[keyFrom] || (keyTo && !this.events[keyTo])) {
      const dates = store.getIndiDates();
      let to_ = to || date.add(1, 'day').add(-1, 'second');
      if (to_.isAfter(dayjs.tz(dates.endPlan))) to_ = dayjs.tz(dates.endPlan);

      const timerFrom = performance.now();
			const now = dayjs().toISOString()

      await api.indiGetEvents(
				{
	        from: keyFrom,
	        to: toISOString(to_.startOf('day'))
      	},
				dates.startPlan,
				dates.endPlan,
				now
			)
				.then(data => {
	        for(const key in data) {
	          if (key !== 'debug') {
							const dateForCache = toISOString(dayjs(key).startOf('day'))
		          this.events[dateForCache] = {
		            day: data[key].day,
								feedback: data[key]?.feedback,
		            events: (data[key].events as Indi.Events.Event[])
		              .sort((a, b) => {
		                if(dayjs(a.endDate).diff(dayjs(b.endDate)) < 0) return -1;
		                else return 1;
		              })
		              .sort(a => {
		                if(a.isImportant) return -1;
		                else return 1;
		              })
		          };
						}
	        }

	        const timerTime = Math.trunc(performance.now() - timerFrom);
	        if (!to && timerTime > 4500) {
	          const msg = `Время загрузки моего дня '${timerTime}ms'`;
	          api.sendNotify(msg);
	        }
      })
    }
  }

  async loadMonth(currentDate?: string) {
    if (store.isAuth) {
      const dates = store.getIndiDates();
			const now = dayjs().toISOString()
      const result = await api.indiGetMonth(dates.startPlan, dates.endPlan, currentDate ?? now);
			this.percents = {...this.percents, ...result.percents}
			this.month = result
    }
  }

  async loadTariffs(country: Region = Region.ru) {
    this.tariffs = await api.indiGetTariffs(country);
  }

  async registration(email: string, language: string, firstName?: string, birth?: any, partner?: string) {
    const data = {
      channel: email,
      language,
      info: {
        firstName, birth
      },
      partner
    } as Indi.signUpOrSignIn.Request;
    await api.indiSignUpOrSignInWithEmail(data);
    const cb = await api.cb({ conversion: '' });
    await store.initLogin(cb.code);
  }

  async setMyBlockLoaded(value: boolean) {
    this.myBlockLoaded = value;
  }

  async setMonthBlockLoaded(value: boolean) {
    this.monthBlockLoaded = value;
  }

	setShowEvents(value: boolean) {
		this.showEvents = value
	}

	getIcon(date: dayjs.Dayjs) {
		const percent = this.percents[date.format('DD.MM.YYYY')]

		if (!percent) return

		if (percent < 31) {
			return TenseIcon
		}

		if (30 < percent && percent < 51) {
			return	MediumIcon
		}

		return HarmonicIcon
	}

	getColor(date: dayjs.Dayjs) {
		const percent = this.percents[date.format('DD.MM.YYYY')]
		if (!percent) {
			return {
				color: 'var(--text-primary)',
				background: '#FFFFFF',
        highligh: '#FFFFFF',
			}
		}

		if (percent < 31) {
			return {
				color: '#E78060',
				background: badBackgroundPath,
        highligh: '#F3DEDB',
			}
		}

		if (30 < percent && percent < 51) {
			return	{
				color: '#F0A066',
				background: typicalBackgroundPath,
        highligh: '#FDEADC',
			}
		}

		return {
			color: '#7DCA55',
			background: luckyBackgroundPath,
      highligh: '#D9EECD',
		}
	}

  getPercent(date: dayjs.Dayjs) {
    return this.percents[date.format('DD.MM.YYYY')]
  }
}
