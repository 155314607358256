import React from 'react';
import styled from 'styled-components';

import Button from '../components/Button';

import {InfoGray as InfoIcon, Warning2 as WarningIcon} from '../../../assets/icons/system/system-icons';


const Unscheduled = ({
  setShowModal,
  setShowCancel,
}: {
  setShowModal: (v: any) => void;
  setShowCancel: (v: any) => void;
}) => (
  <>
    <ChooseTime>
      Выберите время
      <WarningIcon />
    </ChooseTime>
    <Button
      color="gradient"
      style={{ marginBottom: '16px' }}
      onClick={() => {
        setShowModal(true);
        setShowCancel(false);
      }}
      size="large"
    >
      Выбрать время
    </Button>
    <Description>
      <InfoIcon />
      <p>Пожалуйста выберите новое время для консультации.</p>
    </Description>
  </>
)

export default Unscheduled;

const ChooseTime = styled.p`
  color: #F59300;
  font-size: 1.5rem;
  line-height: 1.3;
  margin-bottom: 1rem;

  & > svg {
    vertical-align: text-bottom;
    margin-left: 0.5rem;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-bottom: 16px;

  > svg {
    margin-right: 8px;
    width: 18px;
    height: 18px;
  }

  > p {
    font-size: 14px;
    line-height: 16px;
    min-height: 18px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
  }
`;