import { useState } from "react"
import styled, { css } from "styled-components"
import { ChevronLeft } from "../../assets/icons/system/system-icons"
import store from "../../store/Store"
import { PromocodePopup } from "./PromocodePopup"

interface PromocodeInputProps {
	className?: string;
	applied: boolean;
	subscription?: boolean;
	disabled?: boolean;
}

export const PromocodeButton = (props: PromocodeInputProps) => {
	const [ showModal, setShowModal ] = useState(false)

	const toggle = () => {
		if (props.applied) {
			store.promocode.resetPromocode()
		} else {
			setShowModal(true)
		}
	}

	return (
		<>
			<PromocodeContainer className={props.className ?? ''} applied={props.applied} onClick={toggle} disabled={props.disabled}>
				<div>{props.applied ? 'Сбросить промокод' : 'Применить промокод'}</div>
				<ChevronRight />
			</PromocodeContainer>

			{
				showModal && <PromocodePopup onClose={() => setShowModal(false)} subscription={props.subscription}/>
		  	}
		</>
	)
}

const PromocodeContainer = styled.div<{applied: boolean; disabled?: boolean}>`
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 0.75rem;
	border-radius: 10px;
	color: ${p => p.applied ? 'var(--color-bright-red)' : 'var(--color-green-dark)'};
	border: 1px solid ${p => p.applied ? 'var(--color-bright-red)' : 'var(--color-green-dark)'};
	font-size: 0.75rem;
	font-weight: 500;

	${p => p.disabled && css`
		opacity: 0.2;
		pointer-events: none;
	`}


	svg {
		width: 0.75rem;
		height: 0.75rem;
		/* margin-left: 0.5rem; */
		color: ${p => p.applied ? 'var(--color-bright-red)' : 'var(--color-green-dark)'};
	}
`

const ChevronRight = styled(ChevronLeft)`
	transform: rotate(180deg);
`
