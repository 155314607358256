import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { searchParams } from '../../helpers/url';

import { useBackWindow } from '../../hooks/router-hooks';
import { ROUTES } from '../../helpers/routes';

export const WINDOW_ID_AUTH: string = 'WINDOW_ID_AUTH';
export const GOOGLE_AUTH_LINK = `${process.env.REACT_APP_AUTH}/api/auth/google`;

interface IAuthProps {
  weak?: boolean;
  googleAuth?: boolean;
}

export const Auth = (props: IAuthProps) => {
  useBackWindow(ROUTES.AUTH, WINDOW_ID_AUTH);

  const ref = useRef<HTMLIFrameElement>(null);

  const sp = new URLSearchParams(window.location.search);
  sp.delete('token');
  let searchStr = sp.toString()
  searchStr = searchStr.length ? `?${searchStr}` : '';

  const url = `${process.env.REACT_APP_AUTH}/#/?ref=${window.location.origin}${window.location.pathname}${searchStr}${props.weak ? `&weak=true` : ``}`;


  const addSearchParamToken = () => {
    const token = new URLSearchParams(ref.current!.contentWindow?.location.search).get('token');

    if (token) {
      const sp = searchParams()
      sp.set('token', token)
      window.location.href = `${window.location.pathname}?${sp}`;
    }
  }

  const onLoad = (evt: any) => {
    if (props.googleAuth === true) {
      setTimeout(() => {
        window.location.href = GOOGLE_AUTH_LINK;
      }, 1000)
    } else {
      addSearchParamToken()
    }
  }

  useEffect(() => {
    const onGoogleAuthMessage = (evt: any) => {
      if (evt.data === 'google_auth') {
        window.location.href = GOOGLE_AUTH_LINK;
      }
    }

    window.addEventListener('message', onGoogleAuthMessage);

    return () => {
      window.removeEventListener('message', onGoogleAuthMessage)
    }

  }, [])

  return <Container>
    <AuthFrame src={url} onLoad={onLoad} ref={ref} name="auth-frame"/>
  </Container>

}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: var(--color-black);
`

const AuthFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  appearance: none;
`
