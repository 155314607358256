import styled from "styled-components"
import { KnobIcon } from "../../assets/icons/system/system-icons"

interface KnobProps {
	className?: string
	onTouchStart(e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>): void
	onMouseDown(e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>): void
}

export const Knob = (props: KnobProps) => {

	return (
		<Container className={props.className ?? ''}
							 onTouchStart={props.onTouchStart}
							 onMouseDown={props.onMouseDown}>
			<KnobIcon/>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: end;

  width: 100%;
	height: 1.5rem;
`
