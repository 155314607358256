import styled, { css } from "styled-components"
import { ThumbsUpThreeIcon } from "../../assets/icons/system/system-icons"

type Reaction = 'positive' | 'negative'

interface ReactionButtonProps {
	className?: string
	reaction: Reaction
	onClick(): void
}

export const ReactionButton = (props: ReactionButtonProps) => {

	return (
		<Container reaction={props.reaction} onClick={props.onClick}>
			<ThumbsUpThreeIcon/>
			<span>
				{
					props.reaction === 'positive'
						? 'Да'
						: 'Нет'
				}
			</span>
		</Container>
	)
}

const Container = styled.div<{reaction: Reaction}>`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	padding: 1rem;
	border-radius: 0.5rem;
	color: ${p => `var(--color-${p.reaction})`};
	background: ${p => `var(--color-${p.reaction}-bg)`};
	cursor: pointer;

	svg {
		margin-right: 0.5rem;
		${p => p.reaction === 'negative' && `
		transform: rotate(180deg);
		`};
		
	}

	${p => p.reaction === 'positive' && css`
		margin-right: 0.25rem;
	`}

	${p => p.reaction === 'negative' && css`
		margin-left: 0.25rem;
	`}
`
