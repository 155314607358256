import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import store from '../../../store/Store';
import { getFormattedInterval } from '../../../helpers/dates';

import { Row, Markup } from '../../../components/legacy';
import InfoBlock, { BlockStatus } from '../../../components/InfoBlock';
import { ClockIcon2  as ClockIcon, ThumbsUpIcon } from '../../../assets/icons/system/system-icons';


export default function SpecialDay(
  {
    id,
  }: {
    id: string;
  }
) {
  const specialDayData = useMemo(() => {
    return store.prognoses.getSpecialDayById(+id);
  }, [id]);

  if(!specialDayData) {
    return null;
  }

  const isDayLucky = specialDayData.qualityType === 'LUCKY';
  const isStarted = dayjs().isAfter(dayjs(specialDayData.period.start, 'DD.MM.YYYY HH:mm:ss'));
  const period = specialDayData.period?.start && specialDayData.period?.end
    ? getFormattedInterval(specialDayData.period.start, specialDayData.period.end)
    : null;

  return (
    <>
      <Container>
        <Row justify='center' mb='1.5rem'>
          <IconCircle lucky={isDayLucky}>
            { isDayLucky
                ? <ThumbsUpIconStyled />
                : <ThumbsDownIconStyled />
            }
          </IconCircle>
        </Row>
        <Row justify='center'>
          <Title>{specialDayData.title}</Title>
        </Row>
        <Row>
          <InfoBlock
            title={period}
            titleRight={isStarted ? 'Уже началось' : null}
            status={isStarted ? BlockStatus.NEGATIVE : BlockStatus.NEUTRAL}
            icon={<ClockIcon />}
          />
        </Row>
        <Row>
          <Markup>
            <p>{specialDayData.description}</p>
          </Markup>
        </Row>
        { specialDayData.recommendations?.[0] &&
            <Row>
              <Markup>
                <CompensatoricTitle>Компенсаторика</CompensatoricTitle>
                <p>{specialDayData.recommendations?.[0].text}</p>
              </Markup>
            </Row>
        }
      </Container>
    </>
  );
}

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #F3F3F3;
  box-shadow: 0px 3.2px 14px rgba(0, 0, 0, 0.06), 0px 0.6px 1.8px rgba(0, 0, 0, 0.04);
  padding: 1rem;
  border-radius: 1rem;
`;

const Title = styled.h2`
  color: var(--text-primary);
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.1666;
  margin: 0;
`;

const ThumbsUpIconStyled = styled(ThumbsUpIcon)`
  width: 2rem;
  height: 2rem;
  fill: rgba(81, 135, 25, 0.6);
`;

const ThumbsDownIconStyled = styled(ThumbsUpIcon)`
  width: 2rem;
  height: 2rem;
  fill: rgba(197, 50, 47, 0.6);
  transform: rotate(180deg);
`;

const IconCircle = styled.div<{lucky?: boolean}>`
  background-color: ${p => p.lucky ? 'rgba(115, 179, 48, 0.1)' : 'rgba(197, 50, 47, 0.1)'};
  width: 86px;
  height: 86px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CompensatoricTitle = styled.h3`
  color: var(--color-green);
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.1666;
  margin-top: 0;
  margin-bottom: 0.5rem;
`;
