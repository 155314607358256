import { makeAutoObservable } from "mobx";
import api from "../helpers/api";

export class Support {
  constructor() {
    makeAutoObservable(this)
  }

  async sendMailToSupport(email: string, title: string, text: string) {
    try {
      const dataPost = {
        email,
        title,
        text,
      }

      await api.indiSendMailToSupport(dataPost)
    } catch(e) {
      console.error(`Error in sending mail to support: ${e}`)
    }
  }
}
