import { makeAutoObservable } from "mobx";
import api from "../helpers/api";

export class Rectification {
  isRectificationStatus = false;
  constructor() {
    makeAutoObservable(this)
    this.loadRectificationStatus();
  }

  setRectificationOrdered(value: boolean) {
    this.isRectificationStatus = value;
    localStorage.setItem('rectification_status', String(value)); 
  }

  loadRectificationStatus() {
    const storedValue = localStorage.getItem('rectification_status');
    this.isRectificationStatus = storedValue === 'true'; 
  }

  async orderRectification() {
    try {
      await api.orderRectification({ isDelayed: true })
      this.setRectificationOrdered(true);
    } catch(e) {
      console.error(`Error in ordering rectification: ${e}`)
    }
  }
}
