import React from 'react';
import styled from "styled-components";

interface IData {
  name: string
  place: string
  dateTime: string
  sign: any
}

interface IPartnerItemProps {
  data: IData | null
}

function PersonCard(props: IPartnerItemProps) {
  if (!props.data) return <></>

  const getSign = () => {
    const SignIcon = props.data?.sign
    return <SignIcon/>
  }

  return (
    <Container>
      <div className={'body'}>
        <div className={'text'}>
          <div className={'name'}>{props.data.name}</div>
          <div className={'date'}>{props.data.dateTime}</div>
          <div className={'place'}>{props.data.place}</div>
        </div>

        <SignContainer>{getSign()}</SignContainer>

      </div>
    </Container>
  );
}

export default PersonCard;

const Container = styled.div`
  position: relative;
  margin-bottom: 1.125rem;
  
  .header {
    display: flex;
    justify-content: space-between;
    
    color: rgba(71, 96, 231, 1);
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0.8rem;
  }
  
  .body {
    display: flex;
    justify-content: space-between;
    
    color: var(--text-secondary);
    font-size: 0.9rem;
    background: #FFFFFF;
    box-shadow: 0px 3.2px 14px rgba(0, 0, 0, 0.06), 0px 0.6px 1.8px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 1rem;

    
    .name {
      color: var(--text-primary);
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 0.5rem;

      ::-webkit-scrollbar { 
        display: none; 
      }
    }
  }

`;

const SignContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  
  min-width: 2rem;
  min-height: 2rem;
  width: 2rem;
  height: 2rem;
  background: rgba(255, 138, 101, 1);
  border-radius: 50%;
  color: white;
  
  svg {
    width: 70%;
    height: 70%;
  }
`;