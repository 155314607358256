import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FlexContainer } from '../../../components/layout/elements';
import { ArrowRight, Harmony, Tension } from '../../../assets/icons/system/system-icons';
import { observer } from 'mobx-react-lite';
import store from '../../../store/Store';
import { getEventsWord } from '../../../helpers/words';
import { gtmSend } from '../../../helpers/url';
import { openMyDay } from '../../my-day/my-day';

const changeNameKostyl = (title: string) => {
  return title.replace('Любовные отношения и романтика', 'Любовь');
}

const spheres = [{
  type: 'significant',
  title: 'Значимые сферы',
},{
  type: 'harmonic',
  title: 'Гармоничная сфера',
},{
  type: 'tense',
  title: 'Напряженная сфера',
}]

export const MainPageBlocksMonth = observer(() => {
  const data = store.events.month;
  const userId = store.sessionData?.id;

  useEffect(() => {
    if(data.description) store.events.setMonthBlockLoaded(true);
  }, [data.description]);

  if(!data.description) return null;


  const openMyPopup = () => {
    openMyDay();
    gtmSend({'event': 'ind_prognosis_general_month-overview_click', 'user_id': userId ? userId : ''})
  }

  return <Container className="ob-0">
    <Header>
      <div>
        <HeaderTitle>Обзор месяца</HeaderTitle>
        <HeaderCounter>{data.eventsCount} {getEventsWord(data.eventsCount)}</HeaderCounter>
      </div>
      <HeaderArrow onClick={() => openMyPopup()}><ArrowRight /></HeaderArrow>
    </Header>
    <Items>
      {spheres.map(item => {
        const value = data.description[item.type].value;
        const text = changeNameKostyl(Array.isArray(value) ? value.join(', ') : value);

        return <Item key={item.type} onClick={() => openMyPopup()}>
          <ItemLeft>
            <ItemTitle type={item.type}>{item.title}</ItemTitle>
            <ItemText>{text}</ItemText>
          </ItemLeft>
          <ItemIcon>
            {item.type === 'harmonic' && <Harmony />}
            {item.type === 'tense' && <Tension />}
          </ItemIcon>
        </Item>
      })}
    </Items>
  </Container>
})

const Container = styled.div`
  position: relative;
  background: var(--color-white);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 24px;

  padding: 1rem;
`

const Header = styled(FlexContainer)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

const HeaderTitle = styled.div`
  margin-bottom: 0.3rem;
  font-size: 0.875rem;
  color: var(--text-secondary);
`

const HeaderCounter = styled.div`
  font-size: 1.125rem;
  line-height: 110%;
`

const HeaderArrow = styled.div`
  border-radius: 44px;
  background: var(--color-black);
  padding: 0.125rem 0.625rem;
  color: var(--color-white);
`

const Items = styled(FlexContainer)`
  flex-direction: column;
  gap: 0.25rem;
`

const Item = styled(FlexContainer)`
  padding: 0.75rem;
  background: var(--element-background-gray);
  border-radius: 4px;

  &:nth-child(1){
    border-radius: 12px 12px 4px 4px;
  }
  &:nth-child(3){
    border-radius: 4px 4px 12px 12px;
  }
`

const ItemLeft = styled.div`
  flex: 1;
`

const ItemTitle = styled.div<{type: string}>`
  font-size: 0.75rem;
  margin-bottom: 0.375rem;
  color: var(--text-secondary);

  ${p => p.type === 'harmonic' && css`
    color: var(--color-green);
  `}

  ${p => p.type === 'tense' && css`
    color: var(--color-orange);
  `}
`

const ItemText = styled.div`
  font-size: 1.125rem;
  line-height: 135%;
`

const ItemIcon = styled.div`
  width: min-content;
`
