import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Indi } from 'src/libs';
import styled from 'styled-components';
import { InfoFilledIcon, LoadingIcon } from '../../../assets/icons/system/system-icons';
import { FlexContainer } from '../../../components/layout/elements';
import { getTimeOfDay, toISOString } from '../../../helpers/dates';
import { gtmSend } from '../../../helpers/url';
import { IDayDescription } from '../../../store/Events';
import store from '../../../store/Store';
import { windowsStore } from '../../../store/Windows';
import { getAppLS, getMyDayLS, getPinnedGroups, MyDayTips, setMyDayLS } from '../../../store/localStorageHelpers';
import { openPotential } from '../../potential/Potential';
import { groupBySphere, sortGroups } from '../helpers/events';
import MyEventsScrollView, { WINDOW_ID_MYDAY_SCROLL_VIEW } from './MyEventsScrollView';
import { MyDayEventDescription } from './event/my-day-event-description';
import { MyDayEventsGroup } from './event/my-day-events-group';
import { MyDayFeedback } from './my-day-feedback';
import { MyDayNoEvents } from './event/my-day-no-events';
import { MyDayOffer } from './my-day-offer';


const timeOfDateTitles = {
	morning: 'Доброе утро',
	afternoon: 'Добрый день',
	evening: 'Добрый вечер'
}

export const MyDayDay = observer(({
  date,
  events,
  day,
	feedback,
  containerRef,
  firstMyDayItem
}: {
  date: dayjs.Dayjs
  events: Indi.Events.Event[]
  day: IDayDescription | null
	feedback?: Indi.Events.Feedback
  containerRef: React.RefObject<HTMLDivElement>
  firstMyDayItem?: boolean
}) => {
	const [pinnedGroups, setPinnedGroups] = useState(getPinnedGroups());
  const [showTip, setShowTip] = useState('')

	const eventsContainerRef = useRef<HTMLDivElement | null>(null)

	const name = store.profile.firstName
	const hasOffer = store.sessionData?.indi && store.sessionData?.indi?.tariffId !== 3
	const showEvents = store.events.showEvents
	const percent = store.events.percents[date.format('DD.MM.YYYY')]
  const userId = store.sessionData?.id;
  const windows = windowsStore.getWindows()

	const scrollToEvents = useCallback(() => {
    const myDayDataLS = getMyDayLS()

		if (showEvents && myDayDataLS?.onboarding?.showedOnboarding) {
			setTimeout(function () {
				const top = eventsContainerRef.current?.getBoundingClientRect().top ?? 0

        containerRef.current?.scrollTo({
					top: top - 50,
					behavior: 'smooth',
				})
			}, 300)

      store.events.setShowEvents(false)
		}
	}, [showEvents, eventsContainerRef, containerRef])

	useEffect(() => {
		scrollToEvents()
	}, [showEvents, eventsContainerRef, scrollToEvents])

	useEffect(() => {
		if (!percent) {
			store.events.loadMonth(toISOString(date.startOf('day')))
		}
	}, [percent, date])

	const getSortGroups = () => {
		const groups = Object.entries(groupBySphere(events)).map(([key]) => key) as Indi.Events.Spheres[]
		return sortGroups(groups)
	}

	const pinEventsGroup = (group: Indi.Events.Spheres, pinned: boolean) => {
		let newPinnedGroups = [...pinnedGroups];

		if(pinned) newPinnedGroups = pinnedGroups.filter((item: string) => item !== group)
		else newPinnedGroups.unshift(group);

		const myDayLS = getMyDayLS();
		myDayLS.pinnedGroups = newPinnedGroups;
		setMyDayLS(myDayLS);

		setPinnedGroups(newPinnedGroups);
	}

	const isGroupPinned = (group: Indi.Events.Spheres) => {
		return pinnedGroups.includes(group);
	}

	const openEvent = (id: number, events: Indi.Events.Event[]) => {
		events.length && windowsStore.open(WINDOW_ID_MYDAY_SCROLL_VIEW, <MyEventsScrollView list={events} currentId={id} />, 'fade')
	}

	const importantEvent = events
		.filter(event => event.isImportant)
		.sort((e1, e2) => dayjs(e1.startDate).isAfter(dayjs(e2.startDate)) ? -1 : 1)

	const groupsForFilterSortedByPin = getSortGroups()
		.sort((g1, g2) => {
			const p1 = pinnedGroups.indexOf(g1);
			const p2 = pinnedGroups.indexOf(g2);
			return p1 > p2 ? -1 : 1
		})

	const openImportantEvent = useCallback((id: number) => {
		openEvent(id, importantEvent)
	}, [importantEvent])

	const Icon = store.events.getIcon(date)

  const onClickPercent = () => {
    openPotential()
    gtmSend({ 'event': 'indi_potential_events_feed', 'user_id': userId ? userId : '' })
  }

  const onCloseTip = useCallback(() => {
    setShowTip('')

    const myDayDataLS = getMyDayLS()

    const newData = {
      ...myDayDataLS,
      onboarding: {
        ...myDayDataLS.onboarding,
        showedTips: [
          ...myDayDataLS.onboarding?.showedTips ?? [],
          MyDayTips.ACCORDION
        ],
      }
    }

    setMyDayLS(newData)
  }, [])

  const checkTip = useCallback(() => {
    const myDayDataLS = getMyDayLS()
    const sessionCount = getAppLS()?.sessionCount

    if (windows.length > 1 || myDayDataLS?.onboarding?.showedTips?.includes(MyDayTips.ACCORDION)) return

    if (store.onboarding.detectOldUser()) {
      onCloseTip()
      return
    }

    if (
      sessionCount &&
      sessionCount > 2 &&
      !myDayDataLS?.usedAccordion
    ) {
      for (const group of groupsForFilterSortedByPin) {
        const groupedEvents = events.filter(event => event.sphere === group && !event.isImportant)

        if (groupedEvents.length > 1) {
          setTimeout(() => setShowTip(group), 2000)
          break
        }
      }
    }
  }, [events, groupsForFilterSortedByPin, onCloseTip, windows])

  const onCloseTipHandler = useCallback(() => {
    onCloseTip()
    gtmSend({ 'event': 'indi_onboarding_events_description3_stack', 'user_id': userId ? userId : '' })
  }, [onCloseTip, userId])

  useEffect(() => {
    return () => {
      showTip && onCloseTip()
    }
  }, [showTip, onCloseTip])

  useEffect(() => {
    checkTip()
  }, [checkTip])

  return <Container>
		<Header>
			<Title>{timeOfDateTitles[getTimeOfDay()]}, {name}</Title>
			<Percent
        color={store.events.getColor(date).color}
        onClick={onClickPercent}
      >
        {Icon && <Icon/>} <span>{percent}%</span> <InfoFilledIcon/>
      </Percent>
		</Header>

    <MyDayEventDescription data={day} />

		<MyDayFeedback date={toISOString(date.startOf('day'))} feedback={feedback}/>

    {events.length === 0 && <MyDayNoEvents currentDate={date} />}

		<GroupContainer ref={eventsContainerRef}>
			<MyDayEventsGroup
				key={'important'}
				group={Indi.Events.Spheres.IMPORTANT}
				events={importantEvent}
				currentDate={date}
				openEvent={openImportantEvent}
			/>

			{hasOffer && <MyDayOffer header/>}

  	  {
				events.length > 0 && groupsForFilterSortedByPin.map((group, index) => {
					const filteredEvents = events
								.filter(event => event.sphere === group && !event.isImportant)
								.sort((e1, e2) => dayjs(e1.startDate).isAfter(dayjs(e2.startDate)) ? -1 : 1)

					return (
            <MyDayEventsGroup
              key={group}
              index={index}
  						group={group}
  						events={filteredEvents}
  						currentDate={date}
  						openEvent={(id: number) => openEvent(id, filteredEvents)}
  						pinEventsGroup={(pinned: boolean) => pinEventsGroup(group, pinned)}
  						pinned={isGroupPinned(group)}
              showTip={showTip ===  group}
              closeTip={onCloseTipHandler}
              firstMyDayItem={firstMyDayItem}
              eventsContainerRef={eventsContainerRef}
  					/>
          )
		  	})
			}

		</GroupContainer>
  </Container>
})

export const MyDayDayLoading = ({
  date
}: {
  date: dayjs.Dayjs
}) => {
  return <Container>
    <LoadingContainer><LoadingIcon /></LoadingContainer>
    <Text>Загружаем прогноз</Text>
    <Skeleton count={4} style={{height: '200px', borderRadius: '24px', marginBottom: '0.75rem'}}/>
  </Container>
}

const Container = styled.div`
  margin-bottom: 2rem;
  padding: 1.5rem 1rem 0 1rem;
  overflow: hidden;
`

const Header = styled(FlexContainer)`
  justify-content: space-between;
`

const LoadingContainer = styled(FlexContainer)`
  padding: 1rem 0 0.5rem;
  justify-content: center;

  > svg {
    width: 2.25rem;
    height: 2.25rem;
  }
`

const Title = styled.div`
	color: var(--text-third);
	font-size: 0.875rem;
	font-weight: 500;
`

const Percent = styled(FlexContainer)<{color: string}>`
	justify-content: center;
  align-items: center;

	color: ${p => p.color};

	span {
		margin-left: 0.25rem;
	}
`

const Text = styled.div`
  margin-bottom: 1.5rem;
  color: var(--text-primary);
  font-size: 0.875rem;
  text-align: center;
  font-weight: 500;
`

const GroupContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

  position: relative;
	height: 150%;
`
