import React, { useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import PercentCircle from '../../../components/PercentCircle';
import styled from 'styled-components';
import { capitalize } from '../../../helpers/strings';
import store from '../../../store/Store';

export default function Calendar(
  {
    day = dayjs(),
    setDay,
  }:
  {
    day: Dayjs;
    setDay(val: any): void;
  }
) {
  const week = useMemo(() => {
    const days = [];
    const startOftheWeek = dayjs().startOf('w');
    for(let i = 0; i < 7; i++) {
      days.push(startOftheWeek.add(i, 'd'));
    }
    return days;
  }, []);

  const daysData = useMemo(() => {
    return week.map(d => store.prognoses.getDay(d));
  }, [store.prognoses.prognoses]);

  return (
    <Container>
      { week?.length && week.map((d, i) => {
        const current = d.format('DD.MM.YYYY') === day.format('DD.MM.YYYY');
        const percent = daysData[i]?.qualityPct || 0;
        return (
          <WeekDay key={d.toISOString()} onClick={() => setDay(d)}>
            <WeekDayName current={current}>{capitalize(d.format('dd'))}</WeekDayName>
            <PercentCircle percent={percent} size='1.75rem' text={d.format('DD')} isFilled={current} />
          </WeekDay>
        );
      })}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WeekDay = styled.div`
  text-align: center;
  cursor: pointer;
`;

const WeekDayName = styled.span<{current?: boolean}>`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-align: center;
  color: ${p => p.current ? 'black' : 'rgba(64, 64, 64, 0.6)'};
  display: inline-block;
  margin-bottom: 0.375rem;
`;