export const highlightMyDayElements = () => {
  const container = document.getElementById('my-day-container')
  const pins = document.querySelectorAll<HTMLElement>('.pin')
  const headers = document.querySelectorAll<HTMLElement>('.my-day-events-group-header')
  const events = document.querySelectorAll<HTMLElement>('.my-day-event-new')

  container?.classList.add('block-scroll')

  pins.forEach(pin => {
    pin.classList.add('onboarding-highlighted-element')
  })

  headers.forEach(header => {
    header.classList.add('onboarding-highlighted-element')
  })

  events.forEach(event => {
    if (!event.classList.contains('isImportant')) {
      const computedStyle = getComputedStyle(event)
      event.style.zIndex = computedStyle.zIndex + 120
      event.style.pointerEvents = 'none'
    }

  })
}

export const hideMyDayElements = () => {
  const container = document.getElementById('my-day-container')
  const innerContainer = document.getElementById('my-day-inner-container')
  const pins = document.querySelectorAll<HTMLElement>('.pin')
  const headers = document.querySelectorAll<HTMLElement>('.my-day-events-group-header')
  const events = document.querySelectorAll<HTMLElement>('.my-day-event-new')

  container?.classList.remove('block-scroll')

  if (innerContainer) {
    innerContainer.style.transform = 'translateX(-100%)'
    innerContainer.classList.add('smooth')
  }

  pins.forEach(pin => {
    pin.classList.remove('onboarding-highlighted-element')
  })

  headers.forEach(header => {
    header.classList.remove('onboarding-highlighted-element')
  })

  events.forEach((event, index) => {
    if (!event.classList.contains('isImportant')) {
      const computedStyle = getComputedStyle(event)
      event.style.zIndex = String(Number(computedStyle.zIndex) - 120)
      event.style.pointerEvents = 'initial'
    }
  })
}
