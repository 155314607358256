import React from 'react';
import styled from "styled-components";
import { capitalize } from '../../../../helpers/strings';

export interface ICardData {
  title: string
  actions: string[]
  key: string
}

interface ICategoryProps {
  data: ICardData
}

export default React.memo(function Card(props: ICategoryProps) {
  return (
    <Container categoryKey={props.data.key} id={props.data.key}>
      <Title>{props.data.title}</Title>
      <List>
        {
          props.data.actions.map((action, index) => {
            return (
              <li key={`action_${index}`}><span>{capitalize(action)}</span></li>
            )
          })
        }
      </List>

    </Container>
  );
})

const Container = styled.div<{categoryKey: string}>`
  border-radius: 12px;
  background-color: ${p => `var(--color-${p.categoryKey}-bg)` };
  padding: 1rem;
  margin-bottom: 1.125rem;
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.188rem;
  margin-bottom: 0.5rem;
`

const List = styled.ul`
  color: var(--text-secondary);
  font-size: 0.875rem;
  padding-left: 1.5rem;
  list-style-type: circle;

  li {
    list-style-type: disc;
    margin-bottom: 0.75rem;
    color: var(--text-disabled);
  }

  span {
    color: var(--text-secondary);
  }
`
