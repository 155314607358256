import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

export interface IChartData {
	key: string
	percent: number
	color: string
	title: string
}

interface IChartProps {
	data: IChartData[]
	showTitle: boolean
	currentCategory?: string
}

function Chart(props: IChartProps) {
	const hasCompatibility = !!props.data.find((item) => item.percent !== 0)
	const isSlimScreen = window.outerWidth <= 370

	let offsetSum = 4
	const gap = 4

	const P = (isMobile ? 70 : 100) + 5 * gap
	const R = P / (2 * Math.PI)

	return (
		<CircleContainer className={'CircleContainer'} P={P}>
			<svg className='chart' viewBox='0 0 50 50'>
				{props.data.map((item) => {
					const getArcLength = () => {
						if (hasCompatibility) {
							return isMobile ? item.percent * 0.7 : item.percent
						} else {
							return P / 5
						}
					}

					const fontSize = isSlimScreen ? '1.7px'	: '2.5px'
					const k = 1.3
					const halfPercent = getArcLength() / 2
					const arc = halfPercent + offsetSum
					const deg = (arc * 360) / (2 * Math.PI * R)
					const angle = (deg * Math.PI) / 180

					const getTextAnchorValue = () => {
						const quarterCircle = P / 4
						const threeQuartersCircle = P * (3 / 4)

						if (quarterCircle <= arc && arc <= threeQuartersCircle) {
							return 'end'
						} else {
							return 'start'
						}
					}

					const getX = () => {
						return 25 + R * Math.cos(angle) * k
					}

					const getY = () => {
						return 25.7 + R * Math.sin(angle) * k
					}

					const res = (
						<React.Fragment key={`circle_${item.key}`}>
							<circle
								id={`circle_${item.key}`}
								stroke={item.color}
								opacity={
									item.percent === 0 ||
									(props.currentCategory &&
										props.currentCategory !== item.key) ||
									!hasCompatibility
										? 0.15
										: 1
								}
								strokeDasharray={`${getArcLength()}, ${P}`}
								strokeDashoffset={`-${offsetSum}`}
								className='circle'
								r={R}
								cx='50%'
								cy='50%'
							/>

							{props.showTitle && (
								<text
									fontSize={fontSize}
									fill={item.color}
									textAnchor={getTextAnchorValue()}
									x={getX()}
									y={getY()}
								>
									{item.title}
								</text>
							)}
						</React.Fragment>
					);

					offsetSum += hasCompatibility ? getArcLength() + gap : P / 5 + gap

					return res
				})}
			</svg>
		</CircleContainer>
	);
}

export default Chart

const CircleContainer = styled.div<{P: number}>`
	position: relative;

	.chart {
		display: block;
		position: relative;
		margin: 0 auto;
		width: 100%;
		max-height: 15rem;

		@media print {
			max-height: 23em;
		}
	}

	.circle {
		fill: none;
		stroke-width: 2;
		stroke-linecap: round;
		animation: progress 1s ease-out forwards;
	}

	@keyframes progress {
		0% {
			stroke-dasharray: ${p => `0 ${p.P}`};
		}
	}
`
