import React, { ReactNode, useEffect, useRef, useState } from "react"
import { FlexContainer } from "../layout/elements"
import styled, { css } from "styled-components"
import { Button } from "./Button/Button"
import { observer } from "mobx-react-lite"
import { getMainPageLS, setMainPageLS } from "../../store/localStorageHelpers"
import store from "../../store/Store"


interface WidgetsAccordionProps {
  className?: string
  widgets: ReactNode[]
  title: string
  icon: ReactNode
  index: number
}

export const WidgetsAccordion = observer((props: WidgetsAccordionProps) => {
  const {
    className = '',
    widgets,
    title,
    icon,
    index,
  } = props

  const widgetsRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const collapsed = store.widgets.collapsed
  const mainPageLS = getMainPageLS()
  const isOneWidget = widgets.length === 1

  useEffect(() => {
    const widgetsList = widgetsRef.current?.querySelectorAll<HTMLElement>('.astroEvent')

    if (collapsed && !isOneWidget) {
      widgetsList?.forEach((widget, index) => {
        if (index === 1) {
          widget.style.transform = 'translateY(-3rem) scale(0.9)'
        }

        if (index === 2) {
          widget.style.transform = 'translateY(-7.7rem) scale(0.8)'
        }

        if (index > 2) {
          widget.style.transform = 'translateY(-20rem) scale(0)'
        }

        widget.style.zIndex = `${4 - index}`
        widget.style.pointerEvents = 'none'
      })
    } else {
      widgetsList?.forEach(widget => {
        widget.style.transform = 'translateY(0) scale(1)'
        widget.style.zIndex = '1'
        widget.style.pointerEvents = 'auto'
      })
    }
  }, [collapsed, widgets, isOneWidget])

  useEffect(() => {
    if (containerRef.current && index === 0) {
      store.onboarding.setWidgetsAccordion(containerRef)
    }
  }, [containerRef, index])

  const updateManePageLS = (value: boolean) => {
    const updatedMainPageLS = {
      ...mainPageLS,
      widgets: {
        ...mainPageLS.widgets,
        collapsed: value,
      }
    }

    setMainPageLS(updatedMainPageLS)
  }

  const collapseWidgets = () => {
    store.widgets.setCollapsed(true)
    updateManePageLS(true)
  }

  const showWidgets = () => {
		if (collapsed && widgets.length > 1) {
      store.widgets.setCollapsed(false)
      updateManePageLS(false)
    }
  }

  return (
    <Container
      ref={containerRef}
      className={className}
      collapsed={collapsed}
      isOneWidget={isOneWidget}
    >
      <Header>
        <HeaderLeft>
          {icon}
          <span>{title}</span>
        </HeaderLeft>
        <HeaderRight>
  				{!collapsed && !isOneWidget && <ButtonShowLess onClick={collapseWidgets}>Показать меньше</ButtonShowLess>}
        </HeaderRight>
    </Header>

    <Widgets
      className={'widgets-astro-event-container'}
      ref={widgetsRef}
      onClick={showWidgets}
      collapsed={collapsed}
      isOneWidget={isOneWidget}
    >
      {
        widgets.map((widget, index) => <React.Fragment key={`widget-astro-event-${index}`}>{widget}</React.Fragment>)
      }
    </Widgets>

    <Footer>
      {!collapsed && widgets.length > 3 && <ButtonShowLess onClick={collapseWidgets}>Показать меньше</ButtonShowLess>}
    </Footer>
    </Container>
  )
})

const Container = styled(FlexContainer)<{collapsed: boolean, isOneWidget: boolean}>`
  flex-direction: column;

  width: 100%;
  max-height: ${p => p.isOneWidget ? '8rem' : '11rem'};

  ${p => !p.collapsed && css`
    max-height: 100%;
	`}
`

const Header = styled(FlexContainer)`
  align-items: center;
  margin-bottom: 0.75rem;

  > svg {
    &:first-child {
      margin-right: 0.375rem;
    }
  }
`

const HeaderLeft = styled(FlexContainer)`
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }

  span {
    white-space: nowrap;
  }
`

const HeaderRight = styled(FlexContainer)`
  justify-content: end;
  align-items: center;

  margin-left: auto;
`

const Widgets = styled(FlexContainer)<{collapsed: boolean, isOneWidget: boolean}>`
  flex-direction: column;
  gap: 0.75rem;
	position: relative;

  .astroEvent {
    transition: all 0.3s ease-out;
  }

  ${p => p.collapsed && p.isOneWidget && css`
    .astroEvent:nth-child(1) {
      z-index: 4;
    }

    .astroEvent:nth-child(2) {
      transform: translateY(-3rem) scale(0.9);
      z-index: 3;
    }

    .astroEvent:nth-child(3) {
      transform: translateY(-7.7rem) scale(0.8);
      z-index: 2;
    }

    .astroEvent:nth-child(n + 3) {
      transform: translateY(-20rem) scale(0);
    }
  `}
`

const Footer = styled(FlexContainer)`
  justify-content: end;
  align-items: center;

  width: 100%;
	padding: 0.5rem 0;
`

const ButtonShowLess = styled(Button)`
  display: block;
  width: fit-content;
  padding: 0.375rem 0.5rem;
  color: var(--text-primary);
  font-size: 0.75rem;
  font-weight: 500;
  background: var(--color-white);
  filter: var(--shadow);
`
