import React from 'react';
import styled from 'styled-components';
import { FlexContainer } from './layout/elements';
import PwaLogoPath from '../assets/images/pwa-logo.png';
import { Cross, OptionsSafari, OptionsChrome } from '../assets/icons/system/system-icons';
import { isSafari } from 'react-device-detect';

export const AppInstallPopup = ({
  onClick,
  webPush
}: {
  onClick: () => void;
  webPush?: boolean;
}) => {
  const title = webPush ? 'Чтобы получать уведомления, добавьте на главный экран' : 'Добавьте на главный экран'
  let secondSection;

  if(isSafari) {
    secondSection = <SecondSection>
      <ul>
        <li>Нажмите на <OptionsSafari /> в нижней панели Safari</li>
        <li>Пролистайте вниз и нажмите <span>На экран «Домой»</span></li>
      </ul>
    </SecondSection>;
  } else {
    secondSection = <SecondSection>
      <ul>
        <li>Нажмите на <OptionsChrome /> справа сверху</li>
        <li>Нажмите <span>Установить приложение</span> или <span>Добавить на главный экран</span></li>
      </ul>
    </SecondSection>;
  }

  return <Container  className="ob-0">
    <CommonSection>
      <Logo><img src={PwaLogoPath} alt="PWA" /></Logo>
      <div>
        <Title>{title}</Title>
        <SubTitle>Запускайте Chronos как приложение</SubTitle>
      </div>
      <Button onClick={onClick}><Cross /></Button>
    </CommonSection>
    {secondSection}
  </Container>
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  z-index: var(--z-index-modal);
  width: 100%;
  background-color: #FFFFFF;
  font-size: 0.75rem;
  color: var(--text-secondary);
`

const CommonSection = styled(FlexContainer)`
  gap: 0.75rem;
  align-items: center;
  padding-right: 1rem;
`


const Logo = styled.div`
  padding: 0.8rem 0 0.4rem 0.5rem;
  > img {
    display: block;
    width: 3rem;
    height: 3rem;
  }
`

const Button = styled(FlexContainer)`
  margin-left: auto;
  border-radius: 50%;
  background-color: rgba(246, 246, 246, 1);
  align-items: center;
  justify-content: center;
  min-width: 2rem;
  width: 2rem;
  height: 2rem;
  color: var(--text-secondary);
`

const Title = styled.div`
  font-size: 0.875rem;
  color: var(--text-primary);
  font-weight: 500;
  margin-bottom: 0.25rem;
`

const SubTitle = styled.div`
`

const SecondSection = styled.div`
  border-top: 1px solid var(--element-border-10);
  padding: 0.75rem 1rem;

  > ul {
    margin: 0;
    list-style: decimal;
    padding: 0 1rem;

    > li {
      line-height: 135%;

      > svg {
        display: inline;
        vertical-align: middle;
        margin: 0 0.25rem;
      }

      > span {
        color: var(--color-gradus-blue);
      }
    }

    > li:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`
