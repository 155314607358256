import React from 'react';
import styled from "styled-components";

interface IProgressBarProps {
  step: number
  numberSteps: number
  inModal: boolean
}

const FOOTER_HEIGHT = '66px';

export function ProgressBar(props: IProgressBarProps) {
  const renderProgressBar = () => {
    const strips = []
    for (let i = 0; i < props.numberSteps; i++){
      strips.push(<span key={`stripe_${i}`}></span>)
    }

    return strips
  }

  return (
    <Container step={props.step} inModal={props.inModal} numberSteps={props.numberSteps}>{renderProgressBar().map(item => item)}</Container>
  );
}

const Container = styled.div<{ step: number; bottom?: number; inModal?: boolean; numberSteps: number;}>`
  width: calc(100% - 32px);
  max-width: 550px;
  height: 2px;
  position: fixed;
  bottom: calc(${p => (p.bottom || 0) + 16 + 'px'} + ${p => !p.inModal ? FOOTER_HEIGHT : '0px'});

  display: flex;
  justify-content: space-between;
  align-items: end;

  span {
    display: block;
    width: calc(${p => 100 / p.numberSteps + '%'});
    height: 2px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 0 0.5rem;
  }

  span:nth-child(${p => p.step}) {
    background-color: rgba(0, 0, 0, 0.67);
  }
`
