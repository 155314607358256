import styled from "styled-components"
import { BellIcon, OkIcon } from "../../../assets/icons/trial/trial-icons"
import { Switcher } from "../../../libs"

interface PaymentReminderProps {
	className?: string
	notify: boolean
	setNotify(value: boolean): void
}

export const PaymentReminder = (props: PaymentReminderProps) => {

	return (
		<Container className={props.className}>
			<div className='left'>
				<BellIcon/>
	      <div>Напомнить об окончании <br/> пробного периода за два дня</div>
			</div>
      <StyledSwitcher
        backgroundColor={'var(--color-green) !important'}
				checked={props.notify}
        onChange={props.setNotify}
        icon={<OkIcon/>}/>
    </Container>
	)
}

const Container = styled.div`
  display: flex;
	justify-content: space-between;

	width: 100%;
  margin: 1rem 0;
  color: var(--text-secondary);


	.left {
		display: flex;
		align-items: center;
		text-align: left;

		svg {
			width: 3rem;
			height: 3rem;
		}
	}
`

const StyledSwitcher = styled(Switcher)`
  & input:checked + div > div {
    left: calc(100% - var(--padding));
    margin-left: calc(-1 * 1.5rem);
  }

  & > div {
    width: 2.75rem;
    height: 1.5rem;

    & > div {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`
