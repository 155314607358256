import React, { forwardRef, useImperativeHandle, Ref, useCallback } from "react";
import styled, { css } from "styled-components";
import { Warning } from "../../assets/icons/system/system-icons";

export interface IRefConfirmCodeInput {
  clear: () => void;
}

export default forwardRef(function ConfirmCodeInput({
  length = 4,
  onFilling,
  errorMessage = ''
}: {
  length?: number;
  onFilling: (code: string) => void;
  errorMessage?: string
},
  ref: Ref<IRefConfirmCodeInput>) {

  const cellRefs = React.useRef<HTMLInputElement[]>([]);

  React.useEffect(() => {
    if (cellRefs.current?.[0]) {
      (cellRefs.current?.[0] as HTMLInputElement)?.focus()
    }
  }, [cellRefs]);

  const onKeyDown = useCallback((evt: React.KeyboardEvent) => {
    evt.preventDefault();

    const cell = evt.target as HTMLInputElement;
    const cellIdx = Number(cell.dataset.idx);

    switch (evt.key) {
      case 'Backspace':
        if (cellIdx > 0 && cell.value === '') {
          (cellRefs.current?.[cellIdx - 1] as HTMLInputElement)?.focus();
        }
        cell.value = '';
        return;
      case 'ArrowRight':
        if (cellIdx < length - 1) {
          (cellRefs.current?.[cellIdx + 1] as HTMLInputElement)?.focus();
        }
        return;
      case 'ArrowLeft':
        if (cellIdx > 0) {
          (cellRefs.current?.[cellIdx - 1] as HTMLInputElement)?.focus();
        }
        return;
      default:
        if (!isFinite(+evt.key)) return;
        cell.value = evt.key;
        if (cellIdx < length - 1) {
          (cellRefs.current?.[cellIdx + 1] as HTMLInputElement)?.focus();
        }

        const codeSymbols = cellRefs.current?.reduce((acc: string[], curr: HTMLInputElement) => {
          if (curr?.value) {
            acc.push(curr.value)
          };
          return acc;
        }, []);
        if (codeSymbols.length === length) {
          const code = codeSymbols.join('');
          onFilling?.(code);
        }
    }
  }, [length, onFilling]);

  const Cells = React.useMemo(() => {
    cellRefs.current = [];
    const cells = [];

    for (let idx = 0; idx < length; idx++) {
      cells.push(
        <Cell
          type="text"
          minLength={1}
          maxLength={1}
          placeholder=" " // не убирать, нужен для работы псевдокласса :placeholder-shown
          key={`cell_${idx}`}
          data-idx={idx}
          ref={(ref) => cellRefs.current[idx] = (ref as HTMLInputElement)}
          onKeyDown={onKeyDown}
          error={Boolean(errorMessage)}
          inputMode="numeric"
        />
      );
    }
    return cells;
  }, [length, errorMessage, onKeyDown]);

  const clearCells = () => {
    cellRefs.current?.forEach((cell: HTMLInputElement) => {
      cell && (cell.value = '');
    });
  }

  useImperativeHandle(ref, () => ({ clear: clearCells }))

  return (
    <ConfirmCodeInputContainer>
      <CellsContainer>
        {Cells}
      </CellsContainer>
      {errorMessage && <ErrorMessage><Warning />{errorMessage}</ErrorMessage>}
    </ConfirmCodeInputContainer>
  )
});

const ConfirmCodeInputContainer = styled.div`
  position: relative;
  margin-top: 2.5rem;
`;

const CellsContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
`;

const Cell = styled.input<{ error: boolean }>`
  position: relative;
  appearance: none;
  outline: none;
  width: 4.344rem;
  height: 4.063rem;
  text-align: center;
  border-radius: 0.5rem;
  background-color: var(--input-background);

  font-size: 2rem !important;
  color: var(--color-black);
  caret-color: var(--input-border);
  border: 1px solid transparent;
  transition: border-color 0.2s ease-out;

  &:focus,
  &:not(:placeholder-shown) {
    border-color: var(--input-border);
    background-color: var(--input-background-focus);
  }


  ${p => p.error && css`
    background-color: #FEEEEE !important;
    border-color: transparent !important;
  `}
`
const ErrorMessage = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  line-height: 115%;
  color: var(--color-red);

  & svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
`;
