import { makeAutoObservable } from "mobx";

interface ShowHandler {
  servicesModal: boolean;
  scheduleModal: boolean;
  notificationsModal: boolean;
}

export class Modal {
  constructor() {
    makeAutoObservable(this)
  }

  showHandler: ShowHandler = {
    servicesModal: false,
    scheduleModal: false,
    notificationsModal: false,
  }
}
