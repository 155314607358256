import { makeAutoObservable } from "mobx";
import { isMobile } from "react-device-detect";

export enum Sections {
  MAIN = 'main',
  MY_EVENTS = 'my_events',
  MY_NOTES = 'my_notes',
  PRODUCTS = 'products',
  CONSULTATIONS = 'consultations',
  MONTH_REVIEW = 'month_review',
  PROFILE = 'profile',
}

export enum ShutterPositions {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom'
}

export const shutterBottom = 125

export class Shutter {
  constructor() {
    makeAutoObservable(this)
  }

  shutterRef: React.RefObject<HTMLDivElement> | undefined
  activeSection: Sections = Sections.MAIN
  position: ShutterPositions = ShutterPositions.BOTTOM

  setShutterRef(ref: React.RefObject<HTMLDivElement>) {
    this.shutterRef = ref
  }

  setActiveSection(section: Sections) {
    this.activeSection = section
  }

  hideShutter() {
    if (this.shutterRef?.current && isMobile) {
      this.shutterRef.current.style.display = 'none'
    }
  }

  showShutter() {
    if (this.shutterRef?.current) {
      this.shutterRef.current.style.display = 'block'
    }
  }

  setPosition(value: ShutterPositions) {
    this.position = value
  }
}
