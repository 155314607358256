import styled from "styled-components"
import { Price } from "./Price"
import { Button, Radius, TButtonColor } from './ui/Button/Button'

enum Background {
  PINK = 'pink',
}

interface OfferProps {
  className?: string
  title: string
  icon: any
  text?: string
  price?: string
  oldPrice?: string
  textPrice?: string
  buttonTitle?: string
  buttonColor?: TButtonColor
  background?: Background | string
  radius?: Radius
  onClick(): void
}

export const Offer = (props: OfferProps) => {
  const {
    className,
    title,
    icon,
    text,
    price,
    oldPrice,
    textPrice,
    buttonTitle,
    buttonColor,
    background = Background.PINK,
    radius,
    onClick,
  } = props

  const getBackground = () => {
    switch (background) {
      case Background.PINK:
        return 'radial-gradient(123.6% 75.07% at 48.6% 57.89%, rgba(122, 73, 203, 0.17) 4.73%, rgba(127, 74, 205, 0.00) 100%), #FFF'
      default:
        return background
    }
  }

  return (
    <Container className={className}
      background={getBackground()}>
      <Header className='title'>
        <p>{title}</p>{icon}
      </Header>
      <div className='text'>{text}</div>
      <Button
				onClick={onClick}
        color={buttonColor}
				className={'button'}
        radius={radius}>
        {
          buttonTitle
            ? <>{buttonTitle}</>
            : <Price oldPrice={oldPrice}
              price={price}
              text={textPrice} />
        }
      </Button>
    </Container>
  )
}

const Container = styled.div<{ background: string }>`
  padding: 1rem;
  border-radius: 24px;
  border: 0.5px solid var(--new-border, rgba(0, 0, 0, 0.03));
  background: ${p => p.background};
  box-shadow: 0px 0.75px 2px 0px rgba(137, 146, 172, 0.12), 0px 4px 14px 0px rgba(129, 136, 157, 0.08);

 .title {
  font-size: 1.25rem;
  font-weight: 500;
 }

 .text {
  color: var(--text-secondary);
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
 }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`
