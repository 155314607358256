import { ReactNode } from "react"
import { Cross } from "../../../assets/icons/system/system-icons"
import styled, { css } from "styled-components"
import { isMobile } from "react-device-detect"

interface MainPageInfoBannerProps {
  className?: string
  onClick(): void
  onClose?(): void
  icon?: ReactNode
  text: string
}

export const MainPageInfoBanner = (props: MainPageInfoBannerProps) => {
  const {
    className = '',
    onClick,
    onClose,
    icon,
    text,
  } = props

  const onCloseHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    onClose?.()
  }

  return (
    <Container
      className={className}
      onClick={onClick}
      isMobile={isMobile}
    >
      <Description>
        <div>{icon}</div>
        <div>{text}</div>
      </Description>
      <Close onClick={event => onCloseHandler(event)}>
        <Cross/>
      </Close>
    </Container>
  )
}

const Container = styled.div<{isMobile: boolean}>`
  display: flex;
  justify-content: space-between;

  color: var(--text-primary-white);
  background: black;
  border-radius: 1rem;
  margin: 1rem;
  padding: 0.75rem;

  ${p => p.isMobile && css`
    position: absolute;
  `};
`
const Description = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  svg {
    width: 2rem;
    height: 2rem;
  }
`
const Close = styled.div`

`
