import React from 'react';
import styled, { css } from 'styled-components';
import { FlexContainer } from '../../../components/layout/elements';
import { ChevronLeft } from '../../../assets/icons/system/system-icons';
import { windowsStore } from '../../../store/Windows';

export const WindowHeader = ({
  title = '',
  subtitle = '',
  onBack,
  className
}: {
  title: string | JSX.Element,
  subtitle?: string | JSX.Element
  onBack?: () => void,
  className?: string
}) => {

  const renderRightPart = () => {
    if (isRenderRightPart()) {
      return windowsStore.contextMenu
    }
    return <></>
  }

  const isRenderRightPart = () => {
    return !!windowsStore.contextMenu;
  }

  return <Container className={`no-print ${className}`} rightPart={isRenderRightPart()}>
    {
      onBack && (
        <RightSide onClick={onBack}>
          <ChevronLeftStyled  />
        </RightSide>
      )
    }

    <CenterSide>
      <div className='title'>{title}</div>
      {Boolean(subtitle) && <div className='subtitle'>{subtitle}</div>}
    </CenterSide>

    {renderRightPart()}
  </Container>
}

const Container = styled(FlexContainer)<{ rightPart: boolean }>`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  ${p => p.rightPart && css`
    justify-content: space-between;
  `}

  padding: 1.5rem 1rem 1rem 1rem;
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.9);

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`
const CenterSide = styled.div`
  /* display: flex; */
  position: relative;
  /* align-items: center; */
  font-weight: 500;

  & .title {
    font-size: 1.25rem;
  }
`;
const RightSide = styled.div`
  display: flex;
  align-items: center;

  padding: 1rem 0.5rem;
`

const ChevronLeftStyled = styled(ChevronLeft)`
  width: 1.25rem;
  height: 1.25rem;
	opacity: 0.4;
  margin-right: 0.25rem;
`
