import { makeAutoObservable } from "mobx";
import api from "../helpers/api";
import store from "./Store";


export enum SubscriptionStatus {
  ACTIVE = 'Активна',
  INACTIVE = 'Неактивна',
  TRIAL = 'Пробный период',
  NOT_PASSED = 'Оплата не прошла',
}

export class Subscription {
  constructor() {
    makeAutoObservable(this)
  }

  subscriptionStatus: SubscriptionStatus = SubscriptionStatus.INACTIVE

  async updateSubscribe(auto_payment: boolean, reason?: string) {
    try {
      await api.indiUnsubscribe({auto_payment, reason})
      await store.refresh()
    } catch(e) {
      console.error(`Error in updating subscription: ${e}`)
    }
  }

  setSubscriptionStatus(status: SubscriptionStatus) {
    this.subscriptionStatus = status
  }
}
