import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ArrowRight as ArrowIcon, ClockIcon2 as ClockIcon, EventNoteIcon, PinIcon } from '../../../assets/icons/system/system-icons';
import InfoBlock, { BlockStatus } from '../../../components/InfoBlock';
import { Label, Link, Markup, PinButton, Row } from '../../../components/legacy';
import { capitalize, copyToClipboard } from '../../../helpers/strings';
import store from '../../../store/Store';
import { windowsStore } from '../../../store/Windows';
import PrognosesMonth, { WINDOW_ID_PROGNOSES_MONTH } from '../prognoses-month';
import Actions from './Actions';

export default function Month(
  {
    extended = false,
    formattedMonth = dayjs().format('MM.YYYY'),
    isPinned = false,
    togglePin = () => {},
  }: {
    extended?: boolean;
    formattedMonth?: string;
    isPinned?: boolean;
    togglePin?: (v: any) => void;
  }
) {
  const targetMonth = dayjs(formattedMonth, 'MM.YYYY');
  const currentMonthFormatted = dayjs().format('MM.YYYY');
  const link = `/prognoses/month/${formattedMonth}`;

  const monthData = useMemo(() => {
    return store.prognoses.getMonth();
  }, [formattedMonth]);

  const handleLink = (e: any) => {
    e.preventDefault();
    //window.location.href = `${link}`;
    windowsStore.open(
      WINDOW_ID_PROGNOSES_MONTH,
      <PrognosesMonth formattedMonth={formattedMonth} />,
    )
    window.dataLayer.push({event: 'indi_general_month_prognosis_read_more', user_id: '123abc'})
  }

  if(!monthData) {
    return null;
  }

  const description = monthData?.description?.replaceAll('\n', '<br />') || ''

  return (
    <>
      { !extended &&
          <Row justify="space-between" align="center" mb="0">
            <Row mb="0" align="center" gap="0.75rem">
              <EventNoteIconStyled />
              {isPinned && <PinButton><PinIcon /></PinButton>}
              <SectionTitle>Прогноз на месяц</SectionTitle>
            </Row>
            <Actions
              actions={[
                {
                  label: 'Скопировать ссылку',
                  action: () => copyToClipboard(`${window.location.host}${link}`),
                },
                {
                  label: isPinned ? 'Открепить' : 'Закрепить',
                  action: togglePin,
                },
              ]}
            />
          </Row>
      }
      <Container>
        { !extended &&
            <Row gap="0.25rem">
              <Label>Текущий месяц</Label>
            </Row>
        }

        <Row mb={extended ? '1rem' : '0.5rem'}>
          <Title>{capitalize(targetMonth.format('MMMM'))}</Title>
        </Row>

        { formattedMonth !== currentMonthFormatted
            ? <Row>
                <InfoBlock title={`До начала ${dayjs().to(targetMonth, true)}`} status={BlockStatus.UPCOMING} icon={<ClockIcon />} />
              </Row>
            : null
        }

        <Row>
          <Markup short={!extended}>
            <p dangerouslySetInnerHTML={{__html: description}}></p>
            { extended && monthData.recommendations?.length
                ? monthData.recommendations.map((recommendation, i: number) => (
                    <React.Fragment key={`recommendation_${i}`}>
                      <h4>{recommendation.title}</h4>
                      <ul>
                        {recommendation.text.split('\n').map((item: string) => (
                          <li key={`recommendation_${i}_${item}`}>{item}</li>
                        ))}
                      </ul>
                    </React.Fragment>
                  ))
                : null
            }
          </Markup>
        </Row>

        {!extended && <Link onClick={handleLink}>Читать далее <ArrowIcon /></Link>}
      </Container>
    </>
  );
}

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #F3F3F3;
  box-shadow: 0px 3.2px 14px rgba(0, 0, 0, 0.06), 0px 0.6px 1.8px rgba(0, 0, 0, 0.04);
  padding: 1rem;
  border-radius: 1rem;
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.1666;
  color: var(--color-black);
  margin: 0;
`;

const SectionTitle = styled.h3`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.1666;
  color: var(--text-primary);
  margin: 0;
`;

const EventNoteIconStyled = styled(EventNoteIcon)`
  width: 1.125rem;
  height: 1.125rem;
`;
