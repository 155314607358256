import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { openEditWindow } from 'src/modules/edit/Edit';
import { FreePeriod } from 'src/modules/payment/helpers/tariffs';
import { isHasSubscriptionOrTrial } from 'src/modules/paywall/helpers/tariffs';
import { IAuthIndi } from 'src/store/Auth';
import { history } from '../MainRouter';
import { ROUTES } from '../helpers/routes';
import { getPathnames } from '../helpers/url';
import { openConsultations } from '../modules/consultations/Consultations';
import { openPaywallWindow } from '../modules/main-page/components/main-page-blocks-subscription-banner';
import { MyDay, WINDOW_ID_MYDAY, openMyDay } from '../modules/my-day/my-day';
import { openNotes } from '../modules/notes/Notes';
import { openPaymentWindow, openSmsRegistrationWindow } from '../modules/paywall/Paywall';
import { openProducts } from '../modules/products/Products';
import ProductConfirmation from '../modules/products/components/Confirmation';
import {
  EMOTIONS,
  SOLAR,
  SYNASTRY,
  WINDOW_ID_CONFIRMATION
} from '../modules/products/helpers/constants';
import { openProfile } from '../modules/profile/Profile';
import { openPrognoses } from '../modules/prognoses/prognoses';
import { popupsStore } from '../store/Popups';
import { Sections } from '../store/Shutter';
import store from '../store/Store';
import { windowsStore } from '../store/Windows';
import { getMainPageLS } from '../store/localStorageHelpers';


export const useRouter = () => {
  const profile = store.profile;
  const productsList = store.products.products;

  const sp = new URLSearchParams(window.location.search);
  const hasPathname = window.location.pathname.length > 1;
  const [, , token, shared] = getPathnames();

  useEffect(() => {
    if (profile.email && window.location.href.includes(ROUTES.PROFILE)) {
      openProfile();
      store.shutter.setActiveSection(Sections.PROFILE)
    }
  }, [profile]);

  useEffect(() => {
    if (window.location.href.includes('professional')) {
      openConsultations();
      store.shutter.setActiveSection(Sections.CONSULTATIONS)
    }

    if (
      window.location.pathname.includes('products') &&
      window.location.pathname.split('/').length === 2
    ) {
      openProducts();
      store.shutter.setActiveSection(Sections.PRODUCTS)
    }

    if (window.location.href.includes('prognoses')) {
      openPrognoses();
      store.shutter.setActiveSection(Sections.MONTH_REVIEW)
    }

    if (
      window.location.href.includes('trial') ||
      window.location.href.includes('paywall') ||
      window.location.href.includes('landing') ||
      window.location.href.includes('resubscription-e-mail')
    ) {
      openPaywallWindow();
      // openPayLandingWindow();
    }

    if (window.location.href.includes('services')) {
      openConsultations();
      store.shutter.setActiveSection(Sections.CONSULTATIONS)
    }

    if (window.location.href.includes('session')) {
      openConsultations();
      store.shutter.setActiveSection(Sections.CONSULTATIONS)
    }

    if (window.location.href.includes('my')) {
      openMyDay();
      store.shutter.setActiveSection(Sections.MY_EVENTS)
    }

    if (window.location.href.includes('notes')) {
      openNotes()
      store.shutter.setActiveSection(Sections.MY_NOTES)
    }

    if (window.location.href.includes('auth')) {
      const goToRef = () => {
        const ref = sp.get('ref')
        sp.delete('ref')
        ref && history.push({ pathname: `/${ref}`, search: sp.toString() })
      }

      openSmsRegistrationWindow(goToRef);
    }

    if (window.location.href.includes('data_filling')) {
      const onAuthSuccess = (channel: string, sessionData?: IAuthIndi) => {
        if (!sessionData) return

        const hasSubscriptionOrTrial = isHasSubscriptionOrTrial(sessionData)
        const tariff = store.events.tariffs.find(item => item.period === 1)
        const trialPeriod = 7

        if (!hasSubscriptionOrTrial) {
          openPaymentWindow(
            tariff!,
            {
              freePeriod: FreePeriod.TRIAL,
              trialPeriod,
              direct: true,
            },
        );
        }
      }

      openEditWindow({
        beforeAuth: true,
        onProfileUpdate: () => {
          openSmsRegistrationWindow(
            (channel: string, sessionData?: any) => {
              onAuthSuccess(channel, sessionData)
            },
            true,
          )
        },
        direct: true,
      })
    }
  }, []);

  useEffect(() => {
    if (productsList.length > 0 && !shared) {
      if (window.location.href.includes('products/synastry')) {
        store.products.selectProduct(productsList.find(item => item.code === SYNASTRY)!);
        windowsStore.open(WINDOW_ID_CONFIRMATION, <ProductConfirmation product={SYNASTRY} />)
      }

      if (window.location.href.includes('products/emotions')) {
        store.products.selectProduct(
          productsList.find((item) => item.code === EMOTIONS)!
        );
        windowsStore.open(
          WINDOW_ID_CONFIRMATION,
          <ProductConfirmation product={EMOTIONS} />
        );
      }

      if (window.location.href.includes('products/solyar')) {
        store.products.selectProduct(productsList.find(item => item.code === SOLAR)!);
        windowsStore.open(WINDOW_ID_CONFIRMATION, <ProductConfirmation product={SOLAR} />)
      }

      if (sp.has('token') && !hasPathname && !isMobile && getMainPageLS().onboarding?.showedOnboarding) {
        // если успели открыть уже какое нибудь окно
        if (windowsStore.getWindows().length || popupsStore.getPopups().length)
          return;

        popupsStore.open(
          WINDOW_ID_MYDAY,
          <MyDay />,
          '',
          'var(--color-white)',
          true
        );
      }
    }
  }, [productsList]);
};

export const useBackWindow = (
  pathname: string,
  id: string,
  options?: {
    exact: boolean
  }
) => {
  useEffect(() => {
    window.location.hash = `${id.replace(/WINDOW_ID_/, '')}`;

    const onBack = () => {
      let currentPath = window.location.pathname + window.location.search;

      if (options?.exact && currentPath !== '/') {
        return;
      }

      windowsStore.close(id, true);
      popupsStore.close(id, true);
      document.title = `Chronos Plus — Умный персональный прогноз`;
    };

    window.addEventListener('popstate', onBack);

    return () => {
      document.title = `Chronos Plus — Умный персональный прогноз`;
      window.removeEventListener('popstate', onBack);
    };
  }, [pathname, id, options]);
};

export const useBackSoftRestart = (path: string) => {
  useEffect(() => {
    history.push(path);
    const close = (e: Event) => {
      windowsStore.closeAll();
      popupsStore.closeAll();
      setTimeout(() => history.push('/'), 300);
    };
    window.addEventListener('popstate', close);
    return () => {
      window.removeEventListener('popstate', close);
    };
  }, [path]);
};
