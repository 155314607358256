import { IServiceDataEx, SessionFormat, IInfoEx, ISession } from '../../libs';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import styled, { css } from 'styled-components';
import { history } from '../../MainRouter';
import { ArrowRight as ArrowIcon } from '../../assets/icons/system/system-icons'; // TODO: check icon
import ButtonSwitcher, { IOption } from '../../components/ui/ButtonSwitcher';
import Loader from '../../components/ui/Loader';
import { SERVICE_ID } from '../../helpers/ls/consultations';
import { gtmSend, searchParams } from '../../helpers/url';
import useLS, { useLSState } from '../../hooks/useLS';
import store from '../../store/Store';
import { getConsultationsLS, setConsultationsLS } from '../../store/localStorageHelpers';
import { HeaderContext } from '../popups/Popups';
import BottomButton from './components/BottomButton';
import { ConsultationModal } from './components/ConsultationModal';
import DescriptionBlock from './components/DescriptionBlock';
import Help from './components/Help';
import ServiceGroups from './components/ServiceGroups';
import { getSpecialistText, groupingServices } from './helpers/services';
import SessionCard from '../../components/SessionCard';
import { CONSULTATION_MODAL } from './Consultations';


export interface IServiceGroup {
  type: IInfoEx;
  services: IServiceDataEx[];
}

export const sessionFormatOptions: IOption[] = [
  {
    value: SessionFormat.Online,
    label: 'Устные'
  },
  {
    value: SessionFormat.Offline,
    label: 'Письменные'
  }
];

interface IRouterProps {
  id: string;
}
interface IServicesProps extends RouteComponentProps<IRouterProps> {
}

export default observer(function Services(props: IServicesProps): JSX.Element {
  const [serviceGroups, setServiceGroups] = useState<IServiceGroup[] | null>(null);
  const [sessions, setSessions] = useState<ISession[] | null>(null);
  const [showModal, setShowModal] = useState(false)
  const [showInfo, setShowInfo] = useState(true)

  const [, , saveServiceId, removeServiceId] = useLS(SERVICE_ID);
  const [sessionFormat, setSessionFormat] = useLSState('session-format', SessionFormat.Online);
  const [, , saveIsPickService, removeIsPickService] = useLS('is-pick-service');
  const [, , saveSessionCreator, removeSessionCreator] = useLS('session-creator');

  const { setIsFirstScreen } = useContext(HeaderContext)
  const sp = searchParams()

	const comingSessions = sessions
			?.filter(item => dayjs().isBefore(dayjs(item.start).add(item.data.duration, 'minute')))
    		.sort((session1, session2) => dayjs(session1.start).isBefore(dayjs(session2.start)) ? -1 : 1)

	const userId = store.sessionData?.id;

  const onCardClick = (service: IServiceDataEx) => {
    handleService(service);
  }

  const handleService = async (service: IServiceDataEx) => {
    saveServiceId(service.id);
    saveIsPickService('1');
    setSessionFormat(service.format || sessionFormat)
    store.setCurrentService(service.id);
    await store.setProfessionals(service.id, service.format || sessionFormat);
    history.push({pathname: '/professionals', search: sp.toString()});
  };

	const checkModalShowing = () => {
		const consultationsLS = getConsultationsLS()
		const modal = sp.get('modal')

		if (modal === CONSULTATION_MODAL && !consultationsLS.onboarding?.showedModal) {
			setShowModal(true)
			setConsultationsLS({
				...consultationsLS,
        onboarding: {
          ...consultationsLS.onboarding,
          showedModal: true,
        }
			})
		}
	}

  const onCloseModal = () => {
    setShowModal(false)
  }

	const checkInfoShowing = () => {
		const consultationsLS = getConsultationsLS()

		if (!consultationsLS.onboarding?.showedInfo) {
			setShowInfo(false)
			setConsultationsLS({
				...consultationsLS,
        onboarding: {
          ...consultationsLS.onboarding,
          showedInfo: true,
        }
			})
		} else {
			setShowInfo(false)
		}
	}

	useEffect(() => {
		setIsFirstScreen(true)

		return () => {
			setIsFirstScreen(false)
		}
	}, [setIsFirstScreen])

  useEffect(() => {
    const { id } = props.match.params;

    if (store.services && store.serviceCategories) {
      const services = groupingServices(store.services, store.serviceCategories, sessionFormat);

      setServiceGroups(services);

      if (id) {
        const targetService = store.getService(+id);
        if(targetService) {
          handleService(targetService);
        }
      }
    }
  }, [store.services, store.serviceCategories, sessionFormat]);

  useEffect(() => {
    localStorage.removeItem('FirstLoginAttempt')

    store.setSessions();

    const { id } = props.match.params;
    if (id) {
      const block = searchParams().get('block');
      saveSessionCreator(block || 'unknown');
      //handleService({id: +id} as IServiceDataEx)
    } else {
      removeSessionCreator();
    }

		checkModalShowing()
		checkInfoShowing()
  }, []);

  useEffect(() => {
    setSessions(store.profileSessions);
  }, [store.profileSessions]);


  const profHandler = () => {
    ym('reachGoal','skip-products_click-button_main-page');

    removeServiceId();
    removeIsPickService();

    history.push({pathname: '/professionals', search: sp.toString()});
    document.title = `Консультации • Выбор астролога`;
  };

	const getDateTitle = (date: string, duration: number, isClosed?: boolean) => {
		const leftFiveMinutes = dayjs(date).diff(dayjs(), 'minute') <= 5
		const isGoingOn = dayjs().isAfter(dayjs(date)) && dayjs().isBefore(dayjs(date).add(duration, 'minute'))
		const isTomorrow = dayjs(date).isTomorrow()
		const isToday = dayjs(date).isToday()
		const prefix = isClosed ? 'Ближайшая сессия' : 'Следующая сессия'

		if (leftFiveMinutes) return 'Ссылка на консультацию доступна'
		if (isGoingOn) return 'Консультация началась'
		if (isToday) return `${prefix} сегодня`
		if (isTomorrow) return `${prefix} завтра`

		return `${prefix} ${dayjs(date).format('DD MMMM, dddd')}`
	}

  const goToSession = (id: number) => {
		history.push({pathname: `/session/${id}`, search: sp.toString()})
		gtmSend({'event': 'indi_consultation-page_click', 'user_id': userId ? userId : ''})
	}

  if (!serviceGroups) {
    return <Loader />;
  }

  if (showModal) return <ConsultationModal onClose={onCloseModal}/>

  return (
		<ServicesContainer>
      {
  			showInfo && <DescriptionBlock
  										text={'В разделе выбора услуг вы можете просмотреть запланированные консультации и записаться на новые'}
  										onClose={() => setShowInfo(false)}/>
  		}

			<ButtonSwitcher
				options={sessionFormatOptions}
				value={sessionFormat}
				onChange={setSessionFormat}
			/>

			{
				comingSessions &&
					<SessionsContainer>
						{
							comingSessions[0] &&
								<SessionContainer>
									<Title>{getDateTitle(comingSessions[0].start, comingSessions[0]?.data.duration, true)}</Title>
									<CustomSessionCard
										closest
					          {...comingSessions[0]}
					          onClick={() => goToSession(comingSessions[0].id)}
					        />
								</SessionContainer>
						}

						{
							comingSessions[1] &&
								<SessionContainer>
									<Title third>{getDateTitle(comingSessions[1].start, comingSessions[1]?.data.duration)}</Title>
									<CustomSessionCard
					          {...comingSessions[1]}
					          onClick={() => goToSession(comingSessions[1].id)}
					        />
								</SessionContainer>
						}
					</SessionsContainer>
			}

    	<Help />

	    <DescriptionBlock
	      style={{ marginTop: '1.5rem' }}
	      text={getSpecialistText(sessionFormat)}
	    />

	    <ServiceGroups
				groups={serviceGroups}
				sessionFormat={sessionFormat}
				onServiceClick={onCardClick} />

	    <BottomButton onClick={profHandler}>
	      <ButtonContent><span>Сначала выбрать специалиста</span><ArrowIcon/></ButtonContent>
	    </BottomButton>
  	</ServicesContainer>
	)
})

const SessionContainer = styled.div`
	margin-top: 1rem;
`;

const CustomSessionCard = styled(SessionCard)<{closest?: boolean}>`
  width: 100%;
	margin-top: 0.75rem;

	${p => p.closest && css`
		box-shadow: 0px 1.2000000476837158px 3.5999999046325684px 0px rgba(90, 91, 106, 0.11);
		box-shadow: 0px 6.400000095367432px 14.399999618530273px 0px rgba(58, 58, 68, 0.13);
	`};
`;

const ServicesContainer = styled.main`
  padding-bottom: 46px;
  display: flex;
  flex-direction: column;
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 2.4rem;
  font-size: 0.8rem;
  letter-spacing: 0.25px;

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.4rem;
  }

  & > span {
    margin-top: 0.1rem;
  }
`

const Title = styled.div<{third?: boolean}>`
	font-weight: 500;
	color: ${p => p.third ? 'var(--text-third)' : '--text-primary'};
`

const SessionsContainer = styled.div`
	margin-top: 0.5rem;
`
