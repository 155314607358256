import React from "react"
import styled from "styled-components"
import { BookmarkEmpty, BookmarkFull } from "../../../assets/icons/notes/icons"

interface BookmarkIconProps {
  className?: string
  saved?: boolean
  color: string
  background: string
  onClick?(): void
}

export const BookmarkIcon = ({
  className = '',
  saved,
  color,
  background,
  onClick,
}: BookmarkIconProps) => {

  const [localSaved, setLocalSaved] = React.useState<boolean>(Boolean(saved));

  React.useEffect(() => {
    setLocalSaved(Boolean(saved));
  }, [saved]);

  const Icon = localSaved ? <BookmarkFull/> : <BookmarkEmpty/>;

  const onLocalClick = () => {
    onClick?.();
    setLocalSaved(!localSaved);
  }


  return (
    <Container
      className={className}
      onClick={onLocalClick}
    >
      <IconContainer
        className="icon-container"
        background={background}
        color={color}
      >
        {Icon}
      </IconContainer>
    </Container>
  )
}

const Container = styled.div`
  padding: 0.6rem;
`

const IconContainer = styled.div<{background: string, color: string}>`
  background: ${p => p.background};
  color: ${p => p.color};
  padding: 0.35rem;
  border-radius: 0.5rem;

  svg {
    width: 0.85rem;
    height: 0.85rem;
  }
`
