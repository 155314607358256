import Paywall1 from '../../../assets/images/paywall/1.png';
import Paywall2 from '../../../assets/images/paywall/2.png';
import Paywall3 from '../../../assets/images/paywall/3.png';
import Paywall4 from '../../../assets/images/paywall/4.png';
import Paywall5 from '../../../assets/images/paywall/5.png';

export type TPaywallSlide = {
	id: number;
	text: string;
	image: string;
}

export const slides: TPaywallSlide[] = [
	{
		id: 1,
		text: 'Персональный прогноз ориентируется на время и место рождения',
		image: Paywall1
	},{
		id: 2,
		text: 'Будьте в курсе небесных событий, влияющих именно на вас',
		image: Paywall2
	},{
		id: 3,
		text: 'Читайте рекомендации по событиям и улучшайте качество жизни',
		image: Paywall3
	},{
		id: 4,
		text: 'Планируйте свои дела по всем сферам жизни',
		image: Paywall4
	},{
		id: 5,
		text: 'Более\u00A03000\u00A0пользователей оценили нас на 4.8 звезд',
		image: Paywall5
	},
]
