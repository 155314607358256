
import React from 'react';
import { createBrowserHistory } from 'history';
import { observer } from 'mobx-react-lite';
import { Route, Router, Switch } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';
import App from './App';
import { useRouter } from './hooks/router-hooks';
import { servicesRoutes } from './modules/consultations/Consultations';
import TinkoffPayed from './modules/consultations/TinkoffPayed';
import store from './store/Store';


export const history = createBrowserHistory();

export default observer(function MainRouter() {
  useRouter();

  return <>
    <YMInitializer
      accounts={[49958389]}
      options={{
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        ecommerce: "dataLayer",
        userParams: {
          UserID: store.profile.id,
          beta: false,
          partnerParams: store.partner.getPartnerParams()
        }
      }}
      version="2"
    />
    <Router history={history}>
      <Switch>
				<Route path={servicesRoutes.TinkoffPayed.path} exact component={TinkoffPayed}/>
				<Route path="*" exact component={App}/>
      </Switch>
    </Router>
  </>
})
