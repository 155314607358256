import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Indi } from 'src/libs';
import store from 'src/store/Store';
import styled, { css } from 'styled-components';
import { ChevronDownIcon, Harmony, Tension } from '../../../../assets/icons/system/system-icons';
import { Colors, Positions, Tip } from '../../../../components/Tip';
import { Toaster } from '../../../../components/Toaster';
import { FlexContainer } from '../../../../components/layout/elements';
import { getFormattedInterval } from '../../../../helpers/dates';
import { gtmSend } from '../../../../helpers/url';
import { windowsStore } from '../../../../store/Windows';
import { getMyDayLS, MyDayTips, setMyDayLS } from '../../../../store/localStorageHelpers';
import { openNotes, WINDOW_ID_NOTES } from '../../../notes/Notes';
import { BookmarkIcon } from '../../../notes/components/BookmarkIcon';
import { WindowHeader } from '../../../windows/components/WindowHeader';
import { eventsOptions, getDaysAheadString } from '../../helpers/events';
import { consultation, diagnostic, EventBanner } from './EventBanner/EventBanner';
import MyDayEventImportant from './my-day-event-important';
import { MyDayEventRecommendations } from './my-day-event-recommendations';
import { MyDayEventRisks } from './my-day-event-risks';


const tipContent = {
  text : [
    'Обращайте внимание на напряженность и риски. Следуйте рекомендациям, чтобы минимизировать их влияние',
    'Период действия события указывает время, в течение которого событие активно и влияет на вас'
  ],
  buttonTitle : [
    'Далее',
    'Завершить'
  ]
}

export const MyDayEvent = observer(({
  event,
  onClick,
  onClose,
  onNext,
  onPrev,
  opened = false,
  current,
  backButton = false,
  className,

}: {
  event: Indi.Events.Event,
  onClick?: (event: Indi.Events.Event) => void,
  onClose?: () => void,
  onPrev?: () => void,
  onNext?: () => void,
  opened?: boolean,
  current: dayjs.Dayjs,
  backButton?: boolean,
  className?: string;

}) => {
  const [toasterTitle, setToasterTitle] = useState<string | null>(null)
  const [showTip, setShowTip] = useState<number | null>(null)
  const [showEventBanner, setShowEventBanner] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)
  const eventHeaderRef = useRef<HTMLDivElement>(null)
  const datesRef = useRef<HTMLDivElement>(null)
  const timerId = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    const hideToaster = () => {
      setToasterTitle(null)
    }

    containerRef.current?.addEventListener('scroll', hideToaster)

    return () => {
      containerRef.current?.removeEventListener('scroll', hideToaster)
    }
  }, [])

  const profile = store.profile;
  const userId = store.sessionData?.id
  const windows = windowsStore.getWindows()

  const eventOptions = eventsOptions[event.sphere];
  const SmallIcon = eventOptions.iconSmall;
  const importantBg = eventOptions.bg;
  const TypeIcon = event.type === 'harmonic' ? Harmony : Tension;

  const important = false;//event.isImportant && !opened;

  const checkBanners = useCallback(async () => {
    if (event.type !== 'tense') return

    if (userId) {
      const banners = await store.banners.getBanners(userId) ?? []

      for (const banner of banners) {
        const bannerSphere = banner.bannerId.split('_')[2] as Indi.Events.Spheres

        if (
          consultation.includes(bannerSphere) ||
          (diagnostic.includes(bannerSphere) && diagnostic.includes(event.sphere))
        ) {
          const overOneMonth = dayjs().diff(dayjs(banner.timestamp), 'month') > 0

          if (overOneMonth) {
            setShowEventBanner(true)
          }

          return
        }
      }

      setShowEventBanner(true)
    }
  }, [userId, event.sphere, event.type])

  useEffect(() => {
    checkBanners()
  }, [checkBanners])

  const dates = getFormattedInterval(
    dayjs(event.startDate).format('DD.MM.YYYY HH:mm:ss'),
    dayjs(event.endDate).format('DD.MM.YYYY HH:mm:ss'),
  )

  const daysAhead = getDaysAheadString(event.startDate, event.endDate, current, profile.email);
  const datesStyles = {color: (daysAhead.new ? eventOptions.textColor : undefined)};

  const savedRecommendations = React.useMemo(() => {
    return store.notes
    .findNote(event.sphere, event.title, event.startDate, event.endDate)?.recommendations
    .map(item => item.text ?? '') ?? []
  }, [store.notes.notesList])


  const toggleRecommendation = (text: string, remove?: boolean) => {

    if (remove) {
      const note = store.notes.findNote(event.sphere, event.title, event.startDate, event.endDate)
      const recommendationId = note?.recommendations.find(item => item.text === text)?.id

      if (!recommendationId) return

      store.notes.removeFromNote(note.id, recommendationId)

      setToasterTitle('Удалено из Моих заметок')

    } else {

      userId && store.notes.addToNote(userId, event.sphere, event.title, event.startDate, event.endDate, text)
      setToasterTitle('Добавлено в Мои заметки')

      gtmSend({'event': 'ind_prognosis_note_adding', 'user_id': userId ? userId : ''})
    }

    timerId.current && clearTimeout(timerId.current)
    timerId.current = setTimeout(() => setToasterTitle(null), 7000);

  }

  const openNotesHandler = () => {
    const fromNotes = windows.find(window => window.id === WINDOW_ID_NOTES)

    if (fromNotes) {
      windowsStore.close(WINDOW_ID_NOTES)
    }

    openNotes(true, true)
  }

  const onCloseTip = useCallback(() => {
    setShowTip(null)

    const myDayDataLS = getMyDayLS()

    const newData = {
      ...myDayDataLS,
      onboarding: {
        ...myDayDataLS.onboarding,
        showedTips: [
          ...myDayDataLS.onboarding?.showedTips ?? [],
          MyDayTips.EVENTS
        ],
      }
    }
    setMyDayLS(newData)
  }, [])

  const checkTip = useCallback(() => {
    const myDayDataLS = getMyDayLS()

    if (myDayDataLS?.onboarding?.showedTips?.includes(MyDayTips.EVENTS)) return

    if (store.onboarding.detectOldUser()) {
      onCloseTip()
      return
    }

    setTimeout(() => setShowTip(1), 2000)
  }, [onCloseTip])

  const skipTip = useCallback(() => {
    onCloseTip()
    gtmSend({'event': `indi_onboarding_events_description1_skip`, 'user_id': userId ? userId : ''})
  }, [onCloseTip, userId])

  useEffect(() => {
    checkTip()
  }, [checkTip])

  useEffect(() => {
    return () => {
      const myDayDataLS = getMyDayLS()

      if (!myDayDataLS?.onboarding?.showedTips?.includes(MyDayTips.EVENTS)) {
        onCloseTip()
      }
    }
  }, [])

  const tipButton = useMemo(() => {
    const onClickHandler = () => {
      if (!showTip) return

       if (showTip === 1) {
        setShowTip(2)
        gtmSend({'event': `indi_onboarding_events_description1`, 'user_id': userId ? userId : ''})
       } else {
         onCloseTip()
         gtmSend({'event': `indi_onboarding_events_description2`, 'user_id': userId ? userId : ''})
       }
    }

    const index = (showTip ?? 1) - 1

    return {
      title: tipContent.buttonTitle[index],
      onClick: onClickHandler,
      position: Positions.RIGHT
    }
  }, [showTip, onCloseTip, userId])

  const indicatorButton = useMemo(() => {
    return {
      length: 2,
      current: (showTip ?? 1),
    }
  }, [showTip])

  const getTipTop = () => {
    if (showTip === 1) {
      return eventHeaderRef.current?.getBoundingClientRect().bottom ?? 0
    } else {
      return datesRef.current?.getBoundingClientRect().bottom ?? 0
    }
  }

  return (
  <>
    <Container
      ref={containerRef}
      importantBg={important ? importantBg : undefined}
      onClick={() => onClick?.(event)}
      opened={opened}
      className={`ob-z-index ${className ? ` ${className}` : ''}`}
    >
      {backButton &&
        <StyledWindowHeader
          onBack={onClose}
          title={(
            onPrev
            ? <ToPrevButton onClick={onPrev}>
                <span>К предыдущему</span> <ChevronDownIcon />
              </ToPrevButton>
            : ''
            )}
        />
      }

      {event.isImportant && <MyDayEventImportant event={event} />}

      <EventHeader color={eventOptions.textColor} ref={eventHeaderRef}>
        <SmallIcon />
        <span>{eventOptions.title}</span>
        <EventRightBar>
          {
            event.isImportant
              ? <span>Важно</span>
              : <span style={{ color: `var(--color-${event.type === 'harmonic' ? 'green' : 'orange'})` }}>{event.type === 'harmonic' ? 'Гармонично' : 'Напряжённо'}</span>
          }
          <TypeIcon />
        </EventRightBar>

      </EventHeader>

      <Title>{event.title}</Title>

      <Dates ref={datesRef}>
        <span>{dates}</span>
        {!important && <span style={datesStyles}>{daysAhead.text}</span>}
      </Dates>

      {(opened || event.isImportant) &&
        <Description>
          {Array.isArray(event.description) && event.description.map((item: string, idx: number) => <p key={`desc_item_${idx}`}>
            {item}
          </p>)}
          {typeof event.description === 'string' && event.description}
        </Description>
      }

      {!important && event.risks.length > 0 && <MyDayEventRisks risks={event.risks} />}

      {/* <Banner
        title='Узнайте точное время рождения'
        text='Свяжемся с вами, чтобы рассказать об услуге ректификации (уточнение времени рождения)'
        buttonContent={<ButtonContent title='Узнать' />}
        onClick={()=> {}}
      /> */}


      {
        !important && event.recommendations.length > 0 && (
          <MyDayEventRecommendations
            layout='vertical'
            items={event.recommendations}
            sphere={event.sphere}
            savedRecommendations={savedRecommendations}
            toggleRecommendation={(text, remove) => toggleRecommendation(text, remove)}
          />
        )
      }

      {showEventBanner && <EventBanner sphere={event.sphere}/>}

      {/* {important && <ReadMore>
        <ReadMoreIcon><ArrowRight /></ReadMoreIcon> Посмотреть событие
      </ReadMore>} */}

      {
        onNext &&
        <ScrollControll className="scroll-view-controll no-print" onClick={() => onNext()}>
          <div className="control-title">Пролистайте к следующему</div>
          <div className="control-button"><ChevronDownIcon /></div>
        </ScrollControll>
      }

      {
        showTip && (
          <TipWrapper showTip={showTip} height={getTipTop()}>
            <StyledTip
              color={Colors.DARK}
              text={tipContent.text[showTip - 1]}
              onClose={skipTip}
              trianglePosition={showTip === 1 ? Positions.TOP_RIGHT : Positions.TOP_LEFT}
              button={tipButton}
              indicator={indicatorButton}
              showTip={showTip}
              important={event.isImportant}
            />
          </TipWrapper>
        )
      }

    </Container>

    {
      toasterTitle && (
        <ToasterWrapper>
          <StyledToaster
            icon={<StyledBookmarkIcon color='#F49550' background='rgba(244, 149, 80, 0.1)'/>}
            title={toasterTitle}
            btnTitle='Перейти'
            onClick={openNotesHandler}
            color={'#F49550'}
            />
        </ToasterWrapper>
      )
     }
  </>
  )
})

const Container = styled(FlexContainer) <{ importantBg?: string, opened: boolean }>`
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  gap: 0.875rem;
  padding: 0 1rem 0.75rem;
  margin: 0.75rem 0.5rem;
  height: calc(100%); //100% - (0.75rem + (0.75rem * 2))
  overflow-y: auto;
  /* overscroll-behavior: contain; */

  border-radius: 2rem;
  box-shadow: 0px 4px 14px rgba(129, 136, 157, 0.08), 0px 0.75px 2px rgba(137, 146, 172, 0.12);

  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }


  ${p => !p.opened && css`
    padding-top: 0.75rem;
    background-color: var(--color-white);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 24px;
  `}

  ${p => p.importantBg && css`
    background: url(${p.importantBg});
    background-size: cover;
    background-repeat: no-repeat;
    height: 25rem;
  `}
`

export const EventHeader = styled(FlexContainer) <{ color: string }>`
  align-items: center;
  color: ${p => p.color};

  > svg {
    &:first-child {
      width: 1.125rem;
      height: 1.125rem;
      margin-right: 0.375rem;
    }
  }
`

export const EventRightBar = styled(FlexContainer)`
  gap: 0.5rem;
  align-items: center;
  margin-left: auto;
  font-size: 1rem;
  line-height: 100%;

  > svg{
      width: 1.375rem;
      height: 1.375rem;
  }
  > span {
    border-radius: 900px;
    background: var(--color-white);
    padding: 0.25rem 0.5rem;
  }
`

const Title = styled.div`
  width: 90%;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 110%;
`

const Dates = styled(FlexContainer)`
  justify-content: space-between;
  font-size: 1rem;
  color: var(--text-secondary);
`

export const ReadMore = styled(FlexContainer)`
  align-items: center;
  gap: 0.75rem;
  font-size: 0.875rem;
  color: var(--text-primary);
  margin-top: auto;
`

export const ReadMoreIcon = styled(FlexContainer)`
  align-items: center;
  justify-content: center;
  width: 2.6875rem;
  height: 1.75rem;
  border-radius: 44px;
  background: var(--text-primary);
  color: var(--color-white);
`

const Description = styled.div`
  width: 95%;
  font-size: 1rem;
  line-height: 135%;

  p {
    margin-bottom: 1rem;

    :last-child {
      margin-bottom: 0.5rem;
    }
  }
`

const StyledWindowHeader = styled(WindowHeader)`
  position: sticky;
  top: -2px;
  background-color: var(--color-white);
  z-index: var(--z-index-header);
`;

const ScrollControll = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: auto;
  padding: 1rem 0.5rem;
  width: 95%;
  z-index: 9;
  background-color: #fff;
  cursor: pointer;

  color: var(--text-third);

  & .control-title {
    position: relative;
    margin-top: 0.5rem;
    font-size: 0.875rem;
    letter-spacing: -0.025em;
  }

  & .control-button {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

const ToPrevButton = styled.button`
  display: flex;
  position: relative;
  appearance: none;
  border: none;
  background-color: transparent;
  cursor: pointer;

  font-size: 0.875rem;
  color: var(--text-third);

  & svg {
    color: inherit;
    margin-left: 0.25rem;
    transform: rotate(-180deg);
  }
`;

const StyledBookmarkIcon = styled(BookmarkIcon)`
  padding: 0;
`

const ToasterWrapper = styled.div`
  padding: 0 1rem;
  width: 90%;
`

const StyledToaster = styled(Toaster)`
  width: 90%;
`

const TipWrapper = styled.div<{showTip: number, height: number}>`
  position: absolute;
  top: ${p => `${p.height}px`};

  ${p => p.showTip === 1 && css`
    right: 10px;
  `}
`

const StyledTip = styled(Tip)<{showTip: number, important: boolean}>`
  max-width: 320px;

  ${p => p.showTip === 1 && css`
    .triangle {
      right: ${`${p.important ? 4 : 5}rem`};
    }
  `}

  ${p => p.showTip === 2 && css`
    .triangle {
      left: 3rem;
    }
  `}
`
