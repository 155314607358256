import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import styled from 'styled-components';
//import { history } from '../../../routing';
import store from '../../store/Store';
import { MainPadding, WindowContainer } from '../../components/layout/elements';
import { ROUTES } from '../../helpers/routes';
import { useBackWindow } from '../../hooks/router-hooks';
import { windowsStore } from '../../store/Windows';
import { WindowHeader } from '../windows/components/WindowHeader';
import Day from './components/Day';
import { gmtLocal } from './prognoses-daily';


export const WINDOW_ID_PROGNOSES_DAY: string = 'WINDOW_ID_PROGNOSES_DAY';

export default observer(function PrognosesDay({
  dayId
}: {
  dayId: string
}) {
  useBackWindow(ROUTES.PROGNOSES_DAY, WINDOW_ID_PROGNOSES_DAY);

  useEffect(() => {
    store.prognoses.getPrognosesFeed(dayjs().toISOString(), gmtLocal);
  }, []);

  const onClose = () => {
    windowsStore.close(WINDOW_ID_PROGNOSES_DAY);
  }

  if(!store.prognoses.prognoses) {
    return null;
  }

  return (
    <WindowContainer>
      <WindowHeader title='Общий прогноз' onBack={onClose}/>
      <Container>
        <Day extended={true} id={dayId} />
      </Container>
    </WindowContainer>
  )
});

const Container = styled(MainPadding)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
`;
