import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { Ok } from '../../assets/icons/system/system-icons';
import { useClickOutside } from '../../hooks/dom-hooks';
import { FlexContainer } from '../layout/elements';
import { ISelectOptions } from './Select';

export const SelectDropdown = <T extends ISelectOptions>({
  onCancel,
  value,
  options,
  plain = false,
  onSelect
}: {
  onCancel?: () => void,
  options: T[],
  value: T,
  plain?: boolean;
  onSelect: (value: T) => void;
}) => {
  const onClick = (option: T) => {
    onSelect(option);
    onCancel?.();
  }

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => onCancel?.());

  return <Container ref={ref}>
    <Group>
      {options.map(option => 
        <Option key={option.id} plain={plain} active={option.id === value.id} onClick={() => onClick(option)}>{option.title}<Ok /></Option>
      )}
    </Group>
    {/* {onCancel && <Group>
      <Option plain color={'red'} onClick={onCancel}><Ok />Отменить выбор</Option>
    </Group>} */}
  </Container>
}

const Container = styled.div`
  position: relative;

  padding: 0.25rem 1rem;

  border-radius: 1rem;
  background: var(--color-white);

  box-shadow: 0px 0.6px 1.8px 0px #0000000A;
  box-shadow: 0px 3.2px 14px 0px #0000000F;

`

const Option = styled(FlexContainer)<{plain?: boolean, color?: string, active?: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  
  line-height: 1.375rem;
  font-size: 1.1rem;
  text-wrap: nowrap;

  ${p => p.plain && css`
    font-size: 1rem;
    line-height: 2.5rem;
  `}

  color: var(--text-primary);

  ${p => p.color === 'red' && css`
    color: var(--color-red);
  `}

  > svg {
    width: 1rem;
    height: 1rem;

    ${p => !p.active && css`
      visibility: hidden;
    `}
  }

  /* &:not(:last-child) {
    border-bottom: 1px solid rgba(60, 60, 67, 0.36);
  } */
`

const Group = styled.div`
  &:not(:last-child) {
    border-bottom: 8px solid rgba(60, 60, 67, 0.1);
  }
`