import { makeAutoObservable } from "mobx";

export interface Description {
  title: string
  text: string
  subtitle?: string
}

export interface Amounts {
  oldPrice: number
  finalPrice?: number
  discount?: number
}

export interface DataUpdateTariffModal {
  description: Description
  amounts: Amounts
}

export default class Tariffs {
  constructor() {
    makeAutoObservable(this);
  }

  dataUpdateToSixMonthTariff: DataUpdateTariffModal = {
    description: {
      title: 'Получите месяц прогнозов в подарок',
      text: 'Оформите подписку на 6 месяцев — так дешевле.',
      subtitle: 'Списание раз в 6 месяцев',
    },
    amounts: {
      oldPrice: 2994,
      finalPrice: 2499,
      discount: 17,
    }
  }
}
