import styled, { CSSProperties } from 'styled-components';
import { Cross, InfoIcon } from '../../../assets/icons/system/system-icons';


interface DescriptionBlockProps {
	style?: CSSProperties
  text: string
	onClose?(): void
}

export default function DescriptionBlock(props: DescriptionBlockProps): JSX.Element {
	const {
		style,
		text,
		onClose,
	} = props

  return <Container style={style}>
    <div className='info'><InfoIcon/></div>
    <Text>
      {text}
    </Text>
		{onClose && <div className='close'><Cross onClick={onClose}/></div>}
  </Container>;
}

const Container = styled.div`
  display: flex;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 14px;
  box-sizing: border-box;
	font-weight: 500;

	.info {
		svg {
	    margin-right: 10px;
	    width: 20px;
	    height: 20px;

	    path {
	      fill: #000000;
	    }
	  }
	}

	.close {
		svg {
			color: var(--text-secondary);
		}
	}
`;

const Text = styled.p`
  flex: 1;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.87);
`;
