import { Switcher } from "@chronos/ui"
import styled from "styled-components"
import { OkIcon } from "../../assets/icons/trial/trial-icons"

interface SystemSwitcherProps {
  className?: string
  value: boolean
  onChange(value: boolean): void
}

export const SystemSwitcher = (props: SystemSwitcherProps) => {
  const {
    className,
    value,
    onChange,
  } = props

  return (
    <StyledSwitcher
      className={className ?? ''}
      backgroundColor={'var(--color-green) !important'}
			checked={value}
      onChange={onChange}
      icon={<OkIcon/>}
  />
  )
}

const StyledSwitcher = styled(Switcher)`
  & input:checked + div > div {
    left: calc(100% - var(--padding));
    margin-left: calc(-1 * 1.5rem);
  }

  & > div {
    width: 2.75rem;
    height: 1.5rem;

    & > div {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`
