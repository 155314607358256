import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ArrowSmall as ArrowIcon } from '../../../assets/icons/system/system-icons';
import store from '../../../store/Store';
import Title from '../components/Title';
import useLS from '../../../hooks/useLS';
import Loader from '../../../components/ui/Loader';
import { history } from '../../../MainRouter';
import Policy from '../../../components/Policy';
import { searchParams } from '../../../helpers/url';
import { CustomButton } from '../components/BottomButton';

import { ISession, SessionFormat } from '../../../libs';
//import ym from 'react-yandex-metrika'

let payButtonIsClicked = false;

export default observer(function Payment(): JSX.Element {
  const params = useParams<{ id: string }>();
  const sp = searchParams()
  const [session, setSession] = useState<ISession | null>(null);
  const [activeTime] = useLS('active-time');
  const [sessionId] = useLS('session-id');
  const [sessionFormat] = useLS('session-format');

  if (sessionId === null) {
    store.clearStorage();
    history.push({pathname: `/services`, search: sp.toString()});
  }

  const getSession = async () => {
    if (sessionId) {
      const session = await store.getSession(+sessionId!);
      setSession(session);
    }
  };

  useEffect(() => {
    getSession();
  }, [params.id, store.sessionData]);

  const confirmationHandler = () => {
    if (session && !payButtonIsClicked) {
      store.payments.paymentTinkoff(session.id)
        .then((result: any) => {
          payButtonIsClicked = true;
          if (result?.url) window.location.href = result.url;
        })
        .catch((err) => {
          console.log('err from payment payment -', err)
        })
    }
  };

  if (!session || !store.currentService || !store.professional) return <Loader />;

  const getPrice = () => {
    const professionalService = store.professional?.services
      .find(service => service.id === store.currentService?.id)

    return professionalService?.price.value ?? store.currentService!.price.value
  }

  const price_ = Math.round(getPrice() * (1 - (store.partner.partnerDiscount)));

  return <PaymentContainer>
    <Title
      service={store.currentService}
      sessionFormat={+sessionFormat! as SessionFormat}
      text={`${store.professional?.profile.firstName || ''} ${store.professional?.profile.lastName || ''}`}
      dateTime={activeTime!}
    />
    <Text>
      Проверьте данные перед <br/>
      оплатой
    </Text>
    <Column>
      <Label>Имя</Label>
      <Value>{session?.fullName || session?.data.fullName}</Value>
    </Column>
    <Column>
      <Label>Дата и время рождения </Label>
      <Value>{session?.data.birth.dateTime ? dayjs.utc(session?.data.birth.dateTime).format('DD MMM YYYY, HH:mm') : ''}</Value>
    </Column>
    <Column>
      <Label>Место рождения</Label>
      <Value>{session?.data.birth.place?.name}</Value>
    </Column>
    <Column>
      <Label>К оплате</Label>
      <Value style={{color: 'var(--color-green)'}}>{price_}₽</Value>
    </Column>
    <CustomButton
      fixedPosition={false}
      color={'green'}
      onClick={confirmationHandler}
    >
      <ButtonContent>Оплатить<ArrowIcon/></ButtonContent>
    </CustomButton>

    <Policy fixedPosition={false} />
  </PaymentContainer>
});

const Value = styled.p`
  font-size: 24px;
  color: #000000;
`;

const Label = styled.p`
  color: var(--text-third);
  margin-bottom: 4px;
  font-size: 14px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const PaymentContainer = styled.main`
  display: flex;
  flex-direction: column;

  padding-bottom: 6rem;
`;

const Text = styled.p`
  color: var(--text-secondary);
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 165%;
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  letter-spacing: 0.2px;
  margin-top: 0.1rem;

  & svg {
    margin-left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
`
