import React from 'react';
import styled from 'styled-components';
import { Article } from '../helpers/blog';
import { windowsStore } from 'src/store/Windows';
import { FlexContainer, WindowContainer } from 'src/components/layout/elements';
import { WindowHeader } from 'src/modules/windows/components/WindowHeader';
import { AudioArticlePlayer } from 'src/components/AudioPlayer';


export const WINDOW_ID_ARTICLE_AUDIO: string = 'WINDOW_ID_ARTICLE_AUDIO';

interface BlogArticleAudioProps {
  article: Article;
  title: string;
}

export const BlogArticleAudio: React.FC<
  BlogArticleAudioProps
> = ({ article, title }) => {

  const descriptionNotNull = article.description || '';

  const onClose = () => windowsStore.close(WINDOW_ID_ARTICLE_AUDIO)

  return (
    <WindowContainer>
      <WindowHeader title={title} onBack={onClose}/>
      <Container>
        <ContentContainer>
          <Image src={article.background} alt="" />
          <Title>{article.title}</Title>
          <AuthorName>{article.author}</AuthorName>
          <AudioPlayerContainer>
            <AudioArticlePlayer audio={article.audio || ''} />
          </AudioPlayerContainer>
          <Description dangerouslySetInnerHTML={{ __html: descriptionNotNull }} />
          {/* <BlogArticlesFeedBack date={''} /> */}
        </ContentContainer>
      </Container>
    </WindowContainer>
  );
};

const Container = styled.div`
  margin: 0;
  padding: 0;
`;
const ContentContainer = styled(FlexContainer)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  text-align: center;
`;
const Title = styled.div`
  color: #000000b2;
  font-size: 24px;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 12px;
`;

const AuthorName = styled.div`
  color: #00000066;
  margin-bottom: 1rem;
  font-size: 16px;
`;
const Description = styled(FlexContainer)`
  font-size: 18px;
  font-weight: 400;
  color: #000000e5;
  margin-bottom: 22px;
  text-align: left;
  line-height: 1.4;
`;
const Image = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 12px;
  object-fit: cover;
  object-position: center 20%;
`;
const AudioPlayerContainer = styled.div`
  width: 100%;
`;
