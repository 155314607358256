import React from 'react';
import styled, { css } from "styled-components";
import Chart, { IChartData } from '../../../../components/ui/Chart';
import { getSignColor, TSignColor } from '../../../../helpers/colors';
import { ChapterDataType, SynastryCalcDataType, SynastryDataBlockType } from "./types";
import { capitalize } from '../../../../helpers/strings';

type CategoryPropsType = {
  data: SynastryDataBlockType
  chartData: IChartData[]
  influenceData: ChapterDataType
  partners: SynastryCalcDataType
  signs: any
  categoryKey: string
}

export default React.memo(function Category(props: CategoryPropsType) {
  const getPartnerContent = (partnerIndex: 1 | 2) => {
    const sign = props.signs?.[partnerIndex - 1];
    const SignIcon = sign?.icon;
    const signColor = getSignColor(sign?.key);

    const name = props.partners[`name${partnerIndex}`];

    const positiveSection = props.data.positive;
    const negativeSection = props.data.negative;

    const positive = positiveSection?.[`partner${partnerIndex}`];
    const negative = negativeSection?.[`partner${partnerIndex}`];

    const isEmptyGeneral = !positiveSection?.general?.length && !negativeSection?.general?.length;

    return <>
      {((positive.length + negative.length > 0) || isEmptyGeneral) &&
        <PartnerHeader>
          <SignContainer
            key={`sign_${partnerIndex}`}
            className={partnerIndex === 1 ? 'first' : 'second'}
            color={signColor}
          >
            {SignIcon && <SignIcon/>}
          </SignContainer>
          <div>{name}</div>
        </PartnerHeader>
      }

      {positive.length + negative.length > 0
        ? <>
          {positive.length > 0 && <PositiveContent>
            {positive.map((item, index) => {
              return <div key={`positive_${partnerIndex}_${index}`}><span>{item}</span></div>
            })}
          </PositiveContent>}

            {negative.map((item, index) =>
              <React.Fragment key={`negative_${partnerIndex}_${index}`}>
                {(index > 0 || positive.length > 0) && <Delimiter/>}
                <NegativeContent>
                  <h2 className={'stress_title'}>Напряжение</h2>
                  <div>{item.text}</div>

                  <h2 className={'compensation_title'}>Компенсаторика</h2>
                  <ul>{item.compensation.map((item, index) => {
                    return <li key={`compensation_${index}`}><span>{capitalize(item)}</span></li>
                  })}</ul>
                </NegativeContent>
              </React.Fragment>
            )}
        </>
        : isEmptyGeneral
          ? <EmptyMsg>{props.data.partnerEmptyMsg.split('{{partner}}').join(name) ?? props.data.emptyMsg}</EmptyMsg>
          : <></>
      }
    </>;
  };

  const getGeneralContent = () => {
    const generalPositive = props.data.positive?.general;
    const generalNegative = props.data.negative?.general;

    // уходим, если нет general в positive
    if (!generalPositive?.length && !generalNegative?.length) return <></>

    const sign1 = props.signs?.[0];
    const SignIcon1 = sign1?.icon;
    const signColor1 = getSignColor(sign1?.key);
    const sign2 = props.signs?.[1];
    const SignIcon2 = sign2?.icon;
    const signColor2 = getSignColor(sign2?.key);

    return <>
      <PartnerHeader>
        <SignContainer
          key={`sign_${0}`}
          className={'first'}
          color={signColor1}
          style={{marginRight: '0'}}
        >
          {SignIcon1 && <SignIcon1 />}
        </SignContainer>
        <span className='plus-sign'>+</span>
        <SignContainer
          key={`sign_${1}`}
          className={'second'}
          color={signColor2}
        >
          {SignIcon2 && <SignIcon2 />}
        </SignContainer>
        <h3 className='general-title'>Для обоих партнеров</h3>
      </PartnerHeader>

      <Container>
        <Content>
          {
            Boolean(generalPositive?.length) &&
            <div style={{ marginBottom: '2rem' }} className='general-positive'>
              {generalPositive.map((item: any, idx: number) => <div style={{ marginBottom: '1rem' }} key={`positive_item_${idx}`}>{item}</div>)}
            </div>
          }

          {
            Boolean(generalNegative?.length) &&
            <NegativeContent>
              <h2 className='stress_title'>Напряжение</h2>
              {generalNegative.map((item: any, idx: number) => <div style={{ marginBottom: '1rem' }} key={`negative_item_${idx}`}>{item.text}</div>)}

              {
                Boolean(generalNegative.some((item: any) => item.compensation)) &&
                <div style={{ marginTop: '2rem' }} className='general-compensations'>
                  <h2 className='compensation_title'>Компенсаторика</h2>
                  <ul>
                    {generalNegative.map((item: any, idx: number) => item.compensation.map((text: string, index: number) => <li key={`generalNegative_${index}`}><span>{capitalize(text)}</span></li>))}
                  </ul>
                </div>
              }
            </NegativeContent>
          }

        </Content>
      </Container>
    </>
  }

  const getContent = () => {
    if (props.data?.aspects?.length > 0) {
      return <Content>
        <Intro>{props.data.intro}</Intro>
        {getPartnerContent(1)}
        {getPartnerContent(2)}
        {getGeneralContent()}
        <Delimiter/>
      </Content>
    } else {
      return <Content>
        <Intro>{props.data.intro}</Intro>
        {props.data.emptyMsg}
      </Content>
    }
  }



  return (
    <Container id={props.categoryKey}>
      <Header>
        <ChartContainer className="no-print"><Chart data={props.chartData} showTitle={false} currentCategory={props.categoryKey}/></ChartContainer>
        <Title>{props.data.title}</Title>
      </Header>

      <InfluenceTablet degreeOfInfluence={props.influenceData.degreeOfInfluence}>
        <div className={'title'}>{props.influenceData.title}</div>
        <div>{props.influenceData.text}</div>
      </InfluenceTablet>

      {getContent()}
    </Container>
  );
})

export const Container = styled.div`
  padding-top: 2rem;
  page-break-inside: auto;
`

export const Title = styled.div`
  font-size: 1.25rem;
`

const PartnerHeader = styled.div`
  display: flex;
  align-items: center;

  color: var(--text-primary);
  font-weight: 500;

  & .plus-sign {
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--text-third);
  }
`

export const Content = styled.div`
  color: var(--text-primary);
  padding-top: 0.5rem;
  font-weight: 400;
  line-height: 1.375rem !important;

  ul {
    padding-left: 1.5rem;
    color: var(--text-third);
  }

  li {
    margin-bottom: 0.5rem;
  }

  span {
    color: var(--text-primary);
  }
`

const Intro = styled.div`
  margin-bottom: 1rem;
`

const PositiveContent = styled.div`
  margin-bottom: 1.5rem;

  div {
    margin-bottom: 0.8rem;
  }
`

export const SignContainer = styled.div<{color: TSignColor}>`
  width: 2rem;
  height: 2rem;
  color: ${p => `var(--circle-zodiacs-elements-${p.color})`};
  margin-right: 0.5rem;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const NegativeContent = styled.div`
  margin-bottom: 1.5rem;

  h2 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.3rem;
  }

  .stress_title {
    color: var(--color-red) !important;
  }

  .compensation_title {
    color: var(--color-green);
  }

  div {
    margin-bottom: 1rem;
  }

  li {
    list-style-type: disc;
    color: var(--color-green);

    span {
      color: var(--text-primary);
    }
  }
`

const Delimiter = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  max-height: 1px;
  margin: 0.375rem 0;
  padding: 0;
  background: var(--blackout-background);
`

export const InfluenceTablet = styled.div<{degreeOfInfluence: string}>`
  color: var(--text-secondary);
  background: var(--color-rusty-bg);
  border-radius: 8px;
  line-height: 1.125rem;
  padding: 1rem;
  margin: 1rem 0;

  .title {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  ${props => props.degreeOfInfluence === 'strong' && css`
    background: var(--color-rusty-bg);

    .title {
      color: var(--color-rusty);
    }
  `}

  ${props => props.degreeOfInfluence === 'average' && css`
    background: var(--color-orange-bg);

    .title {
      color: var(--color-orange);
    }
  `}

  ${props => props.degreeOfInfluence === 'weak' && css`
    background: var(--color-purple-bg);

    .title {
      color: var(--color-purple);
    }
  `}
`

const EmptyMsg = styled.div`
    margin-bottom: 1rem;
`

const ChartContainer = styled.div`
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
`
