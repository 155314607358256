import * as eventIcons from '../../../assets/icons/events/events-icons';
import dayjs from 'dayjs';
import { getDaysWord } from '../../../helpers/words';
import { isTester } from '../../../helpers/autotests';
import { Indi } from '../../../libs';

export type TEventOptions = {
  title: string,
  shortTitle: string,
  color: string,
  bgColor: string
  textColor: string,
  icon: React.FC,
  iconSmall: React.FC,
  bg: string
}

export const eventsOptions: {
  [key in Indi.Events.Spheres]: TEventOptions
} = {
  emotions: {
    title: 'Эмоции',
    shortTitle: 'Эмоции',
    // color: '#E3F0FF',
    color: 'rgba(108, 145, 241, 0.1)',
    bgColor: 'rgba(108, 145, 241, 0.25)',
    textColor: 'rgba(108, 145, 241, 1)',
    iconSmall: eventIcons.EmotionsEventSmallIcon,
    icon: eventIcons.EmotionsEventIcon,
    bg: eventIcons.EmotionsEventBgPath
  },
  love: {
    title: 'Любовь',
    shortTitle: 'Любовь',
    // color: '#FFF1F1',
    color: 'rgba(231, 122, 122, 0.1)',
    bgColor: 'rgba(231, 122, 122, 0.25)',
    textColor: 'rgba(231, 122, 122, 1)',
    iconSmall: eventIcons.LoveEventSmallIcon,
    icon: eventIcons.LoveEventIcon,
    bg: eventIcons.LoveEventBgPath
  },
  communication: {
    title: 'Общение',
    shortTitle: 'Общение',
    // color: '#DFF3FF',
    color: 'rgba(92, 179, 231, 0.1)',
    bgColor: 'rgba(92, 179, 231, 0.25)',
    textColor: 'rgba(92, 179, 231, 1)',
    iconSmall: eventIcons.CommunicationEventSmallIcon,
    icon: eventIcons.CommunicationEventIcon,
    bg: eventIcons.CommunicationEventBgPath
  },
  health: {
    title: 'Здоровье',
    shortTitle: 'Здоровье',
    // color: '#EFF9E5',
    color: 'rgba(130, 167, 93, 0.1)',
    bgColor: 'rgba(130, 167, 93, 0.25)',
    textColor: 'rgba(130, 167, 93, 1)',
    iconSmall: eventIcons.HealthEventSmallIcon,
    icon: eventIcons.HealthEventIcon,
    bg: eventIcons.HealthEventBgPath
  },
  career: {
    title: 'Карьера',
    shortTitle: 'Карьера',
    // color: '#FBF5E3',
    color: 'rgba(240, 160, 101, 0.1)',
    bgColor: 'rgba(240, 160, 101, 0.25)',
    textColor: 'rgba(240, 160, 101, 1)',
    iconSmall: eventIcons.CareerEventSmallIcon,
    icon: eventIcons.CareerEventIcon,
    bg: eventIcons.CareerEventBgPath
  },
  finance: {
    title: 'Финансы',
    shortTitle: 'Финансы',
    // color: '#FAEEFF',
    color: 'rgba(205, 160, 225, 0.1)',
    bgColor: 'rgba(205, 160, 225, 0.25)',
    textColor: 'rgba(205, 160, 225, 1)',
    iconSmall: eventIcons.FinanceEventSmallIcon,
    icon: eventIcons.FinanceEventIcon,
    bg: eventIcons.FinanceEventBgPath
  },
  travel: {
    title: 'Поездки',
    shortTitle: 'Поездки',
    // color: '#E4F4EF',
    color: 'rgba(112, 204, 176, 0.1)',
    bgColor: 'rgba(112, 204, 176, 0.25)',
    textColor: 'rgba(112, 204, 176, 1)',
    iconSmall: eventIcons.TravelEventSmallIcon,
    icon: eventIcons.TravelEventIcon,
    bg: eventIcons.TravelEventBgPath
  },
  important: {
    title: 'Важное событие',
    shortTitle: 'Важное событие',
    // color: '#FCF7EF',
    color: 'rgba(112, 204, 176, 0.1)',
    bgColor: 'rgba(112, 204, 176, 0.25)',
    textColor: 'rgba(112, 204, 176, 1)',
    iconSmall: eventIcons.ImportantEventIcon,
    icon: eventIcons.ImportantEventIcon,
    bg: eventIcons.TravelEventBgPath
  },
}

export const groupBySphere = (events: Indi.Events.Event[]) => {
  return events.reduce((acc, next) => {
    const items = {...acc};
    if(!items[next.sphere]) items[next.sphere] = [];
    items[next.sphere].push(next);
    return items;
  }, {} as Record<Indi.Events.Spheres, Indi.Events.Event[]>)
}

export const getDaysAheadString = (from: string, to: string, current: dayjs.Dayjs, email: string | null) => {
  const first = dayjs(from);
  const last = dayjs(to);
  if(first.startOf('day').isSame(current.startOf('day'))) {
    return {
      new: true,
      text: 'Новое'
    };
  }

  const today = (email && isTester(email)) ? current : dayjs();

  const daysAhead = dayjs(last).startOf('day').diff(today.startOf('day'), 'day') + 1;
  return {
    new: false,
    text: `${getDaysWord(daysAhead)}`
  };
}

export const orderSpheres: Indi.Events.Spheres[] = [
  Indi.Events.Spheres.LOVE,
  Indi.Events.Spheres.CAREER,
  Indi.Events.Spheres.FINANCE,
  Indi.Events.Spheres.COMMUNICATION,
  Indi.Events.Spheres.COMMUNICATION,
  Indi.Events.Spheres.EMOTIONS,
  Indi.Events.Spheres.HEALTH,
  Indi.Events.Spheres.TRAVEL,
]

export const sortGroups = (groups: Indi.Events.Spheres[]) => {
  return groups.sort((g1, g2) => orderSpheres.indexOf(g1) - orderSpheres.indexOf(g2))
}
