import styled, { css } from 'styled-components'

export const MainPadding = styled.div`
  padding: 1rem;
`

export const FlexContainer = styled.div`
    display: flex;
`;

export const GridContainer = styled.div`
    display: grid;
`;

export const WindowContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: var(--color-white);
  overflow-y: auto;
`

export const WindowFormContainer = styled.form`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: var(--color-white);
  overflow-y: auto;
`

export const Row = styled.div<{
  mt?: string; 
  mr?: string; 
  mb?: string; 
  ml?: string; 
  justify?: string; 
  align?: string; 
  gap?: string;
  fd?: string;
}>`
  display: flex;
  ${p => css`margin: ${p.mt || '0'} ${p.mr || '0'} ${p.mb || '1rem'} ${p.ml || '0'};`}
  ${p => css`justify-content: ${p.justify || 'initial'};`}
  ${p => css`align-items: ${p.align || 'initial'};`}
  ${p => css`gap: ${p.gap || '0'};`}
  ${p => css`flex-direction: ${p.fd || 'row'};`}
`;