import styled, { css } from "styled-components"
import { ChevronRight } from "../modules/payment/components/payment-common"

export interface Item {
  title: string
  subtitle?: string
  icon?: any
  text?: string
  colorTitle?: string
  colorText?: string
  onClick?(): void
  goTo?(): void
}

interface ContentsListProps {
  className?: string
  title?: string
  items: Item[]
}

export const ContentsList = (props: ContentsListProps) => {
  const {
    className = '',
    title,
    items,
  } = props

  return (
    <Container className={className}>
      {title && <Title>{title}</Title>}

      <List gap={!!title}>
        {
          items.map((item, index) => {
            const onClickHandler = () => {
              item.onClick?.()
              item.goTo?.()
            }

            return (
              <ListItem
                key={`profile-section-item-${index}`}
                onClick={onClickHandler}
              >
                <Left colorTitle={item.colorTitle}>
                  {item.icon && <div>{item.icon}</div>}

                  <div className={'item--title'}>
                    <p>{item.title}</p>
                    {item.subtitle && <p className={'subtitle'}>{item.subtitle}</p>}
                  </div>
                </Left>

                <Right colorText={item.colorText}>
                  <p>{item.text ?? ''}</p>
                  {item.goTo && <ChevronRight/>}
                </Right>
              </ListItem>
            )
          })
        }
      </List>
    </Container>
  )
}

const Container = styled.div`
`

const Title = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-secondary);
`

const List = styled.ul<{gap?: boolean}>`
  ${p => p.gap && css`
    margin-top: 1rem;
  `}
`

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #0000000A;
  padding: 1rem;
  cursor: pointer;
  border-bottom: 1px solid var(--border);

  &:first-child {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  &:last-child {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-bottom: none;
  }
`

const Left = styled.div<{colorTitle?: string}>`
  display: flex;
  gap: 1rem;

  .item--title {
    display: flex;
    flex-direction: column;
    color: ${p => p.colorTitle ?? ''};

    .subtitle {
      font-size: 0.875rem;
      color: var(--text-third);
    }
  }
`

const Right = styled.div<{colorText?: string}>`
  display: flex;
  gap: 0.25rem;

  font-weight: 400;
  color: ${p => p.colorText ?? 'var(--text-third)'};
`
