import React from 'react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FlexContainer } from '../../../components/layout/elements';
import { Button } from '../../../components/ui/Button/Button';
import api from '../../../helpers/api';
import store from '../../../store/Store';
import { cards } from '../helpers/tariffs';
import { defaultPlace } from '../../registration/Registration';
import { WindowHeader } from '../../windows/components/WindowHeader';
import { PaymentContent, PaymentFooter } from '../Payment';
import { ChevronLeft } from '../../../assets/icons/system/system-icons';
import { PersonInfo } from '../../../components/PersonInfo';
import Policy, { PolicyTarget } from '../../../components/Policy';
import { Select } from '../../../components/ui/Select';
import { popupsStore } from '../../../store/Popups';
import { windowsStore } from '../../../store/Windows';
import { TariffSwitcher } from '../../paywall/components/TariffSwitcher';
import { history } from '../../../MainRouter';
import { Indi, PartnerLinkTargets, getUTMCommentForCP } from '../../../libs';
import { PromocodeWindow, WINDOW_ID_PROMOCODE_WINDOW } from '../../promocode/PromocodeWindow';

import ReRecaptcha, { TReReCaptchaApi } from "../../../components/ReRecaptcha";
import { show } from '@chronos/ui';

export const openPromocodeWindow = () => {
  windowsStore.open(
    WINDOW_ID_PROMOCODE_WINDOW,
    <PromocodeWindow subscription/>
  )
}

export const PaymentCommon = observer(({
  onClose,
  tariff
}: {
  onClose: () => void,
  tariff: Indi.indiGetTariffs.Tariff
}) => {
  const { firstName, birth: { dateTime, place = defaultPlace }} = store.profile;
  const email = store.profile.email || '';
  const userStatus = store.auth.userStatus;
  const tariffs = store.events.tariffs.filter(tariff => tariff.id !== 3);
	const partner = store.partner.partner;
	const discount = store.partner.partnerDiscount;
  const isAuth = store.isAuth;

  const [ error, setError ] = useState('');
  const [ card, setCard ] = useState(cards[0]);
  const [ currentTariff, setCurrentTariff ] = useState(tariff);

  const captchaRef = React.useRef<TReReCaptchaApi | null>(null);

	useEffect(() => {
		setPartnerDiscount()
	}, [partner])

	useEffect(() => {
		store.events.loadTariffs(card.country)
	}, [card])

  const onPayment = async () => {
    if(!firstName || !dateTime || !place) {
      setError('Необходимо заполнить данные в форме выше');
      setTimeout(() => setError(''), 2000);
      return;
    }

    try {
      const captchaToken = await captchaRef.current?.executeAsync();

      const result = await api.payment({
        captchaToken,
        type: 'indiSubscribe',
        subscription: currentTariff.id,
        period: currentTariff.period,
        country: card.id,
        language: 'ru',
        userStatus,
        forUserEmail: email,
        comment: getUTMCommentForCP(),
      }, 'charge');

    if (result.failed) {
      throw Error(`common payment fial: ${JSON.stringify(result)}`)
    } else {
			await store.refresh();
			history.push('/')
			windowsStore.closeAll()
			popupsStore.closeAll()
		}

  } catch (err: any) {
    show({
      type: 'error',
      text: `Ошибка при выполнении платежа - ${JSON.stringify(err)}`,
      timeout: 5000
    })
    throw Error(err)
  }


  }

  const tariffInfo = tariffs.find(t => t.id === currentTariff.id)!;
  const periodString = tariffInfo.period === 6 ? '/ в 6 мес' : '';
	const finalPrice = Math.round(tariffInfo.price * (1 - discount))

	const setPartnerDiscount = async () => {
		await store.partner.setPartnerDiscount(PartnerLinkTargets.ChronosPlus, partner)
  }

	const getFinalDiscount = () => {
		let finalDiscount = 0

		if (tariffInfo.oldPrice) {
			finalDiscount = tariffInfo.oldPrice - tariffInfo.price
		}

		if (discount > 0) {
			finalDiscount = Math.round(finalDiscount + tariffInfo.price * discount)
		}

		return finalDiscount
	}

  return <>
    <WindowHeader title='Оплата' onBack={onClose} />
    <PaymentContent>

      {isAuth && <>
        <MiniTitle>Данные рождения</MiniTitle>
        <PersonInfo />
      </>}

      <MiniTitle>Тариф</MiniTitle>
      <Fields>
        <TariffSwitcher
          tariffs={tariffs}
          current={currentTariff}
          setTariff={setCurrentTariff}
					discount={discount}
					symbol={card.symbol}
        />
        {error && <ErrorBlock>{error}</ErrorBlock>}

        <Select title="Карта для оплаты" options={cards} value={card} onSelect={setCard} />

      </Fields>
    </PaymentContent>
    <PaymentFooter>
      <PromocodeRow onClick={openPromocodeWindow}>
        <span>Применить промокод</span>
        <ChevronRight />
      </PromocodeRow>
      <Rows>
        {(tariffInfo.oldPrice || discount > 0) && <Row>
          <span>Скидка</span>
          <PriceInfo color="green">-{getFinalDiscount()} {card.symbol}</PriceInfo>
        </Row>}
        <Row>
          <span>К оплате</span>
          <PriceInfo>{finalPrice} {card.symbol} {periodString}</PriceInfo>
        </Row>
      </Rows>
      <Button onClick={onPayment} color="green">Оплатить</Button>
      <Policy country={card.id} fixedPosition={false}/>

      <ReRecaptcha size="invisible" badge="bottomleft" ref={captchaRef}/>

    </PaymentFooter>
  </>
})


const MiniTitle = styled.div`
  font-size: 0.875rem;
  margin-bottom: 1rem;
  color: var(--text-secondary);
`

const Fields = styled(FlexContainer)`
  padding: 0 0 1rem;
  flex-direction: column;
  gap: 1rem;
`

const ErrorBlock = styled.div`
  color: var(--color-red);
  font-size:  0.875rem;
`

export const PromocodeRow = styled(FlexContainer)`
  align-items: center;
  justify-content: space-between;
  color: var(--text-primary);
  padding: 0.875rem 0;
  border-bottom: 1px solid var(--element-border-10);
  margin-bottom: 0.875rem;
`

const Rows = styled.div`
  margin-bottom: 1rem;
  color: var(--text-secondary);
`

const Row = styled(FlexContainer)`
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
`

const PriceInfo = styled.div<{color?: 'green'}>`
  color: var(--text-primary);
  font-size: 1rem;

  ${p => p.color === 'green' && css`
    color: var(--color-green);
  `}
`
export const ChevronRight = styled(ChevronLeft)`
  width: 1.25rem;
  height: 1.25rem;
  transform: scaleX(-1);
	opacity: 0.4;
`
