import React from 'react';
import styled from 'styled-components';
import { FlexContainer, MainPadding } from '../../../../components/layout/elements';
import { eventsOptions } from '../../helpers/events';
import { Indi } from '../../../../libs';
import { BookmarkIcon } from '../../../notes/components/BookmarkIcon';

export type TRecommendationsItem = 'cell' | 'row';

export const MyDayEventRecommendationsItem = ({
  item,
  sphere,
  index,
  layout = 'cell',
  saved,
  toggleRecommendation,
}: {
  item: string,
  sphere: Indi.Events.Spheres,
  index: number,
  saved: boolean,
  layout?: TRecommendationsItem,
  toggleRecommendation(): void
}) => {

  const eventOptions = eventsOptions[sphere];
  const Icon = eventOptions.icon;

  if (layout === 'row') {
    return <RowContainer background={eventOptions.color}>
      <Text>{item}</Text>
      <BookmarkIcon
        saved={saved}
        background={eventOptions.color}
        color={eventOptions.textColor}
        onClick={toggleRecommendation}
      />
    </RowContainer>
  }

  return <CellContainer background={eventOptions.color}>

    <Title>
      <Circle>{index + 1}</Circle> Рекомендации
    </Title>
    {index === 0 && <BigIcon><Icon /></BigIcon>}
    <Text>{item}</Text>
  </CellContainer>
}


const RowContainer = styled.div<{ background: string }>`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  position: relative;
  padding: 0.75rem 0.5rem 0.75rem 1rem;
  border-radius: 1.25rem;
  border: 1px solid var(--color-gray-bg-transparent);
  background: ${p => p.background};
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  line-height: 135%;
`;

const CellContainer = styled(MainPadding) <{ background: string }>`
  min-width: calc(100% - 2rem - 0.5rem - 2rem);
  min-height: 17.5rem;
  border-radius: 1.25rem;
  background: ${p => p.background};

  display: flex;
  flex-direction: column;
  position: relative;
`

const Title = styled(FlexContainer)`
  align-items: center;
  gap: 0.675rem;
  font-size: 0.75rem;
  color: var(--text-secondary);
`

const Circle = styled(FlexContainer)`
  width: 1.875rem;
  height: 1.875rem;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  color: var(--text-primary);
  font-size: 1rem;
  font-weight: 500;
  border-radius: 50%;
`

const Text = styled.div`
  width: 95%;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--text-primary);
  padding: 0.125rem;
  z-index: 2;
`

const BigIcon = styled.div`
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  width: 5rem;
  height: 5rem;

  > svg {
    width: 100%;
    height: auto;
  }
`
