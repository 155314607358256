import { useState } from 'react';
import styled from 'styled-components';
import { ProgressBar } from "../../../../components/ProgressBar";
import Form from "./Form";
import Payment from "./Payment";
import { MainPadding, WindowContainer } from '../../../../components/layout/elements';
import { IProductCode } from "../../../../store/Payments";
import store from '../../../../store/Store';
import { windowsStore } from '../../../../store/Windows';
import { WindowHeader } from '../../../windows/components/WindowHeader';
import { EMOTIONS, SOLAR, SYNASTRY, WINDOW_ID_CONFIRMATION } from '../../helpers/constants';
import { getConfirmationStepLS, setConfirmationStepLS } from '../../../../store/localStorageHelpers';
import { observer } from 'mobx-react-lite';
import { searchParams } from '../../../../helpers/url';
import { history } from '../../../../MainRouter';
import { useBackWindow } from '../../../../hooks/router-hooks';
import { ROUTES } from '../../../../helpers/routes';


interface IProductConfirmation {
  product: IProductCode
}

export default observer(function ProductConfirmation(props: IProductConfirmation) {
  useBackWindow(ROUTES.PRODUCTS_CONFIRMATION, WINDOW_ID_CONFIRMATION);
  const productTitle = props.product === SYNASTRY 
    ? 'Совместимость в любви' 
      : props.product === EMOTIONS
        ? 'Эмоции' : 'Солярный день рождения'
  document.title = `${productTitle} • Ввод данных`;

  const [step, setStep] = useState(+getConfirmationStepLS() ? +getConfirmationStepLS() : 1)
  const [edit, setEdit] = useState(false)

  const isAuth = store.isAuth
  const sp = searchParams()

  const getLastStep = (product: string) => {
    switch (product) {
      case SYNASTRY:
        return isAuth ? 3 : 4
      case EMOTIONS:
        return isAuth ? 2 : 3
      case SOLAR:
        return isAuth ? 2 : 3
      default:
        return 0
    }
  }

  const getTitle = (product: string) => {
    switch (product) {
      case SYNASTRY:
        return 'Синастрия'
      case EMOTIONS:
        return 'Эмоции'
      case SOLAR:
        return 'Солярный день'
      default: return ''
    }
  }

  const onClose = () => {
    if(edit) {
      setEdit(false);
      setStep(getLastStep(props.product));
    } else {
      if(step === 1) {
        history.push({pathname: '', search: sp.toString()})
        windowsStore.close(WINDOW_ID_CONFIRMATION);
      } else {
        setStep(step => step - 1);
      }
    }
  }

  const onSetStepHandler = (value: number) => {
    setStep(value)
    setConfirmationStepLS(value.toString())
  }

  return (
    <WindowContainer>
      <WindowHeader title={getTitle(props.product)} onBack={onClose} />
      <Content>
        {
          (step < getLastStep(props.product) || edit) &&
          <Form product={props.product}
                edit={edit} 
                lastStep={getLastStep(props.product)}
                setEdit={(value: boolean) => setEdit(value)}
                step={step}
                setStep={onSetStepHandler}/>
        }

        {
          step === getLastStep(props.product) && !edit &&
          <Payment product={props.product}
                  lastStep={getLastStep(props.product)}
                  setEdit={(value: boolean) => setEdit(value)}
                  setStep={onSetStepHandler}/>
        }

        {
          !edit &&
          <ProgressBar step={step}
                      numberSteps={getLastStep(props.product)}
                      inModal={true}/>
        }
      </Content>
    </WindowContainer>
  );
})

const Content = styled(MainPadding)`
  position: relative;
  height: 100%;
`
