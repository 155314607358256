import { getGMTLocal } from './../helpers/dates';
import { makeAutoObservable } from "mobx";
import api from "../helpers/api";
import { Indi } from "src/libs";
import dayjs from 'dayjs';


export type AstroEventsType = 'newMoon' | 'fullMoon' | 'growingMoon' | 'waningMoon' | 'eclipse' | 'eclipseCorridor' | 'retroMercury' | 'retroVenus' | 'retroMars' | 'staticBefore' | 'staticAfter'

export interface Period {
  startDate: string
  endDate: string
}

export interface AstroEvent {
  title: string
  description: string
  retro?: Period
  static?: Period
}

type AstroEventType = Record<string, Indi.GetAstroEvents.AstroEvents>

export class AstroEvents {
  constructor() {
		makeAutoObservable(this)
	}

  astroEvents: AstroEventType = {}

  async getAstroEvents(date: string) {
    if (!this.astroEvents[date]) {
      const utcDate = dayjs(date).subtract(getGMTLocal(), 'hour').toISOString()
      const result = await api.indiGetAstroEvents({ date: utcDate })
      this.astroEvents[date] = result
    }
  }
}
