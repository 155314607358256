import React, { useEffect, useState} from 'react'
import styled from 'styled-components';
import Title from './components/Title';
import ProfessionalCard from './components/ProfessionalCard';
import store from '../../store/Store'
import {observer} from 'mobx-react-lite'
import ym from 'react-yandex-metrika'
import Loader from '../../components/ui/Loader'
import useLS from '../../hooks/useLS';
import { declOfNum } from './helpers/services';
import { SERVICE_ID } from '../../helpers/ls/consultations';
import { searchParams } from '../../helpers/url';
import { history } from '../../MainRouter';
import { IServiceDataEx, IWorkProfilesItem, SessionFormat } from '../../libs';


export default observer(function Professionals(): JSX.Element {
  
  document.title = `Консультации • Выбор астролога`;
  
  const [professionals, setProfessionals] = useState<IWorkProfilesItem[] | null>(null);
  const [service, setService] = useState<IServiceDataEx | null>(null);
  const [sessionCreator, , , removeSessionCreator] = useLS('session-creator');
  const [, , saveProfessionalId, removeProfessionalId] = useLS('professional-id');
  const [serviceId] = useLS(SERVICE_ID, null);
  const [sessionFormat] = useLS('session-format');

  const sp = searchParams()

  const email = store.profile.email || '';
  const isAutoTest = email.includes('autotest');
  const isAutoTestParam = sp.has('_19_')

  const profSpecializations = store.getAllSpecializations();

  useEffect(() => {
    removeProfessionalId();
    store.setProfessional(null);
    if(sessionCreator === 'direct') {
      removeSessionCreator();
    }
  }, [])

  useEffect(() => {
    if (serviceId) {
      setProfessionals(store.professionals);
    } else {
      setProfessionals(store.professionalList);
    }
  }, [store.professionals, store.professionalList]);

  useEffect(() => {
    store.setCurrentService(serviceId);
  }, [serviceId]);

  useEffect(() => {
    setService(store.currentService);
  }, [store.currentService]);

  const handleProf = (professional: IWorkProfilesItem) => {
    ym('reachGoal', 'Astro-list_card-click_astro-profile');
    saveProfessionalId(professional.userId);
    history.push({pathname: `/professional/${String(professional.userId)}`, search: sp.toString()});
  };

  const profTitle = React.useMemo(() => {
    if (!professionals) return '';
    return `подходит ${professionals?.length} ` + (
      professionals?.length === 1
        ? profSpecializations[professionals[0].specialization]?.name
        : declOfNum(professionals!.length, ['консультант', 'консультанта', 'консультантов'])
    );
  }, [professionals]);

  if (!professionals) return <Loader />;

  return <ProfessionalsContainer>
    {service && sessionFormat && <Title
      sessionFormat={sessionFormat! as SessionFormat}
      service={service}
      text={profTitle}
    />}

    { professionals!
        .map(prof => {
          return <ProfessionalCard
                  bestProfessionalId={null}
                  key={`service_${prof.userId}`}
                  { ...prof }
                  onClick={() => handleProf(prof)}
                />;
        })
    }

  </ProfessionalsContainer>;
});

const ProfessionalsContainer = styled.main`
  display: flex;
  flex-direction: column;
`;

