import React, { useEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {observer} from 'mobx-react-lite';
import store from '../../store/Store';
import Month from './components/Month';
import { MainPadding, WindowContainer } from '../../components/layout/elements';
import { useBackWindow } from '../../hooks/router-hooks';
import { ROUTES } from '../../helpers/routes';
import { gmtLocal } from './prognoses-daily';
import { WindowHeader } from '../windows/components/WindowHeader';
import { windowsStore } from '../../store/Windows';

export const WINDOW_ID_PROGNOSES_MONTH: string = 'WINDOW_ID_PROGNOSES_MONTH';

export default observer(function PrognosesMonth({
  formattedMonth
}: {
  formattedMonth: string
}) {
  useBackWindow(ROUTES.PROGNOSES_MONTH, WINDOW_ID_PROGNOSES_MONTH);

  const [loaded, setLoaded] = React.useState<boolean>(false);

  useEffect(() => {
    store.prognoses.getPrognosesFeed(dayjs.utc(formattedMonth, 'MM.YYYY').toISOString(), gmtLocal)
    .then(() => {
      setLoaded(true);
    })
    .catch(() => {
      setLoaded(false);
    });
  }, []);

  const onClose = () => {
    windowsStore.close(WINDOW_ID_PROGNOSES_MONTH);
  }

  if(!loaded) {
    return null;
  }

  return (
    <WindowContainer>
      <WindowHeader title='Обзор месяца' onBack={onClose}/>
      <Container>
        <Month extended={true} formattedMonth={formattedMonth} />
      </Container>
    </WindowContainer>
  )
});

const Container = styled(MainPadding)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
`;
