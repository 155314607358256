import { openSmsAuthWindow } from 'src/modules/registration/sms'
import { getPathnames } from '../../../helpers/url'
import store from '../../../store/Store'
import { windowsStore } from '../../../store/Windows'
import Emotions from '../products/Emotions/Emotions'
import Solar from '../products/Solar/Solar'
import Synastry from '../products/Synastry/Synastry'
import { EMOTIONS, SOLAR, SYNASTRY, productsWindowData } from './constants'

const getProductComponent = (
	product: string,
	token: string,
	shared: boolean
) => {
	switch (product) {
		case SYNASTRY: {
			return (
				<Synastry
					token={shared ? undefined : +token}
					sharedToken={shared ? token : undefined}
				/>
			)
		}
		case EMOTIONS: {
			return (
				<Emotions
					token={shared ? undefined : +token}
					sharedToken={shared ? token : undefined}
				/>
			)
		}
		case SOLAR: {
			return (
				<Solar
					token={shared ? undefined : +token}
					sharedToken={shared ? token : undefined}
				/>
			)
		}
	}
}

export const checkProduct = () => {
	const [, product, token, shared] = getPathnames()
	const isProduct = ['synastry', 'emotions', 'solar'].includes(product)

	if (!product || !isProduct || !token) return

	const productCode = product.toUpperCase()
	const isShared = shared === 'shared'

  const openProduct = () => {
    windowsStore.open(
			productsWindowData[productCode].purchased,
			getProductComponent(productCode, token, isShared)
		)
  }

  if (store.isAuth) {
    openProduct()
	} else {
		openSmsAuthWindow(openProduct)
	}
}
