import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import store from '../../../store/Store';
import { CloseSmallBlack as CloseSmallIcon, CalendarOffIcon, PlusStatusBlue as OkIcon} from '../../../assets/icons/system/system-icons';
import dayjs from 'dayjs';
import 'dayjs/locale/ru.js';
import BottomButton from './BottomButton';
import ym from 'react-yandex-metrika'
import { getDateWithNameFromTime } from '../helpers/services';
import useLS, { useLSState } from '../../../hooks/useLS';
import { observer } from 'mobx-react-lite';
import { ISession, SessionFormat } from '../../../libs';

dayjs.locale('ru');

export default observer(function ScheduleModal(
  props: {
    session?: ISession;
    setSession?: (v: any) => void;
    onClose?: () => void;
    fromCancelation?: boolean;
  }): JSX.Element {
  const [editMode] = useState(!!props.session)
  const [session, setSession] = useState<ISession | null>(props.session || null);
  const [days, setDays] = useState<string[][] | null>(null);
  // const [showButton, setShowButton] = useState(true);
  const [sessionId] = useLSState('session-id');
  const [activeTime, setActiveTime, saveActiveTime] = useLS('active-time');
  const [previousTime, setPreviousTime] = useState(activeTime);
  const [canPostpone, setCanPostpone] = useState(false);

  const isUnscheduled = session?.type === 'unschedule';

  const getSession = async () => {
    if (sessionId !== null) {
      const session = await store.getSession(+sessionId!);
      setSession(session);
    }
  };

  useEffect(() => {
    if(editMode) {
      setInitValues();
    } else {
      getSession();
    }
  }, []);

  useEffect(() => {
    if(!editMode) {
      setDays(store.scheduleSlots);
    }
  }, [store.scheduleSlots]);

  useEffect(() => {
		if(session) {
      const start = dayjs(session.start);
      isUnscheduled ? setCanPostpone(true) : setCanPostpone(dayjs().isBefore(start.subtract(8, 'hours')));
    }
  }, [session]);

  const setInitValues = async () => {
    if(session) {
      setActiveTime(session.start);
      setPreviousTime(session.start);
      const isWriting = +session.data.format === SessionFormat.Offline
      const isPredictOfTheYear = sessionId === 9
      const scheduleSlots = await store.getScheduleSlots(session.userId, isWriting, isPredictOfTheYear, session.data.duration) as string[][];
      setDays(scheduleSlots);
    }
  }

  // const changeHandler = (time: string) => {
  //   setActiveTime(time);
  //   setShowButton(true);
  // }

  const closeHandler = () => {
    previousTime && saveActiveTime(previousTime);
    props.onClose ? props.onClose() : store.modal.showHandler.scheduleModal = false;
  };

  const confirmHandler = async () => {
    if (activeTime !== previousTime) {
      if (session && activeTime) {
        session.start = activeTime;
        session.type = 'session';
        await store.updateSession(session.id, session);

        store.systemAlert('success', 'Консультация перенесена', 4000);
      }
      ym('reachGoal', 'Personal-input_click-button_change-time');
      activeTime && saveActiveTime();
    }

    props.onClose ? props.onClose() : store.modal.showHandler.scheduleModal = false;
  };

  const cancelationHandler = async () => {
    if (session) {
      await store.cancelSession(session.id);
      props.setSession && props.setSession({
        ...session,
        data: {
          ...session.data,
          status: 2,
        },
      });
    }

    props.onClose ? props.onClose() : store.modal.showHandler.scheduleModal = false;
  };

  return <Modal>
    <Header>{canPostpone ? 'Выбор времени' : 'Отмена консультации'}
      <CloseSmallIcon onClick={closeHandler} />
    </Header>
    { canPostpone && <>
        <ModalContainer>
          {days && days.map(day => {
            return <Day key={`day_${day}`}>
              <DayName>
                {getDateWithNameFromTime(day[0])}
              </DayName>
              <Times>
                {day.map(t => {
                  return <Time
                    key={`time_${t}`}
                    active={activeTime === t}
                    onClick={() => setActiveTime(t)}
                  >
                    {dayjs(t).format('HH:mm')}
                  </Time>;
                })}
              </Times>
            </Day>
          })}
        </ModalContainer>
        <BottomButton
          onClick={confirmHandler}
          inModal={true}
          bottom={props.fromCancelation ? 56 : 0}
        >
          <ButtonContent><span>Изменить время</span><OkIcon /></ButtonContent>
        </BottomButton>
      </>
    }
    {props.fromCancelation &&
      <BottomButton
        onClick={cancelationHandler}
        inModal={true}
        color="red"
      >
        <ButtonContent><span style={{color: 'var(--color-red)'}}>{canPostpone ? 'Отменить все равно' : 'Отменить'}</span> <CalendarOffIcon/></ButtonContent>
        </BottomButton>
    }
  </Modal>;
})

const ModalContainer = styled.div`
  max-width: 550px;
  margin: 0 auto;
  width: 100%;
`;

const Time = styled.span<{ active: boolean; }>`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
  padding: 10px 12px;
  background: #FFFFFF;
  box-shadow: 0px 3.2px 14px rgba(0, 0, 0, 0.06), 0px 0.6px 1.8px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 12px 12px 0;
  cursor: pointer;
  border: 2px solid transparent;

  ${props => props.active && css`
    border: 2px solid #4760E7;
  `}

`;

const Times = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DayName = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.37);
  margin-bottom: 6px;
`;

const Day = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
`;

const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #f8fcff;
  justify-content: center;
  padding: 16px;

  > svg {
    width: 24px;
    height: 24px;
    right: 16px;
    position: absolute;
    cursor: pointer;
  }
`;

const Modal = styled.div`
  box-sizing: border-box;
  background: #f8fcff;
  display: flex;
  flex-direction: column;
  position: fixed;
  /* width: 100vw; */
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 70px 16px;
  overflow-y: auto;
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.4rem;
  font-size: 0.83rem;
  color: var(--color-gradus-blue);

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.4rem;
  }

  & > span {
    margin-top: 0.1rem;
    opacity: 0.9;
  }
`;
