export function searchParams() {
  return new URLSearchParams(window.location.search);
}

export const gtmSend = (data: any) => {
  window.dataLayer.push(data)
}

export const mailTo = (email: string) => window.location.href = `mailto:${email}`;

export const getPathnames = () => window.location.pathname.split('/')
