import { Indi } from "src/libs";

interface AstroEvent {
  title: string
  description: string[]
  static?: string[]
  event?: string;
}

type AstroEventContent = Record<Indi.GetAstroEvents.AstroEventsKey | 'staticBefore' | 'staticAfter', AstroEvent>

const staticaText = [
  'Статика – это момент, когда планета временно замедляет своё движение. Этот период ещё более яркий и острый, чем период ретроградности.',
  'Из-за замедленного движения планета концентрирует энергию в себе, поэтому влияние планеты в статике до и после ретроградности может быть довольно ярким как в положительную, так и в отрицательную сторону.',
  'Влияние статики индивидуально для каждого человека. Оно может не затронуть вас вовсе или, наоборот, проявиться ярко. Чтобы понимать, как это событие скажется на вас, необходим анализ вашей натальной карты.'
]

export const astroEventsContent: AstroEventContent = {
  [Indi.GetAstroEvents.AstroEventsKey.LUNAR_ECLIPSE]: {
    title: "Лунное затмение",
    description: [
      "Затмения бывают солнечными и лунными. Лунное вскрывает темы эмоций, родовых сценариев, взаимодействия с окружающим миром, способности адаптироваться. А солнечное делает акцент на проявленности, личности, активном участии в создании своей реальности.",
      "Затмение – это период важных и даже кармических событий, когда могут произойти кардинальные перемены и напомнить о себе прошлые недоработки и ошибки. Высок риск столкнуться с непростыми событиями и ситуациями, которые меняют привычную жизнь, но необходимы для получения опыта.",
      "Несколько дней до и после затмения – непростое время, когда лучше отказаться от важных и поворотных решений, если не уверены, что это благоприятно для вашего будущего. Но если вы точно знаете, какие перемены и в каких сферах вам нужны, затмения позволят заложить первую ступень на пути к желаемому результату.",
      "Важно понимать, как затмение взаимодействует именно с вашей натальной картой, чтобы узнать, какое именно влияние оно окажет на вашу жизнь и в каких сферах могут быть значимые события."
    ],
    event: 'indi_astroevent_lunar_eclipse'
  },
  [Indi.GetAstroEvents.AstroEventsKey.SOLAR_ECLIPSE]: {
    title: "Солнечное затмение",
    description: [
      "Затмения бывают солнечными и лунными. Лунное вскрывает темы эмоций, родовых сценариев, взаимодействия с окружающим миром, способности адаптироваться. А солнечное делает акцент на проявленности, личности, активном участии в создании своей реальности.",
      "Затмение – это период важных и даже кармических событий, когда могут произойти кардинальные перемены и напомнить о себе прошлые недоработки и ошибки. Высок риск столкнуться с непростыми событиями и ситуациями, которые меняют привычную жизнь, но необходимы для получения опыта.",
      "Несколько дней до и после затмения – непростое время, когда лучше отказаться от важных и поворотных решений, если не уверены, что это благоприятно для вашего будущего. Но если вы точно знаете, какие перемены и в каких сферах вам нужны, затмения позволят заложить первую ступень на пути к желаемому результату.",
      "Важно понимать, как затмение взаимодействует именно с вашей натальной картой, чтобы узнать, какое именно влияние оно окажет на вашу жизнь и в каких сферах могут быть значимые события."
    ],
    event: 'indi_astroevent_solar_eclipse'
  },
  [Indi.GetAstroEvents.AstroEventsKey.ECLIPSE_CORRIDOR]: {
    title: "Коридор затмений",
    description: [
      "Это период между первым и последним затмениями в сезоне. Как и в само затмение, это время важных и даже кармических событий, когда могут произойти кардинальные перемены, непростые ситуации и напомнить о себе прошлые недоработки и ошибки. Эмоциональный фон может быть нестабильным, а реакции – резкими.",
      "Уделяйте внимание своему внутреннему состоянию, не забывайте вовремя отдыхать и расслабляться. Будьте внимательны к окружающим, не обостряйте ситуации и не позволяйте втянуть себя в конфликт.",
      "Чтобы узнать, как Коридор затмений скажется на вашей жизни, в каких сферах могут быть значимые события, необходимо проанализировать вашу натальную карту."
    ],
    event: 'indi_astroevent_eclipse_corridor'
  },
  [Indi.GetAstroEvents.AstroEventsKey.MERCURY]: {
    title: "Ретроградный Меркурий",
    description: [
      "Ретроградность – момент оптической иллюзии, когда кажется, что планета двигается в противоположном направлении.",
      "Меркурий отвечает за информацию, коммуникацию, связь, интеллект, обучение, средства связи и передвижение, торговлю, документы. Во время его ретроградного движения в этих сферах вероятны препятствия, задержки, сложности, ошибки и сбои.",
      "Например, опоздания и задержки в дороге; путаница с информацией, посылками, логистикой; сложности при подписании документов, договоров; срывы сделок. Может сбоить техника. Покупки, сделанные в это время, могут разочаровать. Часто в этот период возвращаются знакомые из прошлого.",
      "Благоприятно вернуться к незавершенному обучению, обновить в памяти нужную информацию, возобновить прошлые контакты и тщательно все проверять. Лучше отложить новые начинания и покупки, особенно техники и гаджетов.",
      "Ретроградный Меркурий на каждого человека оказывает персональное влияние. Может не затронуть вас вовсе или, наоборот, проявиться ярко. Чтобы понимать, как это событие скажется на вас, необходим анализ вашей натальной карты."
    ],
    static: staticaText,
    event: 'indi_astroevent_retro_merc'
  },
  [Indi.GetAstroEvents.AstroEventsKey.VENUS]: {
    title: "Ретроградная Венера",
    description: [
      "Ретроградность – момент оптической иллюзии, когда кажется, что планета двигается в противоположном направлении.",
      "Венера отвечает за деньги и материальные ресурсы, отношения, красоту и внешность. Период ретро-Венеры характеризуется спонтанными и непрактичными расходами, поэтому крупные и дорогостоящие покупки лучше не совершать.",
      "Непростой период для отношений, когда захочется все кардинально поменять (вплоть до партнера), но не стоит торопиться, так как принятые решения могут разочаровать впоследствии. Начатые в этот период новые романы также могут стать недолговечными. Могут возвращаться бывшие партнеры, но и их словам не стоит полностью доверять – после ретро-Венеры может наступить разочарование.",
      "Крайне не рекомендуется менять внешность, делать эстетические операции или менять дизайн интерьера. Такие решения впоследствии могут разочаровать. Если желание велико, то можно сделать то, что потом легко исправить, например, перекрасить волосы. Хорошее время для творчества.",
      "Ретроградная Венера на каждого человека оказывает персональное влияние. Может не затронуть вас вовсе или, наоборот, проявиться ярко. Чтобы понимать, как это событие скажется на вас, необходим анализ вашей натальной карты."
    ],
    static: staticaText,
    event: 'indi_astroevent_retro_venus'
  },
  [Indi.GetAstroEvents.AstroEventsKey.MARS]: {
    title: "Ретроградный Марс",
    description: [
      "Ретроградность – момент оптической иллюзии, когда кажется, что планета двигается в противоположном направлении.",
      "Марс отвечает за действие, продуктивность, решительность, физическую активность. В период ретроградности Марса деловая активность снижается, замедляется развитие дел, тормозятся многие процессы. Силы расходуются неэффективно, многие усилия и начинания будут сделаны впустую, о чем станет известно после окончания фазы ретроградности.",
      "В этот период хорошо вернуться к незавершенным делам, чтобы доделать или переделать их. Не стоит планировать операции и хирургические вмешательства, лучше перенести их на более благоприятный период, если это допускает состояние здоровья.",
      "Повышается конфликтность и травмоопасность. Особенно следует беречь руки.",
      "Ретроградный Марс на каждого человека оказывает персональное влияние. Может не затронуть вас вовсе или, наоборот, проявиться ярко. Чтобы понимать, как это событие скажется на вас, необходим анализ вашей натальной карты."
    ],
    static: staticaText,
    event: 'indi_astroevent_retro_mars'
  },
  staticBefore: {
    title: "Статика",
    description: [
      "В статике скорость планеты близка к нулю. Это время перехода в фазу ретроградности.",
      "Период статики еще более яркий и острый, чем период ретроградности, так как планета переполнена энергией, но при этом стоит на месте или двигается очень медленно, тем самым концентрируя эту энергию в себе. Поэтому проявления влияния планеты в статике перед ретроградностью могут быть довольно яркими как в положительную, так и в отрицательную сторону.",
      "Статика перед ретроградностью на каждого человека оказывает персональное влияние. Может не затронуть вас вовсе или, наоборот, проявиться ярко. Чтобы понимать, как это событие скажется на вас, необходим анализ вашей натальной карты."
    ],
  },
  staticAfter: {
    title: "Статика",
    description: [
      "В статике скорость планеты близка к нулю. Это время окончания фазы ретроградности.",
      "Период статики еще более яркий и острый, чем период ретроградности, так как планета переполнена энергией, но при этом стоит на месте или двигается очень медленно, тем самым концентрируя эту энергию в себе. Поэтому проявления влияния планеты в статике после ретроградности могут быть довольно яркими как в положительную, так и в отрицательную сторону.",
      "Статика после ретроградности на каждого человека оказывает персональное влияние. Может не затронуть вас вовсе или, наоборот, проявиться ярко. Чтобы понимать, как это событие скажется на вас, необходим анализ вашей натальной карты."
    ],
  },
}
