import styled, { css } from 'styled-components';
import { UserIcon } from '../../../assets/icons/system/system-icons';
import { FlexContainer } from '../../../components/layout/elements';


const colors = ['#4093F4', '#EF5350', '#EF598B', '#BA68C8', '#4760E7', '#16CAB9', '#73B230', '#F59300', '#ED682E']

export const HeaderAvatar = ({
  avatarUrl,
  userId = 0,
  name = 'П',
	className,
	onClick,
}: {
  avatarUrl?: string;
  userId?: number;
  name?: string;
	className?: string;
	onClick?(): void
}) => {
  const color = colors[userId % colors.length];
  const symbol = name.charAt(0).toUpperCase();

  if(userId === 0) {
    return <Container
							background={'var(--text-primary)'}
							className={className ?? ''}
							onClick={onClick}>
      <UserIcon />
    </Container>
  }

  return <Container
						avatarUrl={avatarUrl}
						background={color}
						className={className ?? ''}
						onClick={onClick}>
    {!avatarUrl && symbol}
  </Container>
}

const Container = styled(FlexContainer)<{avatarUrl?: string, background: string}>`
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  border-radius: 50%;
  color: var(--color-white);
  font-weight: 500;
  font-size: 1.25rem;

  ${p => !p.avatarUrl && css`
    align-items: center;
    justify-content: center;
    background-color: ${p.background};
  `}

  ${p => p.avatarUrl && css`
    background-image: url(${p.avatarUrl});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  `}

`
