import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import minMax from 'dayjs/plugin/minMax';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import 'dayjs/locale/ru.js';
import updateLocale from 'dayjs/plugin/updateLocale';
import isBetween from 'dayjs/plugin/isBetween';

export const extendDayJs = () => {
  dayjs.extend(isTomorrow)
  dayjs.extend(customParseFormat);
  dayjs.extend(minMax);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(isToday);
  dayjs().isToday();
  dayjs.extend(relativeTime);
  dayjs.locale('ru');
  dayjs.extend(updateLocale)
  dayjs.extend(isBetween)

  dayjs.updateLocale('ru', {
    monthsShort: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек']
  })
}
