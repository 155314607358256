import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { ArrowRight, Cross } from '../assets/icons/system/system-icons';
import { MainPadding } from './layout/elements';
import { Button } from './ui/Button/Button';
import { QuantityIndicator } from './ui/QuantityIndicator';


export enum Colors {
  LIGHT = 'light',
  DARK = 'dark'
}

export enum Positions {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  TOP_LEFT = 'top_left',
  TOP_CENTER = 'top_center',
  TOP_RIGHT = 'top_right',
  BOTTOM_LEFT = 'bottom_left',
  BOTTOM_CENTER = 'bottom_center',
  BOTTOM_RIGHT = 'bottom_right',
}

interface Indicator {
  length: number
  current: number
  size?: number
}

interface IButton {
  title: string
  onClick(): void
  icon?: ReactNode
  position: Positions
}

interface TipProps {
  className?: string
  color: Colors
  title?: string
  text?: string
  indicator?: Indicator
  trianglePosition?: Positions
  onClose?(): void
  button?: IButton
  onClickTip?(): void
}

export const Tip = observer((props: TipProps) => {
  const {
    className = '',
    color,
    title,
    text,
    indicator,
    trianglePosition,
    onClose,
    button,
    onClickTip,
  } = props

  const getButton = () => {
    if (!button) return

    if (button.position === Positions.CENTER) {
      return (
        <Footer>
          <StyledButton onClick={button.onClick}>{button.title}</StyledButton>
          {
            indicator && (
              <IndicatorWrapper>
                <QuantityIndicator
                  color={color}
                  quantity={indicator.length ?? 0}
                  currentStep={indicator.current ?? 0}
                  size={indicator.size}
                />
              </IndicatorWrapper>
            )
          }
        </Footer>
      )
    }

    if (button.position === Positions.RIGHT) {
      return (
        <Footer position={Positions.RIGHT}>
          {
            indicator && (
              <IndicatorWrapper>
                <QuantityIndicator
                  color={color}
                  quantity={indicator.length ?? 0}
                  currentStep={indicator.current ?? 0}
                  size={indicator.size}
                />
              </IndicatorWrapper>
            )
          }
          <Action onClick={button.onClick}>
            <div>{button.title}</div>
            <ArrowRight/>
          </Action>
        </Footer>
      )
    }
  }

  const onClickContainer = () => {
    onClickTip?.()
  }

  return (
    <Container id="onboarding-popup" color={color} onClick={onClickContainer} className={className}>
      {trianglePosition && <Triangle className={'triangle'} color={color} position={trianglePosition}/>}

      <Content>
        <div>
          {title && <Title>{title}</Title>}
          {text && <Text>{text}</Text>}
        </div>

        {onClose && <IconContainer onClick={onClose}><Cross /></IconContainer>}
      </Content>

      {button && getButton()}
    </Container>
  )
})

const Container = styled(MainPadding)<{color: string}>`
  background: ${p => p.color === Colors.DARK ? '#333333' : 'var(--color-white)'};
  color: ${p => p.color === Colors.DARK ? 'var(--text-primary-white)' : 'var(--text-primary)'};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  z-index: var(--z-index-modal);
  position: relative;
`

const IconContainer = styled.div`
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const Triangle = styled.div<{position: Positions, color: string}>`
  border: 0.6rem solid transparent;
  border-bottom: 0.6rem solid ${p => p.color === Colors.DARK ? '#333333' : 'var(--color-white)'};
  position: absolute;
	z-index: 1000;

  ${p => p.position === Positions.TOP_LEFT && css`
    top: -1rem;
    left: 3.5rem;
  `}

  ${p => p.position === Positions.TOP_CENTER && css`
    top: -1rem;
    left: 48%;
  `}

  ${p => p.position === Positions.TOP_RIGHT && css`
    top: -1rem;
    right: 3.5rem;
  `}

  ${p => p.position === Positions.BOTTOM_LEFT && css`
		bottom: -1rem;
    left: 3.5rem;
		transform: rotate(180deg);
  `}

  ${p => p.position === Positions.BOTTOM_CENTER && css`
		bottom: -1rem;
    left: 48%;
		transform: rotate(180deg);
  `}

  ${p => p.position === Positions.BOTTOM_RIGHT && css`
		bottom: -1rem;
    right: 3.5rem;
		transform: rotate(180deg);
  `}
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled.div`
  font-size: 1.125rem;
	font-weight: 500;
  line-height: 19.8px;
  margin-bottom: 0.5rem;
`

const Text = styled.div`
  font-size: 0.875rem;
  line-height: 18.9px;
`

const IndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Footer = styled.div<{position?: Positions}>`
  margin-top: 1rem;

  ${p => p.position === Positions.RIGHT && css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`

const Action = styled.div`
  display: flex;
  gap: 0.5rem;
`

const StyledButton = styled(Button)`
  margin-bottom: 1rem;
`
