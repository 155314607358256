import React from 'react'
import styled, {css} from 'styled-components'

export default function Input({
  placeholder,
  value,
  onChange,
  label,
  maxLength,
  className,
  type = 'text',
  bordered,
  error,
  disabled,
  textarea,
  rows,
  style,
  innerRef,
}: {
  type?: string;
  className?: string,
  label?: string;
  placeholder?: string;
  value: string;
  maxLength?: number;
  bordered?: boolean;
  error?: boolean;
  disabled?: boolean;
  textarea?: boolean;
  onChange(value: string): void;
  rows?: number;
  style?: any;
  innerRef?: any;
}): JSX.Element {
  return <Container className={className}>
    {textarea ? <TextareaComponent
      disabled={disabled}
      maxLength={maxLength}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      bordered={bordered || false}
      error={error || false}
      rows={rows}
      style={style}
      ref={innerRef}
    /> : <>
      <InputComponent
        type={type}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        bordered={bordered || false}
        error={error || false}
        style={style}
        ref={innerRef}
      />
      {label && <Label focused={!!value || !!placeholder}>{label}</Label>}
    </>}
  </Container>
};

const TextareaComponent = styled.textarea<{ bordered: boolean; error: boolean; rows?: number }>`
  width: 100%;
  padding: 0 0 12px;
  border: none;
  border-bottom: 2px solid ${props => props.error ? 'red' : 'rgba(0, 0, 0, 0.1)'} !important;
  background: transparent;
  outline: none;
  font-size: ${props => props.rows === 1 ? '16px' : '24px;'};
  font-family: 'Apercu Pro', sans-serif;
  z-index: 1;
  resize: vertical;
  line-height: ${props => props.rows === 1 ? '1.1875;' : 'normal;'};

  &:focus {
    + label {
      transform: translate(0, -32px);
      font-size: 14px;
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.37);
    font-size: 16px;
  }

  ${props => props.bordered && css`
    border: 1px solid ${
      // @ts-ignore
      props => props.error ? 'red' : 'rgba(0, 0, 0, 0.1)'
    };
    box-sizing: border-box;
    border-radius: 8px;
    padding: 14px 16px;
    + label {
      padding: 0 16px;
      bottom: 18px;
    }
  `}
`;

const InputComponent = styled.input<{ bordered: boolean; error: boolean }>`
  width: 100%;
  padding: 0 0 12px;
  border: none;
  border-bottom: 2px solid ${props => props.error ? 'red' : 'rgba(0, 0, 0, 0.1)'} !important;
  background: transparent;
  outline: none;
  font-size: 24px;
  font-family: 'Apercu Pro', sans-serif;
  z-index: 1;

  :focus {
    + label {
      transform: translate(0, -32px);
      font-size: 14px;
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.37);
    font-size: 16px;
  }

  ${props => props.bordered && css`
    border: 1px solid ${
      // @ts-ignore
      props => props.error ? 'red' : 'rgba(0, 0, 0, 0.1)'
    };
    box-sizing: border-box;
    border-radius: 8px;
    padding: 14px 16px;
    + label {
      padding: 0 16px;
      bottom: 18px;
    }
  `}
`;

const Label = styled.label<{ focused: boolean }>`
  color: rgba(0, 0, 0, 0.37);
  font-size: 24px;
  position: absolute;
  bottom: 12px;
  left: 2px;
  transition: all 0.2s ease;

  ${props => props.focused && css`
    transform: translate(0, -32px);
    font-size: 14px;
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
