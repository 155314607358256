import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import store from '../../../store/Store';
import styled from 'styled-components';

export default observer(function PartnerDiscount(){
  const discount = store.partner.partnerDiscount;

  useEffect(() => {
  }, [])

  if(!discount || discount === 0) return null;

  return <PartnerDiscountContainer>
    <Discount>{Math.round(discount * 100)}%</Discount>
    <Labels>
      <Title>У Вас есть скидка!</Title>
      <Text>Дарим скидку на все услуги от нашего партнера</Text>
    </Labels>
  </PartnerDiscountContainer>
});

const PartnerDiscountContainer = styled.div`
  padding: 16px 12px 12px 12px;
  margin-bottom: 16px;
  background: rgb(71, 96, 231);
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.87);
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-gap: 14px;
`

const Discount = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: #2F44B5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
`

const Labels = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Title = styled.div`
  font-size: 18px;
  margin-bottom: 8px;
`

const Text = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
`
