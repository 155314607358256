import React from "react"
import { MainPageBlocksMoon } from "../components/main-page-blocks-moon"
import { MainPageBlocksMy, MainPageBlocksWorld } from "../components/main-page-blocks-world"
import { MainPageBlocksSubscriptionBanner } from "../components/main-page-blocks-subscription-banner"
import { MainPageBlocksMonth } from "../components/main-page-blocks-month"
import { TUserStateKeys } from "./states"

import { Graphic5 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic10 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic15 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic20 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic25 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic30 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic35 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic40 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic45 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic50 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic55 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic60 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic65 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic70 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic75 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic80 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic85 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic90 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic95 } from "../../../assets/icons/world-graphics/world-graphics"
import { Graphic100 } from "../../../assets/icons/world-graphics/world-graphics"


type TMainPageBlocks = {
  block1: React.FC,
  block2: React.FC,
  block3: React.FC
}

export const mainPageBlocks: {
  [key in TUserStateKeys]: TMainPageBlocks
} = {
  unlogin: {
    block1: MainPageBlocksWorld,
    block2: MainPageBlocksMoon,
    block3: MainPageBlocksSubscriptionBanner,
  },
  active: {
    block1: MainPageBlocksMy,
    block2: MainPageBlocksMoon,
    block3: MainPageBlocksMonth,
  },
  pause: { // TODO: 3 is moon
    block1: MainPageBlocksMoon,
    block2: MainPageBlocksWorld,
    block3: MainPageBlocksSubscriptionBanner,
  },
  inpayed: {
    block1: MainPageBlocksMoon,
    block2: MainPageBlocksWorld,
    block3: MainPageBlocksSubscriptionBanner,
  },
  inactive: {
    block1: MainPageBlocksMoon,
    block2: MainPageBlocksWorld,
    block3: MainPageBlocksSubscriptionBanner,
  }
}

export const getMoonGraphicData = (procent: number): {
  color: string,
  icon: React.FC
} => {
  let color = 'var(--color-green)';
  let icon = Graphic5;

  if(procent <= 25) color = 'var(--color-red)';
  else if(procent <= 50) color = 'var(--color-orange)';

  if(procent <= 5) icon = Graphic5
  else if(procent <= 10) icon = Graphic10
  else if(procent <= 15) icon = Graphic15
  else if(procent <= 20) icon = Graphic20
  else if(procent <= 25) icon = Graphic25
  else if(procent <= 30) icon = Graphic30
  else if(procent <= 35) icon = Graphic35
  else if(procent <= 40) icon = Graphic40
  else if(procent <= 45) icon = Graphic45
  else if(procent <= 50) icon = Graphic50
  else if(procent <= 55) icon = Graphic55
  else if(procent <= 60) icon = Graphic60
  else if(procent <= 65) icon = Graphic65
  else if(procent <= 70) icon = Graphic70
  else if(procent <= 75) icon = Graphic75
  else if(procent <= 80) icon = Graphic80
  else if(procent <= 85) icon = Graphic85
  else if(procent <= 90) icon = Graphic90
  else if(procent <= 95) icon = Graphic95
  else if(procent <= 100) icon = Graphic100

  return {
    color,
    icon
  }
}
