import React from 'react'
import styled, { css } from 'styled-components'
import Button from './Button';
import { TColorType } from '../../../libs';

const FOOTER_HEIGHT = '66px';

export default function BottomButton({
  children,
  onClick,
  inModal = false,
  bottom = 0,
  color = 'blue',
  disabled = false,
  fixedPosition = true,
  type,
}: {
  children: React.ReactNode | Array<React.ReactNode>;
  inModal?: boolean;
  onClick?(): void;
  bottom?: number;
  color?: TColorType;
  disabled?: boolean;
  fixedPosition?: boolean;
  type?: 'button' | 'submit' | 'reset';
}): JSX.Element {
  return <>
    <CustomButton onClick={onClick} color={color} inModal={inModal} bottom={bottom} type={type} fixedPosition={fixedPosition}>
      {children}
    </CustomButton>
    <BottomBlock inModal={inModal} bottom={bottom} />
    <Gradient inModal={inModal} bottom={bottom} />
  </>;
}

export const CustomButton = styled(Button) <{ inModal?: boolean; bottom?: number; color: TColorType; fixedPosition?: boolean;}>`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  position: relative;
  width: calc(100% - 32px);
  margin-inline-start: auto;
  margin-inline-end: auto;
  margin-block-start: 1rem;
  left: 0;
  right: 0;
  max-width: 550px;
  flex-shrink: 0;
  z-index: 10;
  height: 3rem;

  position: ${p => p.fixedPosition === true ? 'fixed' : 'relative'};

  bottom: calc(${p => ((p.bottom || 0) + 16) + 'px'} + ${p => (!p.inModal ? FOOTER_HEIGHT : '0px')} + env(safe-area-inset-bottom));

  ${p => p.fixedPosition === false && css`
    bottom: unset;
    margin-inline-start: 0;
    margin-inline-end: 0;
    width: 100%;
  `}

  ${p => p.color === 'blue' && css`
    color: #4760E7;
    background: rgba(71, 96, 231, 0.1);
  `}

  ${p => p.color === 'blue-bg' && css`
    color: var(--text-primary-white);
    background: #4760E7;
  `}

  ${p => p.color === 'red' && css`
    color: #EF5350;
    background: rgba(239, 83, 80, 0.1);
  `}

  ${p => p.color === 'green' && css`
    color: var(--text-primary-white);
    background: #73B330;
  `}

  ${props => props.color === 'gray' && css`
    color: var(--text-primary);
    background: #DAE4EA;
  `}

  ${p => p.color === 'gradient' && css`
    color: var(--text-primary-white);
    background: linear-gradient(267.5deg, #4760E7 34.89%, #C859FF 87.38%);
  `}

  ${props => (props.color.startsWith('#') || props.color.startsWith('rgb') || props.color.startsWith('var')) && css`
    background: ${props.color};
    color: var(--ui-color-white);
  `}

  > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.375rem;
  }

  &[disabled] {
    color: rgba(71, 96, 231, 0.3);
    background: rgba(71, 96, 231, 0.1) none repeat scroll 0% 0%;
  }
`;

CustomButton.defaultProps = {
  fixedPosition: true
};

const BottomBlock = styled.div<{inModal?: boolean; bottom?: number}>`
  content: '';
  position: fixed;
  bottom: calc(${p => ((p.bottom || 0)) + 'px'} + ${p => !p.inModal ? FOOTER_HEIGHT : '0px'});
  left: 0;
  width: 100%;
  height: 56px;
  background: #F6FEFF;
`;

const Gradient = styled.div<{inModal?: boolean; bottom?: number}>`
  content: '';
  position: fixed;
  bottom: calc(${p => ((p.bottom || 0) + 56) + 'px'} + ${p => !p.inModal ? FOOTER_HEIGHT : '0px'});;
  left: 0;
  width: 100%;
  height: 32px;
  background: linear-gradient(0deg, #F6FEFF 0%, rgba(246, 254, 255, 0) 100%);
`;
