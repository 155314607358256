export type TUserState = {
  title: string,
  color?: string,
  key?: TUserStateKeys
}

export type TUserStateKeys = 'unlogin' | 'active' | 'pause' | 'inpayed' | 'inactive';

export const states: {
  [key in TUserStateKeys]: TUserState
} = {
  unlogin: {
    title: 'Войти',
    key: 'unlogin'
  },
  active: {
    title: 'Активно',
    color: 'var(--color-green)',
    key: 'active'
  },
  pause: {
    title: 'Пауза',
    color: 'var(--color-orange)'
  },
  inpayed: {
    title: 'Не оплачено',
    color: 'var(--color-red)'
  },
  inactive: {
    title: 'Нет подписки',
    color: 'var(--color-orange)'
  },
}

export const PROFILE_NAME_GUEST = 'Гость';
export const PROFILE_NAME_PROFILE = 'Профиль';
