import styled, { css } from 'styled-components';
import { UserIcon } from '../assets/icons/system/system-icons';
import { FlexContainer } from './layout/elements';


const colors = ['#4093F4', '#EF5350', '#EF598B', '#BA68C8', '#4760E7', '#16CAB9', '#73B230', '#F59300', '#ED682E']

export const Avatar = ({
  avatarUrl,
  userId = 0,
  name,
	className,
}: {
  avatarUrl?: string;
  userId?: number;
  name?: string;
	className?: string;
}) => {
  const color = colors[userId % colors.length];
  const symbol = name ? name.charAt(0).toUpperCase() : 'П';

  if (userId === 0) {
    return <Container background={'var(--text-primary)'} className={className ?? ''}>
      <UserIcon />
    </Container>
  }

  return <Container avatarUrl={avatarUrl} background={color} className={className ?? ''}>
    {!avatarUrl && symbol}
  </Container>
}

const Container = styled(FlexContainer)<{avatarUrl?: string, background: string}>`
  width: 5.5rem;
  height: 5.5rem;
  flex-shrink: 0;
  border-radius: 50%;
  color: var(--color-white);
  font-weight: 500;
  font-size: 3rem;

  .small {
    width: 2rem;
    height: 2rem;
    font-size: 1.25rem;
  }

  svg {
    width: 3rem;
    height: 3rem;
  }

  ${p => !p.avatarUrl && css`
    align-items: center;
    justify-content: center;
    background-color: ${p.background};
  `}

  ${p => p.avatarUrl && css`
    background-image: url(${p.avatarUrl});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  `}

`
