import dayjs from 'dayjs';
import { IPlace } from '../libs';

export function isEmailInvalid(value: string) {
  const emailRegexp = new RegExp(
    //eslint-disable-next-line
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  )

  return (!emailRegexp.test(value) || '') && 'Введите корректный email';
}

export function isNameInvalid(value: string) {
  if (value.trim() === '') return 'Имя не может быть пустым';
  else if (!/^[a-z,а-я,ё,\s]*$/i.test(value)) return 'В имени могут быть только буквы';
  else return '';
}

export function isDateInvalid(value: string, originalDate?: string) {
  const date = new Date(value);
  
  if(!(date instanceof Date && !isNaN(+date))) return ('Введите корректную дату');

  const minDate = originalDate && dayjs.utc(originalDate, 'DD.MM.YYYY').subtract(1, 'day').hour(0).minute(0)
  const maxDate = originalDate && dayjs.utc(originalDate, 'DD.MM.YYYY').add(1, 'day').hour(23).minute(0)
  const currentDate = dayjs.utc(value).hour(0).minute(0)

  const isoDate = new Date(value).toISOString();
  if (Date.parse(isoDate) >= Date.now()) {
    return ('Введите дату в прошлом');
  } else if (Date.parse(isoDate) < Date.parse('1900')) {
      return ('Введите корректную дату')
  } else if (originalDate && (currentDate < minDate! || currentDate > maxDate!)) {
    return (`Выберите дату между ${dayjs(minDate).format('DD.MM.YYYY')} и ${dayjs(maxDate).format('DD.MM.YYYY')} `);
  }
  else {
      return '';
  }
}

export function isTimeInvalid(time: string) {
  return dayjs(time).isValid() ? '' : 'Введите корректное время';
}

export function isPlaceInvalid(value: IPlace) {
  if(value.lat === 0 && value.lon === 0) {
    return ('Выберите город из списка');
  }
  return '';
}

export const parsePhone = (str?: string) => {
  if(str) {
    const firstSymbol = str.substr(0, 1);
    if(firstSymbol !== '8' && firstSymbol !== '+') {
      return `+${str.trim()}`;
    }
    return str;
  }

  return '+7';
}
