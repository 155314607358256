import React from 'react';
import {InfoGray as MinusIcon} from '../../../assets/icons/system/system-icons';
import styled, {css} from 'styled-components'

type Size = 'small' | 'big';

export default function NorthMaps(props: {
  size?: Size;
  name?: string;
  className?: string;
}): JSX.Element {

  return <NorthMapsContainer className={props.className} size={props.size}>
    <MinusIcon />
    {props.size === 'small' ? 'Без северных широт' : `${props.name} не работает с городами за северным полярным кругом`}
  </NorthMapsContainer>
}

const NorthMapsContainer = styled.div<{ size?: Size }>`
  display: flex;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-left: auto;
  padding-right: 8px;
  height: fit-content;
  align-items: center;
  
  > svg {
    margin-right: 8px;
    min-width: 24px;
    height: 24px;
  }
  
  ${props => props.size === 'big' && css`
    padding: 10px 14px;
    line-height: 16px;

    > svg {
      margin-right: 14px;
    }
  `}
`;
