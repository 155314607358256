import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {Button as ButtonUI} from '../../../libs';
//import { useTranslation } from 'src/i18n/useTranslation';

type AlignType = 'top' | 'left' | 'right' | 'bottom';

export default function AdvancedTooltipWrapper({
  children = null,
  text = '',
  hotkey = '',
  align = 'top',
  delay = 5000
}: {
  children: any;
  text: string;
  hotkey?: string;
  align?: AlignType;
  delay?: number;
}) {
  const tooltipEl = React.useRef<HTMLDivElement>(null);
  const triangleEl = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  //const { t } = useTranslation(); FIXME:

  let timeout: any = null;

  useEffect(() => {
    window.addEventListener('scroll', onMouseLeave);

    return () => {
      window.removeEventListener('scroll', onMouseLeave);
    }
  }, [])

  const onMouseEnter = (_: any) => {
    tooltipEl.current!.style.display = 'flex';
    triangleEl.current!.style.display = 'block';
    setTimeout(() => {
      const rect = tooltipEl.current?.getBoundingClientRect();
      const screenWidth = window.screen.width || window.outerWidth;

      if(screenWidth && rect) {
        if(rect?.width > screenWidth - 32) {
          tooltipEl.current!.style.width = `${screenWidth - 32}px`;
          tooltipEl.current!.style.left = `${Math.floor(-(rect.left - tooltipEl.current!.offsetLeft))}px`;
        } else if(rect.right && rect.right > screenWidth - 16) {
          tooltipEl.current!.style.left = `${Math.floor(tooltipEl.current!.offsetLeft - rect.left + (screenWidth - rect?.width) / 2)}px`;
        } else if (rect.left && rect.left < 16) {
          tooltipEl.current!.style.left = `${Math.floor(16 - (rect.left - tooltipEl.current!.offsetLeft))}px`;
        }
      }
    })
    timeout = delay && setTimeout(onMouseLeave, delay);
  };

  const onMouseLeave = (_: any) => {
    tooltipEl.current!.style.display = 'none';
    triangleEl.current!.style.display = 'none';
    setIsOpen(false);
    clearTimeout(timeout);
  };

  return (
    <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
      <Triangle ref={triangleEl} />
      <Tooltip delay={delay} align={align} ref={tooltipEl} isOpen={isOpen}>
        <Content>
          <Title>
            {/* {t(text)} */}
            {text}
            {hotkey && <ButtonUI style={{ 'marginLeft': '10px' }} bordered size="small" color="transparent" >
              {hotkey}
            </ButtonUI>}
          </Title>
        </Content>
      </Tooltip>
    </Wrapper>
  );
}

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
`;

const Triangle = styled.div`
  display: none;
  position: absolute;
  width: 1rem;
  height: 1rem;
  background: var(--notifications-background);
  top: -1.2rem;
  left: calc(50% - 0.5rem);
  transform: rotate(45deg);
  z-index: 101;
`

const Tooltip = styled.div<{ align: AlignType; isOpen: boolean; delay: number }>`
  display: none;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: calc(50% + 2rem);
  transform: translateX(-50%);
  box-sizing: border-box;
  
  color: var(--text-secondary);
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12), 0px 3px 14px rgba(0, 0, 0, 0.08), 0px 3px 5px rgba(33, 34, 66, 0.04);
  z-index: 100;
  
  border-radius: 6px;

  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    background: var(--notifications-background);
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  @keyframes growUp {
    from { height: 0; } to { height:100%; }  
  }
  
  ${props => props.isOpen && css`
    padding: 1.2rem 1rem;
    width: 250px;
    
    &::after {
      display: none;
    }
  `}

  bottom: 100%;
  margin-bottom: 10px;

  ${props => props.align === 'left' && css`
    left: 0px;
  `}
  ${props => props.align === 'right' && css`
    right: 0px;
    margin-left: 10px;
  `}
`;

const Content = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: var(--notifications-background);
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  border-radius: 6px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  justify-content: center;
  width: max-content;
  max-width: 100%;
`;

