export function formatPhoneNumberForInput(value: string) {
  if (!value) return

  const phoneNumber = value.replace(/[^\d]/g, '')
  const phoneNumberLength = phoneNumber.length

  if (phoneNumberLength < 5) {
    return `+${phoneNumber}`
  }

  if (phoneNumberLength < 8) {
    return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4)}`
  }

  if (phoneNumberLength < 10) {
    return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7)}`
  }

  return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 9)}-${phoneNumber.slice(9, 11)}`
}

export function formatPhoneNumberForDataPost(value: string) {
  const phoneNumber = value.replace(/[^\d]/g, '')
  return `+${phoneNumber}`
}
