import styled, { css } from "styled-components";

const Markup = styled.div<{short?: boolean}>`
  & > p {
    font-weight: 400;
    font-size: ${p => p.short ? '0.875rem' : '1rem'};
    line-height: 1.5;
    color: var(--text-secondary);
    margin-top: 0;
    margin-bottom: 1rem;
    white-space: pre-wrap;
  }

  & > h4 {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.1666;
    color: black;
    margin-top: 0;
    margin-bottom: 0.75rem;
  }

  & > ul > li {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--text-primary);
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  ${p => p.short
      ? css`
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        `
      : ''
  }

  & > *:last-child {
    margin-bottom: 0rem;
  }
`;

export default Markup;
