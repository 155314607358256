import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { CloseSmallBlack as CloseSmallIcon } from '../../../assets/icons/system/system-icons';
import { Button } from '../../../libs';

export default function HelpChoose({
  handleClick,
  buttonText,
  title,
  text,
  containerStyle,
  children,
  closeFn,
}: {
  handleClick?: () => void;
  buttonText?: string;
  title?: string;
  text?: string;
  containerStyle?: object;
  children?: ReactElement;
  closeFn?: () => void
}){
  return (
    <Container style={containerStyle}>
      { closeFn &&
        <CloseIconWrapper onClick={() => closeFn()}>
          <CloseSmallIcon />
        </CloseIconWrapper>
      }
      { title && <Title hasCross={!!closeFn}>{title}</Title> }
      { text && <Text>{text}</Text> }
      { children }
      { handleClick && buttonText &&
        <StyledButton 
          color='transparentWithBorder'
          size='medium'
          onClick={handleClick}
          style={{height: '3rem', opacity: 1}}
        >
          <span>{buttonText}</span>
        </StyledButton> }
    </Container>
  );
}

const Container = styled.div`
  padding: 16px;
  margin-bottom: 16px;
  background: rgb(71, 96, 231);
  border-radius: 10px;
  color: var(--text-primary-white);
  position: relative;
`

const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-primary-white);
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
`

const Title = styled.div`
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 8px;
  padding-right: ${(props: {hasCross: boolean}) => props.hasCross ? '35px' : '0px'};
`

const Text = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
  line-height: 16.5px;
`
const CloseIconWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;

  &:hover {
    color: rgba(255, 255, 255, 0.87);
  }
`
