
import { makeAutoObservable } from 'mobx';
import { ReactNode } from 'react';
import { popupsStore } from './Popups';

import { WINDOW_ID_CONSULTATIONS } from '../modules/consultations/Consultations';
import { WINDOW_ID_MOONPHASE } from '../modules/moon-phase/moon-phase';
import { WINDOW_ID_MYDAY } from '../modules/my-day/my-day';
import { WINDOW_ID_PRODUCTS } from '../modules/products/Products';
import { WINDOW_ID_PROFILE } from '../modules/profile/Profile';
import { WINDOW_ID_PROGNOSES } from '../modules/prognoses/prognoses';
import store from './Store';

export const MAIN_WINDOWS_AND_POPUPS = [
  WINDOW_ID_MYDAY,
  WINDOW_ID_PROGNOSES,
  WINDOW_ID_CONSULTATIONS,
  WINDOW_ID_PRODUCTS,
  WINDOW_ID_PROFILE,
  WINDOW_ID_MOONPHASE
]

export type TWindow = {
  id: string;
  content: ReactNode,
  depth: number,
  animate?: 'fade',
  isModal?: boolean,
}

interface Depths {
  main: number
}

class WindowsStore {
  private windows: TWindow[] = [];

  depths: Depths = {
    main: 5
  }

  contextMenu: React.ReactElement | null = null

  constructor() {
    makeAutoObservable(this);
  }

  getWindows() {
    return this.windows;
  }

  open(
    id: string,
    content: ReactNode,
    animate?: 'fade',
    isModal: boolean = true,
    singleWindow: boolean = false,
    replace: boolean = false,

  ) {
    if (singleWindow && !replace) {
      this.windows = []
    } else {
      let openedWindow = this.windows.find(item => item.id === id);

      if (openedWindow && !replace) {
        return
      }
      
    }

    if (MAIN_WINDOWS_AND_POPUPS.includes(id)) {
      popupsStore.closeAll();
      this.closeAll();
    }

    const newDepths = {
      ...this.depths,
      main: this.depths.main + 1
    };

    this.setDepth(newDepths);

    (document.querySelector('#windows') as HTMLDivElement).style.zIndex = String(newDepths.main);

    const newWindow = {
      id,
      content,
      depth: newDepths.main,
      animate,
      isModal,
    }

    setTimeout(() => this.windows.push(newWindow), 16);
  }

  close(id: string, onlyLast?: boolean) {
    if(onlyLast) {
      const item = this.windows.find(item => item.id === id);
      if(!item) return;
      const itemDepth = item.depth;
      const allDepths = this.windows.concat(popupsStore.getPopups()).map(item => item.depth);

      if(itemDepth !== Math.max(...allDepths)) {
        return;
      }
    }

    this.windows = this.windows.filter(window => window.id !== id);
  }

  closeAll() {
    this.windows = []
  }

  setContextMenu(value: React.ReactElement | null) {
    this.contextMenu = value
  }

  setDepth(value: Depths) {
    this.depths = value
  }
}

export const windowsStore = new WindowsStore();
