import styled from 'styled-components';

export default function RingLoader() {
  return <StyledLoader/>;
}

const StyledLoader = styled.span`
  & {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    border: 5px solid #FFF;
    border-color: rgba(255, 255, 255, 0.50);

    position: relative;
    animation: rotate 1s linear infinite
  }

  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: -5px;
    border-radius: 50%;
    border: 5px solid #FFF;
    animation: prixClipFix 2s linear infinite ;
  }

  @keyframes rotate {
      100%   {transform: rotate(360deg)}
    }

    @keyframes prixClipFix {
        0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
        25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
        50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
        75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
        100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
    }
`;
