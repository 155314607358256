export const buttonStyle = { marginBottom: '1.5rem', opacity: 1, borderRadius: '0.4rem', height: '3rem', fontSize: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }

export const DESKTOP_WIDTH = 380;
export const DESKTOP_GAP = 100;

export const PASS_MIN_LENGTH = 8;
export const emailValidation = (val: string) => /^[a-z,\.,\-,_,\d]+@[a-z]+\.[a-z]{2,}/i.test(val);
export const phoneNumberValidation = (val: string) => /^\+\d[\d\\(\\)\\ -]{4,14}\d$/.test(val);
export const alphaAndDigitValidation = (val: string) => /^(?=.*\d)(?=.*[a-z,A-Z])(?!.*\s)(?!.*[а-яА-ЯЁё]).*$/.test(val);

export const blockScroll = (block: boolean) => {
	const popups = document.querySelectorAll('.popup-scroll')

	if (block) {
		popups.forEach(popup => popup?.classList.add('block-scroll'))
	} else {
		popups.forEach(popup => popup?.classList.remove('block-scroll'))
	}
}
