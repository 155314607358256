import React, { forwardRef, Ref } from "react";
import styled, { css } from "styled-components";
import { HelpIcon } from "../assets/icons/system/system-icons";
import SurvayPng from '../assets/images/banners/survey.png';
import { IUsersPullBannerData } from "../store/Banners/type/banners";
import store from "../store/Store";
import { Button } from "./ui/Button/Button";

export const LS_KEY_usersPullBanner_clicked = 'usersPullBanner_clicked';

export interface UserSurveyBannerProps {
  type?: 'normal' | 'small'
  data?: IUsersPullBannerData;
  onClick?(): void;
  className?: string;
}

export default forwardRef(function UsersPullBanner(props: UserSurveyBannerProps, ref: Ref<HTMLDivElement>) {
  const type = props.type ?? 'normal'
  const {
    title = 'Помогите сделать прогнозы лучше.',
    subtitle = 'Пройдите опрос, что бы мы смогли сделать приложение лучше.',
    buttonCaption = 'Пройти опрос',
    link = 'https://docs.google.com/forms/d/e/1FAIpQLScA-e0cOKw0lcL8BYqvnsdT6Sw81sacW4T_j-IqPZJuE58B6Q/viewform?usp=sf_link',
  } = props.data || {}


  const [isBannerClicked, setIsBannerClicked] = React.useState<boolean>(store.lsGet(LS_KEY_usersPullBanner_clicked));

  const onButtonClick = () => {
    setIsBannerClicked(() => {
      store.lsSave(LS_KEY_usersPullBanner_clicked, true);
      return true;
    });
    props.onClick?.();
  }

  if (isBannerClicked) return null;

  return (
    <UsersPullBannerContainer ref={ref} className={props.className} isSmall={type === 'small'}>
      <div className="wrapper">
        <Picture>
          { type === 'normal'
            ? <img src={SurvayPng}/>
            : <HelpIcon color="var(--color-purpur)" opacity={'1'}/>
          }
        </Picture>
        <Content isSmall={type === 'small'}>
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
        </Content>
      </div>
      <StyledButton color={type === 'normal' ? "blue" : "purpur"} onClick={onButtonClick}><a href={link} target="_blank">{buttonCaption}</a></StyledButton>
    </UsersPullBannerContainer>
  );
});

const UsersPullBannerContainer = styled.div<{ isSmall: boolean; }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--color-white);
  border-radius: 1rem;

  ${p => p.isSmall && css`
    background-color: var(--color-situations-bg);
    border-radius: 0;
  `}


	padding: 1rem;
	color: var(--text-primary-white);
	font-size: 0.875rem;
  margin-bottom: 1.5rem;
  text-align: center;

  & .wrapper {
    display: flex;
    flex-direction: column;

    ${p => p.isSmall && css`
      flex-direction: row;
    `}
  }
`;


const Picture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div<{ isSmall: boolean; }>`
  position: relative;
  text-align: center;

  & .title {
    position: relative;
    max-width: 50%;
    margin: 1rem auto 0;
    font-size: 1rem;
    font-weight: 500;
    color: var(--text-primary);

    ${p => p.isSmall && css`
      margin-top: 0;
      max-width: 90%;
      text-align: left;
    `}
  }

  & .subtitle {
    position: relative;
    max-width: 80%;
    margin: 0.5rem auto 0;
    font-size: 0.875rem;
    color: var(--text-secondary);

    ${p => p.isSmall && css`
      max-width: 90%;
      text-align: left;
    `}
  }
`;

const StyledButton = styled(Button)`
  margin-top: 1rem;

  font-size: 0.875rem;
  padding: 0.75rem 1rem;

  & a {
    text-decoration: none;
    color: var(--color-white);
  }
`
