import React, { useState } from 'react';
import styled from 'styled-components';

import { MoreIcon } from '../../../assets/icons/system/system-icons';
import { useClickOutside } from '../../../hooks/dom-hooks';

export default function Actions(
  {
    actions = [],
  }: {
    actions?: {
      label: string;
      action(p: any): void;
    }[];
  }
) {
  const [isOpen, setIsOpen] = useState(false);
  const forOutsideRef = React.useRef<any>(null);
  useClickOutside(forOutsideRef, () => setIsOpen(false));

  const handleClick = (fn: any) => {
    fn && typeof(fn) === 'function' && fn();
    setIsOpen(!isOpen);
  }

  return (
    <Container>
      <MoreIconButton onClick={handleClick} />
      { isOpen &&
          <Dropdown ref={forOutsideRef}>
            { actions.map(action => (
              <Action key={action.label} onClick={() => handleClick(action.action)}>{action.label}</Action>
            ))}
          </Dropdown>
      }
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: none; // Скрываем пины
`;

const MoreIconButton = styled(MoreIcon)`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`;

const Dropdown = styled.div`
  background-color: var(--color-white);
  border-radius: 0.5rem;
  box-shadow: 1px 8px 10px rgba(0, 0, 0, 0.12), 1px 3px 14px rgba(0, 0, 0, 0.08), 1px 3px 5px rgba(33, 34, 66, 0.04);
  position: absolute;
  right: 0;
  top: 1.5rem;
  z-index: 5;
  overflow: hidden;

  & > div {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--text-primary);
    white-space: nowrap;
    padding: 0.5rem 1rem 0.5rem;
    min-width: 15rem;
    max-width: 100vw;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    &:last-child {
      border-bottom: none;
    }
  }
`;

const Action = styled.div`
  cursor: pointer;
`;