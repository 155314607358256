import styled from 'styled-components';

export enum BlockStatus {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  UPCOMING = 'upcoming',
  PAST = 'past',
  NEUTRAL = 'neutral',
  ALERT = 'alert',
}

const textColors = {
  positive: 'rgba(81, 135, 25, 1)',
  negative: 'rgba(207, 130, 13, 1)',
	alert: '#EFB22F',
  upcoming: 'rgba(71, 96, 231, 1)',
  past: 'rgba(0, 0, 0, 0.6)',
  neutral: 'rgba(0, 0, 0, 0.6)',
};

const iconColors = {
  positive: 'rgba(81, 135, 25, 0.6)',
  negative: 'rgba(230, 188, 121, 1)',
	alert: '#EFB22F',
  upcoming: 'rgba(71, 96, 231, 0.6)',
  past: 'rgba(0, 0, 0, 0.3)',
  neutral: 'rgba(0, 0, 0, 0.6)',
}

const bgColors = {
  positive: 'rgba(115, 178, 48, 0.1)',
  negative: 'rgba(245, 147, 0, 0.1)',
	alert: 'rgba(239, 178, 47, 0.10)',
  upcoming: 'rgba(71, 96, 231, 0.1)',
  past: 'rgba(0, 0, 0, 0.04)',
  neutral: 'rgba(0, 0, 0, 0.04)',
};

export default function InfoBlock(
  {
    status = BlockStatus.POSITIVE,
    icon = null,
    title = null,
    titleRight = null,
    children = null,
		className = '',
  }: {
    status?: BlockStatus;
    icon?: any;
    title?: string | null;
    titleRight?: string | null;
    children?: any;
		className?: string;
  }
) {

  return (
    <Container status={status} className={className}>
      { icon && <Left className='left' color={iconColors[status]}>{ icon }</Left>}
      <Right>
        <TitleRow color={textColors[status]}>
          { title && <span>{title}</span> }
          { titleRight && <span>{titleRight}</span> }
        </TitleRow>
        {children && <Text>{children}</Text>}
      </Right>
    </Container>
  );
}

const Container = styled.div<{status: BlockStatus}>`
  flex-basis: 100%;
  display: flex;
  gap: 0.75rem;
  padding: 0.5rem;
  background: ${p => bgColors[p.status]};
  border-radius: 1rem;

  & > *:nth-child(3) {
    flex-basis: 100%;
    text-align: right;
  }
`;

const Left = styled.div<{color: string}>`
  height: 1.25rem;

  & > svg {
    fill: ${p => p.color};
    stroke: ${p => p.color};
    height: 1.5rem;
    width: 1.5rem;
    flex-shrink: 0;
  }
`;

const Right = styled.div`
  flex-basis: 100%;
`;

const TitleRow = styled.div<{color: string}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;

  & > * {
    color: ${p => p.color};
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.35rem;
  color: var(--text-primary);
  padding: 0.25rem 0 0.5rem;
`;
