import styled from 'styled-components';
import { ReactionButton } from './ui/ReactionButton';
import Input from './ui/Input';
import { Button } from './ui/Button/Button';

export interface Titles {
  question: string;
  feedback: string;
  thanks: string;
}

export interface FeedbackFormProps {
  step: keyof Titles;
  titles: Titles;
  text: string;
	className?: string
  positiveClick: () => void;
  negativeClick: () => void;
  submitClick: () => void;
  textChange: (text: string) => void;
}

export const FeedbackForm: React.FC<FeedbackFormProps> = ({
  step,
  titles,
  text,
	className = '',
  positiveClick,
  negativeClick,
  submitClick,
  textChange,
}) => {
  return (
    <Container className={className}>
      <Title>{titles[step]}</Title>

      {step === 'question' && (
        <Buttons>
          <ReactionButton reaction="positive" onClick={positiveClick} />
          <ReactionButton reaction="negative" onClick={negativeClick} />
        </Buttons>
      )}

      {step === 'feedback' && (
        <div>
          <StyledInput
            value={text}
            onChange={textChange}
            textarea
            placeholder="Нам важно знать ваше мнение"
          />
          <StyledButton color="gray" onClick={submitClick}>
            Отправить
          </StyledButton>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
	width: 100%;
  margin-bottom: 2rem;
`;

const Title = styled.div`
  color: var(--text-third);
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const StyledInput = styled(Input)`
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--element-40, rgba(0, 0, 0, 0.4));
  margin-bottom: 1rem;

  textarea {
    border-bottom: none !important;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 0.5rem;
`;
