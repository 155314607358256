import React, { useEffect, useRef, useState } from "react";

export const useSetSquareSize = <T extends HTMLElement>() => {
  const ref = useRef<T>(null);

  useEffect(() => {
    if(ref.current) {
      ref.current.style.height = ref.current.offsetWidth + 'px';
    }
  }, [])

  return {
    ref
  }
}


export const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  onClick:() => void
) => {

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if(ref.current && !ref.current!.contains(e.target as Node)) {
        onClick();
      }
    }

    document.addEventListener('mousedown', handler)

    return () => {
      document.removeEventListener('mousedown', handler)
    }
  }, [onClick, ref]);
}

export const useElementOnScreen = (options: { root: HTMLElement | null; rootMargin: string; threshold: number }) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const cb = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  }

  useEffect(() => {
    const observer = new IntersectionObserver(cb, options);
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    }
  }, [elementRef, options]);

  return [elementRef, isVisible];
}
