import React from 'react';
import styled from 'styled-components';
import { NatalIcon, RectificationIcon, SynastryIcon, PrognosisIcon, PrognosticIcon, RelocationIcon} from '../../../assets/icons/astro/astro';
import { ISkillEx } from '../../../libs';

const getIcon = (type: string) => {
  switch (type) {
    case 'natal': return <NatalIcon/>;
    case 'election': return <PrognosisIcon/>;
    case 'rectification': return <RectificationIcon/>;
    case 'synastry': return <SynastryIcon/>;
    case 'prognostics': return <PrognosticIcon/>;
    case 'predictions': return <PrognosticIcon/>;
    case 'relocation': return <RelocationIcon/>;
    case 'horar': return <RelocationIcon/>;
    default: return '';
  }
}

export default function Skill(props: {
  data: ISkillEx
}): JSX.Element {

  return <SkillContainer>
    <IconContainer style={{ background: props.data.emoji.background }}>
      {getIcon(props.data.emoji.text)}
    </IconContainer>
    <Title>{props.data.name}</Title>
    <Description>{props.data.description}</Description>
  </SkillContainer>;
}

const Description = styled.p`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
`;

const Title = styled.p`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 4px;
`;

const SkillContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 240px;
  @media (min-width: 768px) {
    margin: 0 24px 24px 0;
    width: calc(50% - 12px);
    &:nth-of-type(2n) {
      margin: 0 0 24px 0;      
    }
  }
`;

const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  
  > svg {
    width: 24px;
    height: 24px;
  }
`;
