
import dayjs from 'dayjs';
import { IPurchasedProduct } from '../store/Products';

export const isIntervalsIntersect = (s1: string, e1: string, s2: string, e2: string) => {
  if (s1 === s2 || s1 === e2 || e1 === s2 || e1 === e2) {
    return true;
  }
  const _s1 = dayjs(s1, 'DD.MM.YYYY HH:mm:ss');
  const _s2 = dayjs(s2, 'DD.MM.YYYY HH:mm:ss');
  const _e1 = dayjs(e1, 'DD.MM.YYYY HH:mm:ss');
  const _e2 = dayjs(e2, 'DD.MM.YYYY HH:mm:ss');

  return (_s1.isAfter(_s2) && _s1.isBefore(_e2)) ||
    (_e1.isAfter(_s2) && _e1.isBefore(_e2)) ||
    (_s1.isBefore(_s2) && _e1.isAfter(_e2));
}

export const getFormattedInterval = (start: string, end: string, full: boolean = false, hideYear: boolean = false) => {
  const _s = dayjs(start, 'DD.MM.YYYY HH:mm:ss');
  const _e = dayjs(end, 'DD.MM.YYYY HH:mm:ss');

  if (full) {
    return `${_s.format('D MMM HH:mm')} — ${_e.format('D MMM HH:mm')}`;
  }

  if (_s.isSame(_e, 'year')) {
    const isThisYear = dayjs().isSame(_s, 'year');

    if (_s.isSame(_e, 'day')) {
      return `${_s.format(isThisYear || hideYear ? 'D MMM HH:mm' : 'D MMM YYYY HH:mm')} – ${_e.format('HH:mm')}`;
    }

    // if (_s.isSame(_e, 'month')) {
    //   return `${_s.format('D')}–${_e.format(isThisYear ? 'D MMM' : 'D MMMM YYYY')}`;
    // }

    return `${_s.format('D MMM')} — ${_e.format(isThisYear || hideYear ? 'D MMM' : 'D MMM YYYY')}`;
  }

  return `${_s.format('D MMM YYYY')} — ${_e.format('D MMM YYYY')}`;
}

export const sortByDate = (a: IPurchasedProduct, b: IPurchasedProduct) => {
  return new Date(a.createdAt || 0) < new Date(b.createdAt || 0) ? 1 : -1;
}

interface IProductsGroupsObject {
  [key: string]: IPurchasedProduct[];
}

export function groupByDate(array: IPurchasedProduct[]) {
  const object = array.reduce((acc: IProductsGroupsObject, obj) => {
    const property = dayjs(obj['createdAt']).format('DD MMM YYYY');
    acc[property] = acc[property] || [];
    acc[property].push(obj);
    return acc;
  }, {});
  return Object.entries(object);
}

export function secondsToHms(duration: number) {
  let h = Math.floor(duration / 60);
  const m = Math.floor(duration % 60);

  let hDisplay = '';
  if (h > 0) {
    if (h === 1) {
      hDisplay = h + ' час '
    } else if (h > 1 && h < 5) {
      hDisplay = h + ' часа '
    } else if (h > 5) {
      hDisplay = h + ' часов '
    }
  }
  const mDisplay = m > 0 ? m + (m === 1 ? " минута " : " минут") : "";

  return hDisplay + mDisplay;
}

export const getYearText = (years: string | number) => {
  years = Number(years);
  if (years < 5) {
    return `${years === 1 ? 'год' : 'года'}`;
  }
  if (years >= 5 && years <= 20) {
    return `лет`;
  }
  if (years > 20) {
    return `${years % 10 === 1 ? 'год' : years % 10 === 0 ? 'лет' : years % 10 >= 5 ? 'лет' : 'года'}`;
  }
};

export const toISOString = (date: dayjs.Dayjs) => {
  return `${date.format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
}

export const toUTCDateTimeFormat = (date: string, time: string) => {
  return dayjs.utc(`${date.split('.').reverse().join('-')}T${time}`).format()
}

export const getStartDateFromUrl = () => {
  const date = dayjs(window.location.pathname.split('/')[2], 'DD-MM-YYYY')
  return date.isValid() ? date : dayjs().startOf('day');
}

export const setDayjsDate = (year: number, month: number = 1, date: number = 1, hour: number = 0, minute: number = 0, second: number = 0) => {
  return dayjs().set('year', year).set('month', month).set('date', date).set('hour', hour).set('minute', minute).set('second', second)
}

export const getTimeOfDay = () => {
	const hour = dayjs().hour()

	if (5 <= hour && hour < 10) {
		return 'morning'
	} else if (10 <= hour && hour < 17) {
		return 'afternoon'
	} else {
		return 'evening'
	}
}

export const getGMTLocal = (inMinutes?: boolean) => {
  const dateNow = new Date()
  return inMinutes ? dateNow.getTimezoneOffset() * -1 : dateNow.getTimezoneOffset() / -60
}
