
import React from 'react';
import styled, {css} from 'styled-components'
import {Ok as OkIcon} from '../../../assets/icons/system/system-icons'; // TODO: check icon
import { secondsToHms } from '../../../helpers/dates';
import { useLocation } from "react-router-dom";
import { servicesRoutes } from '../Consultations';
import { IServiceDataEx, SessionFormat } from '../../../libs';

export default function ServiceCard(props: {
  active?: boolean;
  onClick(): void;
  sessionFormat: SessionFormat;
	className?: string
} & IServiceDataEx): JSX.Element {

  const location = useLocation();
  const priceIsVisible = location.pathname !== servicesRoutes.Services.path;

  const isOnlineFormat = props.sessionFormat === 1;

  if (!props.price) return <Card active={props.active || false}/>;
  return <Card
						className={props.className}
						onClick={props.onClick}
						active={props.active || false}>
    <CardHeader>
      {props.emoji && <IconContainer background={props.emoji.background} active={props.active || false}>
        {props.active
          ? <OkIcon/>
          : <Emoji>{props.emoji.text}</Emoji>}
      </IconContainer>}
      {priceIsVisible && <Price>
        {props.price.value}₽
      </Price>}
      {isOnlineFormat ? <span>{secondsToHms(props.duration)}</span> : <></>}
    </CardHeader>
    <CardBody>
      <Title>{props.name}</Title>
      <Description>{props.description}</Description>
    </CardBody>
  </Card>;
}

const Card = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0 3.2px 14px rgba(0, 0, 0, 0.06), 0 0.6px 1.8px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-top: 1rem;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border: 2px solid transparent;
  cursor: pointer;

  ${props => props.active && css`
    border: 2px solid var(--color-green);
  `}

  :active {
    background: rgba(0, 0, 0, 0.04);
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
  font-weight: 500;
  font-size: 18px;
  color: #4760E7;;
`;

const IconContainer = styled.div<{ background: string; active: boolean; }>`
  width: 40px;
  height: 40px;
  background: ${props => props.active ? 'var(--color-green)' : props.background} ;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background 0.2s ease;

  > svg {
    width: 24px;
    height: 24px;
  }
`;

const Emoji = styled.span`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Price = styled.p`
  margin: 0 0 0 auto;
  color: rgba(71, 96, 231, 1);
  font-size: 18px;

  & + span {
    margin-left: 5px;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0 0 8px;
`;

const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
`;
