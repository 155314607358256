import { show } from "../libs";

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const copyToClipboard = (text: string, message?: string) => {
	navigator?.clipboard?.writeText(text)
		.then(_data => {
      message && show({
        type: 'success',
        text: message,
        timeout: 3000
      })
    })
}

export const numberWord = (value: number, words: string[]) => {
  value = Math.abs(value) % 100;
  const num = value % 10;
  
  if(value > 10 && value < 20) return words[2]; 
  if(num > 1 && num < 5) return words[1];
  if(num === 1) return words[0]; 
	
  return words[2];
}
