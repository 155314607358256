import styled from "styled-components"
import { FilledStarIcon, GiftIcon } from "../../../assets/icons/system/system-icons"
import { Offer } from "../../../components/Offer"
import { Button } from "../../../components/ui/Button/Button"
import { getMyDayLS, setMyDayLS } from "../../../store/localStorageHelpers"
import { useEffect, useState } from "react"
import { gtmSend } from "../../../helpers/url"
import store from "../../../store/Store"
import { observer } from "mobx-react-lite"
import { WINDOW_PAYMENT_PAYWALL, openPaymentPaywallWindow } from "../../payment/components/payment-paywall"
import { windowsStore } from "../../../store/Windows"
import { TariffPeriod } from "../../payment/helpers/tariffs"


interface MyDayOfferProps {
	className?: string
	header?: boolean
	outOfZone?: boolean
}

export const MyDayOffer = observer((props: MyDayOfferProps) => {
	const [ hideOffer, setHideOffer ] = useState(getMyDayLS().hideOffer)
	const userId = store.sessionData?.id || '';

	const closeOffer = () => {
		const data = getMyDayLS()
		data.hideOffer = true
		setMyDayLS(data)
		setHideOffer(true)
	}

	const openPayment = () => {
		gtmSend({ 'event': `ind_prognosis_my-day${props.outOfZone ? '-out-of-zone' : ''}_1year-bunner_click`, 'user_id': userId})
		openPaymentPaywallWindow(TariffPeriod.YEAR, () => windowsStore.close(WINDOW_PAYMENT_PAYWALL))
	}

	if (hideOffer) return null

	return (
		<Container className={props.className}>
			{
				props.header && <Header>
													<div className='right'>
														<FilledStarIcon/>
														Промо
													</div>
													<StyledButton onClick={closeOffer}>Закрыть</StyledButton>
												</Header>
			}
			<Offer title={'Получите 2 месяца в подарок'}
						 icon={<GiftIcon/>}
						 text={'При оплате годовой подписки Chronos Plus'}
						 onClick={openPayment}
						 price={'4 990 ₽'}
						 oldPrice={'5 988 ₽'}
						 textPrice={'Продлить за'}/>
		</Container>
	)
})

const Container = styled.div`
	margin-bottom: 1rem;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;

	margin-bottom: 1rem;

	.right {
		display: flex;
		align-items: center;
	}

	svg {
		margin-right: 0.5rem;
	}
`

const StyledButton = styled(Button)`
  display: block;
  width: fit-content;
  padding: 0.375rem 0.5rem;
  color: var(--text-primary);
  font-size: 0.75rem;
  font-weight: 500;
  background: var(--color-white);
  filter: var(--shadow);
`
