import React from 'react';
import styled, { css } from 'styled-components';

const getColor = (percent: number = 0) => {
  if(percent < 30) {
    return '--color-red';
  }
  if(percent < 70) {
    return '--color-orange';
  }
  return '--color-green';
}

export default function PercentCircle(
    {
      percent = 0,
      size = '1rem',
      isFilled = false,
      text,
      fontSize,
    }:
    {
      percent?: number;
      size?: string;
      isFilled?: boolean;
      text?: string;
      fontSize?: string;
    }
) {
  const color = getColor(percent);

  const calculateSkew = (sectorIndex: number): number => {
    if (percent >= (sectorIndex + 1) * 25) return 0;
    else return (90 + ((percent - sectorIndex * 25) / 25) * 90);
  }

  return (
    <PercentBlock size={size} isFilled={isFilled} percent={percent}>
      <span>
        {(percent >= 0 || isFilled) && <Sector rotate="0" skew={isFilled ? 0 : calculateSkew(0)} color={color}></Sector>}
        {(percent > 25 || isFilled) && <Sector rotate="90" skew={isFilled ? 0 : calculateSkew(1)} color={color}></Sector>}
        {(percent > 50 || isFilled) && <Sector rotate="180" skew={isFilled ? 0 : calculateSkew(2)} color={color}></Sector>}
        {(percent > 75 || isFilled) && <Sector rotate="270" skew={isFilled ? 0 : calculateSkew(3)} color={color}></Sector>}
        { text && <Text isFilled={isFilled} fontSize={fontSize}>{text}</Text> }
      </span>
    </PercentBlock>
  );
}

const PercentBlock = styled.section<{size: string, isFilled?: boolean, percent?: number}>`
  display: flex;
  align-items: center;
  height: ${p => p.size};
  box-shadow: ${p => p.isFilled ? '0px 3.2px 7.2px rgba(0, 0, 0, 0.13), 0px 0.6px 1.8px rgba(0, 0, 0, 0.11)' : ''};
  border-radius: 50%;

  & > span {
    position: relative;
    width: ${p => p.size};
    height: ${p => p.size};
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    overflow: hidden;
    font-size: ${p => p.size};

    ${p => p.isFilled
        ? ''
        : css`
            &:after{
              content: '';
              position: absolute;
              width: 80%;
              height: 80%;
              background: white;
              border-radius: 50%;
              top: 10%;
              left: 10%;
            }
          `
    }
  }
`;

const Sector = styled.i<{rotate: string; skew: number, color: string}>`
  width: 50%;
  height: 50%;
  position: absolute;
  left: 50%;
  top: 0;
  transform-origin: left bottom;
  ${p => css`background: var(${p.color});`}
  transform: rotate(${props => props.rotate}deg) skewY(${props => props.skew}deg);
`;

const Text = styled.div<{isFilled?: boolean; fontSize?: string}>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${p => p.fontSize ? p.fontSize : '0.5em'};
  ${p => p.isFilled
    ? css`color: white;`
    : css`color: black;`
  }
`;
