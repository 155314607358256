import {makeAutoObservable} from 'mobx';
import {getSolarPersonDataLS, setSolarPersonDataLS} from "./localStorageHelpers";
import dayjs from "dayjs";
import api from '../helpers/api';
import { FormatsType, FORMAT_DAYS } from '../modules/products/products/Solar/Solar';
import { IPlace, toDateTime } from '../libs';

export type PersonDataType = {
  name: string | null
  dateTime: string | null
  place: IPlace | null
  gmt?: string | null
}

const defaultPerson: PersonDataType = {
  name: null,
  dateTime: null,
  place: null,
  gmt: null,
}

export default class Solar {
  constructor() {
    makeAutoObservable(this);
  }

  person: PersonDataType = defaultPerson
  activeFormat: FormatsType = FORMAT_DAYS
  solarId: number | null = null
  isPrinting: boolean = false

  setPerson(data: PersonDataType) {
    this.person = data
    setSolarPersonDataLS(data)
  }

  getPerson() {
    const person = getSolarPersonDataLS()

    if (person) {
      return person
    } else {
      return this.person
    }
  }

  getPersonData() {
    const data: any = {}
    const person = this.getPerson()
    const {date, time} = toDateTime(person.dateTime)

    data.name = person.name
    data.date = date
    data.time = time
    data.place1 = person.place.name
    data.place2 = person.location.name
    data.lat1 = person.place.lat
    data.lon1 = person.place.lon
    data.lat2 = person.location.lat
    data.lon2 = person.location.lon
    data.gmt = person?.gmt

    return data
  }

  resetSolar() {
    this.person = defaultPerson
    setSolarPersonDataLS('')
  }

  async getSolar(purchasedSolarId: number) {
    try {
      const result = await api.indiGetSolar({purchasedSolarId})
      this.solarId = result.id
      return result
    } catch(e) {
      console.error(e)
    }
  }

  async freeChargeSolar(data: any) {
    try {
      const result = await api.indiCalculateFreeSolar(data)
      return result
    } catch(e) {
      console.error(e)
    }
  }

  async getShareToken(solarId: string) {
    try {
      const result = await api.indiGetSolarShareToken({solarId})
      return result
    } catch(e) {
      console.error(e)
    }
  }

  async getSharedSolar(token: string) {
    try {
      const result = await api.indiGetSharedSolar({token})
      return result
    } catch(e) {
      console.error(e)
    }
  }

  setActiveFormat(value: FormatsType) {
    this.activeFormat = value
  }

  async updateSolar( purchasedSolarId: number, date: string, time: string, place: string, lon: number, lat: number) {
    try {
      const result = await api.indiUpdateSolar({ purchasedSolarId, date, time, place, lon, lat})
      return result
    } catch(e) {
      console.error(e)
    }
  }

  getStatus(start: string, end: string, type: 'SOLAR' | 'product-card'): {text: string; type: string} | undefined {
    const isStarted = dayjs().isAfter(start)
    const isEnded = dayjs().isAfter(end)
    const isTakingPlace = dayjs().isAfter(start) && dayjs().isBefore(end)

    if (isTakingPlace) {
      const timeLeft = dayjs(dayjs(end).diff(dayjs(), 'ms',true)).format('HH:MM')

      return {text: type === 'SOLAR' ? timeLeft : 'Сейчас идет', type: 'takePlace'}
    }

    if (isEnded) {
      const period = `${dayjs(start).format('DD MMM')} — ${dayjs(end).format('DD MMM, YYYY')}`
      return {text: type === 'SOLAR' ? 'Этап прошел' : period, type: 'end'}
    }

    if (!isStarted) {
      const formattedStart = dayjs(start).format('DD MMM YYYY')
      return  {text: type === 'SOLAR' ? '' : formattedStart, type: 'beforeStart'}
    }
  }

  setIsPrinting(value: boolean) {
    this.isPrinting = value
  }
}
