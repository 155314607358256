import { AppType, ChronosApi, S3Urls } from "../libs";

export const s3urls = new S3Urls();

class Api extends ChronosApi {
  constructor() {
    super(process.env.REACT_APP_BACKEND as string, process.env.REACT_APP_AUTH as string, AppType.INDI);
  }
}

export default new Api();


export const HTTPMessages: {[key: string]: string} = {
  "wrong channel": 'Неправильный формат номера телефона',
  "already exists": 'Номер телефона уже зарегистрирован',
  "exist": 'Номер телефона уже зарегистрирован',
  "token expired": 'Код стал недействительным. Запросите новый код',
  "invalid token": 'Код введен неверно',
  "user not found": 'Пользователь не найден',
  "not_found": 'Пользователь не найден',
  "invalid_credentials": 'Неверный логин или пароль',
  "too many attempts": 'Слишком много попыток ввода. Запросите новый код',
  "too often": "Слишком частые запросы на восстановление",
	'the user has already used the trial': 'Вы уже использовали пробную версию Chronos Plus',
	'blocked': 'Слишком много попыток входа, попробуйте через 3 минуты',

  default: 'Что-то пошло не так. Обратитесь в поддержку'
}

export const getHTTPMessage = (errors: string[]): string => {
  return errors.map(key => {
    return HTTPMessages[key.toLowerCase()] || HTTPMessages.default
  }).join(',')
}
