import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";
import { ArrowRight } from "../../assets/icons/system/system-icons";
import api from "../../helpers/api";
import { SOLAR } from '../../modules/products/helpers/constants';
import { CustomButton } from "../ui/legaсy/BottomButton";
import { toUTCDateTimeFormat } from '../../helpers/dates';
import { DateTimeInput, IPlace, IPlaceEx, Input, InputContainer, PlaceInput, TInputMode, nowISOString, toDateTime } from '../../libs';

export interface IPersonalForm {
  name: string;
  dateTime: string;
  place: IPlace | null;
  location?: IPlace | null;
}

const defaultPlace: IPlace = {
  name: '',
  lat: 0,
  lon: 0
};

type blockedFieldsType = 'name' | 'date' | 'time' | 'place' | 'location'

export default React.memo(function PersonalForm({
  data,
  onSubmit,
  submitTitle = 'Продолжить',
  isEdit = false,
  className = '',
  product,
  blockedFields = [],
  dateOptions,
  originDateTime,
}: {
  data?: IPersonalForm;
  submitTitle?: string;
  isEdit?: boolean;
  onSubmit: (formData: IPersonalForm) => void;
  className?: string;
  product?: string;
  blockedFields?: blockedFieldsType[]
  dateOptions?: { value: string, label: string }[]
  originDateTime?: string
}) {

  const [name, setName] = React.useState('');
  const [place, setPlace] = React.useState<IPlace | null>(defaultPlace);
  const [dateTime, setDateTime] = React.useState(nowISOString());
  const [location, setLocation] = React.useState<IPlace | null>(defaultPlace);

  const isEditPurchasedSolar = isEdit && product === SOLAR && dateOptions && originDateTime

  const [messages, setMessages] = React.useState<{
    name: string | undefined;
    place: string | undefined;
    location: string | undefined;
    date: {
      message: string | undefined;
      invalid: boolean;
    };
    time: {
      message: string | undefined;
      invalid: boolean;
    };
  }>({
    name: undefined,
    place: undefined,
    location: undefined,
    date: {
      message: undefined,
      invalid: false
    },
    time: {
      message: undefined,
      invalid: false
    }
  });

  const disabled = !name || !place!.name || !place!.lat || !place!.lon || messages.date.invalid || messages.time.invalid || (product === SOLAR && (!location!.name || !location!.lat || !location!.lon))

  React.useEffect(() => {
    setName(data?.name || '');
    setPlace(data?.place || defaultPlace);
    setDateTime(data?.dateTime || dayjs().add(dayjs().utcOffset(), 'minute').toISOString());
    setLocation(data?.location || defaultPlace);
  }, [data]);

  const checkModeForFields = (obj: any, field: string): TInputMode => {
    const currentField = (obj as any)[field];
    return (currentField?.invalid || currentField?.length) ? 'error' : 'normal';
  }

  const checkSolarDateTime = (value: string) => {
    const diff = dayjs.utc(originDateTime).diff(dayjs.utc(value), 'hours');
    return Math.abs(diff) <= 48;
  }

  const fieldsChangeHandler = (field: string) => (value?: any) => {
    let message: any = undefined;

    switch (field) {
      case 'name':
        const name = (value as string).trim();
        if (/^[a-z,а-я,ё,\s]*$/i.test(name)) {
          message = name.length === 0 ? 'Имя не может быть пустым' : undefined;
        } else {
          message = 'В имени могут быть только буквы';
        }
        setName(value);
        break;

      case 'place':
        if (typeof value === 'object') {
          setPlace(value);
        } else {
          setPlace({ name: value, lat: 0, lon: 0 });
          message = value.length ? 'Выберите город из списка' : undefined;
        }

        break;

      case 'location':
        if (typeof value === 'object') {
          setLocation(value);
        } else {
          setLocation({name: value, lat: 0, lon: 0});
          message = value.length ? 'Выберите город из списка' : undefined;
        }

        break;

      case 'date':
        const msg = 'Введите корректную дату';
        if (value) {
          const [year] = value?.split('T')?.[0]?.split('-') || [null];
          const lessYear = Boolean(Number(year) < 1900);
          const moreYear = Boolean(Number(year) > dayjs().year());

          if (lessYear || moreYear) {
            message = {
              message: lessYear ? `${msg} (год рождения не может быть меньше 1900)` : (moreYear ? 'Введите дату в прошлом' : undefined),
              invalid: true
            };
          } else {
            const date = value && toDateTime(value).date
            const time = data?.dateTime ? toDateTime(dayjs(data?.dateTime).toISOString()).time : '00:00:00'
            const dateTime = toUTCDateTimeFormat(date, time)

            setDateTime(dateTime);
            message = {
              message: undefined,
              invalid: false
            }

            if (isEditPurchasedSolar) {
              if (!checkSolarDateTime(dateTime)) {
                field = 'time'
                message = {
                  message: 'Введите корректное время',
                  invalid: true
                }
              }
            }


          }
        } else {
          message = { message: msg, invalid: true };
        }
        break;

      case 'time':
        if (!value || dayjs.tz(value) > dayjs()) {
          message = {
            message: 'Введите корректное время',
            invalid: true
          }
          break
        }

        if (isEditPurchasedSolar) {
          if (!checkSolarDateTime(value)) {
            message = {
              message: 'Введите корректное время',
              invalid: true
            }
            break
          }
        }

      if (value) {
          setDateTime(value);
          message = {
            message: undefined,
            invalid: false
          }
        } else {
          message = {
            message: 'Нужно ввести время',
            invalid: true
          }
        }
    }

    setMessages(state => ({ ...state, [field]: message }));
  }

  const onFormSubmit = () => {
    onSubmit?.({name: name.trim(), dateTime, place, location});
  }

  const checkBlock = (type: blockedFieldsType) => {
    return blockedFields.includes(type)
  }

  return (
    <PersonalFormContainer className={`${className} personal-form`}>
      <CustomInputContainer label='Имя' message={messages.name} disabled={checkBlock('name')}>
        <Input
          name="username"
          value={name}
          size='big'
          placeholder='Ваше имя'
          onChange={fieldsChangeHandler('name')}
          mode={checkModeForFields(messages, 'name')}
          disabled={checkBlock('name')}
        />
      </CustomInputContainer>

      <CustomInputContainer label='Дата рождения' message={messages.date.message} disabled={checkBlock('date')}>
        <DateTimeInput
          type='date'
          size='big'
          value={dateTime}
          onChange={fieldsChangeHandler('date')}
          onInvalid={fieldsChangeHandler('date')}
          mode={checkModeForFields(messages, 'date')}
          hideIcon={true}
          utcMode
          disabled={false}
          options={dateOptions}
        />
      </CustomInputContainer>

      <CustomInputContainer label='Время рождения ' message={messages.time.message} disabled={checkBlock('time')}>
        <DateTimeInput
          type='time'
          size='big'
          value={dateTime}
          onChange={fieldsChangeHandler('time')}
          onInvalid={fieldsChangeHandler('time')}
          mode={checkModeForFields(messages, 'time')}
          hideIcon={true}
          utcMode
          disabled={checkBlock('time')}
        />
      </CustomInputContainer>

      <CustomInputContainer label='Место рождения' message={messages.place} disabled={checkBlock('place')}>
        <PlaceInput
          size='big'
          placeholder='Населенный пункт, область, страна'
          value={place!.name}
          place={place}
          onSelect={fieldsChangeHandler('place')}
          // onChange={fieldsChangeHandler('place')}
          asyncDataFn={api.places.bind(api)}
          asyncDetailFn={api.place.bind(api) as () => Promise<IPlaceEx>}
          mode={checkModeForFields(messages, 'place')}
          lang="ru"
          disabled={checkBlock('place')}
        />
      </CustomInputContainer>

      {product === SOLAR && <>
          <CustomInputContainer label='Место проведения' message={messages.location} disabled={checkBlock('location')}>
              <PlaceInput
                  size='big'
                  placeholder='Населенный пункт, область, страна'
                  value={location!.name}
                  place={location}
                  onSelect={fieldsChangeHandler('location')}
                  // onChange={fieldsChangeHandler('location')}
                  asyncDataFn={api.places.bind(api)}
                  asyncDetailFn={api.place.bind(api) as () => Promise<IPlaceEx>}
                  mode={checkModeForFields(messages, 'location')}
                  disabled={checkBlock('location')}
                  lang="ru"
              />
          </CustomInputContainer>
          <Info>Место проведения влияет на точность рассчета солярного дня рождения</Info>
      </>}

      <CustomButton
        disabled={disabled}
        name="submit-button"
        onClick={onFormSubmit}
        color="gradient"
        style={{ opacity: 1 }}
        fixedPosition={false}
      >
        <ButtonContent>{isEdit ? 'Изменить данные' : submitTitle} <ArrowRight /></ButtonContent>
      </CustomButton>
    </PersonalFormContainer>
  );
});

const PersonalFormContainer = styled.div`
  position: relative;
`;

const CustomInputContainer = styled(InputContainer)<{ disabled: boolean }>`
  /* max-height: 5rem; */

  & + & {
    margin-top: 1rem;
  }

  label {
    padding-left: 0.1rem;
    margin-bottom: 1rem;
  }
  input {
    color: ${p => p.disabled ? 'var(--text-third)' : 'var(--text-primary)'};
    top: -5px;
    font-family: 'Apercu Pro', sans-serif;
    padding-bottom: 0.75rem;
    border-bottom: 0.125rem solid var(--border);
  }
  .ui-dropdown:not(.mobile) {
    left: 10px;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--color-white);
  height: 2.3rem;
  font-size: 0.8rem;
  letter-spacing: 0.2px;
  margin-top: 0.1rem;

  & svg {
    margin-left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
`

const Info = styled.div`
  color: var(--text-third);
  font-size: 0.875rem;
`
