export type TSignColor = "land" | "air" | "water" | "fire";

export const getSignColor = (sign: number): TSignColor => {
  const signs: {
    [key: number]: {element: TSignColor}
  } = {
    0: { element: 'fire' },
    1: { element: 'land' },
    2: { element: 'air' },
    3: { element: 'water' },
    4: { element: 'fire' },
    5: { element: 'land' },
    6: { element: 'air' },
    7: { element: 'water' },
    8: { element: 'fire' },
    9: { element: 'land' },
    10: { element: 'air' },
    11: { element: 'water' }
  };

  return signs[sign]?.element;
}