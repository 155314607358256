export const content = [
  {
    title: 'Что влияет на потенциал',
    text: 'Потенциал дня отражает соотношение между благоприятными и напряженными событиями дня',
  },
  {
    title: 'Как формируется потенциал',
    text: 'Например, если из 10 событий вашего дня 8 благоприятные и 2 напряженные, потенциал составит 80%. Это значит, что день может быть благоприятным, но с возможными вызовами',
  },
  {
    title: 'Низкий потенциал',
    text: 'Низкое значение не предвещает плохой день, но указывает на возможные риски. Следуя рекомендациям и избегая нежелательных действий, вы сможете компенсировать эти риски в течении дня',
  },
]
