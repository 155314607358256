import React from 'react';
import { isMobile } from 'react-device-detect';
import styled, { css } from "styled-components";
import { CategoryType } from '../../../modules/products/products/Synastry/types';
import SwiperItem from "./SwiperItem";

export interface ISwipeTab {
  key: CategoryType
  title: string
}

interface ISwiperProps {
  tabs: ISwipeTab[]
}

export default React.memo(function Swiper(props: ISwiperProps) {
  return (
    <Container isMobile={isMobile} className={'no-print'}>
      {props.tabs.map((tab, index) => {
        return <SwiperItem key={tab.key} tab={tab} index={index}/>
      })}
    </Container>
  );
})

const Container = styled.div<{isMobile: boolean}>`
  display: flex;
  justify-content: left;

  width: 100%;
  overflow-x: auto;
  /* position: fixed; TODO: Xo13*/
  top: 4rem;
  left: 0;
  z-index: 5;
  padding-bottom: 1rem;
  /* padding-left: 2.8rem; TODO: Xo13*/
  padding-right: 1rem;
  box-sizing: border-box;

  ${props => props.isMobile && css`
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

    padding-left: 1rem;
  `};

  ${props => !props.isMobile && css`
    ::-webkit-scrollbar {
      height: 0.15rem !important;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--scrollbar-thumb);
      -webkit-border-radius: 2px;
    }

    ::-webkit-scrollbar-track {
      background: var(--scrollbar-track);
      -webkit-border-radius: 2px;
    }
  `}
`
