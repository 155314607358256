import dayjs from 'dayjs';
import { useMemo } from 'react';
import styled from 'styled-components';

import { getFormattedInterval } from '../../../helpers/dates';
import store from '../../../store/Store';
import Actions from './Actions';

import { windowsStore } from 'src/store/Windows';
import { ArrowRight as ArrowIcon, ClockIcon2 as ClockIcon, PinIcon } from '../../../assets/icons/system/system-icons';
import { PinButton, Row } from '../../../components/legacy';
import PrognosesTrend from '../prognoses-trend';


export default function OtherTrends(
  {
    icon,
    title,
    excludeId,
    extended = false,
    isPinned = false,
    togglePin = () => {},
  }: {
    icon?: any;
    title?: string;
    excludeId?: string;
    extended?: boolean;
    isPinned?: boolean;
    togglePin?: (v: any) => void;
  }
) {
  const trendsData = useMemo(() => {
    return excludeId
      ? store.prognoses.getOtherTrendsById(+excludeId)
      : store.prognoses.getTrends();
  }, [excludeId]);

  const handleClick = (id: number) => {
    //window.location.href = `/prognoses/trends/${id}`;
    windowsStore.open(
      `WINDOW_ID_PROGNOSES_TREND_${id}`,
      <PrognosesTrend trendId={String(id)} />,
      undefined,
      false,
      true,
    )
  }

  if(!trendsData?.length) {
    return null;
  }

  return (
    <>
      { (title || icon) &&
          <Row mb="0" align="center" justify="space-between">
            <Row mb="0" align="center" gap="0.75rem">
              {icon}
              {isPinned && <PinButton><PinIcon /></PinButton>}
              {title && <Title>{title}</Title>}
            </Row>
            { !extended &&
                <Actions
                  actions={[
                    {
                      label: isPinned ? 'Открепить' : 'Закрепить',
                      action: togglePin,
                    },
                  ]}
                />
            }
          </Row>
      }
      <Container>
        { trendsData?.map(trend => {
            const period = getFormattedInterval(trend.period.start, trend.period.end);
            const trendStart = dayjs(trend.period.start, 'DD.MM.YYYY HH:mm:ss');
            const isStarted = dayjs().isAfter(trendStart);
            const timeLeft = isStarted
              ? 'Уже началось'
              : `До начала ${dayjs().to(trendStart, true)}`;

            return (
              <Item clickable={true} onClick={() => handleClick(trend.id)} key={trend.id}>
                <InfoWrapper>
                  { trend.title
                      ? <>
                          <ItemTitle>{trend.title}</ItemTitle>
                          <ItemTime started={isStarted}>
                            <ClockIcon />
                            {timeLeft} • {period}
                          </ItemTime>
                        </>
                      : <ItemTitle>{period}</ItemTitle>
                  }
                </InfoWrapper>
                {trend.title && trend.id &&
                  <LinkWrapper>
                    <ArrowIcon />
                  </LinkWrapper>
                }
              </Item>
            )
        })}
      </Container>
    </>
  );
}

const Title = styled.h3`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.1666;
  color: var(--color-black);
  margin: 0;
`;

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #F3F3F3;
  box-shadow: 0px 3.2px 14px rgba(0, 0, 0, 0.06), 0px 0.6px 1.8px rgba(0, 0, 0, 0.04);
  border-radius: 1rem;
  overflow: hidden;
`;

const Item = styled.div<{clickable?: boolean;}>`
  border-bottom: 1px solid #F3F3F3;
  padding: 0.875rem 1.25rem 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${p => p.clickable ? 'pointer' : 'initial'};

  &:last-child {
    border-bottom: none;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const LinkWrapper = styled.div`
  color: var(--text-secondary);
`;

const ItemTitle = styled.h4`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875;
  color: var(--text-primary);
  margin: 0;
`;

const ItemTime = styled.p<{started?: boolean;}>`
  font-size: 0.875rem;
  line-height: 1.5;
  color: ${p => p.started ? 'var(--color-green)' : 'var(--text-secondary)'};
  margin: 0;

  display: inline-flex;
  align-items: center;
  gap: 0.5rem;

  & > svg {
    width: 1rem;
    height: 1rem;
    stroke: ${p => p.started ? 'rgba(81, 135, 25, 0.37)' : 'var(--text-third)'};
  }
`;
