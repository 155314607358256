import styled from "styled-components";

interface HrProps {
  className?: string
}

export const Hr = (props: HrProps) => {
  const {
    className,
  } = props

  return (
    <Line className={className} />
  )
}

const Line = styled.hr`
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 0.5rem;
`;
