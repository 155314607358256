import React from 'react';
import styled from 'styled-components';
import { Loader as uiLoader } from '../../libs';

export default function Loader(props: {
  size?: string;
}) {
  return <StyledLoader size={props.size}/>;
}

const StyledLoader = styled(uiLoader)`
  position: fixed;
  top: 0;
  left: 0;
`;
