import { makeAutoObservable } from 'mobx';
import api from '../helpers/api';
import { setPromocodeDataLS, setPromocodeLS, setPromocodeStateLS } from "./localStorageHelpers";
import store from './Store';
import { IPromocode, show } from '../libs';
import { IProductCode } from './Payments';

export type PromocodeErrorsType = {
  'PROMO_NOT_FOUND': string
  'PROMO_ALREADY_USED': string
  'PROMO_HAVE_INDI_ALREADY': string
  'PROMO_EXPIRED': string
  'PROMO_INCORRECT': string
  'PROMO_USELESS': string
}

export const promocodeErrors: {
  [key: string]: string
} = {
  'PROMO_NOT_FOUND': 'Промокода не существует',
  'PROMO_ALREADY_USED': 'Промокод уже использован',
  'PROMO_HAVE_INDI_ALREADY': 'Промокод уже использован',
  'PROMO_EXPIRED': 'Срок действия промокода истек',
  'PROMO_INCORRECT': 'Промокод поврежден. Обратитесь в тех. поддержку',
  'PROMO_USELESS': 'Все построения из промокода уже куплены'
}

export default class Promocode {
  constructor() {
    makeAutoObservable(this);
  }

  promoCode = '';
  promoData: IPromocode | null = null
  promoError: string = ''

  async checkPromocode(promo: string, headers?: { tentative: string }, subscription?: boolean, product?: IProductCode): Promise<{ type: 'success' | 'error'; text?: string }> {
    try {
      const email = store.payments.email || '';
      const result = await api.checkPromocode(promo, headers, email)
      const error = result.actions.app !== 'indi' || (subscription && !result.actions.subscription)

      if (error) {
        throw Error('PROMO_NOT_FOUND');
      }

      if (product && result.actions.products !== product) {
        throw Error('PROMO_NOT_FOUND');
      }

      this.promoData = result
      setPromocodeDataLS(result)

      return {
        type: 'success',
        text: 'Промокод применён',
      }
    } catch (e: any) {
      api.sendNotify(`promo error 1: ${e.message}; promo: ${promo}`);
      this.promoError = promocodeErrors[e.message as keyof PromocodeErrorsType]
      return {
        type: 'error',
        text: promocodeErrors[e.message as keyof PromocodeErrorsType],
      }
    }
  }

  async usePromocode(promo: string, formationId: number | undefined = undefined, headers?: { tentative: string }, email?: string) {
    try {
      const result = await api.usePromocode(promo, formationId, headers, email)
      return result
    } catch (e: any) {
      api.sendNotify(`promo error 2: ${e.message}; email: ${email}`);
      this.promoError = promocodeErrors[e.message as keyof PromocodeErrorsType]
      show({
        text: promocodeErrors[e.message as keyof PromocodeErrorsType],
        type: 'error'
      })
      throw e
    }
  }

  async cancelPromocode(promoId: string | number, data?: any, headers?: { tentative: string }) {
    await api.cancelPromocode(promoId, data, headers)
  }

  resetPromocode() {
    this.promoData = null
    setPromocodeLS('')
    setPromocodeStateLS('')
    setPromocodeDataLS('')
  }

  setPromoError(value: string) {
    this.promoError = value
  }
}
