import React from 'react';
import styled from 'styled-components';
import miscBannerImg from '../../../assets/images/banner_20241006.png';
import { gtmSend } from '../../../helpers/url';
import store from '../../../store/Store';

export const MainPageMiscBanner = () => {
    const userId = store.sessionData?.id;

    const onClick = () => {
        gtmSend({'event': `indi_banner_veb_osenravn`, 'user_id': userId ? userId : ''})
        setTimeout(() => {
            window.open('https://lp.lablife.pro/rassvet24?utm_source=Chronos&utm_medium=banner&utm_campaign=chronos_ww_lzh-rassvet_andreev&utm_term=application');
        }, 500)
        
    }
    return <Container onClick={onClick}>
        <img src={miscBannerImg} alt={"День осеннего равноденствия"} />
    </Container>
}

const Container = styled.div`
    position: relative;
    box-sizing: border-box;
    margin: 1rem 1rem 0;
    border-radius: 1rem;
    overflow: hidden;
    cursor: pointer;

    img {
        width: 100%;
        display: block;
    }
`
