import { useState } from 'react';
import styled from 'styled-components';
import api from '../../../helpers/api';
import store from '../../../store/Store';
import dayjs from 'dayjs';
import { FeedbackForm, Titles } from '../../../components/FeedbackForm';
import { Indi } from '../../../libs';


const titles: Titles = {
	question: 'Вам было полезно?',
	feedback: 'Спасибо за ответ. Напишите почему вы так считаете?',
	thanks: 'Спасибо за ответ!',
}

export interface MyDayFeedbackProps {
	date: string
	feedback?: Indi.Events.Feedback
	className?: string
}

export const MyDayFeedback = (props: MyDayFeedbackProps) => {
  const [step, setStep] = useState<keyof Titles>('question');
  const [text, setText] = useState('');
  const { date, feedback } = props;

  const sendFeedback = (isUseful: boolean) => {
    try {
      const data: Indi.sendFeedback.Request = {
        date,
        isUseful,
      };

      if (text) data.text = text;

      api.sendFeedback(data);
      store.events.loadEventsByDate(dayjs(date));
    } catch (e) {
      console.error(`Sending feedback error: ${e}`);
    }
  };

  const positive = () => {
    sendFeedback(true);
    setStep('thanks');
  };

  const negative = () => {
    setStep('feedback');
  };

  const submit = () => {
    sendFeedback(false);
    setStep('thanks');
  };

  if (feedback) {
    return (
      <Container className={props.className ?? ''}>
        <Title>{titles['thanks']}</Title>
      </Container>
    );
  }

  return (
    <FeedbackForm
      step={step}
      titles={titles}
      text={text}
      positiveClick={positive}
      negativeClick={negative}
      submitClick={submit}
      textChange={setText}
    />
  );
};

const Container = styled.div`
  margin-bottom: 2rem;
`;

const Title = styled.div`
  color: var(--text-third);
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;
