import dayjs from 'dayjs';
import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { searchParams } from 'src/helpers/url';
import { Pin } from '../../../../components/ui/Pin';
import { observer } from 'mobx-react-lite';
import { Indi } from "src/libs";
import styled, { css } from 'styled-components';
import { FlexContainer } from '../../../../components/layout/elements';
import { Colors, Positions, Tip } from '../../../../components/Tip';
import { Button } from '../../../../components/ui/Button/Button';
import { getMyDayLS, setMyDayLS, MyDayTips } from '../../../../store/localStorageHelpers';
import store from '../../../../store/Store';
import { eventsOptions } from '../../helpers/events';
import { MyDayEventNew } from './my-day-event-new';
import { MyDayNoEvents } from './my-day-no-events';

export const MyDayEventsGroup = observer(({
  group,
  events,
  currentDate,
  openEvent,
	pinEventsGroup,
	pinned,
  className = '',
  showTip,
  closeTip,
  eventsContainerRef,
  index,
  firstMyDayItem,
}: {
  group: Indi.Events.Spheres
  events: Indi.Events.Event[],
  currentDate: dayjs.Dayjs,
  openEvent: (id: number) => void
	pinEventsGroup?: (pinned: boolean) => void
	pinned?: boolean
  className?: string
  showTip?: boolean
  closeTip?: () => void
  eventsContainerRef?: React.RefObject<HTMLDivElement>
  index?: number
  firstMyDayItem?: boolean
}) => {
	const [collapsed, setCollapsed] = useState(true)
	const [heightEventContainer, setHeightEventContainer] = useState(0)
  const [tipPosition, setTipPosition] = useState(0)

	const containerRef = useRef<HTMLDivElement | null>(null)
	const refEvent = useRef<HTMLDivElement | null>(null)

  const sp = searchParams()

	useLayoutEffect(() => {
		refEvent?.current && setHeightEventContainer(refEvent?.current?.offsetHeight)
	}, [refEvent, currentDate])

  const checkEventSP = useCallback(() => {
    const eventId = sp.get('eventId')

    if (
      eventId &&
      events.find(event => event.id === Number(eventId))
    ) {
      openEvent(Number(eventId))
    }
  }, [sp, events, openEvent])

  useEffect(() => {
    checkEventSP()
  }, [checkEventSP])
  useEffect(() => {
    if (showTip && eventsContainerRef?.current && containerRef.current) {
      const position = containerRef.current.getBoundingClientRect().top - eventsContainerRef?.current.getBoundingClientRect().top - 50
      setTipPosition(position)
    }
  }, [showTip, eventsContainerRef, containerRef])

  useEffect(() => {
    if (containerRef.current && index === 0 && firstMyDayItem) {
      store.onboarding.setFirstEventsRef(containerRef)
    }
  }, [containerRef, index, firstMyDayItem])

  const pin = useCallback(() => {
		pinEventsGroup && pinEventsGroup(Boolean(pinned))
	}, [pinEventsGroup, pinned])

  if(events.length <= 0) return null;

  const eventOptions = eventsOptions[group];
  const SmallIcon = eventOptions.iconSmall;

	const showAllEvents = () => {
    const myDayDataLS = getMyDayLS()

		if (collapsed && events.length > 1) setCollapsed(false)

    if (!myDayDataLS?.usedAccordion) {
      const newData = {
        ...myDayDataLS,
        usedAccordion: true
      }

      setMyDayLS(newData)
    }

    if (!myDayDataLS?.onboarding?.showedTips?.includes(MyDayTips.ACCORDION)) {
      closeTip?.()
    }
	}

	const collapseEvents = () => setCollapsed(true)

	const openEventHandler = (id: number) => {
		if (!collapsed || events.length === 1) openEvent(id)
	}

  const noEventsType =  group === 'important' ? group : 'group';

	const getGap = () => {
		const stacks = events.length > 3 ? 3 : events.length

		switch (stacks) {
			case 3:
				return 75
			case 2:
				return 65
			default:
				return 55
		}
	}

	return <Container collapsed={collapsed}
										height={heightEventContainer}
										gap={getGap()}
                    className={className}
                    ref={containerRef}>
    <Header>
      <HeaderLeft className={group !== 'important' ? 'my-day-events-group-header' : ''}>
        <SmallIcon/>
        <span style={{color: group === 'important' ? 'var(--color-gray)' : eventOptions.textColor}}>{eventOptions.title}</span>
      </HeaderLeft>
      <HeaderRight>
				{!collapsed && <ButtonShowLess onClick={collapseEvents}>Показать меньше</ButtonShowLess>}
        {group !== 'important' && <Pin pinned={Boolean(pinned)} setPinned={pin}/>}
      </HeaderRight>
    </Header>

    {events.length === 0 && <MyDayNoEvents type={noEventsType} currentDate={currentDate} />}

    {events.length > 0 && <Events onClick={showAllEvents}>
      {events.map((event, index) => <MyDayEventNew
				key={`${group}_my-day-event-new_${index}`}
        event={event}
        openEvent={openEventHandler}
        current={currentDate}
        isImportant={Boolean(event.isImportant)}
				collapsed={collapsed}
				index={index}
				refEvent={refEvent}
				height={heightEventContainer}
      />)}
    </Events>}

    <Footer>
      {!collapsed && <ButtonShowLess onClick={collapseEvents}>Показать меньше</ButtonShowLess>}
    </Footer>

    {
      showTip && tipPosition !== 0 && (
        <TipWrapper tipPosition={tipPosition}>
          <Tip
            color={Colors.DARK}
            text={'Нажмите на стопку, чтобы просмотреть все события этой сферы'}
            onClose={closeTip}
            onClickTip={closeTip}
            trianglePosition={Positions.BOTTOM_CENTER}
          />
        </TipWrapper>
      )
    }
  </Container>
})

const Container = styled(FlexContainer)<{collapsed: boolean, gap: number, height: number}>`
  flex: 0.75rem;
  flex-direction: column;
  height: 100%;

	${p => (p.collapsed && p.height > 0) && css`
		min-height: ${`${p.height + p.gap}px`};
    overflow: hidden;
	`}
`

const Header = styled(FlexContainer)`
  align-items: center;
  margin-bottom: 0.75rem;

  > svg {
    &:first-child {
      margin-right: 0.375rem;
    }
  }
`

const HeaderLeft = styled(FlexContainer)`
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }

  span {
    white-space: nowrap;
  }
`

const HeaderRight = styled(FlexContainer)`
  justify-content: end;
  align-items: center;

  margin-left: auto;
`

const Events = styled(FlexContainer)`
  flex-direction: column;
  gap: 0.75rem;
	position: relative;
`

const Footer = styled(FlexContainer)`
  justify-content: end;
  align-items: center;

  width: 100%;
	padding: 0.5rem 0;
`

const ButtonShowLess = styled(Button)`
  display: block;
  width: fit-content;
  padding: 0.375rem 0.5rem;
  color: var(--text-primary);
  font-size: 0.75rem;
  font-weight: 500;
  background: var(--color-white);
  filter: var(--shadow);
`

const TipWrapper = styled.div<{tipPosition: number}>`
  position: absolute;
  top: ${p => `${p.tipPosition}px`};
  z-index: var(--z-index-modal);
`
