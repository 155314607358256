import {makeAutoObservable} from 'mobx';
import api from '../helpers/api';
import { windowsStore } from "./Windows";
import { WINDOW_ID_AUTH, Auth as AuthWindow } from "../modules/profile/Auth";
import store from './Store';
import jwt from 'jsonwebtoken'
import { IUsersPullBannerData } from './Banners/type/banners';
import { CurrencyCode, IAuth, Indi, Region } from '../libs';


export interface Offer {
  available: boolean
  type: Indi.IndiUseOffer.Offers

}
export interface IAuthIndi extends IAuth {
  indi?: {
    autoPayment: boolean | null;
    expiryDate: string | null;
    trialExpiryDate: string | null;
    tariffId: number | null;
    subscriptionId: number | null;
    isTrial: boolean;
    amount: number;
    cardLastFour: number;
    startDate: string;
    customization: IUsersPullBannerData;
		country: Region
		currencyCode: CurrencyCode
    offers: Offer[]
    cardType: string
  }
}

export default class Auth {
  constructor() {
    makeAutoObservable(this);
  }

  token: string = ''
  hash: string = ''

  userStatus: any = {};

  async signUpWithEmail(email: string, language: string) {
    try {
      const result = await api.indiSignUpWithEmail({email, language})
      this.setToken(result.token)
      this.setHash(result.hash)
    }
    catch(e) {
      console.error(`Error in sign up with email ${e}`)
    }
  }

  setToken(token: string) {
    this.token = token
  }

  setHash(token: string) {
    this.hash = token
  }

  setUserStatus(data: any) {
    this.userStatus = data;
  }

  goToAuth({ weak = false }: { weak?: boolean }) {
    try {
      windowsStore.open(
        WINDOW_ID_AUTH,
        <AuthWindow weak={weak} />,
        'fade'
      )
    } catch(e) {
      console.error(`Error in go to auth${e}`)
    }
  }

  async checkToken() {
    try {
      const token = store.lsGet('token').access
      const { exp } = jwt.decode(token) as { exp: number }
      const expirationDatetimeInSeconds = exp * 1000;

      if (Date.now() >= expirationDatetimeInSeconds) {
        const result = await store.refresh()
        result && localStorage.setItem('token', JSON.stringify(result.token))
      }

    } catch(e) {
        console.error(e)
    }
  }
}
