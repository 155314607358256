import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { MainPadding, WindowContainer } from '../../components/layout/elements';
import ButtonSwitcher from '../../components/ui/ButtonSwitcher';
import { groupByDate, sortByDate } from '../../helpers/dates';
import { gtmSend, searchParams } from '../../helpers/url';
import { IProductCode } from '../../store/Payments';
import { IProduct, IPurchasedProduct } from '../../store/Products';
import store from '../../store/Store';
import { windowsStore } from '../../store/Windows';
import ProductConfirmation from './components/Confirmation';
import EmptyPurchasedProducts from './components/EmptyPurchasedProducts';
import ProductCard from './components/products-card';
import PurchasedProductCard from './components/purchased-product-сard';
import { EMOTIONS, PRODUCTS_SECTION, SOLAR, SYNASTRY, WINDOW_ID_CONFIRMATION, productsWindowData, sectionsOptions } from './helpers/constants';
import Emotions from './products/Emotions/Emotions';
import Solar from './products/Solar/Solar';
import Synastry from './products/Synastry/Synastry';
import { useBackWindow } from '../../hooks/router-hooks';
import { ROUTES } from '../../helpers/routes';
import { history } from '../../MainRouter';
import { PartnerLinkTargets, Logging } from '../../libs';
import { WindowHeader } from '../windows/components/WindowHeader';
import { useMoveShutter } from '../../hooks/popup-hooks';


export const WINDOW_ID_PRODUCTS: string = 'WINDOW_ID_PRODUCTS';

export const openProducts = () => windowsStore.open(
  WINDOW_ID_PRODUCTS,
  <Products />,
  undefined,
  false,
  true,
)

export interface Discount {
	type: string
	value: number
}

export const Products = observer(() => {
  useBackWindow(ROUTES.PRODUCTS, WINDOW_ID_PRODUCTS);

  const [section, setSection] = useState(store.synastry.activeSection);
  const [discount, setDiscount] = useState({type: '', value: 0});

  const containerRef = useRef<HTMLDivElement | null>(null)

  const productsList = store.products.products;
  const purchasedProductsList = store.products.purchasedProducts.slice().sort(sortByDate);
  const groupedPurchasedProductsList = groupByDate(purchasedProductsList);
  const userId = store.sessionData?.id;
  const sp = searchParams();
	const partner = store.partner.partner
  const shutterRef = store.shutter.shutterRef

  const setSectionHandler = (section: PRODUCTS_SECTION) => {
    store.synastry.setActiveSession(section);
    setSection(section);
  }

  useMoveShutter(containerRef, shutterRef)

  useEffect(() => {
    section === 'catalog'
      ? store.products.getProducts()
      : store.isAuth && store.products.getPurchasedProducts()
      document.title = `Продукты • ${section === 'catalog' ? 'Каталог' : 'Купленные'}`;
  }, [section, store.isAuth]);

  useEffect(() => {
    if (sp.get('my')) setSectionHandler(PRODUCTS_SECTION.PURCHASED);

    history.push({pathname: '/products', search: sp.toString()})
    store.logger.startLoggingSession(Logging.Screens.PRODUCTS)

    return () => {
      history.push({pathname: '/', search: sp.toString()})
      store.logger.createLoggingSession(Logging.Screens.PRODUCTS)
    }
  }, [])

  useEffect(() => {
		setPartnerDiscount()
  }, [partner])

  const getProductComponent = (type: string, token: number) => {
    switch(type) {
      case SYNASTRY: {
        return <Synastry token={token}/>
      }
      case EMOTIONS: {
        return <Emotions token={token}/>
      }
      case SOLAR: {
        return <Solar token={token}/>
      }
    }
  }

  const setPartnerDiscount = async () => {
		const targets = [PartnerLinkTargets.ChronosPlus, PartnerLinkTargets.IndiProduct]

		targets.every(async target => {
			const result = await store.partner.setPartnerDiscount(target, partner)

			if (result.partnerDiscount > 0) {
				setDiscount({type: target, value: result.partnerDiscount})
				return false
			} else {
					return true
			}
		})
  }

  const onClickProductCard = (data: IProduct) => {
    store.products.selectProduct(data);
    gtmSend({'event': `indi_click-${productsWindowData[data.code].gtm}`, 'user_id': userId ? userId : ''})

    windowsStore.open(WINDOW_ID_CONFIRMATION, <ProductConfirmation product={data.code as IProductCode}/>)
  }

  const onClickPurchasedProductCard = (token: number, data: IPurchasedProduct) => {
    windowsStore.open(productsWindowData[data.code].purchased, getProductComponent(data.code, token))
  }

  return (
    <WindowContainer ref={containerRef}>
      <WindowHeader title='Продукты'/>
      <Container className='no-print'>
        <ProductsContainer>
          <StyledButtonSwitcher
            options={sectionsOptions}
            value={section}
            onChange={setSectionHandler}
          />

          {section === PRODUCTS_SECTION.CATALOG && productsList.map(product => {
            return (
              <ProductCard 
                key={product.id}
                data={product}
                onClick={() => {onClickProductCard(product)}}
                discount={discount}
              />
            );
          })}

          {section === PRODUCTS_SECTION.PURCHASED && groupedPurchasedProductsList.map((group, index) => {
            return (
              <React.Fragment key={`group_purchased_${index}`}>
                <GroupTitle>{group[0]}</GroupTitle>
                { 
                  group[1].map(product => <PurchasedProductCard key={product.id} data={product} onClick={() => {onClickPurchasedProductCard(product.id, product)}} />)
                }
              </React.Fragment>
            );
          })}

          {section === PRODUCTS_SECTION.PURCHASED && groupedPurchasedProductsList.length === 0 &&
            <EmptyPurchasedProducts/>
          }
        </ProductsContainer>
      </Container>
    </WindowContainer>
  );
})

const Container = styled(MainPadding)`
`
const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-bottom: 8rem;
`;

const GroupTitle = styled.div`
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--text-third);
  margin-bottom: 0.5rem;
`

const StyledButtonSwitcher = styled(ButtonSwitcher)`
	margin-bottom: 1rem;
`
