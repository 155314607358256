import styled from "styled-components"

interface PriceProps {
	className?: string
	isFree?: boolean
	text?: string
	price?: string
	discount?: string
	oldPrice?: string
}

export const Price = (props: PriceProps) => {
	const {
		text = 'Получить за'
	} = props

	return (
		<Container className={props.className}>
      {
				props.isFree
          ? <span>Получить бесплатно</span>
          : <>
              <span>{text}</span>
            	<span className={'price'}> {props.price}</span>
							{props.oldPrice && <span className={'oldPrice'}>{props.oldPrice}</span>}
           </>
			}
		</Container>
	)
}

const Container = styled.span`
  span {
    margin-right: 0.2rem;
  }

  .oldPrice {
    text-decoration: line-through;
		color: var(--text-secondary-white);
		margin-left: 0.35rem;
  }
`
