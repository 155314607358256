import {makeAutoObservable} from 'mobx';
import { getEmotionsPersonDataLS, setEmotionsPersonDataLS } from "./localStorageHelpers";
import Api from "../helpers/api";
import { IPlace, toDateTime } from '../libs';

export type PersonDataType = {
  name: string | null
  dateTime: string | null
  place: IPlace | null
  gmt?: string | null
}

const defaultPerson: PersonDataType = {
  name: null,
  dateTime: null,
  place: null,
  gmt: null,
}

export default class Emotions {
  constructor() {
    makeAutoObservable(this);
  }

  person: PersonDataType = defaultPerson
  emotionsId: number | null = null

  setPerson(data: PersonDataType) {
    this.person = data
    setEmotionsPersonDataLS(data)
  }

  getPerson() {
    const person = getEmotionsPersonDataLS()

    if (person) {
      return person
    } else {
      return this.person
    }
  }

  getPersonData() {
    const data: any = {}
    const person = this.getPerson()
    const {date, time} = toDateTime(person.dateTime)

    data.name = person.name
    data.date = date
    data.time = time
    data.place = person.place.name
    data.lat = person.place.lat
    data.lon = person.place.lon
    data.gmt = person?.gmt

    return data
  }

  resetEmotions() {
    this.person = defaultPerson
    setEmotionsPersonDataLS('')
  }

  async getEmotions(purchasedEmotionsId: number) {
    const result = await Api.indiGetEmotions({purchasedEmotionsId})
    this.emotionsId = result.id
    return result
  }

  async freeChargeEmotions(data: any, headers?: {tentative: string}) {
    const result = await Api.indiCalculateFreeEmotions(data, headers)
    return result
  }

  async getShareToken(emotionsId: string) {
    try {
      const result = await Api.indiGetEmotionsShareToken({emotionsId})
      return result
    } catch(e) {
      console.error(e)
    }
  }

  async getSharedEmotions(token: string) {
    try {
      const result = await Api.indiGetSharedEmotions({token})
      return result
    } catch(e) {
      console.error(e)
    }
  }
}
