import { useEffect, useRef, useState } from "react";
import store from "../store/Store";

export const useLS = (key: string, defaultValue: any = null, prefix: string = '') => {
  const prefixedKey = prefix
    ? [prefix, key].join('-')
    : key;

  const [val, setVal] = useState(store.ls?.[prefixedKey] || defaultValue);

  useEffect(() => {
    store.lsInit(prefixedKey, defaultValue);
  }, [])

  useEffect(() => {
    setVal(store.ls?.[prefixedKey])
  }, [store.ls?.[prefixedKey]])

  return [
    val,
    (newValue: any) => {
      store.lsSet(prefixedKey, newValue);
    },
    (newValue: any) => {
      store.lsSave(prefixedKey, newValue);
    },
    () => store.lsRemove(prefixedKey),
  ];
};

export default useLS;

export const useLSState = (key: string, defaultValue: any = null, prefix: string = '') => {
  const [item, setItem, saveItem, removeItem] = useLS(key, defaultValue, prefix);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if(firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  }, [item]);

  return [
    item,
    (newVal: any) => {
      saveItem(newVal);
      setItem(newVal);
    },
    removeItem,
  ];
};
