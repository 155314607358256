import dayjs from 'dayjs';
import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import { CameraIcon, UpdateIcon } from '../../../assets/icons/system/system-icons';
import { buttonStyle } from '../../../helpers/ui';
import { gtmSend } from '../../../helpers/url';
import store from '../../../store/Store';
import { Button, ISession, SessionFormat, SessionStatus } from '../../../libs';

interface IZoomButton {
  onClick: () => void,
  icon: React.ReactNode,
  text: string,
  disabled: boolean
  style?: CSSProperties
}

const ZoomButton = ({
  session,
  isPassed,
  canRate,
}: {
  session: ISession;
  isPassed: boolean;
  canRate: boolean;
}) => {
  const meeting = session.data.meeting;
  let buttons: IZoomButton[] = [];
  const timeLeft = `Начало ${dayjs(session.start).fromNow()}, в ${dayjs(session.start).format('HH:mm')}`;
  const leftFiveMinutes = dayjs(session.start).diff(dayjs(), 'minute') <= 5
  const userId = store.sessionData?.id;

  if (isPassed) {
    if (
      session.data.status === SessionStatus.PaidOut ||
      (session.data.status === SessionStatus.Finished && session?.data?.payment?.type === 'fictive' && !canRate)
    ) {
      if (meeting?.recordingUrls) {
        buttons = meeting?.recordingUrls.urls.reduce((sum: any, item, i, array) => {
          let buttonIndex = array.length > 1 ? (i + 1).toString() : '';

          return [...sum, {
            onClick: () => {
              window.open(item.video, '_blank');
            },
            icon: <CameraIcon />,
            text: `Посмотреть видеозапись ${buttonIndex}`,
            disabled: false
          }, {
            onClick: () => {
              window.open(item.audio, '_blank');
            },
            icon: null,
            text: `Прослушать аудиозапись ${buttonIndex}`,
            disabled: false
          }]
        }, []);
      } else {
        const after30Minutes = dayjs(session.start).add(session.data.duration + 30, 'minutes');

        if (after30Minutes.isAfter(dayjs())) {
          buttons = [{
            onClick: () => {
              window.location.reload();
              // gtmSend({ 'event': 'indi_consultation-page_join_click', 'user_id': userId ? userId : '' })
            },
            icon: <UpdateIcon />,
            text: 'Запись звонка загружается',
            disabled: false
          }]
        }
      }
    }
  } else if (!isPassed) {
    if (session.data.status === SessionStatus.Paid && session.data.format !== SessionFormat.Offline) {
      if (meeting?.joinUrl && leftFiveMinutes) {
        buttons = [{
          onClick: () => {
            window.location.href = meeting?.joinUrl!;
            gtmSend({ 'event': 'indi_consultation-page_join_click', 'user_id': userId ? userId : '' })
          },
          icon: null,
          text: 'Подключиться к консультации',
          disabled: false,
          style: {background: 'var(--main-gradient)'}
        }];
      } else {
        buttons = [{
          onClick: () => { },
          icon: null,
          text: timeLeft,
          disabled: true,
          style: { background: 'var(--color-situations-bg)', color: 'var(--color-purpur)' }
        }];
      }
    }
  }

  return <>
    {buttons.map((item: IZoomButton, index: number) =>
      <Button
        color="accent"
        style={{ ...buttonStyle, ...item.style }}
        onClick={item.onClick}
        disabled={item.disabled}
        key={index}
        size="big"
      >
        <ButtonIconContainer>{item.icon}</ButtonIconContainer>{item.text}
      </Button>
    )}
  </>;
}

export default ZoomButton;

const ButtonIconContainer = styled.span`
  margin-right: 0.5rem;
`
