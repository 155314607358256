import React from 'react';
import styled from "styled-components";
import { ShareIcon } from '../../../assets/icons/system/system-icons';
import {CustomButton} from "./Footer";

interface IShareProductProps {
  onClickShareButton(value: {top: number, left: number}): void
}

function ShareProduct(props: IShareProductProps) {
  const ref = React.useRef<HTMLSpanElement>(null);

  const onClickHandler = () => {
    const { top } = ref.current?.getBoundingClientRect()!
    const left = ref.current?.offsetLeft!

    props.onClickShareButton({top, left})
  }

  return (
    <ShareContainer>
      <div className={'icon-container'}><ShareIcon/></div>
      <div className={'text'}>Поделитесь результатом</div>

      <CustomButton color={'var(--color-gradus-blue)'}
                    onClick={onClickHandler}><span
        ref={ref}>Поделиться</span></CustomButton>
    </ShareContainer>
  );
}

export default ShareProduct;

const ShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  box-shadow: 0px 3.2px 14px rgba(0, 0, 0, 0.06), 0px 0.6px 1.8px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
`
