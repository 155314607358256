import React from 'react';
import { useEffect } from 'react';

import {useParams} from 'react-router-dom';
import ym from 'react-yandex-metrika';

type TinkoffStatus = 'success' | 'fail';

export default function TinkoffPayed() {
  const { id, status } = useParams<{ id: string, status: TinkoffStatus }>();

  useEffect(() => {
    let link = `/session/${id}`;
    switch(status) {
      case 'success':
        link += `?payed&token=weak`;
        ym('reachGoal','Confirm_button-click_successful-paid');
        break;
      case 'fail':
        ym('reachGoal','Confirm_button-click_fail-payment')
        break;
    }
    setTimeout(() => {
      window.location.href = link;
    }, 200)
  }, [])

  return <></>
}
