
import { makeAutoObservable } from 'mobx';
import { ReactNode } from 'react';
import { windowsStore, MAIN_WINDOWS_AND_POPUPS } from './Windows';


export type TPopup = {
  id: string;
  content: ReactNode,
  title: string,
  depth: number,
  background?: string,
  fixedHeader?: boolean,
  hight?: string,
}

class PopupsStore {
  private popups: TPopup[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getPopups() {
    return this.popups;
  }

  open(
    id: string,
    content: ReactNode,
    title: string = '',
    background?: string,
    fixedHeader?: boolean,
    hight?: string,
  ) {
    let openedPopup = this.popups.find(item => item.id === id);
    if(openedPopup) {
      this.close(id);
    }

    if(MAIN_WINDOWS_AND_POPUPS.includes(id)) {
      windowsStore.closeAll();
      this.closeAll();
    }

    const newDepths = {
      ...windowsStore.depths,
      main: windowsStore.depths.main + 1
    };

    windowsStore.setDepth(newDepths);

    (document.querySelector('#popups') as HTMLDivElement).style.zIndex = String(newDepths.main);

    const newPopup = {
      id,
      content,
      depth: newDepths.main,
      title,
      background,
      fixedHeader,
      hight,
    }

    this.popups.push(newPopup);
  }

  close(id: string, onlyLast?: boolean) {
    if(onlyLast) {
      const item = this.popups.find(item => item.id === id);
      if(!item) return;
      const itemDepth = item.depth;
      const allDepths = this.popups.concat(windowsStore.getWindows() as TPopup[]).map(item => item.depth);
      if(itemDepth !== Math.max(...allDepths)) {
        return;
      }
    }

    this.popups = this.popups.filter(popup => popup.id !== id);
  }

  closeAll() {
    this.popups = [];
  }

  decreaseZIndex() {
    const depths = windowsStore.depths.main - 2;

    (document.querySelector('#popups') as HTMLDivElement).style.zIndex = String(depths);
  }
}

export const popupsStore = new PopupsStore();
