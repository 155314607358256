import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FlexContainer } from '../../../../components/layout/elements';
import { isMobile } from 'react-device-detect';
import { Indi } from '../../../../libs';
import { Button } from '../../../../components/ui/Button/Button';
import { ArrowRight } from '../../../../assets/icons/system/system-icons';
import store from '../../../../store/Store';
import { gtmSend } from '../../../../helpers/url';
import { MyDayEventRecommendationsItem } from './my-day-event-recommendations-item';

export type TRecommendationsLayout = 'horizontal' | 'vertical';

export const MyDayEventRecommendations = ({
  items,
  sphere,
  layout = 'horizontal',
  savedRecommendations,
  toggleRecommendation,
}: {
  items: string[],
  sphere: Indi.Events.Spheres,
  layout?: TRecommendationsLayout
  savedRecommendations: string[]
  toggleRecommendation(text: string, remove?: boolean): void
}) => {

	const userId = store.sessionData?.id || '';

	useEffect(() => {
		gtmSend({'event': `indi_recomendations_shown_how-to-use`, 'user_id': userId})
	}, [])

  const Container = layout === 'horizontal' ? HorizontalContainer : VerticalContainer;

	const goToArticle = () => {
		const url = 'https://teletype.in/@chronos_astrology/how-to-apply-recomedations'
		window.open(url, '_blank')
		gtmSend({'event': `indi_recommendations_click_how-to-use`, 'user_id': userId})
	}


  return <Container>
		<Header>
			<Title>Рекомендации</Title>
			<StyledButton onClick={goToArticle}
										color='light-blue'>
				Как пользоваться
				<ArrowRight/>
			</StyledButton>
		</Header>

    {
      items.map((item, index) => {
        const saved = savedRecommendations.includes(item)
        return (
          <MyDayEventRecommendationsItem
            layout={layout === 'horizontal' ? 'cell' : 'row'}
            item={item}
            index={index}
            sphere={sphere}
            key={`recomendation_${index}`}
            saved={saved}
            toggleRecommendation={() => toggleRecommendation(item, saved)}
          />
      )})
    }
  </Container>
}

const VerticalContainer = styled.div`
  position: relative;
`;



const HorizontalContainer = styled(FlexContainer)`
  position: relative;
  gap: 0.5rem;
  width: calc(100% + 2rem);
  overflow: auto;

  transform: translateX(-1rem);
  padding: 1rem;

  ${!isMobile && css`
    padding-bottom: 1rem;

    ::-webkit-scrollbar {
      height: 0.25rem !important;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--scrollbar-thumb);
      -webkit-border-radius: 2px;
    }

    ::-webkit-scrollbar-track {
      background: var(--scrollbar-track);
      -webkit-border-radius: 2px;
    }
  `}
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-bottom: 0.75rem;
`

const Title = styled.div`
	color: var(--text-primary);
	font-size: 1rem;
	font-weight: 500;
`

const StyledButton = styled(Button)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 55%;
	padding: 0.25rem 0.75rem;
`
