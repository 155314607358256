import styled from "styled-components";
import { HelpIcon } from '../../../../assets/icons/system/system-icons';
import Chart, { IChartData } from '../../../../components/ui/Chart';


interface ISummaryProps {
  chartData: IChartData[]
  sections: string[]
  text: string
}

function Summary(props: ISummaryProps) {
  const title = props.sections.join(', ')

  return (
    <Container id={'diagramData'}>
      <div className={'subtitle'}>Что преобладает в отношениях</div>
      <Chart data={props.chartData} showTitle/>
      <Info>
        <IconContainer>
          <HelpIcon/>
        </IconContainer>
        На графике показано, из чего состоит ваша пара
      </Info>
      <ContentContainer>
        <div className={'subtitle'}>Преобладает</div>
        <div className={'title'}>{title}</div>
        <div>{props.text}</div>
      </ContentContainer>
    </Container>
  );
}

export default Summary;

const Container = styled.div`
  background: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: 16px;
  margin-bottom: 2rem;

  .subtitle {
    color: var(--text-secondary);
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
`

const Info = styled.div`
  display: flex;
  align-items: center;

  color: var(--color-orange);
  background: var(--color-orange-bg);
  border-radius: 8px;
  padding: 0.625rem;
  margin: 1rem 0;
`

const IconContainer = styled.div`
  margin-right: 0.8rem;
`

const ContentContainer = styled.div`

  .title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.8rem;
  }
`
