import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import styled from "styled-components"
import { BookmarkFull } from "../../assets/icons/notes/icons"
import { ArrowRight } from "../../assets/icons/system/system-icons"
import store from "../../store/Store"
import { openNotes } from "./Notes"


export const WidgetNotes = observer(() => {
  const notes = store.notes.notesList
  const userId = store.sessionData?.id

  const counter = () => {
    const count = notes.reduce((acc, next) => {
      return acc + next.recommendations.length
    }, 0)

    return count
  }

  useEffect(() => {
    userId && store.notes.buildNotes(userId)
  }, [userId])

  const onClickHandler = () => openNotes(false, true)

  return (
    <Container onClick={onClickHandler}>
      <Side className="left">
        <IconContainer>
          <BookmarkFull />
        </IconContainer>
        <p>Мои заметки{counter() > 0 ? `: ${counter()}` : ''}</p>
      </Side>
      <Side>
        <p>Перейти</p>
        <ArrowRight />
      </Side>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 1.25rem;
  margin: 0 1rem;
  background: rgba(227, 175, 98, 0.1);
  border-radius: 1rem;
`

const Side = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;

  .left {

  }
`

const IconContainer = styled.div`
  color: #E3AF62;
  margin-right: 0.25rem;
`
