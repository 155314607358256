import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import styled from 'styled-components';
import { MainPadding, WindowContainer } from '../../components/layout/elements';
import { ROUTES } from '../../helpers/routes';
import { useBackWindow } from '../../hooks/router-hooks';
import store from '../../store/Store';
import { windowsStore } from '../../store/Windows';
import { WindowHeader } from '../windows/components/WindowHeader';
import OtherTrends from './components/OtherTrends';
import Trend from './components/Trend';
import { gmtLocal } from './prognoses-daily';

export const WINDOW_ID_PROGNOSES_TREND: string = 'WINDOW_ID_PROGNOSES_TREND';

export default observer(function PrognosesTrend({
  trendId
}: {
  trendId: string
}) {
  useBackWindow(ROUTES.PROGNOSES_TREND, WINDOW_ID_PROGNOSES_TREND);

  useEffect(() => {
    store.prognoses.getPrognosesFeed(dayjs().toISOString(), gmtLocal);
  }, []);

  const onClose = () => {
    windowsStore.close(WINDOW_ID_PROGNOSES_TREND);
  }

  if(!store.prognoses.prognoses) {
    return null;
  }

  return (
    <WindowContainer>
      <WindowHeader title='Обзор трендов' onBack={onClose}/>
      <Container>
        <Trend extended={true} id={trendId} />
        <OtherTrends
          excludeId={trendId}
          title="Другие тренды"
          extended={true}
        />
      </Container>
    </WindowContainer>
  )
});

const Container = styled(MainPadding)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
`;
