import React from 'react';
import styled from 'styled-components';
import {ReactComponent as DiagnosticSvg} from '../../../assets/images/banners/menu-diagnostic.svg'
import { ChevronLeft } from '../../../assets/icons/system/system-icons';
import { gtmSend } from '../../../helpers/url';
import store from '../../../store/Store';

export const DiagnosticBanner = () => {
    const userId = store.sessionData?.id;

    const onClick = () => {
        gtmSend({'event': `indi_diagnistics_click`, 'user_id': userId ? userId : ''})
        setTimeout(() => {
            window.location.href = 'https://p.chronos.ru/diagnostics_app';
        }, 500)

    }
    return <Container onClick={onClick}>
        <Content>
            <Title>Первый шаг к ясности — <br/>диагностика с астрологом</Title>
            <Text>Узнайте с чего начать <br/>улучшение своей жизни</Text>
            <Button>Узнать больше <ChevronRight /></Button>
        </Content>
        <DiagnosticSvgStyled />
    </Container>
}

const Container = styled.div`
    position: relative;
    box-sizing: border-box;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(201,188,255,1) 100%);
    margin: 1rem 1rem 0;
    border-radius: 1rem;
    padding: 1rem 1rem 1.5rem;
    cursor: pointer;
`

const Content = styled.div`
    position: relative;
    z-index: 2;
`

const Title = styled.div`
    font-size: 1.125rem;
    line-height: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.675rem;
`

const Text = styled.div`
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 500;
    margin-bottom: 1.125rem;
`

const Button = styled.div`
    display: inline-block;
    background: #8754F2;
    color: white;
    font-size: 0.875rem;
    padding: 0.4rem 0.875rem 0.5rem;
    border-radius: 1.5rem;
`

const DiagnosticSvgStyled = styled(DiagnosticSvg)`
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;

`

const ChevronRight = styled(ChevronLeft)`
    display: inline;
    width: 0.75rem;
    height: 0.75rem;
    transform: scaleX(-1);
    opacity: 0.75;
    margin-left: 0.125rem;
`
