import styled from 'styled-components'
import { StarImportantIcon, InfoGray } from '../../../../assets/icons/system/system-icons'
import { eventsOptions } from '../../helpers/events'
import TooltipWrapper from '../../../../components/ui/TooltipWrapper'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Indi } from '../../../../libs'

export default function MyDayEventImportant({
  event
}: {
  event: Indi.Events.Event
}) {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  const eventOptions = eventsOptions[event.sphere];
  const text = event.type === 'harmonic' ?
    'Это событие сегодняшнего дня может проявиться заметнее остальных' :
    'Проявление этого события сегодня может быть заметнее остальных. Чтобы смягчить напряженность события, начните применять рекомендации';

  const onClick = useCallback((e: React.MouseEvent | Event) => {
    e.stopPropagation();
    setOpen(open => !open)
  }, [])

  useEffect(() => {
    if(open) {
      document.addEventListener('click', onClick, true);
      document.addEventListener('scroll', onClick, true);
      return () => {
        document.removeEventListener('click', onClick, true);
        document.removeEventListener('scroll', onClick, true);
      }
    }
  }, [open, onClick])

  return <TooltipWrapper
    text={text}
    open={open}
  >
    <Container onClick={onClick} ref={ref}>
      <StarImportantIconStyled color={eventOptions.textColor} />
      <Title>Важное событие</Title>
      <WhatIsIt>Что это?</WhatIsIt>
      <InfoGrayStyled />
    </Container>
  </TooltipWrapper>
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0rem;
  border: 1px solid var(--element-border-10);
  border-radius: 16px;
  padding: 0.875rem;
  font-size: 0.875rem;
  cursor: pointer;
`

const StarImportantIconStyled = styled(StarImportantIcon)<{color: string}>`
  width: 1.5rem;
  height: 1.5rem;
  color: ${p => p.color};
`

const Title = styled.div`
  color: var(--text-secondary);
`

const WhatIsIt = styled.div`
  margin-left: auto;
  color: var(--text-third);
`

const InfoGrayStyled = styled(InfoGray)`
  width: 1.5rem;
  height: 1.5rem;
  path {
    fill-opacity: 0.37;
  }
`
