import styled from 'styled-components';
import { Cross } from '../../../../assets/icons/system/system-icons';
import { FlexContainer } from '../../../../components/layout/elements';

export const CloseIcon = ({
  className = '',
  onClick
}: {
  className?: string
  onClick: () => void;
}) => {
  return <Container className={className} onClick={onClick}>
    <Cross />
  </Container>
}

const Container = styled(FlexContainer)`
  width: 1.875rem;
  height: 1.875rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  border-radius: 50%;
  background: var(--element-background-white-10);

  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`
