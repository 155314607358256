import { windowsStore } from "src/store/Windows";
import styled from "styled-components";
import { CloseIcon } from './CloseIcon';

import { WINDOW_ID_PAYWALL } from '../../Paywall';

export const closePaywall = () => {
  windowsStore.close(WINDOW_ID_PAYWALL);
}

export const PaywallClose = ({
  className = ''
}: {
  className?: string;
}) => {
  return <PaywallCloseContainer className={className}>
    <CloseIcon onClick={closePaywall} />
  </PaywallCloseContainer>
}

const PaywallCloseContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 1.675rem;
	z-index: var(--z-index-button);
`
