import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import RingLoader from "../../../components/ui/RingLoader";

const titles = [
  'Вычисляем расположение планет...',
  'Строим натальную карту...',
  'Рассчитываем транзиты...',
  'Раскрываем значения...',
]

interface LoadingProps {
  finishLoading(): void
}

export const Loading = memo((props: LoadingProps) => {
  const [index, setIndex] = useState(0)

  const timerId = setTimeout(() => {
    setIndex(prev => prev + 1)
  }, 2000)

  useEffect(() => {
    if (index === titles.length - 1) {
      clearTimeout(timerId)
      props.finishLoading()
    }
  }, [index])

  return (
    <Container>
      <RingLoader/>
      <Title>{titles[index]}</Title>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 60%;
`

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--text-primary-white);
  margin-top: 2rem;
  width: 100%;
  text-align: center;
`
