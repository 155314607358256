import React from 'react';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
// import { ReactComponent as CalendarIcon } from './calendar.svg';
import CalendarIconPath from './calendar.png';
import { ReactComponent as ForwardIcon } from './arrow-forward.svg';

export default function IndiPrognosisBanner ({
  link = '',
  startShow,
  endShow,
  className = ''
}: {
  link: string;
  startShow?: Dayjs;
  endShow?: Dayjs;
  className?: string;
}) {

  const now = dayjs();

  if ((startShow && endShow) && now.isBefore(startShow) || now.isAfter(endShow)) {
    return null;
  }

  return (
    <Container className={className} target="_blank" href={link}>
      <div className="left-side"><Img src={CalendarIconPath} /></div>
      <div className='right-side'>
        <main className="title">Персональный расширенный прогноз по всем сферам жизни</main>
        <footer>
          <Marker>Бесплатно</Marker>
          <Button style={{backgroundColor: 'background: rgba(255, 255, 255, 0.05)'}}>Узнать больше<ForwardIcon/></Button>
        </footer>
      </div>
    </Container>
  );
}

const Container = styled.a`
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  gap: 0.75rem;

  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #4760E7;
  text-decoration: none;

  & .right-side {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 0.75rem;
  }

  & main {
    display: flex;
    position: relative;
    gap: 0.625rem;
  }

  & footer {
    display: flex;
    position: relative;
    gap: 0.625rem;
  }

  & svg {
    flex-shrink: 0;
  }

  & .title {
    font-size: 0.875rem;
    font-weight: 600;
    color: #ffffff;
  }
`;

const Marker = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.625rem;
  border-radius: 7.938rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  background-color: #F49550;

  @media (max-width: 375px) {
    font-size: 0.813rem;
  }
`;

const Button = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1.375rem;
  border-radius: 7.938rem;

  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;

  background-color: rgba(255, 255, 255, 0.05);

  @media (max-width: 375px) {
    font-size: 0.813rem;
  }

  & svg {
    width: 1rem;
    height: 1rem;
    margin-left: 0.2rem;
  }

`;

const Img = styled.img`
  width: 2.3rem;
`