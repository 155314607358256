import React from 'react';
import styled, { css } from 'styled-components';
import { AlertTriangle } from '../../assets/icons/system/system-icons';
import api from '../../helpers/api';
import { FlexContainer } from '../layout/elements';
import { DateTimeInput, IPlace, IPlaceEx, PlaceInput } from '../../libs';

export const TextInput = ({
  value,
  onChange,
  onInvalid,
  onFocus,
  title,
  error,
  notion,
  type = 'text',
  placeholder = '',
  style,
  minDate,
  maxDate,
  className,
  place,
  textarea,
}: {
  value: string,
  onChange: (value: any) => void,
  onInvalid?: () => void,
  onFocus?: () => void,
  title?: string,
  error?: string
  notion?: string,
  type?: string,
  placeholder?: string,
  style?: React.CSSProperties,
  minDate?: string,
  maxDate?:string,
	className?: string,
  place?: IPlace,
  textarea?: boolean,
}) => {
  // const ref = useRef<HTMLDivElement>(null);
  // useEffect(() => {
  //   const removeFocus = () => {
  //     ref.current && ref.current.querySelector('input')?.blur();
  //   }
  //   window.addEventListener('resize', removeFocus)
  // }, [])

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement> |  React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  }

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.code === 'Enter') {
      (e.target as HTMLInputElement).blur();
    }
  }

  if (error) {
    style = {
      ...style,
      borderColor: 'var(--color-red)'
    }
  }

  const getInput = () => {
    if (type === 'place') {
      return (
        <StyledPlaceInput
          value={value}
          place={place}
          placeholder='Населенный пункт, область, страна'
          onSelect={onChange}
          // onChange={onChange}
          asyncDataFn={api.places.bind(api)}
          asyncDetailFn={api.place.bind(api) as () => Promise<IPlaceEx>}
          lang="ru"
          error={error}
          onFocus={onFocus}
        />
      )
    }

    if (type === 'date') {
      return (
        <StyledDateTimeInput
          type={type}
          value={value}
          onChange={onChange}
          onInvalid={onInvalid}
          hideIcon
          utcMode
          error={error}
          onFocus={onFocus}
        />
      )
    }

    if (type === 'time') {
      return (
        <StyledDateTimeInput
          type={type}
          value={value}
          onChange={onChange}
          onInvalid={onInvalid}
          hideIcon
          hideSeconds
          utcMode
          error={error}
          onFocus={onFocus}
        />
      )
    }

    if (textarea ) {
      return (
        <TextArea
          value={value}
          onChange={onChangeHandler}
          placeholder={placeholder}
          style={style}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
        />
      )
    }

    if (type === 'tel') {
      return (
        <StyledPhoneInput
          value={value}
          onChange={onChangeHandler}
          placeholder={placeholder}
          style={style}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          type={type}
          error={error}
        />
      )
    }

    return (
      <Input
        value={value}
        onChange={onChangeHandler}
        type={type}
        placeholder={placeholder}
        style={style}
        onKeyUp={onKeyUp}
        min={minDate}
        max={maxDate}
        onFocus={onFocus}
      />
    )
  }

  return <Container className={className ?? ''}>
    {title && <Title className={'text-input-title'}>{title}</Title>}

    {getInput()}

    <ErrorContainer>
      {error && <>
        <AlertTriangle /> {error}
      </>}
      {!error && notion && <Notion>
        {notion}
      </Notion>}
    </ErrorContainer>
  </Container>
}

const Container = styled.div`
  .place-input-dropdown {
    border: none;
    background: var(--input-background-hex);
    border-radius: 0.75rem;
    transition: background-color 0.2s;
    border: 1px solid var(--color-white);
    outline: none;
  }
`

const Title = styled.div`
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  color: var(--text-secondary);
`

const Input = styled.input`
  width: 100%;
  padding: 0.9rem 1em;
  border: none;
  background-color: var(--input-background);
  border-radius: 0.75rem;
  transition: background-color 0.2s;
  border: 1px solid var(--color-white);
  outline: none;

  &:focus {
    border: 1px solid var(--input-border);
    background: var(--color-white);
  }

  &::placeholder {
    color: var(--text-third);
  }
`

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.9rem 1em;
  border: none;
  background-color: var(--input-background);
  border-radius: 0.75rem;
  transition: background-color 0.2s;
  border: 1px solid var(--color-white);
  outline: none;
  font-family: 'Apercu Pro', sans-serif;
  font-size: 1rem;

  &:focus {
    border: 1px solid var(--input-border);
    background: var(--color-white);
  }

  &::placeholder {
    color: var(--text-third);
  }
`

const ErrorContainer = styled(FlexContainer)`
  min-height: 1.25rem;
  color: var(--color-red);
  align-items: center;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;

  > svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
`

const Notion = styled.div`
  color: var(--text-third);
  line-height: 115%;
  margin-bottom: 1rem;
`

const StyledPlaceInput = styled(PlaceInput) <{ error?: string; onFocus?: () => void }>`
  width: 100%;
  padding: 0.38rem 0.6rem;
  margin-top: 0.5rem;
  border: none;
  background-color: var(--input-background);
  border-radius: 8px;
  transition: background-color 0.2s;
  border: 1px solid var(--color-white);
  outline: none;

  & input {
    border: none;
  }

  ${p => p.error && css`
    border-color: var(--color-red);
  `}
`

const StyledDateTimeInput = styled(DateTimeInput) <{ error?: string; onFocus?: () => void }>`
  width: 100%;
  padding: 0.38rem 0.6rem;
  margin-top: 0.5rem;
  border: none;
  background-color: var(--input-background);
  border-radius: 8px;
  transition: background-color 0.2s;
  border: 1px solid var(--color-white);
  outline: none;

  & input {
    border: none;
  }

  ${p => p.error && css`
    border-color: var(--color-red);
  `}
`;

const StyledPhoneInput = styled(Input) <{error?: string}>`
  ${({error}) => error && css`
    color: var(--error-color);
    background-color: var(--error-color-bg);
    border: none;

    &::placeholder {
      color: var(--error-color)
    }
  `}
`
