import {makeAutoObservable} from 'mobx';
import Api from "../helpers/api";
import {getSynastryPartnerDataLS, setSynastryPartnerDataLS} from "./localStorageHelpers";
import { PRODUCTS_SECTION } from '../modules/products/helpers/constants';
import { IPlace, Indi, toDateTime } from '../libs';

export type PartnerDataType = {
  name: string | null
  dateTime: string | null
  place: IPlace | null
  // gender: GenderType | null
  gmt?: string | null
}

const defaultPartners: PartnerDataType[] = [{
  name: null,
  dateTime: null,
  place: null,
  // gender: null,
  gmt: null,
}, {
  name: null,
  dateTime: null,
  place: null,
  // gender: null,
  gmt: null,
}]

export default class Synastry {
  constructor() {
    makeAutoObservable(this);
  }

  partners: PartnerDataType[] = defaultPartners;
  synastryId: number | null = null;
  trialSynastry: boolean = false;
  activeSection: PRODUCTS_SECTION = PRODUCTS_SECTION.CATALOG;

  getPartner(index: number) {
    return this.partners[index]
  }

  setPartner(index: number, data: PartnerDataType) {
    this.partners[index] = data
    setSynastryPartnerDataLS(data, index)
  }

  async getSynastry(purchasedSynastryId: number) {
    const result = await Api.indiGetSynastry({purchasedSynastryId}) as any;
    this.synastryId = result.id
    return result
  }

  setActiveSession(activeSession: PRODUCTS_SECTION) {
    this.activeSection = activeSession;
  }

  resetSynastry() {
    this.partners = defaultPartners
    for (let i = 0; i < 2; i++) {
      setSynastryPartnerDataLS('',i)
    }
  }

  async freeChargeSynastry(data: Indi.CalculateFreeSynastry.Request, headers?: {tentative: string}) {
    const result = await Api.indiCalculateFreeSynastry(data, headers)
    return result
  }

  async getShareToken(synastryId: string) {
    try {
        const { token } = await Api.indiGetSynastryShareToken({synastryId})
        return token
    } catch(e) {
      console.error(e)
    }
  }

  async getSharedSynastry(token: string) {
    try {
      const result = await Api.indiGetSharedSynastry({token})
      return result
    } catch(e) {
      console.error(e)
    }
  }

  async getTrialSynastry(data: any, headers?: {tentative: string}) {
    try {
      const result = await Api.indiGetTrialSynastry({ ...data, language: 'ru' }, headers)
      return result
    }
    catch (e) {
      console.error(e)
    }
  }

  setShowedTrialSynastry(value: boolean) {
    this.trialSynastry = value
  }

  getPartners() {
    const partnersLS = []
    for (let i = 0; i < 2; i++) {
      const partner = getSynastryPartnerDataLS(i)
      partner && partnersLS.push(partner)
    }

    if (partnersLS.length > 0) {
      return partnersLS
    } else {
      return this.partners
    }
  }

  getPartnersData() {
    const data: any = {}

    this.getPartners().forEach((partner, index) => {
      const { date, time } = toDateTime(partner.dateTime);

      const partnerNumber = index + 1
      data[`name${partnerNumber}`] = partner.name
      data[`date${partnerNumber}`] = date
      data[`time${partnerNumber}`] = time
      data[`lon${partnerNumber}`] = partner.place?.lon.toString()
      data[`lat${partnerNumber}`] = partner.place?.lat.toString()
      data[`place${partnerNumber}`] = partner.place?.name
      data[`gmt${partnerNumber}`] = partner?.gmt
    })

    return data;
  }
}
