import { ReactNode } from "react"
import styled from "styled-components"

interface ModalProps {
	className?: string
	children: ReactNode
}

export const Modal = (props: ModalProps) => {
	const {
		className = '',
		children
	} = props

	return (
		<Container className={className}>
			{children}
		</Container>
	)
}

const Container = styled.div`
  position: fixed;
	top: 0;
  left: 0;
  height:100vh;
  z-index: var(--z-index-modal);
	box-sizing: border-box;
  overflow-y: auto;
`
