import React, { forwardRef, useImperativeHandle, Ref } from "react";
import styled, { css } from "styled-components";
import dayjs, { Dayjs } from "dayjs";

export interface IRefCountDownTimer {
  restart: () => void;
}

export default forwardRef(function CountDownTimer({
  minutes = 1,
  onFinish,
  onClick,
}: {
  minutes: number;
  onFinish?: () => void;
  onClick: () => void;
}, ref: Ref<IRefCountDownTimer>) {

  const timerRef = React.useRef<any>(null);

  const [dayjsTime, setDayjsTime] = React.useState<Dayjs | null>(null);
  const [endCountDown, setEndCountDown] = React.useState<boolean>(false);

  React.useEffect(() => {

    timerRef.current = setTimeout(() => {
      setDayjsTime(dayjs().minute(minutes).second(0));
    }, 1000);

    return () => {
      clearTimeout(timerRef.current);
      clearTimeout(timerRef.current);
    }
  }, []);

  React.useEffect(() => {
    if (!dayjsTime) return;

    const nextTime: Dayjs = dayjsTime.subtract(1, 'second');

    if ((minutes < 2 && nextTime.second() === 0) || (minutes > 1 && nextTime.minute() === 59)) {
      clearTimeout(timerRef.current);
      setEndCountDown(true);
      onFinish?.();
      return;
    }
    timerRef.current = setTimeout(() => {
      setDayjsTime(nextTime);
    }, 1000);

  }, [dayjsTime]);

  const restart = () => {
    clearTimeout(timerRef.current);
    setEndCountDown(false);
    setDayjsTime(dayjs().minute(minutes).second(0));
  }

  useImperativeHandle(ref, () => ({ restart }))

  return (
    <TimerViewContainer visible={Boolean(dayjsTime)}>
      {!endCountDown
        ? <span>Запросить код еще раз через (<span style={{'fontFamily': 'monospace'}}>{dayjsTime?.format('mm:ss')}</span>)</span>
        : <span className="as-button" onClick={onClick}>Запросить код еще раз</span>
      }
    </TimerViewContainer>
  );
});

const TimerViewContainer = styled.div<{ visible: boolean }>`
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 2.5rem;
  font-size: 0.875rem;
  visibility: visible;

  ${p => !p.visible && css`
    visibility: hidden;
  `}

  & .as-button {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem;
    text-align: center;
    color: var(--color-gradus-blue);
    cursor: pointer;
  }

`;
