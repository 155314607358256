export type Position = 'left' | 'right' | 'top' | 'bottom' |'none';
export type MenuPosition = { horizontal: Position; vertical: Position };

export const getAllowedMenuPosition = (ref: HTMLElement, menuTop?: number, menuLeft?: number): MenuPosition => {
    if (!ref) return { horizontal: 'none', vertical: 'none' };

    const { top: selfTop, left: selfLeft, width: menuWidth, height: menuHeight } = ref.getBoundingClientRect();
    const leftPosition = menuLeft ?? selfLeft;
    const topPosition = menuTop ?? selfTop;
    const rightSpaceWidth = document.documentElement.clientWidth - (leftPosition + menuWidth);
    const bottomSpaceHeight = document.documentElement.clientHeight - (topPosition + menuHeight);

    let horizontal: Position;
    let vertical: Position;

    if ((leftPosition >= menuWidth )) {
        horizontal = 'left';
    } else {
        horizontal = 'right';
    }

    if (topPosition >= menuHeight && bottomSpaceHeight < menuHeight) {
        vertical = 'top';
    } else {
        vertical = 'bottom';
    }

    return { horizontal, vertical };
};
