import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

import store from '../../store/Store';

import SpecialDay from './components/SpecialDay';
import OtherItems from './components/OtherItems';
import { MainPadding } from '../../components/layout/elements';
import { useBackWindow } from '../../hooks/router-hooks';
import { ROUTES } from '../../helpers/routes';
import { gmtLocal } from './prognoses-daily';

export const WINDOW_ID_PROGNOSES_SPECIAL_DAY: string = 'WINDOW_ID_PROGNOSES_SPECIAL_DAY';

export default observer(function PrognosesSpecialDay({
  dayId
}: {
  dayId: string
}) {
  useBackWindow(ROUTES.PROGNOSES_SPECIAL_DAY, WINDOW_ID_PROGNOSES_SPECIAL_DAY);

  useEffect(() => {
    store.prognoses.getPrognosesFeed(dayjs().toISOString(), gmtLocal);
  }, []);

  const specialDayData = useMemo(() => {
    return store.prognoses.getSpecialDayById(+dayId);
  }, [dayId, store.prognoses.prognoses]);

  const otherSpecialDays = useMemo(() => {
    if(specialDayData) {
      return store.prognoses.getActualSpecialDays(specialDayData.qualityType, +dayId);
    }
    return null;
  }, [specialDayData]);

  if(!store.prognoses.prognoses || !specialDayData) {
    return null;
  }

  return (
    <Container>
      <SpecialDay id={dayId} />
      { otherSpecialDays?.length
          ? <OtherItems
              title={specialDayData.qualityType === 'LUCKY' ? 'Другие благоприятные дни' : 'Другие неблагоприятные дни'}
              items={otherSpecialDays.map(day => ({
                title: day.title || '',
                period: day.period,
                link: `/prognoses/special-day/${day.id}`,
              }))}
              extended={true}
            />
          : null
      }
    </Container>
  )
});

const Container = styled(MainPadding)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
`;