import React from 'react'

export default function StarIcon(props: {
  active: boolean;
  disabled: boolean;
  onClick(): void;
}): JSX.Element {

  const clickHandler = () => {
    if (!props.disabled) props.onClick()
  }

  return <svg width="36" height="34" viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={clickHandler}>
    <path d="M18 0L23.562 11.1903L36 12.9958L27 21.7014L29.124 34L18 28.1903L6.876 34L9 21.7014L0 12.9958L12.438 11.1903L18 0Z" fill={props.active ? '#F59300' : '#4760E7'} fillOpacity={props.active ? 1 : 0.15}/>
  </svg>;
};
