import styled from 'styled-components';

interface OverlayProps {
  children?: any;
  onClick?(): void;
	className?: string;
  overlayRef?: React.RefObject<HTMLDivElement>
}

export default function Overlay(props: OverlayProps): any {
  const {
    children,
    onClick,
    className,
    overlayRef,
  } = props

  const onClickHandler = () => {
    onClick?.()
  }

  if (children) {
    return (
      <OverlayWrapper className={className ?? ''}>
        <OverlaySibling className='overlay-sibling' onClick={onClickHandler} />
        {children}
      </OverlayWrapper>
    )
  }

  return (
    <OverlaySingle
      className={`${className} overlay-single`}
      ref={overlayRef}
      onClick={onClickHandler}
    />
  )
}

const OverlayWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-index-overlay);
`;

const OverlaySibling = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
  opacity: 0.8;
`;

 const OverlaySingle = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: opacity;
  background-color: rgba(29, 29, 29);
  opacity: 0;
  z-index: var(--z-index-overlay);
 `
