import { observer } from "mobx-react-lite"
import { RefObject, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import Overlay from "../../../components/Overlay"
import { Colors, Positions, Tip } from "../../../components/Tip"
import { gtmSend } from "../../../helpers/url"
import store from "../../../store/Store"
import { hideMyDayElements, highlightMyDayElements } from "./helpers/dom"


interface OnboardingProps {
  myDayRef: RefObject<HTMLDivElement>
  hideOnboarding(): void
}

export const MyDayOnboarding = observer((props: OnboardingProps) => {
  const {
    myDayRef,
    hideOnboarding,
  } = props

  const [showTip, setShowTip] = useState(false)

  const tipWrapperRef = useRef<HTMLDivElement>(null)
  const overlayRef = useRef<HTMLDivElement>(null)

  const userId = store.sessionData?.id
  const firstEventsRef = store.onboarding.firstEventsRef

  const scrollMyDay = (myDayContainer: RefObject<HTMLDivElement>, firstEventRef: RefObject<HTMLDivElement>) => {
    const top = firstEventRef.current?.getBoundingClientRect().top ?? 0

    if (myDayContainer.current && top !== 0)
      myDayContainer.current.scrollTo({
        top: top - 330,
        behavior: 'smooth',
    })
  }

  useEffect(() => {
    if (myDayRef.current && firstEventsRef?.current) {
      highlightMyDayElements()
      scrollMyDay(myDayRef, firstEventsRef)

      setTimeout(() => {
        setShowTip(true)
      }, 1000)
    }
  }, [myDayRef, firstEventsRef])

  const onClickHandler = () => {
    hideOnboarding()
    hideMyDayElements()
    gtmSend({'event': `indi_onboarding_events_common`, 'user_id': userId ? userId : ''})
  }

  const button = {
    title: 'Продолжить',
    onClick: onClickHandler,
    position: Positions.CENTER
  }

  if (!showTip) return null

  return (
    <StyledOverlay overlayRef={overlayRef} className="block-scroll">
      <TipWrapper ref={tipWrapperRef}>
        <Tip
          title="Исследуйте личные сферы"
          text="Лента событий строится на основе ваших данных, охватывает 7 сфер жизни и постоянно обновляется. Изучая их, вы получите ценные инсайты для планирования и улучшения своей жизни"
          color={Colors.LIGHT}
          button={button}
          trianglePosition={Positions.BOTTOM_CENTER}
        />
      </TipWrapper>
  </StyledOverlay>
  )
})

const StyledOverlay = styled(Overlay)`
  align-items: start;

  z-index: var(--z-index-modal);
  padding-top: 4rem;
`

const TipWrapper = styled.div`
  width: 100%;
  padding: 1rem;
`
