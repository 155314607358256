import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { ArrowRightInCircle } from '../../../assets/icons/system/system-icons';
import { FlexContainer } from '../../../components/layout/elements';
import { gtmSend } from '../../../helpers/url';
import store from '../../../store/Store';
import { openMyDay } from '../../my-day/my-day';
import { openPrognoses } from '../../prognoses/prognoses';
import { getMoonGraphicData } from '../helpers/blocks';
import { Sections } from '../../../store/Shutter';

export const MainPageBlocksWorld = observer(() => {

  const isAuthFinished = store.isAuthFinished;
  const dayData = store.prognoses.getDay(dayjs().local());
  const procent = store.prognoses.getDayById(dayData?.id || 0)?.qualityPct || 0;
  const data = getMoonGraphicData(procent);
  const GraphicIcon = data.icon;
  const userId = store.sessionData?.id;

  const onClickHandler = () => {
    openPrognoses()
    store.shutter.setActiveSection(Sections.MONTH_REVIEW)
    gtmSend({'event': 'ind_prognosis_general_day-common-persentage_click', 'user_id': userId ? userId : ''})
  }

  if(!isAuthFinished || procent === 0) {
    return <MainPageBlockContainer>
      <Skeleton style={{'width': '100%', 'height': '100%'}} />
    </MainPageBlockContainer>
  }

  return <MainPageBlockContainer onClick={onClickHandler} className="ob-0">
    <MainPageBlockHeader>
      Общий прогноз
      <ArrowRightInCircle />
    </MainPageBlockHeader>

    {procent > 0 && <>
      <ProcentBlock color={data.color}>
        <Title>{procent}%</Title>
        <div>Потенциал дня</div>
      </ProcentBlock>

      <Graphic><GraphicIcon /></Graphic>
    </>}
  </MainPageBlockContainer>
})

export const MainPageBlocksMy = observer(() => {

  const isAuthFinished = store.isAuthFinished;
  const procent = store.events.month?.description?.percent?.value || 0;
  const data = getMoonGraphicData(procent);
  const GraphicIcon = data.icon;
  const userId = store.sessionData?.id;

  useEffect(() => {
    if(procent) {
      store.events.setMyBlockLoaded(true);
      store.events.loadEventsFirst();
    }
  }, [procent]);

  const openMyPopup = () => {
    openMyDay();

    gtmSend({'event': 'ind_prognosis_general_day-personal-persentage_click', 'user_id': userId ? userId : ''})
  }

  if(!isAuthFinished || procent === 0) {
    return <MainPageBlockContainer >
      <Skeleton style={{'width': '100%', 'height': '100%'}} />
    </MainPageBlockContainer>
  }

  return <MainPageBlockContainer onClick={openMyPopup} className="ob-1" id="main-page-block-my">
    <MainPageBlockHeader>
      Мой день
      <ArrowRightInCircle />
    </MainPageBlockHeader>

    { procent > 0 && <>
      <ProcentBlock color={data.color}>
        <Title>{procent}%</Title>
        <div>Потенциал дня</div>
      </ProcentBlock>

      <Graphic><GraphicIcon /></Graphic>
    </>}
  </MainPageBlockContainer>
})

export const MainPageBlockContainer = styled.div`
  background: rgba(248, 248, 248, 1);
  position: relative;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  overflow: hidden;
  aspect-ratio: 1/1;
`

export const MainPageBlockHeader = styled(FlexContainer)`
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.75rem 0.3rem;
  height: min-content;
  font-size: 0.75rem;
  font-weight: 500;
  flex: 1;
  color: var(--text-secondary);
`

const ProcentBlock = styled.div<{color: string}>`
  padding: 0 0.875rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: ${p => p.color};
`

const Title = styled.div`
  font-size: 2rem;
  line-height: 2rem;
  margin-bottom: 0.25rem;
`

const Graphic = styled.div`
  > svg {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  }
`
