import { memo } from "react"
import styled, { css } from "styled-components"

enum Colors {
  LIGHT = 'light',
  DARK = 'dark'
}

interface QuantityIndicatorProps {
  className?: string
  quantity: number
  currentStep: number
  color: Colors
  size?: number
}

export const QuantityIndicator = memo((props: QuantityIndicatorProps) => {
  const {
    className,
    quantity,
    currentStep,
    color,
    size = 0.5
  } = props

  const getItems = () => {
    const items = []

    for (let i = 0; i < quantity; i++) {
      items.push({current: i + 1 === currentStep})
    }

    return items.map((item, index) => (
      <Item
        key={`quantity-indicator-item-${index}`}
        color={color}
        current={item.current}
        size={size}
      />
    ))
  }

  return (
    <Container className={className ?? ''}>
      {getItems()}
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
`

const Item = styled.div<{color: string, current: boolean, size: number}>`
  width: ${p => `${p.size}rem`};
  height: ${p => `${p.size}rem`};
  border-radius: 50%;
  background: ${p => p.color === Colors.DARK ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.1)'};

  ${p => p.current && css`
      background: ${p.color === Colors.DARK ? 'var(--color-white)' : '#1A1A1A'};
    `
  }
`
