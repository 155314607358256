export const getEventsWord = (count: number) => {
  if(count === 1) return 'событие';
  if(count >= 2 && count <= 4) return 'события';
  return 'событий';
}

export const getAmount = (number: number, returnStr: boolean = false) => {
  if (number > 365) {
    const amount = Math.trunc(number / 365)
    return returnStr ? `${amount}+` : amount
  }

  if (number > 30) {
    const amount = Math.trunc(number / 31)
    return returnStr ? `${amount}+` : amount
  }

  return number
}

export const getDaysWord = (number: number, onlyWord: boolean = false) => {
    if(number === 0) return 'Последний день';
    if(number < 0) return 'Уже прошло';

    const periods = {
      day: ['день', 'дня', 'дней'],
      month: ['месяц', 'месяца', 'месяцев'],
      hour: ['час', 'часа', 'часов'],
      week: ['неделя', 'недели', 'недель'],
      year: ['год', 'года', 'лет'],
    };

    const cases = [2, 0, 1, 1, 1, 2];

    const getWord = () => {
      let date = number
      let period: string[]

      if (number < 31) {
        period = periods.day
      } else if (number < 365) {
        date = Math.floor(number / 31)
        period = periods.month
      } else {
        date = Math.floor(number / 365)
        period = periods.year
      }

      return period[
        date % 100 > 4 && date % 100 < 20
          ? 2
          : cases[
              date % 10 < 5
                ? date % 10
                : 5
              ]
        ]
    }

    if(!onlyWord) {
      return `еще ${getAmount(number, true)} ${getWord()}`
    } else {
      return getWord()
    }
}

export const getLeftWord = (number: number) => {
  const words = ['Остался', 'Осталось']

  return words[
    number % 10 === 1
      ? 0
      : 1
  ]
}

export const getArticleWord = (number: number) => {
  const words = ['статья', 'статьи', 'статей']
	const cases = [2, 0, 1, 1, 1, 2];

  return words[
		number % 100 > 4 && number % 100 < 20
			? 2
			: cases[
					number % 10 < 5
							? number % 10
							: 5
				]
	]
}
