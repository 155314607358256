import React from 'react';
import styled, { css } from 'styled-components';
import { eventsOptions } from '../../helpers/events';
import { EventHeader } from './my-day-event';
import dayjs from 'dayjs';

export const MyDayNoEvents = ({
  type = 'all',
  currentDate
}: {
  type?: 'all' | 'important' | 'group'
  currentDate: dayjs.Dayjs
}) => {

  const eventOptions = eventsOptions['emotions'];
  const SmallIcon = eventOptions.iconSmall;

  const datePrefix = currentDate.isToday() ? 'Сегодня' : 'На выбранную дату';

  let text = 'Нет краткосрочных событий';
  if(type === 'important') text = `${datePrefix} нет важного события`;
  else if(type === 'group') text =  `${datePrefix} нет событий`;

  return <StyledGroupBlock isImportant={type === 'important'}>
    <EventHeader color={'var(--text-secondary)'}>
      {type === 'all' && <SmallIcon />}
      <span>{text}</span>
    </EventHeader>
  </StyledGroupBlock>
}

const StyledGroupBlock = styled.div<{ isImportant: boolean }>`
  padding: 0.75rem 1rem;
  background-color: var(--color-white);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 24px;
  margin-bottom: 0.75rem;
  font-size: 0.875rem;

  ${p => p.isImportant && css`
    background-color: var(--color-bg-important-event);
  `}

  svg {
    path {
      fill: var(--text-secondary);
    }
  }
`
