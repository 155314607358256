import styled from "styled-components";
import { ArrowRight, HelpIcon, TickInCircleIcon } from '../../../../assets/icons/system/system-icons';
import { MainPadding, WindowContainer } from "../../../../components/layout/elements";
import store from "../../../../store/Store";
import { windowsStore } from '../../../../store/Windows';
import { ROUTES } from '../../../../helpers/routes';
import { useBackSoftRestart } from '../../../../hooks/router-hooks';
import { Button } from "../../../../libs";


interface ISuccessPageProps {
  mode: 'payment' | 'result'
}

function SuccessPage(props: ISuccessPageProps) {
  useBackSoftRestart(ROUTES.PRODUCTS_SUCCESS)
  const goToProducts = () => {
    windowsStore.closeAll()
  }

  const getTitle = () => {
    return `${props.mode === 'payment' ? 'Оплата прошла успешно' : 'Результат создан'}, ссылка уже у вас на почте`
  }

  return (
    <WindowContainer>
      <Content>
        <Wrapper>
          <TickInCircleIcon/>
          <div>{getTitle()}</div>
          <div className={'email'}>Письмо отправлено на почту <span>{store.payments.email}</span></div>
          <Button
            onClick={goToProducts}
            color={'transparent'}
          >
            <ButtonContent><ArrowRight />Вернуться к покупкам</ButtonContent>
          </Button>
        </Wrapper>
        <Info>
          <div className={'icon-container'}><HelpIcon/></div>
          <div>Если у вас возникли проблемы, свяжитесь с поддержкой по адресу support@chronos.mg</div>
        </Info>
      </Content>
    </WindowContainer>
  );
}

export default SuccessPage;

const Content = styled(MainPadding)`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  position: relative;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;

  svg {
    width: 4rem;
    height: 4rem;
    color: var(--color-green);
  }

  .email {
    font-size: 1rem;
    font-weight: 400;
    margin: 1rem 0;

    span {
      color: var(--color-gradus-blue);
    }
  }
`

const Info = styled.div`
  display: flex;
  align-items: center;

  font-size: 0.875rem;
  position: absolute;
  bottom: 3rem;
  padding: 1rem;
  margin: 0 1rem;
  color: #CF820D;
  background-color: #FEF5E6;
  border-radius: 0.5rem;

  .icon-container {
    margin-right: 1rem;

    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
`

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.3rem;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  margin-top: 0.1rem;

  & svg {
    color: var(--text-primary);
    transform: rotate(180deg);
    margin-right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
`
