import React, { CSSProperties } from 'react'
import styled, {css} from 'styled-components'

type StyleType = CSSProperties | undefined;

export default function Button({
  color = 'blue',
  textColor,
  children,
  style,
  bordered = false,
  onClick,
  className,
  disabled = false,
  icon,
  size = 'medium',
  type,
  name,
  cursor,
}: {
  children: React.ReactNode | Array<React.ReactNode>;
  disabled?: boolean;
  color?: string;
  textColor?: string;
  style?: StyleType;
  bordered?: boolean;
  className?: string;
  icon?: any;
  onClick?(): void;
  size?: 'medium' | 'large';
  type?: 'button' | 'submit' | 'reset';
  name?: string;
  cursor?: string;
}): JSX.Element {
  const Icon = icon;

  return <CustomButton
    className={className}
    textColor={textColor || ''}
    disabled={disabled}
    onClick={onClick}
    color={color}
    bordered={bordered}
    style={style}
    size={size}
    type={type}
    name={name}
    cursor={cursor}
  >
    {children}
    {icon && <Icon />}
  </CustomButton>;
}

const CustomButton = styled.button<{ disabled: boolean; color: string; bordered: boolean; textColor: string; size: string; cursor?: string }>`
  background: #4760E7;
  color: rgba(255, 255, 255, 0.87);
  width: 100%;
  height: ${props => props.size ==='large' ? '48px' : '38px'};
  border: none;
  outline: none;
  border-radius: 6px;
  font-family: 'Apercu Pro', sans-serif;
  transition: all 0.2s ease;
  box-sizing: border-box;
  cursor: ${props => props.cursor || 'pointer'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => props.size ==='large' ? '1rem' : '0.8333rem'};
  //opacity: 0.9;
  //
  //&:active{
  //  opacity: 1;
  //}
  
  > svg {
    width: 24px;
    height: 24px;
    margin-left: 6px;
  }
  
  &:disabled {
    color: rgba(71, 96, 231, 0.3);
    background: rgba(71, 96, 231, 0.1);
  }
  
  ${props => props.color === 'white' && css`
    color: ${props.textColor === 'white' ? 'rgba(255, 255, 255, 0.87)' : 'var(--text-primary)'};
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
  `}
  
  ${props => props.color === 'blue' && css`
    color: #4760E7;
    background: rgba(71, 96, 231, 0.1);
  `}

  ${props => props.color === 'red' && css`
    color: #EF5350;
    background: rgba(239, 83, 80, 0.1);
  `}
  
  ${props => props.color === 'green' && css`
    color: rgba(255, 255, 255, 0.87);
    background: #73B330;
  `}
  
  ${props => props.color === 'brown' && css`
    color: rgba(255, 255, 255, 0.87);
    background: #9B4B28;
  `}
  
  ${props => props.color === 'gradient' && css`
    background: linear-gradient(267.5deg, #4760E7 34.89%, #C859FF 87.38%);
  `}

  ${props => props.color === 'gray' && css`
    color: var(--text-primary);
    background: #DAE4EA;
  `}

  ${props => props.color === 'dark-gray' && css`
    color: var(--text-primary);
    background: var(--popup-line-background);
  `}

  ${props => props.color === 'light-gray' && css`
    color: var(--text-primary);
    background: var(--color-gray-bg-transparent);
  `}

  ${props => props.color === 'light-green' && css`
    color: var(--color-green);
    background: var(--color-green-bg-transparent);
    border: 1px solid var(--color-green-border-transparent);
  `}

  ${props => props.color === 'light-red' && css`
    color: var(--color-red);
    background: var(--color-red-bg-transparent);
    border: 1px solid var(--color-red-border-transparent);
  `}
`;
