import dayjs from "dayjs"
import styled from "styled-components"
import { FlexContainer } from "./layout/elements"

interface WeekdayIndicatorProps {
	className?: string
	date: dayjs.Dayjs
}

export const WeekdayIndicator = (props: WeekdayIndicatorProps) => {
	const week = []

	for (let i = 1; i < 8; i++) {
		week.push({
			active: props.date.day() === i || (props.date.day() === 0 && i === 7)
		})
	}

	return (
		<Container className={props.className ?? ''}>
			{
				week.map((item, index) => <Item key={`weekday_${index}`} active={item.active}/>)
			}
		</Container>
	)
}

const Container = styled(FlexContainer)`
	justify-content: space-between;

	width: 6.5rem;
`

const Item = styled.span<{active: boolean}>`
	width: 11px;
	height: 3px;
	border-radius: 40px;
	background: ${p => p.active ? 'rgba(0, 0, 0, 0.9)' : 'rgba(0, 0, 0, 0.2)'};
	margin-top: 0.5rem;
	transition: background-color 0.3s;
`
