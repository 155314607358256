import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import InfoBlock, { BlockStatus } from '../../../components/InfoBlock';
import { Label, Link, Markup, PinButton, Row } from '../../../components/legacy';
import store from '../../../store/Store';
import { ArrowRight as ArrowIcon, ClockIcon2 as ClockIcon, Globe as GlobeIcon, PinIcon } from '../../../assets/icons/system/system-icons';
import { getFormattedInterval } from '../../../helpers/dates';
import { copyToClipboard } from '../../../helpers/strings';
import { windowsStore } from '../../../store/Windows';
import PrognosesTrend, { WINDOW_ID_PROGNOSES_TREND } from '../prognoses-trend';
import Actions from './Actions';

export default function Trend(
  {
    extended = false,
    id,
    isPinned = false,
    togglePin = () => {},
  }: {
    extended?: boolean;
    id: string;
    isPinned?: boolean;
    togglePin?: (v: any) => void;
  }
) {
  const link = `/prognoses/trends/${id}`;

  const trendData = useMemo(() => {
    return store.prognoses.getTrendById(+id);
  }, [id]);

  const handleClick = () => {
    //window.location.href = link;
    windowsStore.open(
      WINDOW_ID_PROGNOSES_TREND,
      <PrognosesTrend trendId={id} />,
    )
  }

  if(!trendData?.id) {
    return null;
  }

  const trendStart = dayjs(trendData.period.start, 'DD.MM.YYYY HH:mm:ss');
  const isStarted = dayjs().isAfter(trendStart);

  const todos = trendData.recommendations.filter(recommendation => recommendation.type === 'TODO');
  const promts = trendData.recommendations.filter(recommendation => recommendation.type === 'PROMT');

  const period = getFormattedInterval(trendData.period.start, trendData.period.end);
  const status = isStarted
    ? BlockStatus.NEGATIVE
    : BlockStatus.NEUTRAL

  const timeLeft = isStarted
    ? 'Уже началось'
    : `До начала ${dayjs().to(trendStart, true)}`;

  return (
    <Container>
      { !extended &&
          <Row justify="space-between" align="center">
            <Row gap="0.25rem" mb='0'>
              {isPinned && <PinButton><PinIcon /></PinButton>}
              <Label>Тренды</Label>
            </Row>
            <Actions
              actions={[
                {
                  label: 'Скопировать ссылку',
                  action: () => copyToClipboard(`${window.location.host}${link}`),
                },
                {
                  label: isPinned ? 'Открепить' : 'Закрепить',
                  action: togglePin,
                },
              ]}
            />
          </Row>
      }
      <Row mb="0.75rem" justify={extended ? 'center' : 'flex-start'} mt={extended ? '2rem' : '0'}>
        <Title>{trendData.title}</Title>
      </Row>
      <Row mb="0.75rem">
        <InfoBlock status={status} icon={<ClockIcon />} title={timeLeft} titleRight={period} />
      </Row>
      <Row mb="0.75rem" fd="column">
        <Markup short={!extended}>
          <p>{trendData.description}</p>
          { extended && todos?.length
              ? todos.map((todo, i: number) => (
                  <React.Fragment key={`todo_${i}`}>
                    <h4>{todo.title}</h4>
                    { todo.text &&
                        <ul>
                          { todo.text.split('\n').map((textItem: string) => (
                              <li key={`todo_${i}_${textItem}`}>{textItem}</li>
                            ))
                          }
                        </ul>
                    }
                  </React.Fragment>
                ))
              : null
          }
        </Markup>
        { extended && promts?.length
            ? promts.map((promt, i: number) => (
                <InfoBlock key={`promt_${i}`} status={BlockStatus.UPCOMING} title={promt.title} icon={<GlobeIcon />}>
                  {promt.text}
                </InfoBlock>
              ))
            : null
        }
      </Row>
      { !extended &&
          <Row justify="center" mb="0">
            <Link onClick={handleClick}>Читать далее <ArrowIcon /></Link>
          </Row>
      }
    </Container>
  );
}

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #F3F3F3;
  box-shadow: 0px 3.2px 14px rgba(0, 0, 0, 0.06), 0px 0.6px 1.8px rgba(0, 0, 0, 0.04);
  padding: 1rem;
  border-radius: 1rem;
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.1666;
  color: black;
  margin: 0;
`;
