import  * as signs  from '../assets/icons/astrology/signs'
import { SignType } from '../libs'

export interface IGetSign {
  date: string
  time: string
  lat: number
  lon: number
}

export const signsIcons: {
  [key: number]: any;
} = {
  [SignType.Aries]: signs.AriesIcon,
  [SignType.Taurus]: signs.TaurusIcon,
  [SignType.Gemini]: signs.GeminiIcon,
  [SignType.Cancer]: signs.CancerIcon,
  [SignType.Leo]: signs.LeoIcon,
  [SignType.Virgo]: signs.VirgoIcon,
  [SignType.Libra]: signs.LibraIcon,
  [SignType.Scorpio]: signs.ScorpioIcon,
  [SignType.Sagittarius]: signs.SagittariusIcon,
  [SignType.Capricorn]: signs.CapricornIcon,
  [SignType.Aquarius]: signs.AquariusIcon,
  [SignType.Pisces]: signs.PiscesIcon
};

export default class Astrology {
  async getSigns(data: IGetSign[]) {
    const url = `${process.env.REACT_APP_BACKEND}/api/misc/astro`
    const dataPost = {data}

    try {
      const result = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataPost),
        credentials: 'include'
      })

      return await result.json()
    } catch(err) {
      console.log('Error', err)
    }
  }
}
