import React, {useEffect, useState} from 'react';
import ContextMenuButton from './ContextMenuButton';
import DropDown from './DropDown';
import {MenuItem, contextButtonPositionType} from "./types";

interface IContextMenuProps {
    groupsItems: Array<MenuItem[]>
}

export default React.memo(function ContextMenu(props: IContextMenuProps) {
    const [contextButtonPosition, setContextButtonPosition] = useState<contextButtonPositionType>(null)

    useEffect(() => {
    }, [contextButtonPosition])

    return (
        <>
            <ContextMenuButton onClick={position => setContextButtonPosition(position)}/>
            {
                contextButtonPosition && <DropDown contextButtonPosition={contextButtonPosition}
                                           groupsItems={props.groupsItems}
                                           onClose={() => setContextButtonPosition(null)}/>
            }
        </>
    );
})