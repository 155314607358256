import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { capitalize, copyToClipboard } from '../../../helpers/strings';
import { ArrowRight as ArrowIcon, PinIcon } from '../../../assets/icons/system/system-icons';
import PercentCircle from '../../../components/PercentCircle';
import { Label, Link, Markup, PinButton, Row } from '../../../components/legacy';
import store from '../../../store/Store';
import Actions from './Actions';
import { windowsStore } from '../../../store/Windows';
import PrognosesDay, { WINDOW_ID_PROGNOSES_DAY } from '../prognoses-day';
import WhatToDo from './WhatToDo';


const getLuckText = (percent: number = 0) => {
  if(percent <= 30) {
    return 'Напряженный день';
  }
  if(percent <= 60) {
    return 'Обычный день';
  }
  return 'Гармоничный день';
}

export default function Day(
  {
    extended = false,
    id,
    isPinned = false,
    togglePin = () => {},
  }: {
    extended?: boolean;
    id: string;
    isPinned?: boolean;
    togglePin?: (v: any) => void;
  }
) {
  const link = `/prognoses/day/${id}`;

  const dayData = useMemo(() => {
    return store.prognoses.getDayById(+id);
  }, [id]);

  const label = useMemo(() => {
    if(dayData) {
      const d = dayjs(dayData.period.start, 'DD.MM.YYYY HH:mm:ss');
      if(d.isToday()) {
        return 'Сегодня';
      }
      return capitalize(d.format('dd, D MMM'));
    }
    return null;
  }, [dayData, id]);

  const handleLink = (e: React.SyntheticEvent) => {
    e.preventDefault();
    //window.location.href = link;
    windowsStore.open(
      WINDOW_ID_PROGNOSES_DAY,
      <PrognosesDay dayId={id} />,
      undefined,
      false,
      true,
    )
  }

  if(!dayData) {
    return null;
  }

  return (
    <>
      <Container>
        {!extended &&
            <Row justify="space-between" align="center">
              <Row gap="0.25rem" mb='0'>
                {isPinned && <PinButton><PinIcon /></PinButton>}
                <Label>{label}</Label>
              </Row>
              <Actions
                actions={[
                  {
                    label: 'Скопировать ссылку',
                    action: () => copyToClipboard(`${window.location.host}${link}`),
                  },
                  {
                    label: isPinned ? 'Открепить' : 'Закрепить',
                    action: togglePin,
                  },
                ]}
              />
            </Row>
        }

        <Row mb={extended ? '1rem' : '0'} justify={extended ? 'center' : ''} gap="0.5rem">
          {!extended && <LuckPercent>{dayData.qualityPct || 0}%</LuckPercent>}
          <PercentCircle
            percent={+dayData.qualityPct || 0}
            size={extended ? '86px' : '1.25rem'}
            text={extended ? `${dayData.qualityPct || 0}%` : ''}
            fontSize={extended ? '1.5rem' : ''}
          />
        </Row>

        <Row justify={extended ? 'center' : ''}>
          <DayLuck extended={extended}>{getLuckText(dayData.qualityPct)}</DayLuck>
        </Row>

        <Row>
          <Markup short={!extended}>
            <p>{dayData.description}</p>
          </Markup>
        </Row>

        {!extended && <Link onClick={handleLink}>Читать далее <ArrowIcon /></Link>}
      </Container>
      <WhatToDo extended={extended} id={id} />
    </>
  );
}

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #F3F3F3;
  box-shadow: 0px 3.2px 14px rgba(0, 0, 0, 0.06), 0px 0.6px 1.8px rgba(0, 0, 0, 0.04);
  padding: 1rem;
  border-radius: 1rem;
`;

const LuckPercent = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  color: var(--color-black);
`;

const DayLuck = styled.p<{extended?: boolean}>`
  ${p => p.extended
    ? css`
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.1666;
        color: var(--text-primary);
      `
    : css`
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.5;
        color: var(--text-secondary);
      `
  }
`;
