import { makeAutoObservable } from "mobx";
import api from "../helpers/api";
import { Indi, Logging } from "../libs";
import dayjs from "dayjs";
import store from "./Store";


export class Logger {
	constructor() {
		makeAutoObservable(this)
	}

	id?: number
  sessionsFrom: Record<string, string> = {}

	async createLog(data: Indi.RegistrationLog.PhoneRegistration) {
		const result = await api.createRegistrationLog(data)
		this.id = result.id
	}

	updateLog(data: Indi.RegistrationLog.PhoneRegistration) {
		if (!this.id) return

		const newData: Indi.RegistrationLog.PhoneRegistrationUpdate = {
			id: this.id,
			...data
		}

		api.updateRegistrationLog(newData)
	}

  async createLoggingSession(screen: Logging.Screens) {
    try {
      const userId = store.sessionData?.id

      if (!userId) {
        return
      }

      if (!this.sessionsFrom[screen]) {
        throw Error(`There isn't from for screen ${screen}`)
      }

      const dataPost: Logging.CreateLoggingSession.LoggingSession = {
        userId,
        service: Logging.Services.INDI,
        screen,
        from: this.sessionsFrom[screen],
        to: dayjs().toISOString(),
      }

      await api.createLoggingSession(dataPost)

      this.sessionsFrom = {
        ...this.sessionsFrom,
        [screen]: ''
      }

    } catch(e) {
      console.error(`Create logging session error: ${e}`)
    }
  }

  startLoggingSession(screen: Logging.Screens) {
    const userId = store.sessionData?.id

    if (!userId) {
      return
    }

    this.sessionsFrom = {
      ...this.sessionsFrom,
      [screen]: dayjs().toISOString()
    }
  }

  initLoggingSessionCommon() {
    const userId = store.sessionData?.id

    if (!userId) {
      return
    }

    this.sessionsFrom = {
      ...this.sessionsFrom,
      [Logging.Screens.COMMON]: dayjs().toISOString()
    }

    window.onbeforeunload = () => {
      this.createLoggingSession(Logging.Screens.COMMON)
    }
  }
}
