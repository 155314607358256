import { throttle } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from "styled-components";
import { history } from '../../../../MainRouter';
import Policy, { PolicyTarget } from "../../../../components/Policy";
import Loader from '../../../../components/ui/Loader';
import { Select } from '../../../../components/ui/Select';
import { CustomButton } from '../../../../components/ui/legaсy/BottomButton';
import { gtmSend, searchParams } from '../../../../helpers/url';
import { IGetSign, signsIcons } from '../../../../store/Astrology';
import { IProductCode } from "../../../../store/Payments";
import store from "../../../../store/Store";
import { windowsStore } from '../../../../store/Windows';
import {
	getPaymentIdLS,
	getProductDataLS,
	getPromocodeDataLS,
	getPromocodeLS,
	setConfirmationStepLS,
	setPromocodeLS,
	setPromocodeStateLS
} from "../../../../store/localStorageHelpers";
import { ICard, cards, useCard } from '../../../payment/helpers/tariffs';
import { EMOTIONS, SOLAR, SYNASTRY, WINDOW_ID_CONFIRMATION, WINDOW_ID_EMOTIONS, WINDOW_ID_SOLAR, WINDOW_ID_SUCCESS_PAGE, WINDOW_ID_SYNASTRY, productsWindowData } from '../../helpers/constants';
import Emotions from '../../products/Emotions/Emotions';
import Solar from '../../products/Solar/Solar';
import Synastry from '../../products/Synastry/Synastry';
import { SignResponseType } from "../../products/Synastry/types";
import PersonCard from './PersonCard';
import SuccessPage from './SuccessPage';
import { PartnerLinkTargets, PaymentCardPicker, getUTMCommentForCP, show, toDateTime } from '../../../../libs';
import Promocode from '../../../promocode/Promocode';

import ReRecaptcha, { TReReCaptchaApi } from '../../../../components/ReRecaptcha';

export interface ISynastryData {
  name1: string
  date1: string;
  time1: string;
  lat1: string;
  lon1: string;
  gmt1?: string;
  name2: string
  date2: string;
  time2: string;
  lat2: string;
  lon2: string;
  gmt2?: string;
}

interface IPartnersListProps {
  product: IProductCode
  lastStep: number
  setEdit(value: boolean): void
  setStep(value: number): void
}

export default observer(function Payment(props: IPartnersListProps) {
  const [signs, setSigns] = useState<number[] | null>(null);

	const [card, setCard] = useState(useCard());
  const [price, setPrice] = useState<string>(`${store.products.productData?.price?.value ?? getProductDataLS()?.price.value ?? 0}`);
  const [finalPrice, setFinalPrice] = useState<string>('')

  const [loading ,setLoading] = useState(false);

  const { promoCode, promoData } = store.promocode;
  // const [promoCodeLS, promoDataLS] = [getPromocodeLS(), getPromocodeDataLS()];

  const promocode = () => getPromocodeLS() ?? promoCode;
  const promoId = () => promoData?.id ?? getPromocodeDataLS()?.id;
  const discount = () => {
    const storePromoDiscount = promoData?.actions?.discount!;
    const lsPromoDiscount = getPromocodeDataLS().actions?.discount;
    return (storePromoDiscount > 0 && storePromoDiscount < 1)
      ? storePromoDiscount
      : (lsPromoDiscount > 0 && lsPromoDiscount < 1)
        ? lsPromoDiscount
        : store.partner.partnerDiscount;
  }
  const isFreeCharge = () => {
    const storePromoDiscount = promoData?.actions?.discount!;
    const lsPromoDiscount = getPromocodeDataLS().actions?.discount;
    return storePromoDiscount === 1 || lsPromoDiscount === 1;
  }

  const [isWaiting, setIsWaiting] = useState(false);
  const freePayTimer = useRef<any>(null);

  const sp = searchParams();
  const update = sp.get('update') === 'true';
  const purchasedProductId = sp.get('purchasedProductId');
  const userId = store.sessionData?.id
  const headers = store.isAuth ? undefined : {tentative: store.auth.token}
  const email = store.payments.email || '';
	const partner = store.profile.partner;

  const captchaRef = React.createRef<TReReCaptchaApi>();

  useEffect(() => {
    setPartnerDiscount(props.product)
  }, [])

  useEffect(() => {
    // ждем, когда примонтируется captchaRef, что бы в getPrice получить captchaToken 
    if (!captchaRef.current) return;

    setIsWaiting(true);

    getSigns(getData(props.product));
    getPrice(props.product, promocode(), card);

  }, [card, store.promocode.promoData, store.partner.partnerDiscount, captchaRef.current]);

  const getData = (product: IProductCode): any[] => {
    switch (product) {
      case SYNASTRY:
        return store.synastry.getPartners()
      case EMOTIONS:
        return [store.emotions.getPerson()]
      case SOLAR:
        return [store.solar.getPerson()]
    }
  }

  const getSigns = async (data: any[]) => {

    const getSignsData: IGetSign[] = []

    data.forEach((item, index) => {
      const { date, time } = toDateTime(item.dateTime);
      const lon =  item.place!.lon
      const lat = item.place!.lat
      getSignsData.push({date, time, lon, lat})
    })

    const result = await store.astrology.getSigns(getSignsData)
    const arrSigns = result.map((item: SignResponseType) => ({
      key: item.birthSign,
      icon: signsIcons[item.birthSign]
    }))

    setSigns(arrSigns)
  }

  const getFreeCharge = (product: IProductCode) => {
    const data = {
      paymentId: getPaymentIdLS() ?? store.payments.paymentId,
      promocodeTitle: promocode,
      language: 'ru',
      promoId: promoId(),
      hash: store.auth.hash,
      forUserEmail: email,
      comment: getUTMCommentForCP(),
    }

    switch (product) {
      case SYNASTRY:
        store.synastry.freeChargeSynastry({
          ...data,
          ...store.synastry.getPartnersData(),
        }, headers)
          .then(() => success())
        break
      case EMOTIONS:
        store.emotions.freeChargeEmotions({
          ...data,
          ...store.emotions.getPersonData(),
        }, headers)
          .then(() => success())
        break
      case SOLAR:
        store.solar.freeChargeSolar({
          ...data,
          ...store.solar.getPersonData(),
        })
          .then(() => success())
        break
    }
  }

  const getPrice = async (product: IProductCode, promo: string, card: ICard) => {
    setIsWaiting(true);
    
    captchaRef.current?.reset();
    const captchaToken = await captchaRef.current?.executeAsync();
    const result = await store.payments.getPaymentConfig(product, promo, true, card.country, undefined, undefined, getData(product), captchaToken);

    if (result?.options.amount) {
      setPrice(`${result?.options.amount} ${card.symbol}`);

      let price = Math.round(result?.options.amount * (1 - discount()) + Number.EPSILON);
      if(card.id !== 'ru') price = Math.round((result?.options.amount * (1 - discount())) * 100) / 100;
      setFinalPrice(`${price} ${card.symbol}`)

      setIsWaiting(false);
    }
  }

  const setPartnerDiscount = (type: string) => {
    let partnerTarget: PartnerLinkTargets | undefined = undefined

    switch(type) {
      case SYNASTRY: {
        partnerTarget = PartnerLinkTargets.IndiProduct
        break
      }
    }

    partnerTarget && store.partner.setPartnerDiscount(partnerTarget, partner)
  }

  const goToSuccessPage = (trial?: boolean) => {
    if (trial) {
      windowsStore.open(WINDOW_ID_SUCCESS_PAGE, <SuccessPage mode={'result'}/>)
      return
    }

    windowsStore.open(WINDOW_ID_SUCCESS_PAGE, <SuccessPage mode={'payment'}/>)
  }

  const getPaymentData = (product: IProductCode) => {
    switch (product) {
      case SYNASTRY:
        return store.synastry.getPartnersData()
      case EMOTIONS:
        return store.emotions.getPersonData()
      case SOLAR:
        return store.solar.getPersonData()
    }
  }

  const resetData  = (product: IProductCode) => {
    switch (product) {
      case SYNASTRY:
        store.synastry.resetSynastry()
        break
      case EMOTIONS:
        store.emotions.resetEmotions()
        break
      case SOLAR:
        store.solar.resetSolar()
    }
  }

  const success = () => {
    let attempt = 0;

    setLoading(true)

    setPromocodeLS('');
    setPromocodeStateLS('');

    if (freePayTimer.current) {
      clearTimeout(freePayTimer.current);
    }

    setIsWaiting(true);

    const send = () => {
      freePayTimer.current = setTimeout(() => {
        store.products.checkProduct(getPaymentIdLS() ?? store.payments.paymentId)
          .then((res) => {
            if (res.isPaid) {
              history.push('/')
              windowsStore.close(WINDOW_ID_CONFIRMATION)

              switch (props.product) {
                case SYNASTRY:
                  windowsStore.open(WINDOW_ID_SYNASTRY, <Synastry token={res.purchasedProductId}/>, undefined, true)
                  break
                case EMOTIONS:
                  windowsStore.open(WINDOW_ID_EMOTIONS, <Emotions token={res.purchasedProductId}/>, undefined, true)
                  break
                case SOLAR:
                  windowsStore.open(WINDOW_ID_SOLAR, <Solar token={res.purchasedProductId}/>, undefined, true)
                  break
              }

              setLoading(false)
              setIsWaiting(false)

              gtmSend({'event': `${productsWindowData[props.product].gtm}_payment-success_Payment`, 'user_id': userId ? userId : ''})
            } else {
              if (attempt === 5) {
                const id = store.promocode.promoData?.id ?? getPromocodeDataLS()?.id;
                id && store.promocode.cancelPromocode(id, undefined, headers);
                setIsWaiting(false);
                show({
                  type: 'error',
                  text: "Произошла ошибка. Обратитесь в службу поддержки support@chronos.mg",
                  timeout: 5000
                });
              } else {
                attempt++
                send()
              }
            }
          })
      }, 1000)
    }

    if (store.isAuth) {
      send()
    } else {
      goToSuccessPage()
    }

    setTimeout(() => {
      store.payments.resetPaymentConfig()
      store.promocode.resetPromocode()
      store.partner.resetPartner()
      resetData(props.product)
      setConfirmationStepLS('')
    }, 3000)
  }

  const submit = async () => {
    try {
      //setIsWaiting(true);
      const promo = promocode();

      store.payments.setProductCode(props.product);
      
      captchaRef.current?.reset();
      const captchaToken = await captchaRef.current?.executeAsync();

      if (!captchaToken) {
        console.log('Капча пустая');
      };
      console.log(999, captchaRef.current, captchaToken)
			const result = await store.payments.getPaymentConfig(props.product, promo, update, card.id, undefined, headers, getPaymentData(props.product), captchaToken);

			if (!result) return;

      if (promo) {
        await store.promocode.usePromocode(promo, undefined, headers, email)
      }

      if (isFreeCharge()) {
        getFreeCharge(props.product)
      } else {
        const dataPost = {
          ...getPaymentData(props.product),
          hash: store.auth.hash,
          forUserEmail: email,
          comment: getUTMCommentForCP(),
        }

        store.payments.paymentCloud(dataPost, success, undefined, update, purchasedProductId ? +purchasedProductId : undefined, headers)
          .then(() => {
            gtmSend({'event': `${productsWindowData[props.product].gtm}_payment-start_Payment`, 'user_id': userId ? userId : ''})
          })
      }

      if (store.synastry.trialSynastry) {
        gtmSend({'event': 'sinastry_payment-start_without-trial', 'user_id': userId ? userId : ''})
      } else {
        gtmSend({'event': 'sinastry_payment-start_after-trial', 'user_id': userId ? userId : ''})
      }
    } catch (err) {
      console.error(err);
      const id = promoId();
      id && store.promocode.cancelPromocode(id, undefined, headers)
    } finally {
      setIsWaiting(false);
    }
  }

  const throttleSubmit = React.useCallback(throttle(submit, 3000), [success, card]);

  const getTrial = async (product: string) => {
    switch (product) {
      case SYNASTRY:
        const dataPost = {
          ...store.synastry.getPartnersData(),
          hash: store.auth.hash,
          forUserEmail: email,
          comment: getUTMCommentForCP(),
        }
        const result = await store.synastry.getTrialSynastry(dataPost, headers)

        if (result?.paymentId) {
          if (store.isAuth) {
            store.payments.setPaymentId(result.paymentId)
            success()
            store.synastry.resetSynastry()
          } else {
            goToSuccessPage(true)
          }

        }

        break
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Container>
      <PersonsCards>
        {getData(props.product).map((item, index) => {
          return <PersonCard
            key={`person${index + 1}_${item.dateTime}`}
            signs={signs}
            index={index}
            data={item}
            product={props.product}
            hasTitle
            setStep={props.setStep}
            setEdit={props.setEdit}
          />
        })}
      </PersonsCards>

      <Footer>

        <CardSelectorContainer>
          <Select title="Карта для оплаты" options={cards} value={card} onSelect={setCard} />
        </CardSelectorContainer>

        <Promocode 
          promo={promocode()} 
          headers={headers} 
          disabled={isWaiting}
          product={props.product}
        />

        <Total>
          <div>Итого к оплате</div>
          <div>
            {isFreeCharge()
            ? <>
                <span className={'oldPrice'}>{price}</span>
                <span className={'finalPrice'}>0</span>
              </>
            : <>
                <span className={'oldPrice'}>{discount() > 0 ? price : ''}</span>
                <span className={'finalPrice'}> {parseFloat(price) > 0 ? finalPrice : ''}</span>
              </>}
          </div>
        </Total>

        <CustomButton
          fixedPosition={false}
          onClick={submit}
          color={'green'}
          style={{opacity: 1}}
          inModal={isMobile}
          disabled={isWaiting}
          bottom={isMobile ? 14 : -20}
        >
          <ButtonContent>
            <Price>
              {isFreeCharge()
                ? <span>Получить бесплатно</span>
                : <>
                    <span>Получить за</span>
                    <span className={'oldPrice'}>{discount() > 0 ? price : ''}</span>
                  <span className={'finalPrice'}> {parseFloat(price) > 0 ? finalPrice : ''}</span>
                 </>}
            </Price>
          </ButtonContent>
        </CustomButton>

        {props.product === SYNASTRY && <CustomButton
          fixedPosition={false}
          onClick={() => getTrial(props.product)}
          color={'rgba(0, 0, 0, 0.04)'}
          style={{opacity: 1, color: 'var(--text-primary)', fontWeight: 500}}
          inModal={isMobile}
          disabled={isWaiting}
          bottom={isMobile ? 14 : -20}
        >
          <StyledButtonContent>Пробная версия</StyledButtonContent>
        </CustomButton>}

        <Policy country={card.id} fixedPosition={false} />
      </Footer>

      <ReRecaptcha size="invisible" badge="bottomleft" ref={captchaRef}/>
    
    </Container>
  );
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-bottom: 2.5rem;
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--color-white);
  height: 2.3rem;
  font-size: 0.8rem;
  letter-spacing: 0.2px;
  margin-top: 0.1rem;

  & svg {
    margin-left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
`
export const Price = styled.span`
  span {
    margin-right: 0.2rem;
  }

  .oldPrice {
    text-decoration: line-through;
  }
`

const PaymentCardPickerContainer = styled.div`
  height: 180px;
`

export const PaymentWarning = styled.div`
  font-weight: 400;
  text-align: left;
  color: var(--orange-color);
`

const PersonsCards = styled.div`
`

const Footer = styled.div`
  width: calc(100%);
  max-width: 550px;
  position: relative;
`

const CustomPaymentCardPicker = styled(PaymentCardPicker)`
  margin-bottom: 0.5rem;

  .switcher_info {
    color: var(--text-secondary);

    svg {
      color: var(--text-third);
    }
  }

  .switcher_bg {
    border-radius: 3.313rem;
  }

  .switcher_button {
    border-radius: 3.438rem;
  }
`

const Total = styled.div`
  display: flex;
  justify-content: space-between;

  color: var(--text-secondary);

  .oldPrice {
    color: var(--text-third);
    font-size: 1.5rem;
    text-decoration: line-through;
    margin-right: 0.5rem;
  }

  .finalPrice {
    color: var(--color-green);
    font-size: 1.5rem;
  }
`

const StyledButtonContent = styled(ButtonContent)`
  color: var(--text-primary);
`

const CardSelectorContainer = styled.div`
  position: relative;
  margin: 0.5rem 0 1rem;
`;
