import styled from "styled-components";
import { FlexContainer } from "../layout/elements";
import { Cross } from "../../assets/icons/system/system-icons";

export const CloseButton = ({
	top,
	right,
  onClose
}: {
	top: number
	right: number
  onClose: () => void;
}) => {
  return <Container top={top} right={right} onClick={onClose}>
    <Cross />
  </Container>
}

const Container = styled(FlexContainer)<{top: number, right: number}>`
	align-items: center;
  justify-content: center;

  width: 1.875rem;
  height: 1.875rem;
  cursor: pointer;
  border-radius: 50%;
  background: var(--element-background-white-10);

	position: absolute;
  top: ${p => `${p.top}px`};
	right: ${p => `${p.right}px`};;

  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`
